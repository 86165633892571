<div bsModal #confirmationModal="bs-modal" class="modal fade" role="dialog" aria-hidden="true" [config]="settings">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">

      <div class="modal-header">
        <h5 class="modal-title pull-left"><strong>{{ title | translate }}</strong></h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <h5 class="text-center">{{ message | translate }}</h5>
        <div class="modal-footer">
          <input 
          id="ModalInput"
          *ngIf="isInputForm"
          class="form-control"
          >
          <br>
          <button type="button"
            class="pull-left btn btn-success btn-lg"
            (click)="confirm()"
            >{{ 'COMMON.BTN_YES' | translate }}</button>
          <button type="button"
            class="btn btn-danger btn-lg"
            (click)="cancel()"
          >{{ 'COMMON.BTN_NO' | translate }}</button>
        </div>
      </div>

    </div>
  </div>
</div>
