import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { PersonService, ViewService } from '../../services'
import { UserFormGroup } from '../../interfaces'

@Component({
  selector: 'user-panel',
  templateUrl: './user.panel.html',
  styleUrls: ['./user.panel.styl'],
  providers: [TranslatePipe]
})

export class UserPanel implements OnChanges {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'form' has no initializer and is not defi... Remove this comment to see the full error message
  @Input() form: UserFormGroup
  @Input() isCreate: boolean = false
  @Input() dobRequired: boolean = false

  public contactTypes: string []
  public contactTags: string []

  public locale: string



  // @ts-expect-error ts-migrate(2564) FIXME: Property 'idFilter' has no initializer and is not ... Remove this comment to see the full error message
  public idFilter: string
  public idSource: Observable<any>

  constructor (
    private viewService: ViewService,
    personService: PersonService
  ) {
    this.locale = viewService.getCurrentUser().locale
    this.contactTypes = biz.values(biz.CONTACT_TYPES)
    this.contactTags = biz.values(biz.CONTACT_TAGS)
    this.idSource = this.viewService.newTypeaheadSource(personService, '_id', (observer: any) => { observer.next(this.idFilter) }, undefined, true)
  }

  public ngOnChanges (changes: SimpleChanges) {


    if (changes.form) {
      // Update the filters when form is changed, otherwise ngModel will overlap with
      // formControlName and bad thing will happen
      this.idFilter = this.form.value._id
    }
  }

  public onUserChosen (selection: any) {
    const user = selection.item
    this.idFilter = user._id
    this.form.patchValue(user)
  }

/*
  public removeCurrentDivision () {
    this.viewService.askConfirmation('STUDENT.REMOVE_DIVISION')
    .subscribe(result => {
      if (result) {
        const divisionId = this.form.controls.division.value._id
        const studentId = this.form.controls._id.value

        this.divisionService.removeStudent(divisionId, studentId)
        .subscribe(
          () => {
            this.viewService.toastr.successT('COMMON.MSG_UPDATE_OK')
            this.form.controls.division.setValue(undefined)
            this.form.updateValueAndValidity()
          },
          this.viewService.handleError
        )
      }
    })
  }
*/
}
