import { HttpParams } from '@angular/common/http'

import * as _ from 'lodash-es'

export class QueryString {
  public page: number
  public limit: number
  public sort: any
  public conditions: any
  public populate: string | any
  public select: string
  public q: string

  public constructor (data: any = {}) {
    this.page = data.page
    this.sort = data.sort
    this.limit = data.limit
    this.conditions = data.conditions
    this.populate = data.populate
    this.select = data.select
    this.q = data.q
  }

  public toSearchParams (): HttpParams {
    let searchParams = new HttpParams()

    if (!_.isNil(this.page)) {
      searchParams = searchParams.set('page', this.page + '')
    }
    if (!_.isNil(this.limit)) {
      searchParams = searchParams.set('limit', this.limit + '')
    }
    if (this.q) {
      searchParams = searchParams.set('q', this.q)
    }
    if (this.select) {
      searchParams = searchParams.set('select', this.select)
    }
    if (!_.isEmpty(this.populate)) {
      const val = _.isString(this.populate) ? '' + this.populate : JSON.stringify(this.populate)
      searchParams = searchParams.set('populate', val)
    }
    if (!_.isEmpty(this.sort)) {
      searchParams = searchParams.set('sort', JSON.stringify(this.sort))
    }

    const conditions = {}
    _.forOwn(this.conditions, (value: any, key: any) => {
      if (!_.isNil(value) && ((!_.isString(value) && !_.isArray(value) && !_.isPlainObject(value)) || !_.isEmpty(value))) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        conditions[key] = value
      }
    })
    if (!_.isEmpty(conditions)) {
      searchParams = searchParams.set('conditions', JSON.stringify(conditions))
    }

    return searchParams
  }

  public hasFilters () {
    return !_.isEmpty(this.conditions) || !_.isEmpty(this.q) || !_.isEmpty(this.select) || !_.isNil(this.page) || !_.isNil(this.limit)
  }

}
