import { FormBuilder, FormGroup, Validators } from '@angular/forms'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { PersistentModel } from './persistent-model'
import { Term } from './term'
import { Stage } from './stage'
import { Grade } from './grade'

export interface Division extends PersistentModel {
  name: string
  tenant: string
  term: Term|string
  stage: Stage|string
  grade: Grade|string
  shift: string
}

export class DivisionValidator {

  public static newFormGroup (): FormGroup {
    const fb = new FormBuilder()

    const formGroup = fb.group({
      name: fb.control('', Validators.required),
      grade: fb.control('', Validators.required),
      shift: fb.control(biz.SCHOOL_SHIFTS.MORNING, Validators.required)
    })

    return formGroup
  }
}
