var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TranslatePipe } from '@ngx-translate/core';
import { PrefixedTranslatePipe } from './prefixed-translate.pipe';
var RecipientTypePipe = /** @class */ (function (_super) {
    __extends(RecipientTypePipe, _super);
    function RecipientTypePipe(translatePipe) {
        return _super.call(this, translatePipe, 'RECIPIENT_TYPES.') || this;
    }
    return RecipientTypePipe;
}(PrefixedTranslatePipe));
export { RecipientTypePipe };
