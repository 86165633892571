import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

@Injectable()
export class StoreService {
  private storage: any

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    // Client only code.
    if (isPlatformBrowser(this.platformId)) {
      this.storage = localStorage
    } else {
      this.storage = new InMemoryStorage()
    }
  }

  public clear () {
    this.storage.clear()
  }

  public setItem (key: string, item: string) {
    // if (typeof item != null) {
    //   item = typeof item == 'string' ? item : JSON.stringify(item)
    // }
    this.storage.setItem(key, item)
  }

  public setJson (key: string, item: any) {
    this.setItem(key, item && JSON.stringify(item))
  }

  public getItem (key: string): string {
    return this.storage.getItem(key)
  }

  public getJson (key: string): any {
    const value = this.getItem(key)
    return value ? JSON.parse(value) : value
  }

  public removeItem (key: string) {
    return this.storage.removeItem(key)
  }
}

class InMemoryStorage {
  private map: any = {}

  public setItem (key: string, value: string) {
   this.map[key] = value
  }

  public getItem (key: string): string {
   return this.map[key]
  }

  public removeItem (key: string): string {
    const value = this.map[key]
    delete this.map[key]
    return value
  }

  public clear () {
    this.map = {}
  }
}
