var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnChanges, SimpleChanges } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { Column } from '../../models';
import { ViewService, DivisionService } from '../../services';
import { SearchPanel } from '../../interfaces';
import { SchoolShiftPipe } from '../../pipes';
var StudentDivisionsTab = /** @class */ (function (_super) {
    __extends(StudentDivisionsTab, _super);
    function StudentDivisionsTab(viewService, service, translatePipe) {
        var _this = _super.call(this, viewService, 'guardian') || this;
        _this.service = service;
        _this.translatePipe = translatePipe;
        _this.isRemoving = false;
        return _this;
    }
    // @ts-expect-error ts-migrate(6133) FIXME: 'changes' is declared but its value is never read.
    StudentDivisionsTab.prototype.ngOnChanges = function (changes) {
        if (this.studentId) {
            this.createColumns();
            this.updateFilter();
        }
    };
    StudentDivisionsTab.prototype.onSelect = function (selected) {
        return this.viewService.goToDetail('divisions', 'division', selected);
    };
    // @ts-expect-error ts-migrate(6133) FIXME: 'params' is declared but its value is never read.
    StudentDivisionsTab.prototype.fetch = function (params) {
        return Observable.of(this.divisions);
    };
    StudentDivisionsTab.prototype.createColumns = function () {
        var canRemove = this.viewService.isCurrentTermSelected();
        var actions = [{
                class: 'fa-times action-icon-danger',
                onClick: this.onRemove.bind(this),
                visible: function () { return canRemove; }
            }];
        this.columns = [
            Column.forClick('shift', 'DIVISION.SHIFT', this.onSelect.bind(this), false, new SchoolShiftPipe(this.translatePipe), 90, 90, 'defaultLabelCell'),
            Column.forClick('name', 'DIVISION.NAME', this.onSelect.bind(this)),
            Column.forActions(actions, 50)
        ];
    };
    StudentDivisionsTab.prototype.onRemove = function (row) {
        var _this = this;
        this.viewService.askConfirmation('COMMON.MSG_CONFIRM_REMOVE')
            .subscribe(function (confirmed) {
            if (confirmed) {
                _this.isRemoving = true;
                _this.service.removeStudent(row._id, _this.studentId)
                    .finally(function () { return _this.isRemoving = false; })
                    .subscribe(
                // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
                function (data) {
                    _this.viewService.toastr.successT('COMMON.MSG_OPERATION_OK');
                    var index = _this.divisions.findIndex(function (division) { return division._id === row._id; });
                    _this.divisions.splice(index, 1);
                }, _this.viewService.handleError);
            }
        });
    };
    return StudentDivisionsTab;
}(SearchPanel));
export { StudentDivisionsTab };
