var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Observable } from 'rxjs/Observable';
import * as XLSX from 'xlsx';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { QueryString, Column } from '../../models';
import { ViewService, DivisionService, StudentService } from '../../services';
import { UserValidator, CrudService } from '../../interfaces';
import { UsernamePipe } from '../../pipes';
import { StudentsTab } from '../students-tab';
var DivisionStudentsTab = /** @class */ (function (_super) {
    __extends(DivisionStudentsTab, _super);
    function DivisionStudentsTab(viewService, service, studentService, usernamePipe) {
        var _this = _super.call(this, viewService) || this;
        _this.service = service;
        _this.studentService = studentService;
        _this.usernamePipe = usernamePipe;
        _this.mode = 'search';
        _this.isSubmitting = false;
        _this.isRemoving = false;
        _this.isSaveAllowed = false;
        _this.loadStudents = function (filter) {
            // retain the scope to get access to instance properties
            // https://www.npmjs.com/package/ng2-tag-input#api-for-taginputdropdowncomponent
            var params = new QueryString({
                limit: 20,
                q: filter,
                sort: {
                    'name.last': 1,
                    'name.first': 1
                },
                populate: 'division'
            });
            return _this.studentService.search(params)
                .map(function (result) {
                return result.list.map(function (student) {
                    student.fullName = _this.usernamePipe.transform(student.name);
                    return student;
                });
            }, _this.viewService.handleError);
        };
        _this.mapper = new StudentMapper(viewService.translate);
        return _this;
    }
    DivisionStudentsTab.prototype.switchToInclusionMode = function () {
        this.students = [];
        this.mode = 'inclusion';
    };
    DivisionStudentsTab.prototype.switchToEditionMode = function (student) {
        if (student === void 0) { student = {
            gender: biz.GENDERS.FEMALE
        }; }
        this.form = UserValidator.newFormGroup();
        this.form.patchValue(student);
        this.isCreate = !!student;
        var permission = this.isCreate ? 'create' : 'update';
        this.isSaveAllowed = this.viewService.isAllowed('student', permission);
        this.mode = 'edition';
    };
    DivisionStudentsTab.prototype.switchToSearchMode = function () {
        this.createColumns();
        if (this.form) {
            this.form.reset();
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'UserFo... Remove this comment to see the full error message
            this.form = undefined;
        }
        this.updateFilter();
        this.mode = 'search';
    };
    DivisionStudentsTab.prototype.submit = function () {
        var _this = this;
        if (!this.isSaveAllowed) {
            console.warn('User not allowed to save changes');
            return;
        }
        else if (!this.form || !this.form.valid) { // be extra sure
            return this.viewService.toastr.warningT('COMMON.MSG_INVALID_FORM');
        }
        this.isSubmitting = true;
        var onSuccess = function () { _this.switchToSearchMode(); };
        var onFinally = function () { _this.isSubmitting = false; };
        var model = this.form.value;
        var service = new StudentWrapperService(this.divisionId, this.service);
        var studentId = !this.isCreate && model._id;
        this.viewService.save(studentId, service, this.form, onSuccess, onFinally, model);
    };
    DivisionStudentsTab.prototype.include = function () {
        var _this = this;
        if (!this.students || !this.students.length) {
            return;
        }
        this.isSubmitting = true;
        this.service.includeStudents(this.divisionId, this.students)
            .finally(function () { _this.isSubmitting = false; })
            // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
            .subscribe(function (data) {
            _this.viewService.toastr.successT('DIVISION.MSG_ADD_STUDENT_OK');
            _this.switchToSearchMode();
        }, this.viewService.handleError);
    };
    DivisionStudentsTab.prototype.export = function () {
        // https://github.com/SheetJS/js-xlsx/blob/master/demos/angular2/src/app/sheetjs.component.ts
        // generate workbook
        var wb = XLSX.utils.book_new();
        // prepare data to be written in the exported file
        var output = this.mapper.mapAll(this.rows);
        // generate worksheet and add it to workbook
        var ws = XLSX.utils.aoa_to_sheet(output);
        XLSX.utils.book_append_sheet(wb, ws, 'Listado');
        // save to file
        // https://github.com/SheetJS/js-xlsx/blob/9866dfc010338394e4cfcd33a9fbc15dae017ee5/types/doc.ts#L29
        var wopts = { bookType: 'xlsx', type: 'array', Props: { Author: 'APESA', Company: 'APESA' } };
        XLSX.writeFile(wb, "Alumnos.xlsx", wopts);
    };
    DivisionStudentsTab.prototype.fetch = function (params) {
        return this.service.getStudents(this.divisionId, params);
    };
    DivisionStudentsTab.prototype.createColumns = function () {
        _super.prototype.createColumns.call(this);
        var canRemove = this.viewService.isCurrentTermSelected();
        var actions = [{
                class: 'fa-times action-icon-danger',
                onClick: this.onRemove.bind(this),
                visible: function () { return canRemove; }
            }];
        this.columns.push(Column.forActions(actions, 50));
    };
    DivisionStudentsTab.prototype.onRemove = function (row) {
        var _this = this;
        this.isRemoving = true;
        this.service.removeStudent(this.divisionId, row._id)
            .finally(function () { return _this.isRemoving = false; })
            // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
            .subscribe(function (data) {
            _this.viewService.toastr.successT('COMMON.MSG_OPERATION_OK');
            var index = _this.rows.findIndex(function (student) { return student._id === row._id; });
            _this.rows.splice(index, 1);
            _this.count = _this.rows.length;
        }, this.viewService.handleError);
    };
    return DivisionStudentsTab;
}(StudentsTab));
export { DivisionStudentsTab };
var StudentWrapperService = /** @class */ (function (_super) {
    __extends(StudentWrapperService, _super);
    function StudentWrapperService(id, service) {
        var _this = 
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        _super.call(this, null, '') || this;
        _this.id = id;
        _this.service = service;
        return _this;
    }
    StudentWrapperService.prototype.create = function (object) {
        return this.service.addStudent(this.id, object);
    };
    // @ts-expect-error ts-migrate(6133) FIXME: 'id' is declared but its value is never read.
    StudentWrapperService.prototype.update = function (id, object) {
        throw new Error('This method should never be called');
        // return this.service.updateStudent(this.id, id, object)
    };
    return StudentWrapperService;
}(CrudService));
var StudentMapper = /** @class */ (function () {
    function StudentMapper(translateSvc) {
        var _this = this;
        var headerCodes = [
            'USER.ID',
            'USER.LAST_NAME',
            'USER.FIRST_NAME',
            'TOPIC_TYPES.late',
            'TOPIC_TYPES.absence',
            'TOPIC_TYPES.justified',
            'TOPIC_TYPES.early_pick_up',
        ];
        Observable.from(headerCodes)
            .mergeMap(function (code) { return translateSvc.getTranslate(code); })
            .toArray()
            .subscribe(function (headers) { return _this.headers = headers; });
    }
    StudentMapper.prototype.map = function (v) {
        return [
            v._id,
            v.name.last,
            v.name.first,
            v.membership.stats.late,
            v.membership.stats.absence,
            v.membership.stats.justified,
            v.membership.stats.early_pick_up,
        ];
    };
    StudentMapper.prototype.mapAll = function (data) {
        var _this = this;
        var output = [this.headers];
        data.forEach(function (item) {
            output.push(_this.map(item));
        });
        return output;
    };
    return StudentMapper;
}());
