import { PersistentModel } from './persistent-model'


// @ts-expect-error ts-migrate(6133) FIXME: 'ValidatorFn' is declared but its value is never r... Remove this comment to see the full error message
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms'
import { AppValidators } from '../validators'
import { Division } from './division'
import { DateOnly } from './date-only'

export class Album implements PersistentModel {


  // @ts-expect-error ts-migrate(2564) FIXME: Property '_id' has no initializer and is not defin... Remove this comment to see the full error message
  _id: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'title' has no initializer and is not def... Remove this comment to see the full error message
  title: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'division' has no initializer and is not ... Remove this comment to see the full error message
  division: Division|string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'date' has no initializer and is not defi... Remove this comment to see the full error message
  date: DateOnly


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'attachments' has no initializer and is n... Remove this comment to see the full error message
  attachments: any[]
}

export class AlbumFormGroup extends FormGroup {
  constructor () {
    const fb = new FormBuilder()
    super({
      title: fb.control('', [ Validators.required ]),
      division: fb.control('', [ Validators.required ]),
      // date: fb.control('', [ Validators.required ]),
      attachments: fb.control('', [ AppValidators.nonEmpty() ])
    })
  }

  public patchValue (value: {
    [key: string]: any;
  }, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }): void {
    super.patchValue(value, options)

    // This is needed to initialize the array in case it's not provided by the model

    if (!this.controls.attachments.value) {

      this.controls.attachments.setValue([])
    }
  }

  public addAttachment (att: any) {

    this.controls.attachments.value.push(att)

    this.controls.attachments.updateValueAndValidity()
  }

  public removeAttachmentAt (i: any) {

    this.controls.attachments.value.splice(i, 1)

    this.controls.attachments.updateValueAndValidity()
  }
}

export class AlbumValidator {
  public static newFormGroup (): AlbumFormGroup {
    return new AlbumFormGroup()
  }
}
