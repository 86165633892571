<tag-input *ngIf="!readonly || innerValue.length"
    [(ngModel)]="innerValue"
    [onTextChangeDebounce]="500"
    [onlyFromAutocomplete]="true"
    (onAdd)="onSelectionChange()"
    (onRemove)="onSelectionChange()"
    (onBlur)="onInputBlurred($event)"
    (onFocus)="onInputFocused($event)"
    [clearOnBlur]="true"
    [identifyBy]="'id'"
    [displayBy]="'itemName'"
    [disable]="readonly"
    placeholder=""
    secondaryPlaceholder=""
    theme="nexedu-tags">

  <ng-template let-item="item" let-index="index">
    <div class="tag__wrapper tag__water" [ngClass]="getItemClass(item)">
      <div class="tag__text inline"
          spellcheck="false"
          [attr.title]="item.itemName">
        {{ item.itemName }}
      </div>
      <delete-icon
          aria-label="Remove tag"
          role="button"
          (click)="removeItem(index)"
          *ngIf="!readonly">
      </delete-icon>
    </div>
  </ng-template>

  <tag-input-dropdown
      [autocompleteObservable]="source"
      [appendToBody]="true"
      [identifyBy]="'id'"
      [displayBy]="'itemName'"
      [showDropdownIfEmpty]="true"
      [matchingFn]="matchingFn">
  </tag-input-dropdown>
</tag-input>

<tag-input *ngIf="readonly && !innerValue.length"
    [(ngModel)]="tenantValue"
    [onlyFromAutocomplete]="true"
    [displayBy]="'itemName'"
    [identifyBy]="'id'"
    [disable]="true"
    placeholder=""
    secondaryPlaceholder=""
    theme="nexedu-tags">

  <ng-template let-item="item" let-index="index">
    <div class="tag__wrapper tag__water tag__wrapper_4">
      <div class="tag__text inline"
          spellcheck="false"
          [attr.title]="'school' | recipientType">
        {{ 'school' | recipientType }}
      </div>
    </div>
  </ng-template>
</tag-input>
