import { AbstractControl, ValidatorFn } from '@angular/forms'

function mismatchedFields (controlPath1: string, controlPath2: string): ValidatorFn {
  return (group: AbstractControl): {[key: string]: any} => {


    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const value1 = group.get(controlPath1).value


    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const value2 = group.get(controlPath2).value

    if (value1 !== value2) {
      return { mismatchedFields: true }
    }



    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type '{ [key... Remove this comment to see the full error message
    return undefined
  }
}

export default mismatchedFields
