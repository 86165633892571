var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnChanges, SimpleChanges } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { ViewService, StudentService } from '../../services';
import { SearchPanel } from '../../interfaces';
var StudentAttendanceTab = /** @class */ (function (_super) {
    __extends(StudentAttendanceTab, _super);
    function StudentAttendanceTab(viewService, service, 
    // @ts-expect-error ts-migrate(6138) FIXME: Property 'translatePipe' is declared but its value... Remove this comment to see the full error message
    translatePipe) {
        var _this = _super.call(this, viewService, 'student', 'attendance') || this;
        _this.service = service;
        _this.translatePipe = translatePipe;
        return _this;
    }
    // @ts-expect-error ts-migrate(6133) FIXME: 'changes' is declared but its value is never read.
    StudentAttendanceTab.prototype.ngOnChanges = function (changes) {
        if (this.studentId) {
            this.updateFilter();
        }
    };
    // @ts-expect-error ts-migrate(6133) FIXME: 'selected' is declared but its value is never read... Remove this comment to see the full error message
    StudentAttendanceTab.prototype.onSelect = function (selected) {
        // return this.viewService.goToDetail('divisions', 'division', selected)
    };
    // @ts-expect-error ts-migrate(6133) FIXME: 'params' is declared but its value is never read.
    StudentAttendanceTab.prototype.fetch = function (params) {
        return this.service.getAttendance(this.studentId)
            .map(function (results) {
            var _a;
            var groups = [];
            for (var i = 0; i < results.list.length; i++) {
                var topic = results.list[i];
                if (!i || topic.date.month !== results.list[i - 1].date.month) {
                    groups.push({
                        dateTime: topic.dateTime,
                        topics: [topic],
                        totals: (_a = {},
                            _a[biz.TOPIC_TYPES.LATE_ARRIVAL] = 0,
                            _a[biz.TOPIC_TYPES.ABSENCE] = 0,
                            _a[biz.TOPIC_TYPES.JUSTIFIED_ABSENCE] = 0,
                            _a),
                        open: !i
                    });
                }
                else {
                    groups[groups.length - 1].topics.push(topic);
                }
                groups[groups.length - 1].totals[topic.type]++;
            }
            return groups;
        });
    };
    return StudentAttendanceTab;
}(SearchPanel));
export { StudentAttendanceTab };
