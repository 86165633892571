<tag-input
    [(ngModel)]="innerValue"
    [onlyFromAutocomplete]="true"
    [identifyBy]="identifyBy"
    [displayBy]="displayBy"
    (onAdd)="onTagsChange()"
    (onRemove)="onTagsChange()"
    (onBlur)="onBlur()"
    placeholder="+"
    secondaryPlaceholder="..."
    [clearOnBlur]="true"
    [disable]="readonly"
    [attr.contenteditable]="isTouchDevice()"
    theme='bootstrap'>
    <ng-template let-item="item">
      <ng-container *ngIf="itemTemplate">
        <ng-container *ngTemplateOutlet="itemTemplate; context: item"></ng-container>
      </ng-container>
      <ng-container *ngIf="!itemTemplate">
        {{ item[displayBy] }}
      </ng-container>
    </ng-template>
  <tag-input-dropdown
      [showDropdownIfEmpty]="false"
      [appendToBody]="true"
      [identifyBy]="identifyBy"
      [displayBy]="displayBy"
      [autocompleteObservable]="source">
      <ng-template let-item="item">
        <ng-container *ngIf="dropdownTemplate">
          <ng-container *ngTemplateOutlet="dropdownTemplate; context: item"></ng-container>
        </ng-container>
        <ng-container *ngIf="!dropdownTemplate">
          {{ item[displayBy] }}
        </ng-container>
      </ng-template>
  </tag-input-dropdown>
</tag-input>
