import { Pipe, PipeTransform } from '@angular/core'
import { User } from '../interfaces'
import { UsernamePipe } from './username.pipe'

@Pipe({ name: 'usersArray' })
export class UsersArrayPipe implements PipeTransform {

  constructor (private usernamePipe: UsernamePipe) {}

  transform (value: User[]): string[] {
    if (!value) {
      return []
    }
    return value.map(user => {
      return this.usernamePipe.transform(user.name)
    })
  }
}
