var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { Column } from '../../models';
import { EmployeeService, ViewService } from '../../services';
import { SearchView } from '../../interfaces';
var EmployeesComponent = /** @class */ (function (_super) {
    __extends(EmployeesComponent, _super);
    function EmployeesComponent(viewService, route, service) {
        var _this = _super.call(this, viewService, route, service, 'employees', 'employee') || this;
        _this.columns = [
            _this.routerColumn('_id', 'USER.ID', { isPrimary: true }),
            _this.routerColumn('name.last', 'USER.LAST_NAME', { isPrimary: true }),
            _this.routerColumn('name.first', 'USER.FIRST_NAME', { isPrimary: true }),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('contacts.0', 'USER.CONTACTS', false, null, 'contactCell')
        ];
        _this.params.sort = {
            'name.last': 1,
            'name.first': 1
        };
        return _this;
    }
    return EmployeesComponent;
}(SearchView));
export { EmployeesComponent };
