/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal/modal.directive";
import * as i2 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./confirmation.modal";
var styles_ConfirmationModal = [];
var RenderType_ConfirmationModal = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmationModal, data: {} });
export { RenderType_ConfirmationModal as RenderType_ConfirmationModal };
function View_ConfirmationModal_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "input", [["class", "form-control"], ["id", "ModalInput"]], null, null, null, null, null))], null, null); }
export function View_ConfirmationModal_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { confirmationModal: 0 }), (_l()(), i0.ɵeld(1, 16777216, null, null, 45, "div", [["aria-hidden", "true"], ["bsModal", ""], ["class", "modal fade"], ["role", "dialog"]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, [[1, 4], ["confirmationModal", 4]], 0, i1.ModalDirective, [i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i2.ComponentLoaderFactory], { config: [0, "config"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(4, 0, null, null, 41, "div", [["class", "modal-dialog modal-sm"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(6, 0, null, null, 38, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\n      "])), (_l()(), i0.ɵeld(8, 0, null, null, 12, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "h5", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(15, 0, null, null, 4, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n\n      "])), (_l()(), i0.ɵeld(22, 0, null, null, 21, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(24, 0, null, null, 2, "h5", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(25, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(28, 0, null, null, 14, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmationModal_1)), i0.ɵdid(31, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(33, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(35, 0, null, null, 2, "button", [["class", "pull-left btn btn-success btn-lg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(36, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(39, 0, null, null, 2, "button", [["class", "btn btn-danger btn-lg"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(40, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.isInputForm; _ck(_v, 31, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform(_co.title)); _ck(_v, 12, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 25, 0, i0.ɵnov(_v, 26).transform(_co.message)); _ck(_v, 25, 0, currVal_2); var currVal_4 = i0.ɵunv(_v, 36, 0, i0.ɵnov(_v, 37).transform("COMMON.BTN_YES")); _ck(_v, 36, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 40, 0, i0.ɵnov(_v, 41).transform("COMMON.BTN_NO")); _ck(_v, 40, 0, currVal_5); }); }
export function View_ConfirmationModal_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirmation-modal", [], null, null, null, View_ConfirmationModal_0, RenderType_ConfirmationModal)), i0.ɵdid(1, 114688, null, 0, i5.ConfirmationModal, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationModalNgFactory = i0.ɵccf("confirmation-modal", i5.ConfirmationModal, View_ConfirmationModal_Host_0, {}, { onInitialized: "onInitialized" }, []);
export { ConfirmationModalNgFactory as ConfirmationModalNgFactory };
