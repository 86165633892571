

// @ts-expect-error ts-migrate(6133) FIXME: 'OnInit' is declared but its value is never read.
import { Component, Input, OnInit } from '@angular/core'
import { Topic } from '../../interfaces'

@Component({
  selector: 'feed-post',
  templateUrl: './feed.post.html',
  styleUrls: ['./feed.post.styl']
})

export class FeedPost {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'topic' has no initializer and is not def... Remove this comment to see the full error message
  @Input() topic: Topic
  @Input() locale: string = 'en'
}
