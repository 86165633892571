/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./rich.editor";
var styles_RichEditor = [];
var RenderType_RichEditor = i0.ɵcrt({ encapsulation: 2, styles: styles_RichEditor, data: {} });
export { RenderType_RichEditor as RenderType_RichEditor };
export function View_RichEditor_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["id", "editor"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_RichEditor_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "rich-editor", [], null, null, null, View_RichEditor_0, RenderType_RichEditor)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.RichEditor]), i0.ɵdid(2, 114688, null, 0, i2.RichEditor, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var RichEditorNgFactory = i0.ɵccf("rich-editor", i2.RichEditor, View_RichEditor_Host_0, { locale: "locale", readonly: "readonly" }, {}, []);
export { RichEditorNgFactory as RichEditorNgFactory };
