<div class="search-panel clearfix">
  <div class="form-group col-sm-4 col-md-3">
    <label>{{'SUBJECT.DETAIL_TITLE' | translate}}</label>
    <select class="form-control" [(ngModel)]="this.params.conditions['data.subject']" (change)="updateFilter()">
      <option *ngFor="let subject of subjects" [ngValue]="subject._id">
        {{subject.name}}
      </option>
    </select>
  </div>

  <div class="btn-toolbar pull-right">
      <a class="btn btn-default"
      routerLink="/divisions/{{ divisionId }}/ratings/new">
      <i class="fa fa-file-text-o"></i>
      <span>{{ 'DIVISION.NEW_RATING' | translate }}</span>
    </a>
  </div>
</div>



<div *ngIf="divisionId">
  <appdatatable
      [rows]="rows"
      [columns]="columns"
      [sorts]="params.sort"
      [count]="count"
      [limit]="params.limit"
      [loading]="loading"
      (page)="onPage($event)"
      (sort)="onSort($event)">
  </appdatatable>
</div>
