<div class="panel panel-default report-box">
  <div (click)="toggleCollapsed()" class="panel-body media">
    <div class="media-left">
      <i class="fa fa-fw fa-4x" [ngClass]="iconClass"></i>
    </div>
    <div class="media-body">
      <h3 class="media-heading">{{ (translationEntry + '.HEADING') | translate }}</h3>
      <p>{{ (translationEntry + '.DESCRIPTION') | translate }}</p>
    </div>
  </div>
  <div [collapse]="isCollapsed" class="panel-footer animated">
    <ng-content></ng-content>
  </div>
</div>
