import { Component } from '@angular/core'
import { ToasterConfig } from 'angular2-toaster'

@Component({
  selector: 'app-toastr',
  template: '<toaster-container [toasterconfig]="toastrConfig"></toaster-container> '
})

export class AppToastrComponent {
  public toastrConfig: ToasterConfig

  constructor () {
    this.toastrConfig = new ToasterConfig( {
      newestOnTop: true,
      showCloseButton: true,
      tapToDismiss: false,
      positionClass: 'toast-bottom-right'
    })
  }
}
