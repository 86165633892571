import { Subject } from 'rxjs/Rx'
import { Observable } from 'rxjs/Observable'
import { ModalDirective } from 'ngx-bootstrap'
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation.modal.html'
})

export class ConfirmationModal implements OnInit {
  @Output() onInitialized = new EventEmitter<ConfirmationModal>()


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'confirmationModal' has no initializer an... Remove this comment to see the full error message
  @ViewChild('confirmationModal') confirmationModal: ModalDirective


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'message' has no initializer and is not d... Remove this comment to see the full error message
  public message: string
  public title: string | undefined
  public settings: any

  public isInputForm: boolean = false

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'proxy' has no initializer and is not def... Remove this comment to see the full error message
  private proxy: Subject<boolean>


  ngOnInit () {
    this.settings = {
      backdrop: 'static',
      keyboard: true
    }
    this.onInitialized.emit(this)
  }

  public show (content: string, title?: string|undefined, isInputForm?: boolean): Observable<boolean> {
    if (this.proxy) {
      return Observable.throw(new Error('There is already another modal displayed'))
    }

    if(title === undefined){
      this.title = 'COMMON.CONFIRM_TITLE'
    }else{
      this.title = title;
    }

    if(isInputForm){
      this.isInputForm=isInputForm
    }

    this.message = content
    this.proxy = new Subject<boolean>()
    this.confirmationModal.show()
    return this.proxy
  }

  public hide () {
    this.cancel()
  }
  
  // meant to be used internally, triggered by Confirm button
  public confirm () {
    this.confirmationModal.hide()
    this.callOff(true)
  }

  // meant to be used internally, triggered by Cancel button
  public cancel () {
    this.confirmationModal.hide()
    this.callOff()
  }

  private callOff (result: boolean = false) {
    this.proxy.next(result)
    this.proxy.complete()


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Subjec... Remove this comment to see the full error message
    this.proxy = undefined
  }

  public getInputValue(){
    const input = document.getElementById('ModalInput') as HTMLInputElement | null
    return input?.value
  }

  public clearInputValue(){
    const input = document.getElementById('ModalInput') as HTMLInputElement | null
    if(input!=null){
      input.value =""
    }
  }
}
