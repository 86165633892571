import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core'
import { BatchJobService, AppToastrService } from '../../services'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'batch-job-file-modal',
  templateUrl: './batch-job-file.modal.html',
  exportAs: 'child'
})

export class BatchJobFileModal {
  @ViewChild('batchJobFileModal') modal: any
  @Output('saved') savedEmitter = new EventEmitter<any>()
  @Input() preset: string = environment.CLOUDINARY.IMPORTS_PRESET


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'tags' has no initializer and is not defi... Remove this comment to see the full error message
  public tags: string
  public title: any


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'type' has no initializer and is not defi... Remove this comment to see the full error message
  public type: string

  constructor (
    private service: BatchJobService,
    private toastr: AppToastrService
  ) {
  }

  public show (title: string, type: string) {
    this.title = title
    this.type = type
    this.tags = `batch-job ${type}`
    this.modal.show()
  }

  public hide () {
    this.modal.hide()
  }

  public processData (cloudinaryData: any) {
    this.service.create({
      type: this.type,
      data: cloudinaryData
    })
    .subscribe(


      // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
      data => {
        this.toastr.successT('COMMON.MSG_SAVE_OK')
        this.savedEmitter.next()
        this.modal.hide()
      },
      this.toastr.handleError
    )
  }
}
