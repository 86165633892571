import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Column } from '../../models'
import { GuardianService, ViewService } from '../../services'
import { SearchView, User } from '../../interfaces'

@Component({
  selector: 'app-guardians',
  templateUrl: './guardians.component.html'
})

export class GuardiansComponent extends SearchView<User> {
  public columns: Column [] = [
    this.routerColumn('_id', 'USER.ID', { isPrimary: true }),
    this.routerColumn('name.last', 'USER.LAST_NAME', { isPrimary: true }),
    this.routerColumn('name.first', 'USER.FIRST_NAME', { isPrimary: true }),


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    new Column('contacts.0', 'USER.CONTACTS', false, null, 'contactCell'),


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    new Column('membership.tcAccepted', 'USER.TC_ACCEPTED', false, null, 'boolCell', 80., 80),


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    new Column('membership.devices', 'USER.DEVICES', false, null, 'devicesCell', 90, 90)
  ]

  constructor (
    viewService: ViewService,
    route: ActivatedRoute,
    service: GuardianService
  ) {
    super(viewService, route, service, 'guardians', 'guardian')
    this.params.sort = {
      'name.last': 1,
      'name.first': 1
    }
  }
}
