import { AbstractControl, ValidatorFn } from '@angular/forms'

function matchedFields (controlPath1: string, controlPath2: string): ValidatorFn {
  return (c: AbstractControl): {[key: string]: any} => {


    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const value1 = c.get(controlPath1).value


    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const value2 = c.get(controlPath2).value

    if (value1 === value2) {
      return { matchedFields: true }
    }



    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{ [key: str... Remove this comment to see the full error message
    return null
  }
}

export default matchedFields
