var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { ViewService, StageService, GradeWrapperService } from '../../services';
import { Column } from '../../models';
import { SearchView } from '../../interfaces';
import { ArrayPipe } from '../../pipes';
var GradesComponent = /** @class */ (function (_super) {
    __extends(GradesComponent, _super);
    function GradesComponent(viewService, route, stageService) {
        var _this = _super.call(this, viewService, route, new GradeWrapperService(stageService), 'grades', 'stage', 'add-grade') || this;
        _this.params.populate = 'subjects';
        _this.params.select = 'order name subjects';
        _this.columns = [
            _this.routerColumn('order', 'GRADE.ORDER', { isPrimary: true, minWidth: 90, maxWidth: 90 }),
            _this.routerColumn('name', 'GRADE.NAME', { isPrimary: true }),
            new Column('subjects', 'SUBJECT.SEARCH_TITLE', false, new ArrayPipe('name'), 'defaultLabelsCell')
        ];
        return _this;
    }
    return GradesComponent;
}(SearchView));
export { GradesComponent };
