import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AuthService } from './auth.service'
import { Album, StageDependantCrudService } from '../interfaces'

@Injectable()
export class AlbumService extends StageDependantCrudService<Album> {

  constructor (http: HttpClient, authService: AuthService) {
    super(http, '/albums', authService)
  }
}

