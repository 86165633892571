import { PipeTransform } from '@angular/core'

export class ArrayPipe implements PipeTransform {
  public property: string

  constructor (property: string) {
    this.property = property
  }

  transform (value: any[]): any[] {
    return value && value.map(item => { return item[this.property]})
  }
}
