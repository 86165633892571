var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { Column } from '../../models';
import { MeService, SupervisorService, TeacherService, TopicService, ViewService } from '../../services';
import { SearchView } from '../../interfaces';
import { ShortDatePipe, TopicTypePipe } from '../../pipes';
import { zip } from 'rxjs-compat/observable/zip';
import { BehaviorSubject } from 'rxjs-compat/Rx';
import * as _ from 'lodash-es';
import { Observable } from 'rxjs-compat/Observable';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { RecipientsListModal } from '../../widgets/recipients-list-modal/recipients-list-modal';
var TopicsComponent = /** @class */ (function (_super) {
    __extends(TopicsComponent, _super);
    function TopicsComponent(viewService, route, service, translatePipe, teacherService, supervisorService, meService, modalService) {
        var _this = _super.call(this, viewService, route, service, 'topics', 'topic') || this;
        _this.teacherService = teacherService;
        _this.supervisorService = supervisorService;
        _this.meService = meService;
        _this.modalService = modalService;
        _this.today = moment().startOf('day').valueOf();
        _this.fetchedAuthorsSubject = new BehaviorSubject([]);
        _this.resultsAuthorsSubject = new BehaviorSubject([]);
        _this.searchAuthors = function () {
            console.log('Searching authors');
            return zip(_this.fetchedAuthorsSubject, _this.resultsAuthorsSubject, function (a, b) { return __spreadArrays(a, b); })
                .map(function (e) { return _.uniqBy(e, function (pair) { return pair.id; }); });
        };
        // default sort
        _this.params.sort = { dateTime: -1 };
        _this.params.conditions.type = {
            $in: TopicService.TOPIC_COMPONENT_TYPES
        };
        _this.topicTypes = _this.viewService.optionalList(TopicService.TOPIC_COMPONENT_TYPES, undefined);
        var shortDatePipe = new ShortDatePipe();
        shortDatePipe.locale = viewService.auth.getCurrentUser().locale;
        _this.columns = [
            _this.routerColumn('date', 'TOPIC.DATE', {
                isPrimary: true,
                minWidth: 90,
                maxWidth: 90,
                pipe: shortDatePipe
            }),
            Column.forClick('type', 'TOPIC.TYPE', _this.onSelect.bind(_this), true, new TopicTypePipe(translatePipe), 90, 90, 'defaultLabelCell'),
            _this.routerColumn('title', 'TOPIC.TITLE', { isPrimary: true }),
            new Column('recipients', 'TOPIC.RECIPIENTS', false, undefined, 'topicRecipientsCell', undefined, undefined, undefined, function (topic) {
                _this.openModal(topic);
            }),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('audience', 'TOPIC.AUDIENCE', false, null, 'topicAudienceCell', 120, 120),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('attachments.length', 'TOPIC.ATTACHMENTS', false, null, 'defaultBadgeCell', 80, 80),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('resolvedName', 'TOPIC.AUTHOR', false, null)
        ];
        return _this;
    }
    TopicsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var user = Observable.of(this.meService.getMe())
            .map(function (t) { var _a; return new IdNamePair(t._id, ((_a = t.name.full) !== null && _a !== void 0 ? _a : (t.name.last + ', ' + t.name.first)) + ' (' + t._id + ')'); });
        var teachers = this.teacherService.search()
            .map(function (e) { return e.list.map(function (t) { var _a; return new IdNamePair(t._id, ((_a = t.name.full) !== null && _a !== void 0 ? _a : (t.name.last + ', ' + t.name.first)) + ' (' + t._id + ')'); }); });
        var supervisors = this.supervisorService.search()
            .map(function (e) { return e.list.map(function (t) { var _a; return new IdNamePair(t._id, ((_a = t.name.full) !== null && _a !== void 0 ? _a : (t.name.last + ', ' + t.name.first)) + ' (' + t._id + ')'); }); });
        zip(teachers, supervisors, user, function (a, b, c) { return __spreadArrays([c], a, b); })
            .map(function (e) { return _.uniqBy(e, function (pair) { return pair.id; }); })
            .subscribe(function (e) {
            _this.selectedAuthors = [e[0]];
            _this.fetchedAuthorsSubject.next(e);
            _this.setAuthorsFilter(_this.selectedAuthors);
            _super.prototype.ngOnInit.call(_this);
        });
    };
    TopicsComponent.prototype.setAuthorsFilter = function (idNamePairs) {
        this.params.conditions.createdBy = idNamePairs.map(function (e) { return e.id; });
        this.updateFilter();
    };
    TopicsComponent.prototype.onStartDateChange = function (timestamp) {
        if (!this.params.conditions.timestamp) {
            this.params.conditions.timestamp = {};
        }
        this.params.conditions.timestamp['$gt'] = timestamp;
        this.updateFilter();
    };
    TopicsComponent.prototype.onEndDateChange = function (timestamp) {
        if (!this.params.conditions.timestamp) {
            this.params.conditions.timestamp = {};
        }
        this.params.conditions.timestamp['$lt'] = timestamp;
        this.updateFilter();
    };
    TopicsComponent.prototype.updateTopicFilter = function () {
        if (this.selectedTopic === undefined) {
            this.params.conditions.type.$in = TopicService.TOPIC_COMPONENT_TYPES;
        }
        else {
            this.params.conditions.type.$in = [this.selectedTopic];
        }
        this.updateFilter();
    };
    TopicsComponent.prototype.onDataFetched = function (data) {
        var _this = this;
        this.resultsAuthorsSubject.next(data.list.map(function (e) {
            var _a;
            var createdBy = typeof e.createdBy === 'string' ? e.createdBy : (_a = e.createdBy) === null || _a === void 0 ? void 0 : _a._id;
            return new IdNamePair(createdBy, createdBy);
        }));
        // @ts-ignore
        data.list.forEach(function (e) { var _a, _b; return e.resolvedName = (_b = (_a = _this.fetchedAuthorsSubject.getValue().find(function (a) { return a.id === e.createdBy; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : e.createdBy; });
        _super.prototype.onDataFetched.call(this, data);
    };
    TopicsComponent.prototype.openModal = function (topic) {
        this.modalRef = this.modalService.show(RecipientsListModal, { initialState: { topic: topic }, class: 'modal-lg' });
    };
    return TopicsComponent;
}(SearchView));
export { TopicsComponent };
var IdNamePair = /** @class */ (function () {
    function IdNamePair(id, name) {
        this.id = id;
        this.name = name;
    }
    return IdNamePair;
}());
