<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="getItems()">
      <i class="fa fa-refresh" aria-hidden="true"
          [tooltip]="'COMMON.BTN_REFRESH' | translate"
          placement="bottom"></i>
    </a>
  </h1>
  <div class="btn-toolbar pull-right" role="toolbar">
    <a class="btn btn-success"
        routerLink="/supervisors/new"
        *ngIf="isCreateAllowed">
      <i class="fa fa-plus"></i>
      <span>{{ 'COMMON.BTN_NEW' | translate }}</span>
    </a>
  </div>
</section>

<section class="content">
  <div class="row search-panel">
    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'SUPERVISOR.DETAIL_TITLE' | translate }}</label>
      <input
        type="text"
        [placeholder]="'COMMON.PLACEHOLDER' | translate"
        class="form-control"
        debounce
        [delay]="250"
        (func)="updateFilter()"
        [(ngModel)]="params.q"/>
    </div>
  </div>

  <appdatatable
    [rows]="rows"
    [columns]="columns"
    [sorts]="params.sort"
    [count]="count"
    [limit]="params.limit"
    [loading]="loading"
    (page)="onPage($event)"
    (sort)="onSort($event)">
  </appdatatable>
</section>
