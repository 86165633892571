import { Pipe, PipeTransform } from '@angular/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'mome... Remove this comment to see the full error message
import * as moment from 'moment-timezone'

@Pipe({name: 'time'})
export class TimePipe implements PipeTransform {

  transform (time: any, locale: string): string {


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    if (!time) { return }

    let value
    if (time instanceof moment) {
      value = time
    } else {
      value = moment()
      value.hour(time.hour)
      value.minutes(time.minutes)
    }
    if (locale) {
      value.locale(locale)
    }

    return value.format('LT')
  }

}
