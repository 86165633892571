import { Pipe, PipeTransform } from '@angular/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'

@Pipe({ name: 'genderLabel' })
export class GenderLabelPipe implements PipeTransform {

  transform (value: string): string {
    switch (value) {
      case biz.GENDERS.MALE:
        return 'label-male'
      case biz.GENDERS.FEMALE:
        return 'label-female'
      default:
        return ''
    }
  }
}
