import { OnInit, ElementRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export var RICH_EDITOR_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return RichEditor; }),
    multi: true
};
var RichEditor = /** @class */ (function () {
    function RichEditor(element) {
        this.element = element;
        this._readonly = false;
        this.onChange = Function.prototype;
        this.onTouched = Function.prototype;
        this.initialData = null;
        // @ts-ignore
        this.editor = null;
    }
    Object.defineProperty(RichEditor.prototype, "readonly", {
        get: function () {
            return this._readonly;
        },
        set: function (value) {
            this._readonly = value;
            if (this.editor) {
                this.editor.isReadOnly = value;
            }
        },
        enumerable: false,
        configurable: true
    });
    RichEditor.prototype.ngOnInit = function () {
        var _this = this;
        var editorConfig = {
            removePlugins: ['SourceEditing']
        };
        ClassicEditor
            .create(document.getElementById('editor'), editorConfig)
            .then(function (editor) {
            _this.editor = editor;
            if (_this.initialData) {
                editor.setData(_this.initialData);
                _this.initialData = _this.value;
            }
            editor.model.document.on('change:data', function () {
                var _a;
                if (((_a = _this.editor) === null || _a === void 0 ? void 0 : _a.data) && !_this.initialData) {
                    _this.initialData = _this.value;
                }
                _this.onContentChange();
            });
            editor.isReadOnly = _this.readonly;
        })
            .catch(function (error) {
            console.error(error);
        });
    };
    Object.defineProperty(RichEditor.prototype, "value", {
        get: function () {
            var _a;
            return (_a = this.editor) === null || _a === void 0 ? void 0 : _a.data.get();
        },
        enumerable: false,
        configurable: true
    });
    RichEditor.prototype.onContentChange = function () {
        if (this.initialData === this.value) {
            return;
        }
        this.onChange(this.value);
        this.onTouched();
    };
    RichEditor.prototype.onFocus = function () {
        if (!this.readonly) {
            this.onTouched();
        }
    };
    // ControlValueAccessor
    // model -> view
    RichEditor.prototype.writeValue = function (value) {
        var _a;
        if (value === null)
            value = "";
        (_a = this.editor) === null || _a === void 0 ? void 0 : _a.setData(value);
        this.initialData = value;
    };
    RichEditor.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    RichEditor.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    return RichEditor;
}());
export { RichEditor };
