import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PersistentModel } from './persistent-model'
import { DateOnly } from './date-only'
import { TimeOnly } from './time-only'
import { Division } from './division'
import { Subject } from './subject'


// @ts-expect-error ts-migrate(6133) FIXME: 'AppValidators' is declared but its value is never... Remove this comment to see the full error message
import { AppValidators } from '../validators';

export interface Rating extends PersistentModel {
  subject?: Subject|string
  division: Division|string
  date?: DateOnly
  time?: TimeOnly
  allowMessages?: boolean
}

export class RatingValidator {

  public static newFormGroup (): FormGroup {
    const fb = new FormBuilder()

    const formGroup = fb.group({
      subject: fb.control('', Validators.required),
      division: fb.control('', Validators.required),
      allowMessages: fb.control(false)
    })

    return formGroup
  }
}
