/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./datepicker.popup.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "ngx-bootstrap/datepicker/bs-datepicker.component";
import * as i4 from "ngx-bootstrap/datepicker/bs-datepicker.config";
import * as i5 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i6 from "ngx-bootstrap/datepicker/bs-datepicker-input.directive";
import * as i7 from "ngx-bootstrap/datepicker/bs-locale.service";
import * as i8 from "./dateonlypicker.popup";
var styles_DateOnlyPickerPopup = [i0.styles];
var RenderType_DateOnlyPickerPopup = i1.ɵcrt({ encapsulation: 0, styles: styles_DateOnlyPickerPopup, data: {} });
export { RenderType_DateOnlyPickerPopup as RenderType_DateOnlyPickerPopup };
export function View_DateOnlyPickerPopup_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { popup: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 16777216, null, null, 8, "input", [["bsDatepicker", ""], ["class", "form-control"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "bsValueChange"], [null, "blur"], [null, "ngModelChange"], [null, "input"], [null, "compositionstart"], [null, "compositionend"], [null, "change"], [null, "keyup.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 8).onChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.esc" === en)) {
        var pd_5 = (i1.ɵnov(_v, 8).hide() !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 8).onBlur() !== false);
        ad = (pd_6 && ad);
    } if (("bsValueChange" === en)) {
        var pd_7 = (_co.onModelChange($event) !== false);
        ad = (pd_7 && ad);
    } if (("blur" === en)) {
        var pd_8 = (_co.onBlur() !== false);
        ad = (pd_8 && ad);
    } if (("ngModelChange" === en)) {
        var pd_9 = ((_co.innerValue = $event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(7, 737280, [[1, 4], ["popup", 4]], 0, i3.BsDatepickerDirective, [i4.BsDatepickerConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i5.ComponentLoaderFactory], { bsConfig: [0, "bsConfig"], minDate: [1, "minDate"], maxDate: [2, "maxDate"] }, { bsValueChange: "bsValueChange" }), i1.ɵdid(8, 16384, null, 0, i6.BsDatepickerInputDirective, [i3.BsDatepickerDirective, i7.BsLocaleService, i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef], null, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i6.BsDatepickerInputDirective]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i6.BsDatepickerInputDirective]), i1.ɵdid(11, 671744, null, 0, i2.NgModel, [[8, null], [2, i2.NG_VALIDATORS], [8, null], [2, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(13, 16384, null, 0, i2.NgControlStatus, [i2.NgControl], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "input-group-addon clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fa fa-calendar"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.config; var currVal_8 = _co.innerMinDate; var currVal_9 = _co.innerMaxDate; _ck(_v, 7, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.innerValue; _ck(_v, 11, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 13).ngClassValid; var currVal_5 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_DateOnlyPickerPopup_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dateonlypicker-popup", [], null, null, null, View_DateOnlyPickerPopup_0, RenderType_DateOnlyPickerPopup)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.DateOnlyPickerPopup]), i1.ɵdid(2, 573440, null, 0, i8.DateOnlyPickerPopup, [i1.ElementRef, i7.BsLocaleService], null, null)], null, null); }
var DateOnlyPickerPopupNgFactory = i1.ɵccf("dateonlypicker-popup", i8.DateOnlyPickerPopup, View_DateOnlyPickerPopup_Host_0, { format: "format", locale: "locale", value: "value", minDate: "minDate", maxDate: "maxDate" }, { change: "change" }, []);
export { DateOnlyPickerPopupNgFactory as DateOnlyPickerPopupNgFactory };
