<div class="text-center text-muted" *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</div>

<div class="text-center text-muted" *ngIf="!isLoading && !students?.length">
  {{ 'COMMON.MSG_NO_DATA' | translate }}
</div>

<table class="table table-striped table-hover table-fixed no-more-table" *ngIf="!isLoading && !!students?.length">
  <thead>
    <tr>
      <th class="col-sm-4 col-md-3 col-lg-2">{{ 'USER.FULL_NAME' | translate }}</th>
      <th class="col-sm-2">{{ 'TOPIC_TYPES.assessment' | translate }}</th>
      <th class="col-sm-6 col-md-7 col-lg-8">{{ 'COMMON.COMMENT' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let student of students">
      <td [attr.data-title]="'USER.FULL_NAME' | translate">
        <a routerLink="/students/{{ student._id }}">{{ student.name | username }}</a>
      </td>
      <td [attr.data-title]="'TOPIC_TYPES.assessment' | translate">
        <input *ngIf="isAssessmentAllowed"
            type="text"
            class="form-control input-sm"
            [(ngModel)]="assessments[student._id].assessment">
        <div *ngIf="!isAssessmentAllowed">
          {{ assessments[student._id].assessment | optional }}
        </div>
      </td>
      <td [attr.data-title]="'COMMON.COMMENT' | translate">
        <textarea *ngIf="isAssessmentAllowed"
            class="form-control input-sm"
            [(ngModel)]="assessments[student._id].comment"
            rows="1">
        </textarea>
        <div *ngIf="!isAssessmentAllowed">
          {{ assessments[student._id].comment | optional }}
        </div>
      </td>
    </tr>
  </tbody>
</table>
<div class="row" *ngIf="isAssessmentAllowed">
  <div class="col-xs-12">
    <div class="btn-toolbar btn-footer" role="toolbar">
      <button
          type="button"
          class="btn btn-success pull-right"
          (click)="updateAssessments()"
          [ladda]="isSubmitting">
        {{ 'DIVISION.SAVE_ASSESSMENTS' | translate }}
      </button>
    </div>
  </div>
</div>
