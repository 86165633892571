var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// @ts-expect-error ts-migrate(6133) FIXME: 'ValidatorFn' is declared but its value is never r... Remove this comment to see the full error message
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidators } from '../validators';
var Album = /** @class */ (function () {
    function Album() {
    }
    return Album;
}());
export { Album };
var AlbumFormGroup = /** @class */ (function (_super) {
    __extends(AlbumFormGroup, _super);
    function AlbumFormGroup() {
        var _this = this;
        var fb = new FormBuilder();
        _this = _super.call(this, {
            title: fb.control('', [Validators.required]),
            division: fb.control('', [Validators.required]),
            // date: fb.control('', [ Validators.required ]),
            attachments: fb.control('', [AppValidators.nonEmpty()])
        }) || this;
        return _this;
    }
    AlbumFormGroup.prototype.patchValue = function (value, options) {
        _super.prototype.patchValue.call(this, value, options);
        // This is needed to initialize the array in case it's not provided by the model
        if (!this.controls.attachments.value) {
            this.controls.attachments.setValue([]);
        }
    };
    AlbumFormGroup.prototype.addAttachment = function (att) {
        this.controls.attachments.value.push(att);
        this.controls.attachments.updateValueAndValidity();
    };
    AlbumFormGroup.prototype.removeAttachmentAt = function (i) {
        this.controls.attachments.value.splice(i, 1);
        this.controls.attachments.updateValueAndValidity();
    };
    return AlbumFormGroup;
}(FormGroup));
export { AlbumFormGroup };
var AlbumValidator = /** @class */ (function () {
    function AlbumValidator() {
    }
    AlbumValidator.newFormGroup = function () {
        return new AlbumFormGroup();
    };
    return AlbumValidator;
}());
export { AlbumValidator };
