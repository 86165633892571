/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-file.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-bootstrap/progressbar/progressbar.component.ngfactory";
import * as i3 from "ngx-bootstrap/progressbar/progressbar.component";
import * as i4 from "ngx-bootstrap/progressbar/progressbar.config";
import * as i5 from "@angular/common";
import * as i6 from "ng2-file-upload";
import * as i7 from "./upload-file.component";
var styles_UploadFileComponent = [i0.styles];
var RenderType_UploadFileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadFileComponent, data: {} });
export { RenderType_UploadFileComponent as RenderType_UploadFileComponent };
function View_UploadFileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "progressbar", [["class", "progress-striped"]], [[1, "max", 0], [2, "progress", null]], null, null, i2.View_ProgressbarComponent_0, i2.RenderType_ProgressbarComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProgressbarComponent, [i4.ProgressbarConfig], { value: [0, "value"] }, null), (_l()(), i1.ɵted(2, 0, ["\n  ", "%\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.progress; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).max; var currVal_1 = i1.ɵnov(_v, 1).addClass; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = (_co.progress || 0); _ck(_v, 2, 0, currVal_3); }); }
export function View_UploadFileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "upload-file"], ["ng2FileDrop", ""]], null, [[null, "fileOver"], [null, "drop"], [null, "dragover"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onDragOver($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onDragLeave($event) !== false);
        ad = (pd_2 && ad);
    } if (("fileOver" === en)) {
        var pd_3 = (_co.onFileOver($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "dragover": 0 }), i1.ɵdid(3, 16384, null, 0, i6.FileDropDirective, [i1.ElementRef], { uploader: [0, "uploader"] }, { fileOver: "fileOver" }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["style", "\n  display: flex;\n  flex-direction: column;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Arrastar y soltar archivos para subirlos"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "label", [["class", "custom-file-input"], ["for", "file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "input", [["id", "file"], ["style", "display: none"], ["title", " "], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleFileInput($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadFileComponent_1)), i1.ɵdid(19, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "upload-file"; var currVal_1 = _ck(_v, 2, 0, _co.hovering); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.uploader; _ck(_v, 3, 0, currVal_2); var currVal_4 = _co.uploading; _ck(_v, 19, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.placeholder; _ck(_v, 5, 0, currVal_3); }); }
export function View_UploadFileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "upload-file", [], null, null, null, View_UploadFileComponent_0, RenderType_UploadFileComponent)), i1.ɵdid(1, 114688, null, 0, i7.UploadFileComponent, [i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadFileComponentNgFactory = i1.ɵccf("upload-file", i7.UploadFileComponent, View_UploadFileComponent_Host_0, { tags: "tags", source: "source", preset: "preset" }, { onUpload: "onUpload" }, []);
export { UploadFileComponentNgFactory as UploadFileComponentNgFactory };
