import { Component, OnInit, Output, Input, EventEmitter, NgZone } from '@angular/core'
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.styl']
})

export class UploadImageComponent implements OnInit {
  @Output('onUpload') onUpload = new EventEmitter<any>()


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'tags' has no initializer and is not defi... Remove this comment to see the full error message
  @Input() tags: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'source' has no initializer and is not de... Remove this comment to see the full error message
  @Input() source: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'preset' has no initializer and is not de... Remove this comment to see the full error message
  @Input() preset: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'uploader' has no initializer and is not ... Remove this comment to see the full error message
  public uploader: FileUploader
  public hovering: boolean = false
  public uploading: boolean = false
  public progress: number = 0
  public placeholder = '/public/assets/img/dot.png'
  private cloudName = environment.CLOUDINARY.CLOUD_NAME

  constructor (
    private zone: NgZone
  ) {
  }

  public ngOnInit () {
    const uploaderOptions: FileUploaderOptions = {
      url: `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`,
      autoUpload: true,
      queueLimit: 1, // for uploading one image at a time
      allowedMimeType: ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'image/bmp'], // restrict to images
      isHTML5: true,
      removeAfterUpload: true,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    }

    this.uploader = new FileUploader(uploaderOptions)

    // Add custom tag for displaying the uploaded photo in the list
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      if (this.preset) {
        form.append('upload_preset', this.preset)
      }
      form.append('tags', this.tags)
      form.append('file', fileItem)

      fileItem.withCredentials = false
      return { fileItem, form }
    }

    this.uploader.onBeforeUploadItem = (): any => {
      console.log('upload starting')
      this.uploading = true
      this.progress = 0
    }

    this.uploader.onCompleteItem = (): any => {
      console.log('upload completed')
      this.uploading = false
    }



    // @ts-expect-error ts-migrate(6133) FIXME: 'fileItem' is declared but its value is never read... Remove this comment to see the full error message
    this.uploader.onProgressItem = (fileItem: any, progress: any): any => {
      console.log(`uploading... ${progress}`)
      // http://orizens.com/wp/topics/angular-2-ngzone-intro-the-new-scope-apply/
      this.zone.run(() => {
        this.progress = progress
      })
    }

    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      const res: any = JSON.parse(response)
      // discard all the predominants
      if (res.predominant) {
        delete res.predominant
      }
      // discard all the colors but the first
      if (res.colors) {
        res.colors = res.colors.slice(0, 1)
      }
      // adjust eager transformation
      if (res.eager) {
        const eager = {}
        res.eager.forEach((transformation: any) => {


          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          eager[`w${transformation.width}`] = transformation
        })
        res.eager = eager
      }
      // pass result to listener
      this.onUpload.next(res)
      return { item, response, status, headers }
    }
  }

  public onFileOver (e: any): void {
    this.hovering = e
  }
}
