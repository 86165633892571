<div class="search-panel clearfix">
  <div class="form-group col-sm-4 col-md-3">
    <label>{{'SUBJECT.DETAIL_TITLE' | translate}}</label>
    <select class="form-control" [(ngModel)]="this.params.conditions['data.subject']" (change)="updateFilter()">
      <option *ngFor="let subject of subjects" [ngValue]="subject._id">
        {{subject.name}}
      </option>
    </select>
  </div>
  
  <div class="btn-toolbar pull-right" [tooltip]="user.currentRole=='teacher' ? false : 'Solo los docentes pueden crear examenes'">
      <button class="btn btn-default"
       [disabled]="user.currentRole=='teacher' ? false : true"
      routerLink="/divisions/{{ divisionId }}/exams/new">
      <i class="fa fa-file-text-o"></i>
      <span>{{ 'DIVISION.NEW_EXAM' | translate }}</span>
  </button>
  </div>
</div>



<div *ngIf="divisionId">
  <appdatatable
      [rows]="rows"
      [columns]="columns"
      [sorts]="params.sort"
      [count]="count"
      [limit]="params.limit"
      [loading]="loading"
      (page)="onPage($event)"
      (sort)="onSort($event)">
  </appdatatable>
</div>