var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { TopicService, ViewService } from '../../services';
import { DetailView, TopicValidator } from '../../interfaces';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
var TopicComponent = /** @class */ (function (_super) {
    __extends(TopicComponent, _super);
    function TopicComponent(viewService, service, route) {
        var _this = _super.call(this, viewService, service, route, 'topic', TopicValidator.newFormGroup()) || this;
        _this.options.populate = {
            'recipients.reference': 'name'
        };
        return _this;
    }
    TopicComponent.prototype.submit = function () {
        var _this = this;
        var recipients = this.form.value.recipients;
        if (!this.form.controls.recipients || (recipients && recipients.length)) {
            return _super.prototype.submit.call(this);
        }
        // ask for confirmation before sending a topic to the whole stage
        this.viewService.askConfirmation('TOPIC.CONFIRM_SEND_TO_STAGE')
            .subscribe(function (value) {
            if (value) {
                _super.prototype.submit.call(_this);
            }
        }, this.viewService.handleError);
    };
    TopicComponent.prototype.checkPermissions = function () {
        var _a;
        _super.prototype.checkPermissions.call(this);
        var authorId = typeof this.model.createdBy === 'string' ? this.model.createdBy : (_a = this.model.createdBy) === null || _a === void 0 ? void 0 : _a._id;
        var isCreatedByUser = authorId === this.viewService.auth.getCurrentUser()._id;
        this.isSaveAllowed = this.isCreate || isCreatedByUser;
        this.isDeleteAllowed = isCreatedByUser;
        if (this.isCreate) {
            this.mode = 'create';
        }
        else if (isCreatedByUser) {
            this.mode = 'edit';
        }
        else {
            this.mode = 'readonly';
        }
    };
    TopicComponent.prototype.getValue = function () {
        if (!this.isCreate) {
            return {
                title: this.form.value.title,
                content: this.form.value.content,
                attachments: this.form.value.attachments
            };
        }
        if (this.form.value.recipients && this.form.value.recipients.length > 0) {
            return this.form.value;
        }
        // clone so UI is not affected in case of rejection by back-end
        var newValue = this.viewService.clone(this.form.value);
        var selectedStage = this.viewService.auth.getSelectedStage();
        newValue.recipients = [{
                type: biz.RECIPIENT_TYPES.STAGE,
                reference: selectedStage._id
            }];
        return newValue;
    };
    TopicComponent.prototype.getDefaultDetails = function () {
        return {
            type: biz.TOPIC_TYPES.NEWS,
            allowMessages: false
        };
    };
    return TopicComponent;
}(DetailView));
export { TopicComponent };
