import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Observable } from 'rxjs/Observable'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { Column } from '../../models'
import { ViewService, StudentService } from '../../services'
import { SearchPanel, User, UserValidator, UserFormGroup, CrudService } from '../../interfaces'
import { QueryString } from '../../models'

@Component({
  selector: 'student-guardians-tab',
  templateUrl: './student-guardians.tab.html'
})

export class StudentGuardiansTab extends SearchPanel<any> implements OnChanges {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'studentId' has no initializer and is not... Remove this comment to see the full error message
  @Input() studentId: string
  @Input() editable: boolean = false



  // @ts-expect-error ts-migrate(2564) FIXME: Property 'columns' has no initializer and is not d... Remove this comment to see the full error message
  public columns: Column []


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'form' has no initializer and is not defi... Remove this comment to see the full error message
  public form: UserFormGroup


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'isCreate' has no initializer and is not ... Remove this comment to see the full error message
  public isCreate: boolean
  public isSaveAllowed: boolean = false
  public isSubmitting: boolean = false
  public isRemoving: boolean = false

  constructor (
    viewService: ViewService,
    private service: StudentService
  ) {
    super(viewService, 'guardian')
    this.isSaveAllowed = this.isCreateAllowed
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'changes' is declared but its value is never read.
  public ngOnChanges (changes: SimpleChanges) {
    if (this.studentId) {
      this.createColumns()
      this.updateFilter()
    }
  }

  public switchToEditionMode (guardian: User = {
    gender: biz.GENDERS.FEMALE
  } as User) {
    this.form = UserValidator.newGuardianFormGroup()
    this.form.patchValue(guardian)
    this.isCreate = !!guardian


    // @ts-expect-error ts-migrate(6133) FIXME: 'permission' is declared but its value is never re... Remove this comment to see the full error message
    const permission = this.isCreate ? 'create' : 'update'
  }

  public cancelEditionMode () {
    this.createColumns()
    this.form.reset()


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'UserFo... Remove this comment to see the full error message
    this.form = undefined
    this.updateFilter()
  }

  public saveGuardian () {
    if (!this.isSaveAllowed) {
      console.warn('User not allowed to save changes')
      return
    } else if (!this.form || !this.form.valid) { // be extra sure
      return this.viewService.toastr.warningT('COMMON.MSG_INVALID_FORM')
    }

    this.isSubmitting = true
    const onSuccess = () => { this.cancelEditionMode() }
    const onFinally = () => { this.isSubmitting = false }
    const model = this.form.value
    const service = new GuardianWrapperService(this.studentId, this.service)
    const guardianId = !this.isCreate && model._id
    this.viewService.save(guardianId, service, this.form, onSuccess, onFinally, model)
  }

  public onSelect (selected: any) {
    return this.viewService.goToDetail('guardians', 'guardian', selected.guardian)
  }

  protected fetch (params: QueryString): Observable<any> {
    return this.service.getGuardians(this.studentId, params)
  }

  private createColumns (): void {
    const canRemove = this.viewService.isCurrentTermSelected()
    const actions = [{
      class: 'fa-times action-icon-danger',
      onClick: this.onRemove.bind(this),
      visible: () => canRemove
    }]
    this.columns = [
      Column.forClick('guardian._id', 'USER.ID', this.onSelect.bind(this)),
      Column.forClick('guardian.name.first', 'USER.FIRST_NAME', this.onSelect.bind(this)),
      Column.forClick('guardian.name.last', 'USER.LAST_NAME', this.onSelect.bind(this)),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('relationship', 'STUDENT.GUARDIAN_RELATIONSHIP', false, null, 'defaultLabelCell'),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('guardian.contacts.0', 'USER.CONTACTS', false, null, 'contactCell'),
      Column.forActions(actions, 50)
    ]
  }

  private onRemove (row: any) {
    this.viewService.askConfirmation('COMMON.MSG_CONFIRM_REMOVE')
    .subscribe(confirmed => {
      if (confirmed) {
        this.isRemoving = true

        this.service.removeGuardian(this.studentId, row.guardian._id)
        .finally(() => this.isRemoving = false)
        .subscribe(


          // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
          data => {
            this.viewService.toastr.successT('COMMON.MSG_OPERATION_OK')
            this.getItems()
          },
          this.viewService.handleError
        )
      }
    })
  }
}

class GuardianWrapperService extends CrudService<User> {

  constructor (
    private id: string,
    private service: StudentService
  ) {


    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
    super(null, '')
  }

  public create (object: any): Observable<any> {
    return this.service.addGuardian(this.id, object)
  }

  public update (id: string, object: any): Observable<any> {
    return this.service.updateGuardian(this.id, id, object)
  }
}
