<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content">
  <div class="row promote-from">
    <div class="form-group col-sm-2 col-md-1 text-right hidden-xs">
      <h3>{{ 'PROMOTION.FROM' | translate }}</h3>
    </div>
    <div class="form-group col-xs-6 col-sm-3">
      <label>{{ 'TERM.DETAIL_TITLE' | translate }}</label>
      <p class="form-control-static">{{ selectedTerm.name }}</p>
    </div>
    <div class="form-group col-xs-6 col-sm-3">
      <label>{{ 'STAGE.DETAIL_TITLE' | translate }}</label>
      <p class="form-control-static">{{ selectedStage.name }}</p>
    </div>
    <div class="form-group col-sm-3">
      <label>{{ 'DIVISION.DETAIL_TITLE' | translate }}</label>
      <p class="form-control-static">{{ selectedDivision?.name }}</p>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-sm-2 col-md-1 text-right hidden-xs">
      <h3>{{ 'PROMOTION.TO' | translate }}</h3>
    </div>
    <div class="form-group col-xs-6 col-sm-3">
      <label>{{ 'TERM.DETAIL_TITLE' | translate }}</label>
      <select class="form-control" [(ngModel)]="target.termId" (change)="onTermChange()">
        <option *ngFor="let term of terms" value="{{ term._id }}">{{ term.name }}</option>
      </select>
    </div>
    <div class="form-group col-xs-6 col-sm-3">
      <label>{{ 'STAGE.DETAIL_TITLE' | translate }}</label>
      <select class="form-control" [(ngModel)]="target.stageId" (change)="onStageChange()">
        <option *ngFor="let stage of target.term?.stages" value="{{ stage._id }}">{{ stage.name }}</option>
      </select>
    </div>
    <div class="form-group col-sm-3">
      <label>{{ 'DIVISION.DETAIL_TITLE' | translate }}</label>
      <select class="form-control" [(ngModel)]="target.divisionId" (change)="onDivisionChange()">
        <option *ngFor="let division of divisions[target.stageId]" value="{{ division._id }}">{{ division.name }}</option>
      </select>
    </div>
  </div>

  <div class="form-tab-container">
    <tabset>
      <tab heading="{{ 'STUDENT.SEARCH_TITLE' | translate }}">
        <div class="col-xs-12">
            <table class="table" *ngIf="students !== []">
              <thead>
                <tr>
                  <th class="col-sm-1">{{ 'PROMOTION.PROMOTE' | translate }}?</th>
                  <th class="col-sm-2">{{ 'USER.ID' | translate }}</th>
                  <th class="col-sm-4">{{ 'USER.FULL_NAME' | translate }}</th>
                  <th class="col-sm-5"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let student of students">
                  <td>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" [(ngModel)]="student.selected">
                    </div>
                  </td>
                  <td>{{ student._id }}</td>
                  <td>{{ student.name | username }}</td>
                  <td>
                    <span *ngIf="student.selected && result && result[student._id]" class="text-danger">{{ result[student._id] | translateError }}</span>
                    <span *ngIf="student.selected && result && !result[student._id]" class="text-success"><i class="fa fa-check" aria-hidden="true"></i></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="btn-toolbar btn-footer" role="toolbar">
              <button
                  type="button"
                  class="btn btn-success btn-lg"
                  [disabled]="!selectedDivision || !target.divisionId"
                  [ladda]="isSubmitting"
                  (click)="submit()">
                {{ 'PROMOTION.PROMOTE' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-default btn-lg"
                  (click)="goBack()">
                {{ 'COMMON.BTN_CANCEL' | translate }}
              </button>
            </div>
        </div>
      </tab>
    </tabset>
  </div>
</section>
