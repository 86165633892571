var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApiService } from './api.service';
var CrudService = /** @class */ (function (_super) {
    __extends(CrudService, _super);
    function CrudService(http, urlSpecific, typeDeserializer) {
        if (typeDeserializer === void 0) { typeDeserializer = function (e) { return e; }; }
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.urlSpecific = urlSpecific;
        _this.typeDeserializer = typeDeserializer;
        _this.url = _this.apiUrl + urlSpecific;
        _this.toModel = _this.toModel.bind(_this);
        return _this;
    }
    CrudService.prototype.search = function (params) {
        var _this = this;
        return this.http
            .get(this.url, this.toRequestOptions(params))
            .map(function (json) { return CrudSearchResult.fromJson(json, _this.typeDeserializer); });
    };
    CrudService.prototype.getById = function (id, params) {
        return this.http.get(this.url + "/" + id, this.toRequestOptions(params))
            .map(this.toModel);
    };
    CrudService.prototype.create = function (object) {
        return this.http.post(this.url, object);
    };
    CrudService.prototype.update = function (id, object) {
        return this.http.patch(this.url + "/" + id, object);
    };
    CrudService.prototype.remove = function (id) {
        return this.http.delete(this.url + "/" + id);
    };
    CrudService.prototype.toModel = function (res) {
        return res;
    };
    CrudService.prototype.toId = function (id) {
        return typeof id === 'string' ? id : id._id;
    };
    return CrudService;
}(ApiService));
export { CrudService };
var CrudSearchResult = /** @class */ (function () {
    function CrudSearchResult(list, meta) {
        this.list = list;
        this.meta = meta;
    }
    CrudSearchResult.fromJson = function (json, typeDeserializer) {
        var _a, _b, _c;
        if (Array.isArray(json)) {
            var array = json;
            return new CrudSearchResult(array.map(typeDeserializer), new CrudSearchResultMeta(1, array.length, array.length));
        }
        else {
            var array = json.list;
            var meta = json === null || json === void 0 ? void 0 : json.meta;
            return new CrudSearchResult(array.map(typeDeserializer), new CrudSearchResultMeta((_a = meta === null || meta === void 0 ? void 0 : meta.page) !== null && _a !== void 0 ? _a : 1, (_b = meta === null || meta === void 0 ? void 0 : meta.pageSize) !== null && _b !== void 0 ? _b : array.length, (_c = meta === null || meta === void 0 ? void 0 : meta.total) !== null && _c !== void 0 ? _c : array.length));
        }
    };
    return CrudSearchResult;
}());
export { CrudSearchResult };
var CrudSearchResultMeta = /** @class */ (function () {
    function CrudSearchResultMeta(page, pageSize, total) {
        this.page = page;
        this.pageSize = pageSize;
        this.total = total;
    }
    return CrudSearchResultMeta;
}());
export { CrudSearchResultMeta };
