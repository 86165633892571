import { Pipe, PipeTransform } from '@angular/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'

@Pipe({ name: 'contactTagLabel' })
export class ContactTagLabelPipe implements PipeTransform {

  transform (value: any): string {
    switch (value) {
      case biz.CONTACT_TAGS.PERSONAL:
        return 'label-info'
      case biz.CONTACT_TAGS.WORK:
        return 'label-warning'
      default:
        return ''
    }
  }
}
