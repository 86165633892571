/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../node_modules/ngx-chips/ngx-chips.ngfactory";
import * as i3 from "ngx-chips";
import * as i4 from "@angular/forms";
import * as i5 from "./tags.input";
var styles_TagsInput = [];
var RenderType_TagsInput = i0.ɵcrt({ encapsulation: 2, styles: styles_TagsInput, data: {} });
export { RenderType_TagsInput as RenderType_TagsInput };
function View_TagsInput_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TagsInput_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵand(16777216, [[2, 2]], null, 1, null, View_TagsInput_3)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n      "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.item; var currVal_1 = _co.itemTemplate; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_TagsInput_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n        ", "\n      "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.item[_co.displayBy]; _ck(_v, 1, 0, currVal_0); }); }
function View_TagsInput_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵand(16777216, [[2, 2]], null, 1, null, View_TagsInput_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵand(16777216, [[2, 2]], null, 1, null, View_TagsInput_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.itemTemplate; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.itemTemplate; _ck(_v, 5, 0, currVal_1); }, null); }
function View_TagsInput_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TagsInput_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵand(16777216, [[3, 2]], null, 1, null, View_TagsInput_7)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.item; var currVal_1 = _co.dropdownTemplate; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_TagsInput_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n          ", "\n        "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.item[_co.displayBy]; _ck(_v, 1, 0, currVal_0); }); }
function View_TagsInput_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵand(16777216, [[3, 2]], null, 1, null, View_TagsInput_6)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵand(16777216, [[3, 2]], null, 1, null, View_TagsInput_8)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n      "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dropdownTemplate; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.dropdownTemplate; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TagsInput_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "tag-input", [["placeholder", "+"], ["secondaryPlaceholder", "..."], ["theme", "bootstrap"]], [[1, "contenteditable", 0], [1, "tabindex", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onAdd"], [null, "onRemove"], [null, "onBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.innerValue = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onAdd" === en)) {
        var pd_1 = (_co.onTagsChange() !== false);
        ad = (pd_1 && ad);
    } if (("onRemove" === en)) {
        var pd_2 = (_co.onTagsChange() !== false);
        ad = (pd_2 && ad);
    } if (("onBlur" === en)) {
        var pd_3 = (_co.onBlur() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_TagInputComponent_0, i2.RenderType_TagInputComponent)), i0.ɵdid(1, 4308992, null, 2, i3.TagInputComponent, [i0.Renderer2, i3.ɵc], { displayBy: [0, "displayBy"], identifyBy: [1, "identifyBy"], placeholder: [2, "placeholder"], secondaryPlaceholder: [3, "secondaryPlaceholder"], onlyFromAutocomplete: [4, "onlyFromAutocomplete"], theme: [5, "theme"], clearOnBlur: [6, "clearOnBlur"], disable: [7, "disable"] }, { onAdd: "onAdd", onRemove: "onRemove", onBlur: "onBlur" }), i0.ɵqud(335544320, 1, { dropdown: 0 }), i0.ɵqud(603979776, 2, { templates: 1 }), i0.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TagInputComponent]), i0.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [2, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i0.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [i4.NgControl], null, null), (_l()(), i0.ɵted(-1, 0, ["\n    "])), (_l()(), i0.ɵand(0, [[2, 2]], 0, 0, null, View_TagsInput_1)), (_l()(), i0.ɵted(-1, 0, ["\n  "])), (_l()(), i0.ɵeld(11, 0, null, 0, 5, "tag-input-dropdown", [], null, [["window", "scroll"], ["window", "blur"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).scrollListener() !== false);
        ad = (pd_0 && ad);
    } if (("window:blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12).onWindowBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TagInputDropdown_0, i2.RenderType_TagInputDropdown)), i0.ɵdid(12, 114688, [[1, 4]], 1, i3.TagInputDropdown, [i0.Injector], { showDropdownIfEmpty: [0, "showDropdownIfEmpty"], autocompleteObservable: [1, "autocompleteObservable"], displayBy: [2, "displayBy"], identifyBy: [3, "identifyBy"], appendToBody: [4, "appendToBody"] }, null), i0.ɵqud(603979776, 3, { templates: 1 }), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵand(0, [[3, 2], [2, 2]], null, 0, null, View_TagsInput_5)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, 0, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.displayBy; var currVal_10 = _co.identifyBy; var currVal_11 = "+"; var currVal_12 = "..."; var currVal_13 = true; var currVal_14 = "bootstrap"; var currVal_15 = true; var currVal_16 = _co.readonly; _ck(_v, 1, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_17 = _co.innerValue; _ck(_v, 5, 0, currVal_17); var currVal_18 = false; var currVal_19 = _co.source; var currVal_20 = _co.displayBy; var currVal_21 = _co.identifyBy; var currVal_22 = true; _ck(_v, 12, 0, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTouchDevice(); var currVal_1 = i0.ɵnov(_v, 1).tabindexAttr; var currVal_2 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 7).ngClassValid; var currVal_7 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_TagsInput_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tags-input", [], null, null, null, View_TagsInput_0, RenderType_TagsInput)), i0.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.TagsInput]), i0.ɵdid(2, 49152, null, 0, i5.TagsInput, [], null, null)], null, null); }
var TagsInputNgFactory = i0.ɵccf("tags-input", i5.TagsInput, View_TagsInput_Host_0, { identifyBy: "identifyBy", displayBy: "displayBy", source: "source", dropdownTemplate: "dropdownTemplate", itemTemplate: "itemTemplate", readonly: "readonly", value: "value" }, {}, []);
export { TagsInputNgFactory as TagsInputNgFactory };
