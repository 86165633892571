import { Routes } from '@angular/router';
import { IsUserAllowedGuard, IsUserAlreadyLoggedGuard, IsUserLoggedGuard, IsFormDirtyGuard } from './guards';
import { LayoutsAuthComponent, PAGES } from './layouts/auth/auth';
import { HomeComponent, ReportCardComponent } from './pages';
import { LoginComponent } from './pages';
import { ProfileComponent } from './pages';
import { BatchJobComponent } from './pages';
import { FileJobsComponent } from './pages';
import { ReportsComponent } from './pages';
// custom per app
import { ForcePasswordComponent } from './pages';
import { SubjectComponent, SubjectsComponent } from './pages';
import { DivisionComponent, DivisionsComponent } from './pages';
import { StudentComponent, StudentsComponent } from './pages';
import { GuardianComponent, GuardiansComponent } from './pages';
import { TeacherComponent, TeachersComponent } from './pages';
import { SupervisorComponent, SupervisorsComponent } from './pages';
import { EmployeeComponent, EmployeesComponent } from './pages';
import { TopicsComponent, TopicComponent } from './pages';
import { FeedComponent } from './pages';
import { AlbumComponent } from './pages';
import { AlbumsComponent } from './pages';
import { AttendanceComponent } from './pages';
import { ExamComponent } from './pages';
import { RatingComponent } from './pages';
import { GradesComponent } from './pages';
import { GradeComponent } from './pages';
import { PromotionComponent } from './pages';
var ɵ0 = PAGES.home.data, ɵ1 = PAGES.profile.data, ɵ2 = PAGES.forcePassword.data, ɵ3 = PAGES.batchJobs.data, ɵ4 = PAGES.batchJob.data, ɵ5 = PAGES.subjects.data, ɵ6 = PAGES.subject.data, ɵ7 = PAGES.grades.data, ɵ8 = PAGES.grade.data, ɵ9 = PAGES.divisions.data, ɵ10 = PAGES.division.data, ɵ11 = PAGES.reportCard.data, ɵ12 = PAGES.students.data, ɵ13 = PAGES.student.data, ɵ14 = PAGES.guardians.data, ɵ15 = PAGES.guardian.data, ɵ16 = PAGES.teachers.data, ɵ17 = PAGES.teacher.data, ɵ18 = PAGES.employees.data, ɵ19 = PAGES.employee.data, ɵ20 = PAGES.supervisors.data, ɵ21 = PAGES.supervisor.data, ɵ22 = PAGES.topics.data, ɵ23 = PAGES.topic.data, ɵ24 = PAGES.albums.data, ɵ25 = PAGES.album.data, ɵ26 = PAGES.feed.data, ɵ27 = PAGES.attendance.data, ɵ28 = PAGES.promotion.data, ɵ29 = PAGES.exam.data, ɵ30 = PAGES.rating.data;
var routes = [
    // logged routes
    {
        path: '',
        component: LayoutsAuthComponent,
        canActivate: [IsUserLoggedGuard],
        children: [
            {
                // redirecting to default child route, based on
                // http://stackoverflow.com/a/42590459/2115580
                // http://stackoverflow.com/a/37605802/2115580
                path: '',
                pathMatch: 'full',
                redirectTo: PAGES.home.path
            },
            {
                path: PAGES.home.path,
                component: HomeComponent,
                data: ɵ0
            },
            {
                path: PAGES.profile.path,
                component: ProfileComponent,
                data: ɵ1
            },
            {
                path: PAGES.forcePassword.path,
                component: ForcePasswordComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ2
            },
            {
                path: PAGES.batchJobs.path,
                component: FileJobsComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ3
            },
            {
                path: PAGES.batchJob.path,
                component: BatchJobComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ4
            },
            // custom per app
            // {
            //   path: PAGES.terms.path,
            //   component: TermsComponent,
            //   canActivate: [ IsUserAllowedGuard ],
            //   data: PAGES.terms.data
            // },
            // {
            //   path: PAGES.term.path,
            //   component: TermComponent,
            //   canActivate: [ IsUserAllowedGuard ],
            //   canDeactivate: [ IsFormDirtyGuard ],
            //   data: PAGES.term.data
            // },
            {
                path: PAGES.subjects.path,
                component: SubjectsComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ5
            },
            {
                path: PAGES.subject.path,
                component: SubjectComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ6
            },
            {
                path: PAGES.grades.path,
                component: GradesComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ7
            },
            {
                path: PAGES.grade.path,
                component: GradeComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ8
            },
            {
                path: PAGES.divisions.path,
                component: DivisionsComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ9
            },
            {
                path: PAGES.division.path,
                component: DivisionComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ10
            },
            {
                path: PAGES.reportCard.path,
                component: ReportCardComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ11
            },
            {
                path: PAGES.students.path,
                component: StudentsComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ12
            },
            {
                path: PAGES.student.path,
                component: StudentComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ13
            },
            {
                path: PAGES.guardians.path,
                component: GuardiansComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ14
            },
            {
                path: PAGES.guardian.path,
                component: GuardianComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ15
            },
            {
                path: PAGES.teachers.path,
                component: TeachersComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ16
            },
            {
                path: PAGES.teacher.path,
                component: TeacherComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ17
            },
            {
                path: PAGES.employees.path,
                component: EmployeesComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ18
            },
            {
                path: PAGES.employee.path,
                component: EmployeeComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ19
            },
            {
                path: PAGES.supervisors.path,
                component: SupervisorsComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ20
            },
            {
                path: PAGES.supervisor.path,
                component: SupervisorComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ21
            },
            {
                path: PAGES.topics.path,
                component: TopicsComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ22
            },
            {
                path: PAGES.topic.path,
                component: TopicComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ23
            },
            {
                path: PAGES.albums.path,
                component: AlbumsComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ24
            },
            {
                path: PAGES.album.path,
                component: AlbumComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ25
            },
            {
                path: PAGES.feed.path,
                component: FeedComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ26
            },
            {
                path: PAGES.attendance.path,
                component: AttendanceComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ27
            },
            {
                path: PAGES.promotion.path,
                component: PromotionComponent,
                canActivate: [IsUserAllowedGuard],
                data: ɵ28
            },
            {
                path: PAGES.exam.path,
                component: ExamComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ29
            },
            {
                path: PAGES.rating.path,
                component: RatingComponent,
                canActivate: [IsUserAllowedGuard],
                canDeactivate: [IsFormDirtyGuard],
                data: ɵ30
            },
            {
                path: PAGES.reports.path,
                component: ReportsComponent
            }
        ]
    },
    // not logged routes
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [IsUserAlreadyLoggedGuard]
    },
    // not found route when no match is found
    {
        path: '**',
        redirectTo: ''
    }
];
var AppRoutes = /** @class */ (function () {
    function AppRoutes() {
    }
    return AppRoutes;
}());
export { AppRoutes };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30 };
