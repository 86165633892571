import { Pipe } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import { PrefixedTranslatePipe } from './prefixed-translate.pipe'

@Pipe({
  name: 'gender',
  pure: false
})
export class GenderPipe extends PrefixedTranslatePipe {

  constructor(translatePipe: TranslatePipe) {
    super(translatePipe, 'GENDERS.')
  }
}
