<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true" [tooltip]="'COMMON.BTN_BACK' | translate" placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content">
  <!--
  <div class="search-panel">
    <div class="btn-group btn-strip"
        *ngIf="structure?.length > 1"
        btnRadioGroup
        [(ngModel)]="stageIdx">
      <button class="btn"
          *ngFor="let stage of structure; let i = index"
          [ngStyle]="{ 'width.%': 100 / structure.length }"
          (click)="onStageChange()"
          [btnRadio]="i">
        {{ stage.name }}
      </button>
    </div>
    <div class="btn-group btn-strip"
        *ngIf="stage?.grades?.length > 1"
        btnRadioGroup
        [(ngModel)]="gradeIdx">
      <button class="btn"
          *ngFor="let grade of stage?.grades; let i = index"
          [ngStyle]="{ 'width.%': 100 / stage.grades.length }"
          (click)="onGradeChange()"
          [btnRadio]="i">
        {{ grade.name }}
      </button>
    </div>
    <div class="btn-group btn-strip"
        *ngIf="grade?.divisions?.length > 0"
        btnRadioGroup
        [(ngModel)]="divisionIdx">
      <button class="btn"
          *ngFor="let division of grade?.divisions; let i = index"
          [ngStyle]="{ 'width.%': 100 / grade.divisions.length }"
          (click)="onDivisionChange()"
          [btnRadio]="i">
        {{ division.name }}
      </button>
    </div>
  </div>
-->
  <div class="row search-panel">
    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'COMMON.DATE' | translate }}</label>
      <datepicker-popup [(ngModel)]="selectedDate" [maxDate]="today" [locale]="locale"
        (change)="onSelectedDateChange()">
      </datepicker-popup>
    </div>
    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'GRADE.DETAIL_TITLE' | translate }}</label>
      <select class="form-control" [(ngModel)]="gradeIdx" (change)="onGradeChange()">
        <option *ngFor="let grade of stage?.grades; let i = index" [ngValue]="i">
          {{ grade.name }}
        </option>
      </select>
    </div>
    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'DIVISION.DETAIL_TITLE' | translate }}</label>
      <select class="form-control" [(ngModel)]="divisionIdx" (change)="onDivisionChange()">
        <option *ngFor="let division of grade?.divisions; let i = index" [ngValue]="i">
          {{ division.name }}
        </option>
      </select>
    </div>
  </div>

  <div class="text-center text-muted" *ngIf="isLoading">
    <i class="fa fa-circle-o-notch fa-spin"></i>
  </div>

  <div class="text-center text-muted" *ngIf="!isLoading && !students?.length">
    {{ 'COMMON.MSG_NO_DATA' | translate }}
  </div>

  <table class="table table-striped table-hover table-fixed table-attendance" *ngIf="!isLoading && !!students.length">
    <thead>
      <tr>
        <th class="student">
          {{ 'STUDENT.DETAIL_TITLE' | translate }}
        </th>
        <th class="text-center attendance" *ngFor="let day of days; let i = index" (click)="changeSelectedDate(day)"
          [ngClass]="{ available: day <= today, weekend: i > 4, active: day === selected }">
          <span>{{ day | amDateFormat: 'D MMM' }}</span>
          <br>
          <span class="text-muted">{{ day | amDateFormat: 'dddd' }}</span>
        </th>
      </tr>
    </thead>
    <tbody [formGroup]="form">
      <tr *ngFor="let student of students; let i = index">
        <td>
          <a routerLink="/students/{{ student._id }}">
            {{ student.name | username }}
          </a>
        </td>
        <td class="text-center attendance" *ngFor="let day of days; let i = index"
          [ngClass]="{ available: day <= today, active: day === selected, weekend: i > 4 }">

          <span [formGroupName]="'first'">
            <div class="btn-group" btnRadioGroup *ngIf="day === selected && form.get('first').get(student._id)"
              [formControlName]="student._id">
              <div *ngIf="shiftType === 'double'" style="color: #666;">{{ firstShiftName }}:</div>
              <button type="button" class="btn btn-presence" btnRadio="presence" tooltip="Presente">
                <i class="fa fa-check"></i>
              </button>
              <button type="button" class="btn btn-late" btnRadio="late" tooltip="Tarde">
                <i class="fa fa-clock-o"></i>
              </button>
              <button type="button" class="btn btn-absence" btnRadio="absence" tooltip="Ausente">
                <i class="fa fa-times"></i>
              </button>
              <button type="button" class="btn btn-justified" btnRadio="justified" tooltip="Justificada">
                <i class="fa fa-times-circle-o"></i>
              </button>
              <button type="button" class="btn btn-early_pick_up" tooltip="Retiro anticipado" (click)="onEarlyPickUpClick('first_alt', student._id)" [class.active]="form.get('first_alt').get(student._id).value === 'early_pick_up'">
                <i class="fa fa-caret-square-o-right"></i>
              </button>
            </div>
          </span>

          <span [formGroupName]="'second'">
            <div class="btn-group" btnRadioGroup
              *ngIf="shiftType === 'double' && day === selected && form.get('second').get(student._id)"
              [formControlName]="student._id">
              <div *ngIf="shiftType === 'double'" style="color: #666;margin-top: 4px;">{{ secondShiftName }}:</div>
              <button type="button" class="btn btn-presence" btnRadio="presence" tooltip="Presente">
                <i class="fa fa-check"></i>
              </button>
              <button type="button" class="btn btn-late" btnRadio="late" tooltip="Tarde">
                <i class="fa fa-clock-o"></i>
              </button>
              <button type="button" class="btn btn-absence" btnRadio="absence" tooltip="Ausente">
                <i class="fa fa-times"></i>
              </button>
              <button type="button" class="btn btn-justified" btnRadio="justified" tooltip="Justificada">
                <i class="fa fa-times-circle-o"></i>
              </button>
              <button type="button" class="btn btn-early_pick_up" tooltip="Retiro anticipado" (click)="onEarlyPickUpClick('second_alt', student._id)" [class.active]="form.get('second_alt').get(student._id).value === 'early_pick_up'">
                <i class="fa fa-caret-square-o-right"></i>
              </button>
            </div>
          </span>

          <span *ngIf="day <= today && day !== selected">
            <div [ngSwitch]="weekSheets['first'][day] && weekSheets['first'][day][student._id]">
              <i class="fa fa-check text-muted" *ngSwitchCase="'presence'"></i>
              <span class="fa-stack text-warning" aria-hidden="true" *ngSwitchCase="'late'"
                [tooltip]="'late' | topicType" placement="bottom">
                <i class="fa fa-square-o fa-stack-2x"></i>
                <i class="fa fa-clock-o fa-stack-1x"></i>
              </span>
              <span class="fa-stack text-danger" aria-hidden="true" *ngSwitchCase="'absence'"
                [tooltip]="'absence' | topicType" placement="bottom">
                <i class="fa fa-square-o fa-stack-2x"></i>
                <i class="fa fa-times fa-stack-1x"></i>
              </span>
              <span class="fa-stack text-info" aria-hidden="true" *ngSwitchCase="'justified'"
                [tooltip]="'justified' | topicType" placement="bottom">
                <i class="fa fa-square-o fa-stack-2x"></i>
                <i class="fa fa-times-circle-o fa-stack-1x"></i>
              </span>
              <span style="margin-left: 4px;" class="fa-stack text-dark" aria-hidden="true" *ngIf="weekSheets['first_alt'][day][student._id] === 'early_pick_up'"
                [tooltip]="'early_pick_up' | topicType" placement="bottom">
                <i class="fa fa-square-o fa-stack-2x"></i>
                <i class="fa fa-caret-square-o-right fa-stack-1x"></i>
              </span>
              <!--
              <i class="fa fa-check text-muted" *ngSwitchCase="'presence'"></i>
              <i class="fa fa-clock-o text-warning" *ngSwitchCase="'late'"></i>
              <i class="fa fa-times text-danger" *ngSwitchCase="'absence'"></i>
              <i class="fa fa-times-circle-o text-info" *ngSwitchCase="'justified'"></i> -->
              <!-- <span *ngSwitchDefault>{{ day }} | {{ student._id }}</span> -->
            </div>
            <div *ngIf="shiftType === 'double'"
              [ngSwitch]="weekSheets['second'][day] && weekSheets['second'][day][student._id]"
              style="margin-top: 32px;">
              <i class="fa fa-check text-muted" *ngSwitchCase="'presence'"></i>
              <span class="fa-stack text-warning" aria-hidden="true" *ngSwitchCase="'late'"
                [tooltip]="'late' | topicType" placement="bottom">
                <i class="fa fa-square-o fa-stack-2x"></i>
                <i class="fa fa-clock-o fa-stack-1x"></i>
              </span>
              <span class="fa-stack text-danger" aria-hidden="true" *ngSwitchCase="'absence'"
                [tooltip]="'absence' | topicType" placement="bottom">
                <i class="fa fa-square-o fa-stack-2x"></i>
                <i class="fa fa-times fa-stack-1x"></i>
              </span>
              <span class="fa-stack text-info" aria-hidden="true" *ngSwitchCase="'justified'"
                [tooltip]="'justified' | topicType" placement="bottom">
                <i class="fa fa-square-o fa-stack-2x"></i>
                <i class="fa fa-times-circle-o fa-stack-1x"></i>
              </span>
              <span style="margin-left: 4px;" class="fa-stack text-dark" aria-hidden="true" *ngIf="weekSheets['second_alt'][day][student._id] === 'early_pick_up'"
                [tooltip]="'early_pick_up' | topicType" placement="bottom">
                <i class="fa fa-square-o fa-stack-2x"></i>
                <i class="fa fa-caret-square-o-right fa-stack-1x"></i>
              </span>
              <!--
              <i class="fa fa-check text-muted" *ngSwitchCase="'presence'"></i>
              <i class="fa fa-clock-o text-warning" *ngSwitchCase="'late'"></i>
              <i class="fa fa-times text-danger" *ngSwitchCase="'absence'"></i>
              <i class="fa fa-times-circle-o text-info" *ngSwitchCase="'justified'"></i> -->
              <!-- <span *ngSwitchDefault>{{ day }} | {{ student._id }}</span> -->
            </div>
          </span>

          <span *ngIf="day > today || (day === selected && !form.get(_id))">
          </span>

        </td>
      </tr>
    </tbody>
  </table>
</section>

<div class="sticky-footer">
  <div class="btn-toolbar" role="toolbar">
    <div class="btn-group" role="group" *ngIf="isSaveAllowed && students">
      <button type="button" class="btn btn-success btn-lg" (click)="submit()" [disabled]="!students?.length"
        [ladda]="isSubmitting">
        {{ 'COMMON.BTN_SAVE' | translate }}
      </button>
    </div>
    <div class="btn-group" role="group">
      <button type="button" class="btn btn-default btn-lg" (click)="goBack()">
        {{ 'COMMON.BTN_CANCEL' | translate }}
      </button>
    </div>

    <!--     <div class="pull-right-sm">
      <i class="fa fa-fw fa-chevron-left clickable"></i>
      {{ days[0] | amDateFormat: 'D MMM' }} - {{ days[4] | amDateFormat: 'D MMM' }}
      <i class="fa fa-fw fa-chevron-right clickable"></i>
    </div> -->
    <!--     <div class="btn-group pull-right-md" role="group" *ngIf="isDeleteAllowed">
      <button
          type="button"
          class="btn btn-danger btn-lg"
          (click)="remove()"
          [ladda]="isSubmitting">
        {{ 'COMMON.BTN_REMOVE' | translate }}
      </button>
    </div> -->
  </div>
</div>