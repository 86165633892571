<div>
  <div class="input-group">
    <input type="text"
        class="form-control"
        bsDatepicker
        #popup="bsDatepicker"
        [bsConfig]="config"
        [minDate]="innerMinDate"
        [maxDate]="innerMaxDate"
        (bsValueChange)="onModelChange($event)"
        (blur)="onBlur()"
        [(ngModel)]="innerValue" />
    <div class="input-group-addon clickable" (click)="popup.toggle()">
      <i class="fa fa-calendar"></i>
    </div>
  </div>
</div>
        <!-- (focus)="popup.show()" -->
        <!-- (blur)="popup.hide(); onBlur()" -->
