<section class="content">
  <div class="row content">
    <img src="/public/assets/img/home.png" style="width: 60%; margin-left: 20%">

    <p> Tutoriales:  </p>
      <a href="https://res.cloudinary.com/nexedu-prod2/image/upload/v1619444787/sp3dpkhzztf872l8tdhl.pdf" target="_blank">  Padres y Alumnos</a>
      <br> 
       <a href="https://res.cloudinary.com/nexedu-prod2/image/upload/v1619444786/qtizir6z7u557frjscj1.pdf" target="_blank">  Docentes</a>
       <br>  
       <a href="https://res.cloudinary.com/nexedu-prod2/image/upload/v1619444786/zo2ukz5dqp8ndavma0x3.pdf" target="_blank">  Supervisores y Administrativos</a>
       <br>
 
    <p>Para soporte técnico envía un correo a <a href="mailto:contacto@nexedu.com.ar">contacto@nexedu.com.ar</a> o comunicate por Whatsapp al <a href="wa.me/5491165841301/?text=Hola!" target="_blank">1165841301</a>  </p>
  </div>
</section>
