import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as XLSX from 'xlsx';


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { QueryString, Column } from '../../models';
import { ViewService, DivisionService, StudentService, AppTranslateService } from '../../services';
import { User, UserValidator, UserFormGroup, CrudService } from '../../interfaces';
import { UsernamePipe } from '../../pipes';
import { StudentsTab } from '../students-tab';
@Component({
    selector: 'division-students-tab',
    templateUrl: './division-students.tab.html',
    styleUrls: ['./division-students.tab.styl']
})
export class DivisionStudentsTab extends StudentsTab {
    @Input()


    // @ts-expect-error ts-migrate(2564) FIXME: Property 'divisionId' has no initializer and is no... Remove this comment to see the full error message
    divisionId: string;
    public mode: string = 'search';
    public isSubmitting: boolean = false;
    public isRemoving: boolean = false;


    // @ts-expect-error ts-migrate(2564) FIXME: Property 'form' has no initializer and is not defi... Remove this comment to see the full error message
    public form: UserFormGroup;


    // @ts-expect-error ts-migrate(2564) FIXME: Property 'isCreate' has no initializer and is not ... Remove this comment to see the full error message
    public isCreate: boolean;
    public isSaveAllowed: boolean = false;


    // @ts-expect-error ts-migrate(2564) FIXME: Property 'students' has no initializer and is not ... Remove this comment to see the full error message
    public students: string[];
    private mapper: StudentMapper;
    constructor(viewService: ViewService, private service: DivisionService, private studentService: StudentService, private usernamePipe: UsernamePipe) {
        super(viewService);
        this.mapper = new StudentMapper(viewService.translate);
    }
    public switchToInclusionMode() {
        this.students = [];
        this.mode = 'inclusion';
    }
    public switchToEditionMode(student: User = {
        gender: biz.GENDERS.FEMALE
    } as User) {
        this.form = UserValidator.newFormGroup();
        this.form.patchValue(student);
        this.isCreate = !!student;
        const permission = this.isCreate ? 'create' : 'update';
        this.isSaveAllowed = this.viewService.isAllowed('student', permission);
        this.mode = 'edition';
    }
    public switchToSearchMode() {
        this.createColumns();
        if (this.form) {
            this.form.reset();


            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'UserFo... Remove this comment to see the full error message
            this.form = undefined;
        }
        this.updateFilter();
        this.mode = 'search';
    }
    public submit() {
        if (!this.isSaveAllowed) {
            console.warn('User not allowed to save changes');
            return;
        }
        else if (!this.form || !this.form.valid) { // be extra sure
            return this.viewService.toastr.warningT('COMMON.MSG_INVALID_FORM');
        }
        this.isSubmitting = true;
        const onSuccess = () => { this.switchToSearchMode(); };
        const onFinally = () => { this.isSubmitting = false; };
        const model = this.form.value;
        const service = new StudentWrapperService(this.divisionId, this.service);
        const studentId = !this.isCreate && model._id;
        this.viewService.save(studentId, service, this.form, onSuccess, onFinally, model);
    }
    public include() {
        if (!this.students || !this.students.length) {
            return;
        }
        this.isSubmitting = true;
        this.service.includeStudents(this.divisionId, this.students)
            .finally(() => { this.isSubmitting = false; })
            // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
            .subscribe(data => {
            this.viewService.toastr.successT('DIVISION.MSG_ADD_STUDENT_OK');
            this.switchToSearchMode();
        }, this.viewService.handleError);
    }
    public loadStudents = (filter: string) => {
        // retain the scope to get access to instance properties
        // https://www.npmjs.com/package/ng2-tag-input#api-for-taginputdropdowncomponent
        const params = new QueryString({
            limit: 20,
            q: filter,
            sort: {
                'name.last': 1,
                'name.first': 1
            },
            populate: 'division'
        });
        return this.studentService.search(params)
            .map(result => {
            return result.list.map((student: any) => {
                student.fullName = this.usernamePipe.transform(student.name);
                return student;
            });
        }, this.viewService.handleError);
    };
    public export() {
        // https://github.com/SheetJS/js-xlsx/blob/master/demos/angular2/src/app/sheetjs.component.ts
        // generate workbook
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // prepare data to be written in the exported file
        const output = this.mapper.mapAll(this.rows);
        // generate worksheet and add it to workbook
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(output);
        XLSX.utils.book_append_sheet(wb, ws, 'Listado');
        // save to file
        // https://github.com/SheetJS/js-xlsx/blob/9866dfc010338394e4cfcd33a9fbc15dae017ee5/types/doc.ts#L29
        const wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array', Props: { Author: 'APESA', Company: 'APESA' } };
        XLSX.writeFile(wb, `Alumnos.xlsx`, wopts);
    }
    protected fetch(params: QueryString): Observable<any> {
        return this.service.getStudents(this.divisionId, params);
    }
    protected createColumns(): void {
        super.createColumns();
        const canRemove = this.viewService.isCurrentTermSelected();
        const actions = [{
                class: 'fa-times action-icon-danger',
                onClick: this.onRemove.bind(this),
                visible: () => canRemove
            }];
        this.columns.push(Column.forActions(actions, 50));
    }
    private onRemove(row: any) {
        this.isRemoving = true;
        this.service.removeStudent(this.divisionId, row._id)
            .finally(() => this.isRemoving = false)
            // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
            .subscribe(data => {
            this.viewService.toastr.successT('COMMON.MSG_OPERATION_OK');
            const index = this.rows.findIndex(student => (student as any)._id === row._id);
            this.rows.splice(index, 1);
            this.count = this.rows.length;
        }, this.viewService.handleError);
    }
}
class StudentWrapperService extends CrudService<User> {
    constructor(private id: string, private service: DivisionService) {


        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        super(null, '');
    }
    public create(object: any): Observable<any> {
        return this.service.addStudent(this.id, object);
    }


    // @ts-expect-error ts-migrate(6133) FIXME: 'id' is declared but its value is never read.
    public update(id: string, object: any): Observable<any> {
        throw new Error('This method should never be called');
        // return this.service.updateStudent(this.id, id, object)
    }
}
class StudentMapper {
    // FIXME a better solution would be to map columns to translated titles


    // @ts-expect-error ts-migrate(2564) FIXME: Property 'headers' has no initializer and is not d... Remove this comment to see the full error message
    public headers: string[];
    constructor(translateSvc: AppTranslateService) {
        const headerCodes = [
            'USER.ID',
            'USER.LAST_NAME',
            'USER.FIRST_NAME',
            'TOPIC_TYPES.late',
            'TOPIC_TYPES.absence',
            'TOPIC_TYPES.justified',
            'TOPIC_TYPES.early_pick_up',
        ];
        Observable.from(headerCodes)
            .mergeMap(code => translateSvc.getTranslate(code))
            .toArray()
            .subscribe(headers => this.headers = headers);
    }
    public map(v: any) {
        return [
            v._id,
            v.name.last,
            v.name.first,
            v.membership.stats.late,
            v.membership.stats.absence,
            v.membership.stats.justified,
            v.membership.stats.early_pick_up,
        ];
    }
    public mapAll(data: any) {
        const output = [this.headers];
        data.forEach((item: any) => {
            output.push(this.map(item));
        });
        return output;
    }
}
