// based on
// https://github.com/l10178/angular-pretty-size/blob/master/src/app/prettysize/pretty-size.pipe.ts

import { Pipe, PipeTransform } from '@angular/core'
import { PrettySizeService } from '../services/pretty-size.service'

@Pipe({
  name: 'prettySize'
})
export class PrettySizePipe implements PipeTransform {

  constructor (private prettySizeService: PrettySizeService,) {
  }

  transform (value: number): string {
    return this.prettySizeService.pretty(value);
  }
}
