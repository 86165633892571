<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="reset()">
      <i class="fa fa-refresh" aria-hidden="true"
          [tooltip]="'COMMON.BTN_REFRESH' | translate"
          placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content">
  <div class="row">
    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8 col-lg-offset-3 col-lg-6">
      <feed-post *ngFor="let topic of topics" [topic]="topic"></feed-post>
      <div *ngIf="hasMore">
        <button type="button" class="btn btn-default btn-block" (click)="getNextPage()">
          {{ 'COMMON.BTN_LOAD_MORE' | translate }}
        </button>
      </div>
    </div>
  </div>
</section>
