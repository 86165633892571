import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { StageDependantCrudService, User } from '../interfaces'
import { QueryString } from '../models'
import { AuthService } from './auth.service'

@Injectable()
export class GuardianService extends StageDependantCrudService<User> {

  constructor (http: HttpClient, authService: AuthService) {
    super(http, '/guardians', authService)
  }

  public getStudents (id: string, params: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/students`, this.toRequestOptions(params))
  }
}
