

// @ts-expect-error ts-migrate(6133) FIXME: 'OnInit' is declared but its value is never read.
import { Component, OnInit, Input } from '@angular/core'

@Component( {
  /* tslint:disable */
  selector: 'report-box',
  /* tslint:enable */
  templateUrl: './report-box.component.html',
  styleUrls: ['./report-box.component.styl'],
})
export class ReportBoxComponent {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'iconClass' has no initializer and is not... Remove this comment to see the full error message
  @Input() iconClass: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'translationEntry' has no initializer and... Remove this comment to see the full error message
  @Input() translationEntry: string
  public isCollapsed = true

  constructor (
  ) {
  }

  public toggleCollapsed () {
    this.isCollapsed = !this.isCollapsed
  }
}
