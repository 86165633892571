import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SubjectService, ViewService } from '../../services'
import { DetailView, SubjectValidator, Subject } from '../../interfaces'

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html'
})

export class SubjectComponent extends DetailView<Subject> {

  constructor (
    viewService: ViewService,
    service: SubjectService,
    route: ActivatedRoute
  ) {
    super(viewService, service, route, 'subject', SubjectValidator.newFormGroup())
    this.deletePermission = 'remove'
  }

  protected getValue () {
    return this.form.value
  }
}
