import { EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
// @ts-expect-error ts-migrate(6133) FIXME: 'StageService' is declared but its value is never ... Remove this comment to see the full error message
import { DivisionService, StudentService, ViewService } from '../../services';
import { QueryString } from '../../models';
// @ts-expect-error ts-migrate(6133) FIXME: 'Stage' is declared but its value is never read.
import { Recipient, User, Division } from '../../interfaces';
var INPUT_PATTERN = /^(\$|#|@)/; // /^(\$|#|@).+/
// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export var RECIPIENTS_SELECTOR_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return RecipientsSelector; }),
    multi: true
};
var RecipientsSelector = /** @class */ (function () {
    function RecipientsSelector(divisionService, studentService, viewService) {
        var _a;
        var _this = this;
        this.divisionService = divisionService;
        this.studentService = studentService;
        this.viewService = viewService;
        this.readonly = false;
        this.onFocus = new EventEmitter();
        this.onBlur = new EventEmitter();
        this.onLoadingStart = new EventEmitter();
        this.onLoadingEnd = new EventEmitter();
        this.onChange = Function.prototype;
        this.onTouched = Function.prototype;
        this.innerValue = [];
        this.RECIPIENT_TYPES = biz.values(biz.RECIPIENT_TYPES);
        this.loadStudents = function (filter) {
            var params = _this.getParams(filter, 20);
            return _this.studentService.search(params);
        };
        this.loadDivisions = function (filter) {
            var params = _this.getParams(filter);
            return _this.divisionService.search(params);
        };
        this.recipientsConfig = (_a = {},
            _a[biz.RECIPIENT_TYPES.USER] = { loader: this.loadStudents, mapper: this.mapUserToItem, char: '@' },
            _a[biz.RECIPIENT_TYPES.DIVISION] = { loader: this.loadDivisions, mapper: this.mapDivisionToItem, char: '#' },
            _a);
        this.source = function (filter) {
            // retain the scope to get access to instance properties
            // https://www.npmjs.com/package/ng2-tag-input#api-for-taginputdropdowncomponent
            // if there's only one recipient type, search for it and ignore the first character if it's a special one
            if (_this._recipientTypes.length === 1) {
                // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                var recipientConfig = _this.recipientsConfig[_this._recipientTypes[0]];
                if (filter.charAt(0) === recipientConfig.char) {
                    filter = filter.slice(1);
                }
                return _this.loadRecipients(filter, recipientConfig.loader, recipientConfig.mapper);
            }
            // iterate over allowed recipient types and check
            for (var i = _this._recipientTypes.length - 1; i >= 0; i--) {
                // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                var recipientConfig = _this.recipientsConfig[_this._recipientTypes[i]];
                if (filter.charAt(0) === recipientConfig.char) {
                    filter = filter.slice(1);
                    return _this.loadRecipients(filter, recipientConfig.loader, recipientConfig.mapper);
                }
            }
            // if it gets to this point no initial character was matched, so search for students if they are allowed
            if (_this._recipientTypes.indexOf(biz.RECIPIENT_TYPES.USER) > -1) {
                return _this.loadRecipients(filter, _this.loadStudents, _this.mapUserToItem);
            }
            // always return an observable
            return Observable.of([]);
        };
    }
    Object.defineProperty(RecipientsSelector.prototype, "recipientTypes", {
        get: function () {
            return this._recipientTypes;
        },
        set: function (recipientTypes) {
            var _this = this;
            recipientTypes = recipientTypes || [];
            // remove invalid types
            if (recipientTypes.length) {
                this._recipientTypes = recipientTypes.filter(function (recipientType) {
                    return _this.RECIPIENT_TYPES.find(function (type) { return recipientType === type; });
                });
            }
            // consider all the types if none is left in the list provided
            if (!recipientTypes.length) {
                this._recipientTypes = this.RECIPIENT_TYPES;
            }
            // Quick fix, remove stages from the recipient types
            this._recipientTypes = this._recipientTypes.filter(function (type) { return type !== biz.RECIPIENT_TYPES.STAGE; });
            // remove existing recipients non included in the restricted list
            this.innerValue = this.innerValue.filter(function (item) {
                return _this._recipientTypes.find(item.type);
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RecipientsSelector.prototype, "value", {
        get: function () {
            return this.innerValue.map(function (item) {
                return {
                    type: item.type,
                    reference: item.id
                };
            });
        },
        set: function (_value) {
            var _this = this;
            _value = _value || [];
            this.innerValue = _value.map(function (recipient) {
                var validType = _this._recipientTypes.find(function (type) { return type === recipient.type; });
                if (validType) {
                    // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                    return _this.recipientsConfig[recipient.type].mapper(recipient.reference);
                }
            }).filter(function (elem) { return elem; }); // Filter out any undefined elements inside the array
        },
        enumerable: false,
        configurable: true
    });
    RecipientsSelector.prototype.removeItem = function (index) {
        // this was not working properly when removing through the X button on each tag item
        // const item = this.innerValue.splice(index, 1)[0]
        var item = this.innerValue[index];
        if (item) {
            this.tagInput.removeItem(item, index);
        }
    };
    RecipientsSelector.prototype.getItemClass = function (item) {
        switch (item.type) {
            case biz.RECIPIENT_TYPES.DIVISION:
                return 'tag__wrapper_2';
            case biz.RECIPIENT_TYPES.USER:
                return 'tag__wrapper_1';
            default:
                return 'tag__wrapper_4';
        }
    };
    RecipientsSelector.prototype.onInputFocused = function (value) {
        this.onFocus.emit(value);
    };
    RecipientsSelector.prototype.onInputBlurred = function (value) {
        this.onBlur.emit(value);
        this.onTouched();
    };
    RecipientsSelector.prototype.onSelectionChange = function () {
        this.onChange(this.value);
    };
    RecipientsSelector.prototype.matchingFn = function (value, target) {
        // console.log(`${value} - ${JSON.stringify(target)}`)
        // discard tag character
        if (INPUT_PATTERN.test(value)) {
            value = value.slice(1);
        }
        var targetValue = target.itemName.toString();
        return targetValue && targetValue
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0;
    };
    RecipientsSelector.prototype.loadRecipients = function (filter, loader, mapper) {
        var _this = this;
        this.onLoadingStart.emit();
        return loader(filter)
            .finally(function () { _this.onLoadingEnd.emit(); })
            .map(function (result) { return result.list.map(mapper); }, this.viewService.handleError);
    };
    RecipientsSelector.prototype.getParams = function (filter, limit) {
        var props = {
            q: filter
        };
        if (limit) {
            props.limit = limit;
        }
        return new QueryString(props);
    };
    RecipientsSelector.prototype.mapUserToItem = function (user) {
        return {
            id: user._id,
            itemName: user.name.last + ", " + user.name.first,
            type: biz.RECIPIENT_TYPES.USER
        };
    };
    RecipientsSelector.prototype.mapDivisionToItem = function (division) {
        return {
            id: division._id,
            itemName: division.name,
            type: biz.RECIPIENT_TYPES.DIVISION
        };
    };
    // ControlValueAccessor
    // model -> view
    RecipientsSelector.prototype.writeValue = function (value) {
        this.value = value;
    };
    RecipientsSelector.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    RecipientsSelector.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    return RecipientsSelector;
}());
export { RecipientsSelector };
