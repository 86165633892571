import { Pipe, PipeTransform } from '@angular/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'mome... Remove this comment to see the full error message
import * as moment from 'moment-timezone'

@Pipe({name: 'shortDate'})
export class ShortDatePipe implements PipeTransform {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'locale' has no initializer and is not de... Remove this comment to see the full error message
  public locale: string

  transform (value: any, locale: string): string {


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    if (!value) { return }

    value = moment(value)
    locale = locale || this.locale
    if (locale) {
      value.locale(locale)
    }
    return value.format('L')
  }
}
