<div class="row">
  <div class="col-xs-12">

    <form class="form-fixed-help"
        [formGroup]="form"
        (ngSubmit)="submit()">

      <div class="row">
        <div class="form-group col-sm-3"
            [ngClass]="{ 'has-error': !form.controls.subject.valid && form.controls.subject.touched }">
          <label>
            {{ 'SUBJECT.DETAIL_TITLE' | translate }}
            <required-icon [visible]="mode == 'create' || mode == 'edit'"></required-icon>
          </label>
          <select class="form-control"
              *ngIf="mode=='create'"
              formControlName="subject"
              (change)="onSubjectChosen()">
            <option *ngFor="let subject of subjects" [ngValue]="subject._id">
              {{ subject.name }}
            </option>
          </select>
          <div class="form-control-static" *ngIf="mode=='edit' || mode=='readonly'">
            {{ model.subject.name }}
          </div>
          <span class="help-block">
            <span *ngIf="form.controls.subject.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
          </span>
        </div>

        <div class="form-group col-sm-6"
            [ngClass]="{ 'has-error': !form.controls.teachers.valid && form.controls.teachers.touched }">
          <label>
            {{ 'TEACHER.SEARCH_TITLE' | translate }}
            <required-icon></required-icon>
          </label>
          <tags-input
              formControlName="teachers"
              identifyBy="_id"
              displayBy="fullName"
              [source]="searchTeachers">
          </tags-input>
          <span class="help-block">
            <span *ngIf="form.controls.teachers.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
          </span>
        </div>

      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="btn-toolbar btn-footer" role='toolbar'>
            <button
                type="submit"
                class="btn btn-success btn-lg"
                *ngIf="mode == 'create' || mode == 'edit'"
                [disabled]="!form.valid"
                [ladda]="isSubmitting">
              {{ 'COMMON.BTN_SAVE' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-default btn-lg"
                (click)="cancel()">
              {{ 'COMMON.BTN_CANCEL' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-danger btn-lg pull-right-md"
                *ngIf="false"
                (click)="remove()"
                [ladda]="isSubmitting">
              {{ 'COMMON.BTN_REMOVE' | translate }}
            </button>
          </div>
        </div>
      </div>

    </form>

  </div>
</div>
