/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/angular2-toaster/src/toaster-container.component.ngfactory";
import * as i2 from "angular2-toaster/src/toaster-container.component";
import * as i3 from "angular2-toaster/src/toaster.service";
import * as i4 from "./app-toastr.component";
var styles_AppToastrComponent = [];
var RenderType_AppToastrComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppToastrComponent, data: {} });
export { RenderType_AppToastrComponent as RenderType_AppToastrComponent };
export function View_AppToastrComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "toaster-container", [], null, null, null, i1.View_ToasterContainerComponent_0, i1.RenderType_ToasterContainerComponent)), i0.ɵdid(1, 245760, null, 0, i2.ToasterContainerComponent, [i3.ToasterService, i0.ChangeDetectorRef, i0.NgZone], { toasterconfig: [0, "toasterconfig"] }, null), (_l()(), i0.ɵted(-1, null, [" "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toastrConfig; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppToastrComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-toastr", [], null, null, null, View_AppToastrComponent_0, RenderType_AppToastrComponent)), i0.ɵdid(1, 49152, null, 0, i4.AppToastrComponent, [], null, null)], null, null); }
var AppToastrComponentNgFactory = i0.ɵccf("app-toastr", i4.AppToastrComponent, View_AppToastrComponent_Host_0, {}, {}, []);
export { AppToastrComponentNgFactory as AppToastrComponentNgFactory };
