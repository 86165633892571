// @ts-expect-error ts-migrate(6133) FIXME: 'PipeTransform' is declared but its value is never... Remove this comment to see the full error message
import { TemplateRef, EventEmitter } from '@angular/core';
import * as _ from 'lodash-es';
import { SortDirection } from '@swimlane/ngx-datatable';
var AppDataTableComponent = /** @class */ (function () {
    function AppDataTableComponent() {
        this.selectEmitter = new EventEmitter();
        this.pageEmitter = new EventEmitter();
        this.sortEmitter = new EventEmitter();
        this.loading = false;
        this._iconsConfig = {
            sortAscending: 'fa fa-caret-up',
            sortDescending: 'fa fa-caret-down'
        };
        this._sorts = [];
        this._columns = [];
        this._rows = [];
        this._limit = 0;
        this._count = 0;
        this._page = 1;
        this._internalChange = false;
    }
    Object.defineProperty(AppDataTableComponent.prototype, "page", {
        set: function (val) {
            this._page = val;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppDataTableComponent.prototype, "rows", {
        set: function (vals) {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
            this._rows = vals;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppDataTableComponent.prototype, "limit", {
        set: function (val) {
            this._limit = val;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppDataTableComponent.prototype, "count", {
        set: function (val) {
            if (val <= this._limit && this._page !== 1) {
                this._internalChange = true;
                this._page = 1;
            }
            this._count = val;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppDataTableComponent.prototype, "columns", {
        set: function (cols) {
            var _this = this;
            if (!_.isEmpty(cols)) {
                _.each(cols, function (col) {
                    if (col) {
                        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        col.headerTemplate = col.headerTemplate ? _this[col.headerTemplate] : _this.colHeader;
                        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        col.cellTemplate = col.cellTemplateName ? _this[col.cellTemplateName] : _this.defaultCell;
                    }
                });
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
                this._columns = cols;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppDataTableComponent.prototype, "sorts", {
        set: function (sorts) {
            this._sorts = _.map(sorts, function (value, key) {
                return {
                    prop: key,
                    dir: value === 1 ? SortDirection.asc : SortDirection.desc
                };
            });
        },
        enumerable: false,
        configurable: true
    });
    AppDataTableComponent.prototype.onSelect = function (_a) {
        var selected = _a.selected;
        this.selectEmitter.next(selected[0]);
    };
    AppDataTableComponent.prototype.onSort = function (event) {
        var _a;
        this._page = 1;
        var sort = (_a = {},
            _a[event.sorts[0].prop] = event.sorts[0].dir === SortDirection.asc ? 1 : -1,
            _a);
        this.sortEmitter.next({
            sort: sort,
            page: this._page,
            offset: this._page - 1 // backward compatibility
        });
    };
    AppDataTableComponent.prototype.onPage = function (event) {
        if (this._internalChange) {
            this._internalChange = false;
            return;
        }
        event.offset = event.page - 1; // for backward compatibility
        this.pageEmitter.next(event);
    };
    return AppDataTableComponent;
}());
export { AppDataTableComponent };
