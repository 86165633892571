import { FormControl, FormGroup, Validators } from '@angular/forms'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { AppValidators } from '../validators'

export interface PasswordChange {
  password: string
  newPassword: string
}

export class PasswordChangeValidator {

  public static newFormGroup (): FormGroup {
    const password = new FormControl('', [ Validators.required, Validators.minLength(biz.PASSWORD_MIN_LENGTH) ])
    const newPassword = new FormControl('', [ Validators.required, Validators.minLength(biz.PASSWORD_MIN_LENGTH) ])
    const rePassword = new FormControl('', Validators.required)

    return new FormGroup(
      {
        password,
        newPassword,
        rePassword
      }, Validators.compose([
        AppValidators.matchedFields('password', 'newPassword'),
        AppValidators.mismatchedFields('newPassword', 'rePassword')
      ])
    )
  }
}
