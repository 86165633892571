var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as _ from 'lodash-es';
import { SearchPanel } from './search-panel';
var SearchView = /** @class */ (function (_super) {
    __extends(SearchView, _super);
    function SearchView(viewService, route, service, searchPath, resource, createPermission) {
        var _this = _super.call(this, viewService, resource, createPermission) || this;
        _this.route = route;
        _this.service = service;
        _this.searchPath = searchPath;
        _this.pageData = {};
        _this.constantConditions = {};
        _this.defaultConditions = {};
        _this.route.data.subscribe(function (pageData) { _this.pageData = pageData; });
        return _this;
    }
    SearchView.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams
            .subscribe(function (params) {
            _this.params.conditions = _this.params.conditions ? _this.params.conditions : {};
            if (params) {
                _this.fromQueryString(params);
            }
            _.forOwn(_this.constantConditions, function (value, key) {
                _this.params.conditions[key] = value;
            });
            _.forOwn(_this.defaultConditions, function (value, key) {
                if (!_this.params.conditions[key]) {
                    _this.params.conditions[key] = value;
                }
            });
            _this.getItems();
        }, this.viewService.handleError);
    };
    SearchView.prototype.onSelect = function (selected) {
        return this.viewService.goToDetail(this.searchPath, this.resource, selected);
    };
    SearchView.prototype.onPage = function (event) {
        this.params.page = event.offset + 1;
        this.toQueryString();
    };
    SearchView.prototype.onSort = function (event) {
        this.params.sort = event.sort;
        this.params.page = event.page;
        this.toQueryString();
    };
    SearchView.prototype.updateFilter = function () {
        // items will be fetched if URL has changed
        this.params.page = 1;
        this.toQueryString();
    };
    SearchView.prototype.routerColumn = function (prop, name, options) {
        var _this = this;
        options = options || {};
        options.routerLink = options.routerLink || (function (row) { return ['/', _this.searchPath, row._id]; });
        return _super.prototype.routerColumn.call(this, prop, name, options);
    };
    SearchView.prototype.fetch = function (params) {
        return this.service.search(params);
    };
    SearchView.prototype.fromQueryString = function (qs) {
        var _this = this;
        if (!qs) {
            return;
        }
        if (qs.page) {
            this.params.page = Number(qs.page);
        }
        if (qs.limit) {
            this.params.limit = Number(qs.limit);
        }
        if (qs.q) {
            this.params.q = qs.q;
        }
        var sortPattern = /^sort\.(.+)/;
        var conditionsPattern = /^conditions\.(.+)/;
        var sort = {};
        var conditions = {};
        _.forOwn(qs, function (value, key) {
            if (!_this.unflatParams(conditions, conditionsPattern, key, value)) {
                _this.unflatParams(sort, sortPattern, key, value);
            }
        });
        if (!_.isEmpty(sort)) {
            this.params.sort = sort;
        }
        if (!_.isEmpty(conditions)) {
            this.params.conditions = conditions;
        }
    };
    SearchView.prototype.toQueryString = function () {
        if (!this.params) {
            return;
        }
        var qs = {};
        if (this.params.page) {
            qs.page = this.params.page;
        }
        if (this.params.limit) {
            qs.limit = this.params.limit;
        }
        if (this.params.q) {
            qs.q = this.params.q;
        }
        this.flatParams(qs, this.params.sort, 'sort');
        this.flatParams(qs, this.params.conditions, 'conditions');
        // populate: string | object
        // select: string
        this.viewService.goTo(['.'], {
            queryParams: qs,
            relativeTo: this.route
            // replaceUrl: true
        });
    };
    SearchView.prototype.flatParams = function (accum, object, prefix) {
        _.forOwn(object, function (value, key) {
            if (!_.isNil(value) && ((!_.isString(value) && !_.isArray(value) && !_.isPlainObject(value)) || !_.isEmpty(value))) {
                accum[prefix + '.' + key] = JSON.stringify(value);
            }
        });
    };
    SearchView.prototype.unflatParams = function (accum, pattern, key, value) {
        var result = key.match(pattern);
        if (result) {
            accum[result[1]] = JSON.parse(value);
        }
        return result;
    };
    return SearchView;
}(SearchPanel));
export { SearchView };
