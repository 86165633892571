import { Component, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TopicService, ViewService } from '../../services'
import { DetailView, Topic, TopicValidator } from '../../interfaces'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html'
})

export class TopicComponent extends DetailView<Topic> {
  @ViewChild('topicPanel') topicPanel: any

  mode!: 'create' | 'edit' | 'readonly'

  constructor (
    viewService: ViewService,
    service: TopicService,
    route: ActivatedRoute
  ) {
    super(viewService, service, route, 'topic', TopicValidator.newFormGroup())
    this.options.populate = {
      'recipients.reference': 'name'
    }
  }

  public submit () {
    const recipients = this.form.value.recipients

    if (!this.form.controls.recipients || (recipients && recipients.length)) {
      return super.submit()
    }

    // ask for confirmation before sending a topic to the whole stage
    this.viewService.askConfirmation('TOPIC.CONFIRM_SEND_TO_STAGE')
    .subscribe(
      value => {
        if (value) {
          super.submit()
        }
      },
      this.viewService.handleError
    )
  }

  protected checkPermissions () {
    super.checkPermissions()
    const authorId = typeof this.model.createdBy === 'string' ? this.model.createdBy : this.model.createdBy?._id
    const isCreatedByUser = authorId === this.viewService.auth.getCurrentUser()._id
    this.isSaveAllowed = this.isCreate || isCreatedByUser
    this.isDeleteAllowed = isCreatedByUser
    if (this.isCreate) {
      this.mode = 'create'
    } else if (isCreatedByUser) {
      this.mode = 'edit'
    } else {
      this.mode = 'readonly'
    }
  }

  protected getValue () {
    if (!this.isCreate) {
      return {
        title: this.form.value.title,
        content: this.form.value.content,
        attachments: this.form.value.attachments
      }
    }
    if (this.form.value.recipients && this.form.value.recipients.length > 0) {
      return this.form.value
    }

    // clone so UI is not affected in case of rejection by back-end
    const newValue = this.viewService.clone(this.form.value)
    const selectedStage = this.viewService.auth.getSelectedStage()
    newValue.recipients = [{
      type: biz.RECIPIENT_TYPES.STAGE,
      reference: selectedStage._id
    }]
    return newValue
  }

  protected getDefaultDetails (): Topic {
    return {
      type: biz.TOPIC_TYPES.NEWS,
      allowMessages: false
    } as Topic
  }
}
