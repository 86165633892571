<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
  <div class="btn-toolbar pull-right-sm"
      role="toolbar">
    <a class="btn btn-default"
        routerLink="/users/{{ model._id }}/force-password"
        *ngIf="isForcePasswordAllowed">
      <i class="fa fa-key"></i>
      <span>{{ 'USER.FORCE_PASSWORD' | translate }}</span>
    </a>
  </div>
</section>

<section class="content text-center text-muted" *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</section>

<section class="content" *ngIf="!isLoading">
  <div class="row">
    <div class="col-xs-12">

      <form class="form-fixed-help"
          (ngSubmit)="submit()">
        <user-panel [form]="form" [isCreate]="isCreate"></user-panel>

        <div class="row">
          <div class="col-xs-12">
            <div class="btn-toolbar btn-footer" role="toolbar">
              <button
                  type="submit"
                  class="btn btn-success btn-lg"
                  *ngIf="isSaveAllowed"
                  [disabled]="!form.valid"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_SAVE' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-default btn-lg"
                  (click)="goBack()">
                {{ 'COMMON.BTN_CANCEL' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-danger btn-lg pull-right-md"
                  *ngIf="isDeleteAllowed"
                  (click)="remove()"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_REMOVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</section>
