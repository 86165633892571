var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a;
// @ts-expect-error ts-migrate(6133) FIXME: 'FormArray' is declared but its value is never rea... Remove this comment to see the full error message
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
var VOLATILE_CONTROLS = [{
        name: 'date',
        default: ''
    }, {
        name: 'recipients',
        default: []
    }, {
        name: 'audience',
        default: {
            guardian: true,
            student: false
        }
    }];
var VOLATILE_CONTROLS_MAP = {};
VOLATILE_CONTROLS.forEach(function (item) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    VOLATILE_CONTROLS_MAP[item.name] = item.default;
});
var CONTROLS_BY_TYPE = (_a = {},
    _a[biz.TOPIC_TYPES.NEWS] = ['recipients', 'audience'],
    _a[biz.TOPIC_TYPES.HOLIDAY] = ['date'],
    _a[biz.TOPIC_TYPES.STRIKE] = ['date', 'recipients'],
    _a[biz.TOPIC_TYPES.NO_CLASSES] = ['date', 'recipients'],
    _a[biz.TOPIC_TYPES.ACHIEVEMENT] = ['recipients'],
    _a[biz.TOPIC_TYPES.WARNING] = ['recipients'],
    _a);
var Topic = /** @class */ (function () {
    function Topic(_id, title, content, type, date, time, timestamp, dateTime, allowMessages, recipients, audience, stats, term, stage, attachments, createdBy) {
        this._id = _id;
        this.title = title;
        this.content = content;
        this.type = type;
        this.date = date;
        this.time = time;
        this.timestamp = timestamp;
        this.dateTime = dateTime;
        this.allowMessages = allowMessages;
        this.recipients = recipients;
        this.audience = audience;
        this.stats = stats;
        this.term = term;
        this.stage = stage;
        this.attachments = attachments;
        this.createdBy = createdBy;
    }
    Topic.fromJson = function (json) { return new Topic(json._id, json.title, json.content, json.type, json.date, json.time, json.timestamp, json.dateTime, json.allowMessages, json.recipients, json.audience, json.stats, json.term, json.stage, json.attachments, json.createdBy); };
    return Topic;
}());
export { Topic };
var TopicFormGroup = /** @class */ (function (_super) {
    __extends(TopicFormGroup, _super);
    function TopicFormGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TopicFormGroup.prototype.patchValue = function (value, options) {
        _super.prototype.patchValue.call(this, value, options);
        // This is needed to initialize the array in case it's not provided by the model
        if (!this.controls.attachments.value) {
            this.controls.attachments.setValue([]);
        }
    };
    return TopicFormGroup;
}(FormGroup));
var TopicValidator = /** @class */ (function () {
    function TopicValidator() {
    }
    TopicValidator.newFormGroup = function () {
        var fb = new FormBuilder();
        var form = new TopicFormGroup({
            type: fb.control('', Validators.required),
            date: fb.control('', Validators.required),
            title: fb.control('', Validators.required),
            content: fb.control(''),
            attachments: fb.control(''),
            allowMessages: fb.control(false)
        });
        form.controls.type.valueChanges.subscribe(function (v) {
            // Remove all volatile controls and keep the ones needed(all of them if that topic type has no specific controls)
            VOLATILE_CONTROLS.forEach(function (item) {
                form.removeControl(item.name);
            });
            if (CONTROLS_BY_TYPE[v]) {
                CONTROLS_BY_TYPE[v].forEach(function (fieldName) {
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    form.addControl(fieldName, fb.control(VOLATILE_CONTROLS_MAP[fieldName]));
                });
            }
            else {
                VOLATILE_CONTROLS.forEach(function (field) {
                    form.addControl(field.name, fb.control(field.default));
                });
            }
        });
        form.controls.type.updateValueAndValidity({ emitEvent: true });
        return form;
    };
    return TopicValidator;
}());
export { TopicValidator };
