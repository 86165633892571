<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="getItems()">
      <i class="fa fa-refresh" aria-hidden="true"
          [tooltip]="'COMMON.BTN_REFRESH' | translate"
          placement="bottom"></i>
    </a>
  </h1>

  <div class="btn-toolbar pull-right" role="toolbar">
    <div class="btn-group" *ngIf="isImportAllowed" dropdown placement="bottom right">
      <button
          type="button"
          class="btn btn-default"
          (click)="uploadFileModal.show('BATCH_JOB.IMPORT_STUDENTS', 'import-students')">
        {{ 'COMMON.BTN_IMPORT' | translate }}
      </button>
      <button
          type="button"
          dropdownToggle
          class="btn btn-default dropdown-toggle dropdown-toggle-split">
        <span class="caret"></span>
        <span class="sr-only">Help menu</span>
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
        <li role="menuitem">
          <a class="dropdown-item" href="https://s3-sa-east-1.amazonaws.com/nexedu/templates/nexedu-carga-alumnos.xlsx" target="_blank">{{ 'BATCH_JOB.IMPORT_STUDENTS_SAMPLE' | translate }}</a>
        </li>
      </ul>
    </div>

    <a class="btn btn-success"
        routerLink="/students/new"
        *ngIf="isCreateAllowed">
      <i class="fa fa-plus"></i>
      <span>{{ 'COMMON.BTN_NEW' | translate }}</span>
    </a>
  </div>
</section>

<section class="content">
  <div class="row search-panel">
    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'STUDENT.DETAIL_TITLE' | translate }}</label>
      <input
        type="text"
        [placeholder]="'COMMON.PLACEHOLDER' | translate"
        class="form-control"
        debounce
        [delay]="250"
        (func)="updateFilter()"
        [(ngModel)]="params.q"/>
    </div>
  </div>

  <appdatatable
    [rows]="rows"
    [columns]="columns"
    [sorts]="params.sort"
    [count]="count"
    [limit]="params.limit"
    [loading]="loading"
    (page)="onPage($event)"
    (sort)="onSort($event)">
  </appdatatable>
</section>

<batch-job-file-modal #uploadFileModal="child" (saved)="updateFilter()"></batch-job-file-modal>
