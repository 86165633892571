import { Injectable } from '@angular/core'
import { Response } from '@angular/http'
import { HttpErrorResponse } from '@angular/common/http'
import { ToasterService } from 'angular2-toaster'
import { AppTranslateService } from './app-translate.service'

@Injectable()
export class AppToastrService {

  constructor (
    private toastr: ToasterService,
    private appTranslate: AppTranslateService
  ) {
    this.handleError = this.handleError.bind(this)
    this.error = this.error.bind(this)
    this.warning = this.warning.bind(this)
    this.success = this.success.bind(this)
    this.info = this.info.bind(this)
  }

  public error (message: string, title?: string) {
    this.toastr.pop('error', title, message)
  }

  public warning (message: string, title?: string) {
    this.toastr.pop('warning', title, message)
  }

  public success (message: string, title?: string) {
    this.toastr.pop('success', title, message)
  }

  public info (message: string, title?: string) {
    this.toastr.pop('info', title, message)
  }

  public errorT (message: string) {
    this.translate(message, this.error)
  }

  public warningT (message: string) {
    this.translate(message, this.warning)
  }

  public successT (message: string) {
    this.translate(message, this.success)
  }

  public infoT (message: string) {
    this.translate(message, this.info)
  }

  public handleError (err: any): any {
    err = this.getErrorObject(err)

    if (err.berrCode) {
      const errKey = `ERROR.${err.berrCode}`
      this.appTranslate.getTranslate(errKey).subscribe(value => {
        if (errKey === value) {
          value = err.message
        }
        return this.error(value)
      })
    } else {
      return err.message ? this.error(err.message) : this.errorT('ERROR.BERR-X0000')
    }
  }

  private translate (message: string, handler: any) {
    this.appTranslate.getTranslate(message).subscribe(handler)
  }

  private getErrorObject (error: Response | any) {
    let body: any
    if (error instanceof HttpErrorResponse) {
      body = (error as HttpErrorResponse).error
    } else if (error instanceof Response) {
      body = error.json() || {}


      // @ts-expect-error ts-migrate(6133) FIXME: 'err' is declared but its value is never read.
      const err = new Error(body.message)
      body.status = error.status
    } else {
      body = error
    }
    return body
  }
}
