var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../interfaces';
var BatchJobService = /** @class */ (function (_super) {
    __extends(BatchJobService, _super);
    function BatchJobService(http) {
        return _super.call(this, http, '/batch-jobs', function (e) { return e; }) || this; // TODO implement typeDeserializer
    }
    BatchJobService.prototype.getErrorsByBatchJob = function (id, params) {
        return this.http.get(this.url + "/" + id + "/errors", this.toRequestOptions(params));
    };
    BatchJobService.prototype.exportErrors = function (id) {
        return this.http.post(this.url + "/" + id + "/errors/export", {});
    };
    return BatchJobService;
}(CrudService));
export { BatchJobService };
