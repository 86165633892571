import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'username' })
export class UsernamePipe implements PipeTransform {
  transform (value: any): string {


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    if (!value) { return }
    return `${value.last}, ${value.first}`
  }
}
