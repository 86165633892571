<appdatatable
    [rows]="rows"
    [columns]="columns"
    [sorts]="params.sort"
    [count]="count"
    [limit]="params.limit"
    [loading]="loading"
    (page)="onPage($event)"
    (sort)="onSort($event)">
</appdatatable>
