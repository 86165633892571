import { Observable } from 'rxjs'
import { StageService } from './stage.service'
import { QueryString } from '../models'
import { Grade, CrudService } from '../interfaces'

export class GradeWrapperService extends CrudService<Grade> {

    constructor (private stageService: StageService) {


      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
      super(null, '')
    }

    public getById (id: string, params: QueryString) {
      return this.stageService.getGradeById(id, params)
    }

    public search (params: QueryString) {
      return this.stageService.getGrades(params)
    }

    public create (object: any): Observable<any> {
      return this.stageService.addGrade(object)
    }

    public update (gradeId: string, object: any): Observable<any> {
      return this.stageService.updateGrade(gradeId, object)
    }

    public remove (gradeId: string): Observable<any> {
      return this.stageService.removeGrade(gradeId)
    }
}
