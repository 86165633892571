/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "ngx-bootstrap/tooltip/tooltip.directive";
import * as i3 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i4 from "ngx-bootstrap/tooltip/tooltip.config";
import * as i5 from "../../pipes/username.pipe";
import * as i6 from "../../pipes/short-date-time.pipe";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../pipes/short-date.pipe";
import * as i9 from "./topic-action-badge";
var styles_TopicActionBadge = [];
var RenderType_TopicActionBadge = i0.ɵcrt({ encapsulation: 2, styles: styles_TopicActionBadge, data: {} });
export { RenderType_TopicActionBadge as RenderType_TopicActionBadge };
function View_TopicActionBadge_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "li", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "highlight pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [["class", "text-muted pull-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵppd(8, 1), (_l()(), i0.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.user.name)); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent.parent, 1), _v.context.$implicit.timestamp)); _ck(_v, 7, 0, currVal_1); }); }
function View_TopicActionBadge_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "ul", [["class", "list-unstyled list-activities"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopicActionBadge_2)), i0.ɵdid(4, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activities; _ck(_v, 4, 0, currVal_0); }, null); }
function View_TopicActionBadge_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "span", [["aria-hidden", "true"], ["class", "badge label-success"], ["containerClass", "activities-detail"], ["placement", "bottom"]], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i2.TooltipDirective, [i0.ViewContainerRef, i0.Renderer2, i0.ElementRef, i3.ComponentLoaderFactory, i4.TooltipConfig], { tooltip: [0, "tooltip"], placement: [1, "placement"], containerClass: [2, "containerClass"] }, null), (_l()(), i0.ɵted(2, null, ["\n  ", "\n"])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 2); var currVal_1 = "bottom"; var currVal_2 = "activities-detail"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.activities.length; _ck(_v, 2, 0, currVal_3); }); }
function View_TopicActionBadge_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-exclamation-circle text-danger"]], null, null, null, null, null))], null, null); }
export function View_TopicActionBadge_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.UsernamePipe, []), i0.ɵpid(0, i6.ShortDateTimePipe, []), (_l()(), i0.ɵand(0, [["activitiesTooltip", 2]], null, 0, null, View_TopicActionBadge_1)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopicActionBadge_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TopicActionBadge_4)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activities.length; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.activities.length; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_TopicActionBadge_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "topic-action-badge", [], null, null, null, View_TopicActionBadge_0, RenderType_TopicActionBadge)), i0.ɵprd(135680, null, i7.TranslatePipe, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef]), i0.ɵprd(4608, null, i8.ShortDatePipe, i8.ShortDatePipe, []), i0.ɵdid(3, 49152, null, 0, i9.TopicActionBadge, [], null, null)], null, null); }
var TopicActionBadgeNgFactory = i0.ɵccf("topic-action-badge", i9.TopicActionBadge, View_TopicActionBadge_Host_0, { activities: "activities" }, {}, []);
export { TopicActionBadgeNgFactory as TopicActionBadgeNgFactory };
