import { Pipe, PipeTransform } from '@angular/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'

@Pipe({name: 'batchJobStatusLabel'})
export class BatchJobStatusLabelPipe implements PipeTransform {
  private BATCH_JOB_STATUS_LABELS = {
    [biz.BATCH_JOB_STATUSES.pending]: 'label-default',
    [biz.BATCH_JOB_STATUSES.processing]: 'label-info',
    [biz.BATCH_JOB_STATUSES.completed]: 'label-success',
    [biz.BATCH_JOB_STATUSES.failed]: 'label-danger'
  }

  transform (value: string): string {
    if (!value || typeof value !== 'string') {


      // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      return
    }



    // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    return this.BATCH_JOB_STATUS_LABELS[value] || ''
  }

}
