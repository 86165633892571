import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { QueryString } from '../models'
import { ApiService, SearchableService, User } from '../interfaces'

@Injectable()
export class PersonService extends ApiService implements SearchableService<User> {
  private url = this.apiUrl + '/persons'

  constructor (protected http: HttpClient,) {
    super()
  }

  public search (params?: QueryString): Observable<any> {
    return this.http.get(this.url, this.toRequestOptions(params))
  }
}
