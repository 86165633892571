import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { AuthService } from '../services'

@Injectable()
export class IsUserLoggedGuard implements CanActivate {

  constructor (
    private router: Router,
    private authService: AuthService
  ) {
  }

  public canActivate () {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['login'])
      return false
    }
    return true
  }
}
