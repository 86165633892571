var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Column } from '../../models';
import { ViewService, GuardianService } from '../../services';
import { StudentsTab } from '../students-tab';
var GuardianStudentsTab = /** @class */ (function (_super) {
    __extends(GuardianStudentsTab, _super);
    function GuardianStudentsTab(viewService, service) {
        var _this = _super.call(this, viewService) || this;
        _this.service = service;
        _this.params.limit = 50;
        _this.params.sort = {
            'name.last': 1,
            'name.first': 1
        };
        return _this;
    }
    GuardianStudentsTab.prototype.fetch = function (params) {
        return this.service.getStudents(this.guardianId, params);
    };
    GuardianStudentsTab.prototype.createColumns = function () {
        _super.prototype.createColumns.call(this);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        this.columns.push(new Column('division.shift', 'DIVISION.SHIFT', false, null, 'schoolShiftCell', 120, 120));
        this.columns.push(new Column('division.name', 'DIVISION.DETAIL_TITLE', false));
    };
    return GuardianStudentsTab;
}(StudentsTab));
export { GuardianStudentsTab };
