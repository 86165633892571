import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PersistentModel } from './persistent-model'
import { DateOnly } from './date-only'
import { TimeOnly } from './time-only'
import { Division } from './division'
import { Subject } from './subject'
import { AppValidators } from '../validators';

export interface Exam extends PersistentModel {
  subject?: Subject|string
  division: Division|string
  content?: string
  date?: DateOnly
  time?: TimeOnly
  allowMessages?: boolean
}

export class ExamValidator {

  public static newFormGroup (): FormGroup {
    const fb = new FormBuilder()

    const formGroup = fb.group({
      subject: fb.control('', Validators.required),
      division: fb.control('', Validators.required),
      date: fb.control('', [Validators.required, AppValidators.futureDateOnly()]),
      time: fb.control('', Validators.required),
      content: fb.control(''),
      allowMessages: fb.control(false)
    })

    return formGroup
  }
}
