import { Component, Input, forwardRef, DoCheck } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


// @ts-expect-error ts-migrate(6133) FIXME: 'moment' is declared but its value is never read.
import * as moment from 'moment';

// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export const TIME_ONLY_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TimeOnlyPicker),
  multi: true
};

@Component({
  selector: 'timeonlypicker',
  templateUrl: './time-only.picker.html',
  providers: [TIME_ONLY_VALUE_ACCESSOR]
})

export class TimeOnlyPicker implements ControlValueAccessor, DoCheck {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'innerValue' has no initializer and is no... Remove this comment to see the full error message
  public innerValue: Date
  public onChange: any = Function.prototype;
  public onTouched: any = Function.prototype;
  // private registered = false
  // private adjusted = false


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'oldValue' has no initializer and is not ... Remove this comment to see the full error message
  private oldValue: number

  public get value(): any {
    if (!this.innerValue) {
      return
    }
    return {
      hour: this.innerValue.getHours(),
      minutes: this.innerValue.getMinutes()
    }
  }

  @Input()
  public set value(_value: any) {
    let adjusted = false
    const date = new Date()

    if (_value) {
      if (_value.hour != null && _value.hour >= 0 && _value.hour <= 23) {
        date.setHours(_value.hour)
      } else {
        adjusted = true
      }
      if (_value.minutes != null && _value.minutes >= 0 && _value.minutes <= 59) {
        date.setMinutes(_value.minutes)
      } else {
        date.setMinutes(0)
        adjusted = true
      }
      // this.innerValue = date
    } else {
      date.setMinutes(0)
      adjusted = true
    }

    this.innerValue = date
    this.oldValue = date.valueOf()
    if (adjusted) {
      // if (this.registered) {
        this.onChange(this.value)
      // } else {
      //   this.adjusted = true
      // }
    }
    // } else {
    //   this.adjusted = false
    // }
  }

  public ngDoCheck() {
    // workaround since timepicker doesn't provide any hook for change detection
    if (!this.innerValue) {
      if (this.oldValue != undefined) {


        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
        this.oldValue = undefined
        this.onChange(this.value)
      }
    } else {
      if (this.innerValue.valueOf() !== this.oldValue) {
        this.oldValue = this.innerValue.valueOf()
        this.onChange(this.value)
      }
    }
  }

  public onBlur () {
    this.onTouched();
  }

  // ControlValueAccessor
  // model -> view
  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
    // this.registered = true
    // if (this.adjusted) {
    //   this.onChange(this.value)
    // }
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
