var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
// @ts-expect-error ts-migrate(6133) FIXME: 'Assessment' is declared but its value is never re... Remove this comment to see the full error message
import { StageDependantCrudService, Division, Course, User, Exam, Rating } from '../interfaces';
import { AuthService } from './auth.service';
var DivisionService = /** @class */ (function (_super) {
    __extends(DivisionService, _super);
    function DivisionService(http, auth) {
        return _super.call(this, http, '/divisions', auth) || this;
    }
    DivisionService.prototype.searchByTermAndStage = function (termId, stageId, params) {
        var tempUrl = this.apiUrl + ("/terms/" + termId + "/stages/" + stageId) + this.urlSpecific;
        return this.http.get(tempUrl, this.toRequestOptions(params));
    };
    // courses management
    DivisionService.prototype.getCourses = function (id, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/courses", this.toRequestOptions(params));
    };
    DivisionService.prototype.getCourseById = function (id, courseId, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/courses/" + courseId, this.toRequestOptions(params));
    };
    DivisionService.prototype.addCourse = function (id, course) {
        this.updateUrl();
        return this.http.post(this.url + "/" + id + "/courses", course);
    };
    DivisionService.prototype.updateCourse = function (id, courseId, course) {
        this.updateUrl();
        return this.http.patch(this.url + "/" + id + "/courses/" + courseId, course);
    };
    DivisionService.prototype.removeCourse = function (id, courseId) {
        this.updateUrl();
        return this.http.delete(this.url + "/" + id + "/courses/" + courseId);
    };
    // student management
    DivisionService.prototype.getStudents = function (id, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/students", this.toRequestOptions(params));
    };
    DivisionService.prototype.addStudent = function (id, student) {
        this.updateUrl();
        return this.http.post(this.url + "/" + id + "/students", student);
    };
    DivisionService.prototype.removeStudent = function (id, studentId) {
        this.updateUrl();
        return this.http.delete(this.url + "/" + id + "/students/" + studentId);
    };
    DivisionService.prototype.includeStudents = function (id, students) {
        this.updateUrl();
        return this.http.post(this.url + "/" + id + "/students/include", students);
    };
    DivisionService.prototype.excludeStudents = function (id, students) {
        this.updateUrl();
        return this.http.post(this.url + "/" + id + "/students/exclude", students);
    };
    DivisionService.prototype.promoteStudents = function (from, to, students) {
        return this.http.post(this.url + "/" + from + "/students/promote", { to: to, students: students });
    };
    // attendance management
    DivisionService.prototype.saveAttendance = function (id, date, report) {
        this.updateUrl();
        if (typeof date === 'number') {
            date = new Date(date);
        }
        date = {
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate()
        };
        return this.http.post(this.url + "/" + id + "/attendance/" + date.year + "/" + date.month + "/" + date.date + "?isV2=true", { report: report });
    };
    DivisionService.prototype.getAttendanceByWeek = function (id, year, isoWeek) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/attendance/" + year + "/weeks/" + isoWeek + "?isV2=true");
    };
    // subjects management
    DivisionService.prototype.getSubjects = function (id, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/subjects", this.toRequestOptions(params));
    };
    // exam management
    DivisionService.prototype.getExams = function (id, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/exams", this.toRequestOptions(params));
    };
    DivisionService.prototype.getExamById = function (id, examId, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/exams/" + examId, this.toRequestOptions(params));
    };
    DivisionService.prototype.addExam = function (id, exam) {
        this.updateUrl();
        return this.http.post(this.url + "/" + id + "/exams", exam);
    };
    DivisionService.prototype.updateExam = function (id, examId, exam) {
        this.updateUrl();
        return this.http.patch(this.url + "/" + id + "/exams/" + examId, exam);
    };
    DivisionService.prototype.removeExam = function (id, examId) {
        this.updateUrl();
        return this.http.delete(this.url + "/" + id + "/exams/" + examId);
    };
    DivisionService.prototype.getExamAssessments = function (id, examId, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/exams/" + examId + "/assessments", this.toRequestOptions(params));
    };
    DivisionService.prototype.updateExamAssessments = function (id, examId, studentsWithAssessments) {
        this.updateUrl();
        return this.http.patch(this.url + "/" + id + "/exams/" + examId + "/assessments", studentsWithAssessments);
    };
    // ratings management
    DivisionService.prototype.getRatings = function (id, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/ratings", this.toRequestOptions(params));
    };
    DivisionService.prototype.getRatingById = function (id, ratingId, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/ratings/" + ratingId, this.toRequestOptions(params));
    };
    DivisionService.prototype.addRating = function (id, rating) {
        this.updateUrl();
        return this.http.post(this.url + "/" + id + "/ratings", rating);
    };
    DivisionService.prototype.updateRating = function (id, ratingId, rating) {
        this.updateUrl();
        return this.http.patch(this.url + "/" + id + "/ratings/" + ratingId, rating);
    };
    DivisionService.prototype.removeRating = function (id, ratingId) {
        this.updateUrl();
        return this.http.delete(this.url + "/" + id + "/ratings/" + ratingId);
    };
    DivisionService.prototype.getRatingAssessments = function (id, ratingId, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/ratings/" + ratingId + "/assessments", this.toRequestOptions(params));
    };
    DivisionService.prototype.updateRatingAssessments = function (id, ratingId, studentsWithAssessments) {
        this.updateUrl();
        return this.http.patch(this.url + "/" + id + "/ratings/" + ratingId + "/assessments", studentsWithAssessments);
    };
    return DivisionService;
}(StageDependantCrudService));
export { DivisionService };
