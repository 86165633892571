// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
// https://github.com/angular/angular-cli/wiki/stories-application-environments

export const base = {
  // THIS ARE TESTING CONFIG! DONT USE THAT IN PROD! CHANGE THAT!
  production: false,
  silent: false,
  LOCALE: 'es',
  TIMEZONE: 'America/Argentina/Buenos_Aires',
  API_HOST: 'localhost:4103',
  API_URL: 'http://localhost:4103',
  API_PATH: '/api/v1',
  sourceMap: true,
  CLOUDINARY: {
    CLOUD_NAME : 'nexedu-staging',
    TENANTS_PRESET: 'tenants',
    IMPORTS_PRESET: 'imports',
    ATTACHMENTS_PRESET: 'attachments',
    ALBUMS_PRESET: 'albums'
  },
  KEEN: {
    PROJECT_ID: '',
    KEY: ''
  }
}
