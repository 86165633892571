<ng-template #activitiesTooltip>
  <ul class="list-unstyled list-activities">
    <li *ngFor="let activity of activities" class="clearfix">
      <span class="highlight pull-left">{{ activity.user.name | username }}</span>
      <span class="text-muted pull-right">{{ activity.timestamp | shortDateTime }}</span>
    </li>
  </ul>
</ng-template>
<span class="badge label-success"
    *ngIf="activities.length"
    aria-hidden="true"
    [tooltip]="activitiesTooltip"
    placement="bottom"
    containerClass="activities-detail">
  {{ activities.length }}
</span>
<i class="fa fa-exclamation-circle text-danger"
    *ngIf="!activities.length"
    aria-hidden="true"></i>
