

// @ts-expect-error ts-migrate(6133) FIXME: 'FormArray' is declared but its value is never rea... Remove this comment to see the full error message
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { PersistentModel } from './persistent-model'
import { DateOnly } from './date-only'
import { TimeOnly } from './time-only'
import { User } from './user'
import { Division } from './division'
import { Stage } from './stage'
import { Term } from './term'
import { Name } from './name';

const VOLATILE_CONTROLS = [{
  name: 'date',
  default: ''
}, {
  name: 'recipients',
  default: []
}, {
  name: 'audience',
  default: {
    guardian: true,
    student: false
  }
}]
const VOLATILE_CONTROLS_MAP = {}
VOLATILE_CONTROLS.forEach(item => {


  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  VOLATILE_CONTROLS_MAP[item.name] = item.default
})
const CONTROLS_BY_TYPE = {
  [biz.TOPIC_TYPES.NEWS]: ['recipients', 'audience'],
  [biz.TOPIC_TYPES.HOLIDAY]: ['date'],
  [biz.TOPIC_TYPES.STRIKE]: ['date', 'recipients'],
  [biz.TOPIC_TYPES.NO_CLASSES]: ['date', 'recipients'],
  [biz.TOPIC_TYPES.ACHIEVEMENT]: ['recipients'],
  [biz.TOPIC_TYPES.WARNING]: ['recipients']
}

export class Topic implements PersistentModel {
  constructor (
      readonly _id: string,
      readonly title: string,
      readonly content: string,
      readonly type: string,
      readonly date: DateOnly,
      readonly time: TimeOnly,
      readonly timestamp: number,
      readonly dateTime: number,
      readonly allowMessages: boolean,
      readonly recipients: Recipient[],
      readonly audience: { guardian: boolean; student: boolean },
      readonly stats: { recipients: number; messages: number; delivered: number; read: number; likes: number; bookmark: number },
      readonly term: Term | string,
      readonly stage: Stage | string,
      readonly attachments: any[],
      readonly createdBy: string | { _id: string, id: string, name: Name }) {
  }

  static fromJson = (json: any): Topic => new Topic(
      json._id,
      json.title,
      json.content,
      json.type,
      json.date,
      json.time,
      json.timestamp,
      json.dateTime,
      json.allowMessages,
      json.recipients,
      json.audience,
      json.stats,
      json.term,
      json.stage,
      json.attachments,
      json.createdBy
  )
}

export interface Recipient {
  type: string,
  reference: User|Division|Stage|string
}

class TopicFormGroup extends FormGroup {
  public patchValue (value: {
    [key: string]: any;
  }, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }): void {
    super.patchValue(value, options)

    // This is needed to initialize the array in case it's not provided by the model


    if (!this.controls.attachments.value) {


      this.controls.attachments.setValue([])
    }
  }
}

export class TopicValidator {

  public static newFormGroup (): FormGroup {
    const fb = new FormBuilder()

    const form = new TopicFormGroup({
      type: fb.control('', Validators.required),
      date: fb.control('', Validators.required),
      title: fb.control('', Validators.required),
      content: fb.control(''),
      attachments: fb.control(''),
      allowMessages: fb.control(false)
    })



    form.controls.type.valueChanges.subscribe(v => {
      // Remove all volatile controls and keep the ones needed(all of them if that topic type has no specific controls)
      VOLATILE_CONTROLS.forEach(item => {
        form.removeControl(item.name)
      })

      if (CONTROLS_BY_TYPE[v]) {


        CONTROLS_BY_TYPE[v].forEach(fieldName => {


          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          form.addControl(fieldName, fb.control(VOLATILE_CONTROLS_MAP[fieldName]))
        });
      } else {
        VOLATILE_CONTROLS.forEach(field => {
          form.addControl(field.name, fb.control(field.default))
        })
      }
    })


    form.controls.type.updateValueAndValidity({ emitEvent: true })

    return form
  }

  // public static newEventFormGroup (): FormGroup {
  //   const fb = new FormBuilder()

  //   const form = new TopicFormGroup({
  //     type: fb.control('', Validators.required),
  //     title: fb.control('', Validators.required),
  //     date: fb.control('', Validators.required),
  //     time: fb.control('', Validators.required),
  //     content: fb.control(''),
  //     attachments: fb.control(''),
  //     allowMessages: fb.control(false),
  //     recipients: fb.control('', Validators.required),
  //     audience: fb.control({})
  //   })

  //   return form
  // }
}
