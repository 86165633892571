import { FormBuilder, FormGroup, Validators } from '@angular/forms'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { PersistentModel } from './persistent-model'

export interface Contact extends PersistentModel {
  type: string
  tag: string
  value: string
}

export class ContactValidator {

  public static newFormGroup (contact: Contact = {
      type: biz.CONTACT_TYPES.MOBILE,
      tag: biz.CONTACT_TAGS.PERSONAL
    } as Contact
  ): FormGroup {
    const fb = new FormBuilder()

    const form = fb.group({
      type: fb.control(contact.type, Validators.required),
      tag: fb.control(contact.tag, Validators.required),
      value: fb.control(contact.value)
    })


    form.controls.type.valueChanges.subscribe(newValue => {
      if (newValue === biz.CONTACT_TYPES.EMAIL) {
        // https://github.com/angular/angular/blob/2.4.10/modules/%40angular/forms/src/model.ts#L879
        // form.removeControl('data')

        form.controls.value.setValidators([ Validators.required, Validators.email ])
      } else {
        // https://github.com/angular/angular/blob/2.4.10/modules/%40angular/forms/src/model.ts#L870
        // form.removeControl('data')

        form.controls.value.setValidators(Validators.required)
      }

      form.controls.value.updateValueAndValidity({ onlySelf: false, emitEvent: true })
    })

    form.controls.type.updateValueAndValidity({ emitEvent: true })

    return form
  }
}
