import matchedFields from './matched-fields.validator'
import mismatchedFields from './mismatched-fields.validator'
import futureDateOnly from './future-date-only.validator'
import nonEmpty from './non-empty.validator'

export const AppValidators: any = {
  matchedFields,
  mismatchedFields,
  futureDateOnly,
  nonEmpty
}
