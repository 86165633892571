<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content">
  <div class="row">
    <div class="col-xs-12">
      <form class="form-fixed-help"
          [formGroup]="form"
          (ngSubmit)="submit()">

        <div class="row">
          <div class="form-group col-sm-6 col-md-3">
            <label>{{ 'USER.ID' | translate }}</label>
            <p class="form-control-static">
              {{ user?._id }}
            </p>
          </div>

          <div class="form-group col-sm-6 col-md-3">
            <label>{{ 'USER.FULL_NAME' | translate }}</label>
            <p class="form-control-static">
              {{ user?.name | username }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-6 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.newPassword.valid && form.controls.newPassword.touched }">
            <label>{{ 'PROFILE.NEW_PASSWORD' | translate }}</label>
            <input type="password"
                class="form-control"
                formControlName="newPassword">
            <span class="help-block">
              <span *ngIf="form.controls.newPassword.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
              <span *ngIf="form.controls.newPassword.hasError('minlength')">{{ 'CLIENTERROR.CERR-00002' | translate }}</span>
            </span>
          </div>

          <div class="form-group col-sm-6 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.rePassword.valid && form.controls.rePassword.touched }">
            <label>{{ 'PROFILE.RETYPE_PASSWORD' | translate }}</label>
            <input type="password"
                class="form-control"
                formControlName="rePassword">
            <span class="help-block">
              <span *ngIf="form.controls.rePassword.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
              <span *ngIf="form.controls.rePassword.hasError('equalTo')">{{ 'CLIENTERROR.CERR-00004' | translate }}</span>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <button type="submit"
                class="btn btn-success"
                [disabled]="!user || !form.valid">
              {{ 'COMMON.BTN_CONFIRM' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-default"
                (click)="goBack()">
              {{ 'COMMON.BTN_CANCEL' | translate }}
            </button>
          </div>
        </div>

      </form>
    </div>
  </div>
</section>
