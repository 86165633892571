<div class="panel feed-post">
  <div class="panel-heading">
    <div class="media">
      <div class="media-left">
        <a href="javascript:void(0)">
          <img class="media-object img-circle photo-profile" src="http://0.gravatar.com/avatar/38d618563e55e6082adf4c8f8c13f3e4?s=40&d=mm&r=g" width="40" height="40" alt="...">
        </a>
      </div>
      <div class="media-body">
        <div class="media-body-inner">
          <a href="#" class="panel-title">Author</a>
          <a href="#" class="post-time">{{ topic.dateTime | timeAgo }}</a>
          <a href="#" class="post-expand"><i class="fa fa-chevron-down"></i></a>
        </div>
      </div>
    </div>
  </div>

  <div class="panel-body" [ngSwitch]="topic.type">
    <div *ngSwitchCase="'late'" class="post-block bg-warning text-warning"><h1>{{ topic.type | topicType }}</h1></div>
    <div *ngSwitchCase="'absence'" class="post-block bg-danger text-danger"><h1>{{ topic.type | topicType }}</h1></div>
    <div *ngSwitchCase="'justified'" class="post-block bg-info text-info"><h1>{{ topic.type | topicType }}</h1></div>
    <div *ngSwitchCase="'exam'" class="post-block post-exam">
      <h4>{{ topic.type | topicType }}</h4>
      <h1>{{ topic.title }}</h1>
    </div>
    <div *ngSwitchCase="'news'">
      <h3>{{ topic.title }}</h3>
      <p [innerHTML]="topic.content"></p>
    </div>
  </div>

  <div class="panel-footer">
    <div class="post-actions">
      <ul class="list-unstyled list-inline">
        <li><a href="javascript:void(0)"><i class="fa fa-thumbs-up"></i> Like</a></li>
        <li><a href="javascript:void(0)"><i class="fa fa-comment"></i> Comment</a></li>
        <li><a href="javascript:void(0)"><i class="fa fa-share-alt"></i> Share</a></li>
      </ul>
    </div>
  </div>
</div>
