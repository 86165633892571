import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { Column } from '../../models'
import { ViewService, StudentService } from '../../services'
import { SearchPanel } from '../../interfaces'
import { QueryString } from '../../models'


// @ts-expect-error ts-migrate(6133) FIXME: 'TopicTypePipe' is declared but its value is never... Remove this comment to see the full error message
import { TopicTypePipe } from '../../pipes'

@Component({
  selector: 'student-attendance-tab',
  templateUrl: './student-attendance.tab.html',
  styleUrls: ['./student-attendance.tab.styl'],
  providers: [TranslatePipe]
})
export class StudentAttendanceTab extends SearchPanel<any> implements OnChanges {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'studentId' has no initializer and is not... Remove this comment to see the full error message
  @Input() studentId: string



  // @ts-expect-error ts-migrate(2564) FIXME: Property 'columns' has no initializer and is not d... Remove this comment to see the full error message
  public columns: Column []

  constructor (
    viewService: ViewService,
    private service: StudentService,


    // @ts-expect-error ts-migrate(6138) FIXME: Property 'translatePipe' is declared but its value... Remove this comment to see the full error message
    private translatePipe: TranslatePipe
  ) {
    super(viewService, 'student', 'attendance')
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'changes' is declared but its value is never read.
  public ngOnChanges (changes: SimpleChanges) {
    if (this.studentId) {
      this.updateFilter()
    }
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'selected' is declared but its value is never read... Remove this comment to see the full error message
  public onSelect (selected: any) {
    // return this.viewService.goToDetail('divisions', 'division', selected)
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'params' is declared but its value is never read.
  protected fetch (params: QueryString): Observable<any> {
    return this.service.getAttendance(this.studentId)
    .map(results => {
      const groups = []
      for (var i = 0; i < results.list.length; i++) {
        const topic = results.list[i]
        if (!i || topic.date.month !== results.list[i - 1].date.month) {
          groups.push({
            dateTime: topic.dateTime,
            topics: [topic],
            totals: {
              [biz.TOPIC_TYPES.LATE_ARRIVAL]: 0,
              [biz.TOPIC_TYPES.ABSENCE]: 0,
              [biz.TOPIC_TYPES.JUSTIFIED_ABSENCE]: 0
            },
            open: !i
          })
        } else {


          groups[groups.length - 1].topics.push(topic)
        }


        groups[groups.length - 1].totals[topic.type]++
      }
      return groups
    })
  }
}
