var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CrudService } from './crud.service';
var StageDependantCrudService = /** @class */ (function (_super) {
    __extends(StageDependantCrudService, _super);
    function StageDependantCrudService(http, urlSpecific, authService, termOnly, typeDeserializer) {
        if (termOnly === void 0) { termOnly = false; }
        if (typeDeserializer === void 0) { typeDeserializer = function (e) { return e; }; }
        var _this = _super.call(this, http, urlSpecific, typeDeserializer) || this;
        _this.http = http;
        _this.urlSpecific = urlSpecific;
        _this.authService = authService;
        _this.termOnly = termOnly;
        return _this;
    }
    StageDependantCrudService.prototype.search = function (params) {
        this.updateUrl();
        return _super.prototype.search.call(this, params);
    };
    StageDependantCrudService.prototype.getById = function (id, params) {
        this.updateUrl();
        return _super.prototype.getById.call(this, id, params);
    };
    StageDependantCrudService.prototype.create = function (object) {
        this.updateUrl();
        return _super.prototype.create.call(this, object);
    };
    StageDependantCrudService.prototype.update = function (id, object) {
        this.updateUrl();
        return _super.prototype.update.call(this, id, object);
    };
    StageDependantCrudService.prototype.remove = function (id) {
        this.updateUrl();
        return _super.prototype.remove.call(this, id);
    };
    StageDependantCrudService.prototype.updateUrl = function () {
        var term = this.authService.getSelectedTerm();
        this.url = this.apiUrl + "/terms/" + term._id;
        if (!this.termOnly) {
            var stage = this.authService.getSelectedStage();
            this.url += "/stages/" + stage._id;
        }
        this.url += "" + this.urlSpecific;
    };
    return StageDependantCrudService;
}(CrudService));
export { StageDependantCrudService };
