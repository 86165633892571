var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../interfaces';
var PersonService = /** @class */ (function (_super) {
    __extends(PersonService, _super);
    function PersonService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.url = _this.apiUrl + '/persons';
        return _this;
    }
    PersonService.prototype.search = function (params) {
        return this.http.get(this.url, this.toRequestOptions(params));
    };
    return PersonService;
}(ApiService));
export { PersonService };
