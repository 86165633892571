var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DetailView, AlbumValidator } from '../../interfaces';
import { ViewService, AlbumService, DivisionService } from '../../services';
var AlbumComponent = /** @class */ (function (_super) {
    __extends(AlbumComponent, _super);
    function AlbumComponent(viewService, service, route, divisionService) {
        var _this = _super.call(this, viewService, service, route, 'employee', AlbumValidator.newFormGroup(), false) || this;
        _this.divisionService = divisionService;
        _this.divisions = [];
        _this.preset = environment.CLOUDINARY.ALBUMS_PRESET;
        _this.tags = "album, topic, " + _this.viewService.getTenant()._id;
        return _this;
    }
    AlbumComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.isCreate) {
            this.divisionService.search().subscribe(function (divisions) {
                _this.divisions = divisions.list;
            });
        }
    };
    AlbumComponent.prototype.getValue = function () {
        return this.form.value;
    };
    AlbumComponent.prototype.onFileUpload = function (res) {
        this.form.addAttachment(res);
    };
    AlbumComponent.prototype.removeAttachment = function (i) {
        this.form.removeAttachmentAt(i);
    };
    return AlbumComponent;
}(DetailView));
export { AlbumComponent };
