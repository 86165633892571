<accordion [closeOthers]="false">
  <accordion-group *ngFor="let group of groups"
      (isOpenChange)="toggleGroup($event, group)"
      [(isOpen)]="group.open">
    <div accordion-heading class="clearfix">
      <span>{{ group.name || ('TOPIC.VIRTUAL_DIVISION' | translate) }}</span>
      <span class="badge label-default"
          [tooltip]="'STUDENT.SEARCH_TITLE' | translate"
          placement="bottom">
        {{ group.total }}
      </span>
      <i class="fa pull-right" [ngClass]="group.open ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </div>

    <div *ngIf="group.open">
      <div class="text-center text-muted" *ngIf="group.loading">
        <i class="fa fa-circle-o-notch fa-spin"></i>
      </div>

      <div class="text-center text-muted" *ngIf="!group.loading && !group.students?.length">{{ 'COMMON.MSG_NO_DATA' | translate }}</div>

      <table class="table table-hover table-striped table-sm-half" *ngIf="!group.loading && group.students?.length">
        <thead>
          <tr>
            <th class="col-xs-9">{{ 'USER.FULL_NAME' | translate }}</th>
            <th class="col-xs-3 text-center">{{ 'TOPIC.SEEN' | translate }}</th>
            <th class="col-xs-3 text-center">{{ 'TOPIC.DELIVERED' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entry of group.students">
            <td class="text-ellipsis">
              <a routerLink="/students/{{ entry.student._id }}">{{ entry.student.name | username }}</a>
            </td>
            <td class="text-center">
              <topic-action-badge [activities]="entry.activities.read"></topic-action-badge>
            </td>
            <td class="text-center">
              <topic-action-badge [activities]="entry.activities.delivered"></topic-action-badge>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </accordion-group>
</accordion>
