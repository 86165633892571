<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content">
  <div class="row">
    <div class="col-xs-12">
      <form class="form-fixed-help"
          (ngSubmit)="submit()"
          [formGroup]="form">

        <div class="row">
          <div class="form-group col-sm-6 col-md-3">
            <label>
              {{ 'SUBJECT.DETAIL_TITLE' | translate }}
              <required-icon [visible]="isCreate"></required-icon>
            </label>
            <select *ngIf="isSaveAllowed && isCreate"
                class="form-control"
                formControlName="subject">
              <option *ngFor="let subject of subjects" [ngValue]="subject._id">
                {{ subject.name }}
              </option>
            </select>
            <div class="form-control-static" *ngIf="!isSaveAllowed || !isCreate">
              <a routerLink="/subjects/{{ model?.subject }}">{{ model?.title }}</a>
            </div>
          </div>

          <div class="form-group col-sm-6 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.date.valid && form.controls.date.touched }">
            <label>
              {{ 'TOPIC.DATE' | translate }}
              <required-icon></required-icon>
            </label>
            <dateonlypicker-popup *ngIf="isSaveAllowed" (change)="dateChanged()" formControlName="date" [locale]="locale"></dateonlypicker-popup>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              {{ form.get('date').value | shortDate : locale }}
            </p>
            <span class="help-block">
              <span *ngIf="form.controls.date.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
              <span *ngIf="form.controls.date.hasError('futureDateOnly')">{{ 'ERROR.BERR-X0026' | translate }}</span>
            </span>
          </div>

          <div class="form-group col-sm-6 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.time.valid && form.controls.time.touched }">
            <label>
              {{ 'TOPIC.TIME' | translate }}
              <required-icon></required-icon>
            </label>
            <timeonlypicker *ngIf="isSaveAllowed" formControlName="time"></timeonlypicker>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              {{ form.get('time').value | time }}
            </p>
            <span class="help-block">
              <span *ngIf="form.controls.time.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-xs-12"
              [ngClass]="{ 'has-error': !form.controls.content.valid && form.controls.content.touched }">
            <rich-editor [readonly]="!isSaveAllowed" formControlName="content" [locale]="locale"></rich-editor>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="btn-toolbar btn-footer" role="toolbar">
              <button
                  type="submit"
                  class="btn btn-success btn-lg"
                  *ngIf="isSaveAllowed"
                  [disabled]="!form.valid"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_SAVE' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-danger btn-lg pull-right-md"
                  *ngIf="isDeleteAllowed"
                  (click)="remove()"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_REMOVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
  <div class="row" *ngIf="!isCreate">
    <div class="col-xs-12">
      <div class="form-tab-container">
        <tabset>
          <tab heading="{{ 'DIVISION.ASSESSMENTS' | translate }}">
            <div class="col-xs-12">
              <exam-assessments [isAssessmentAllowed]="isAssessmentAllowed" [divisionId]="divisionId" [examId]="model?._id"></exam-assessments>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</section>
