import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Name } from '../interfaces'
import { Observable } from 'rxjs/Observable'
import { AuthService } from './auth.service'
import { map } from 'rxjs/operators'

@Injectable()
export class ReportCardsService {
  public baseUrl = environment.API_URL

  constructor (private readonly http: HttpClient, private readonly authService: AuthService) {
  }

  getReportCardsForStudent = (studentId: string): Observable<ReportCard[]> => {
    return this.http.get<ReportCard[]>(this.baseUrl + '/api/v1/report-cards', {
      params: {
        tenantId: this.authService.getTenant()._id,
        termId: this.authService.getSelectedTerm()._id,
        stageId: this.authService.getSelectedStage()._id,
        studentId: studentId
      }
    }).pipe(map(reportCards => {
      return reportCards.map((e) => {
        e.title = 'Boletín ' + e.termDescription
        return e
      })
    }))
  }

  getReportCardById (reportCardId: string): Observable<ReportCard> {
    return this.http.get<ReportCard>(this.baseUrl + '/api/v1/report-cards/' + reportCardId)
  }

  async applyActions (reportCardId: string, actions: ReportCardActionJSON[]): Promise<void> {
    await this.http.patch(this.baseUrl + '/api/v1/report-cards/' + reportCardId, actions).toPromise()
  }
}

export interface ReportCard {
  readonly _id?: string;

  title?: string | undefined;

  // Student
  readonly studentId: string;
  readonly studentName: Name;

  // Tenant
  readonly tenantId: string;
  readonly tenantName: string;

  // Division
  readonly gradeId: string;
  readonly gradeName: string;

  // Division
  readonly divisionId: string;
  readonly divisionName: string;

  // Term
  readonly termId: string;
  readonly termDescription: string;

  // Stage
  readonly stageId: string;
  readonly stageName: string

  // Subjects
  readonly subjects: ReadonlyArray<ReportCardSubject>

  // History
  readonly history: ReadonlyArray<ReportCardAction>;

  // Author
  readonly authorId: string;
  readonly authorName: Name;

  // Template
  readonly templateId: string;
  readonly stages: ReadonlyArray<ReportCardStage>;
}

export interface ReportCardSubject {
  readonly subjectId: string;
  readonly subjectName: string;
  readonly teachers: ReadonlyArray<ReportCardSubjectTeacher>;
}

export interface ReportCardSubjectTeacher {
  readonly teacherId: string;
  readonly teacherName: Name;
}

export interface ReportCardAction {
  readonly authorId: string;
  readonly authorName: Name;
  readonly type: ReportCardActionType;
  readonly timestamp: number;
  readonly payload: ActionPayload;
}

export type ReportCardActionType = 'CreateReportCard' | 'SetGrade' | 'Approve' | 'Disapprove' |'PublishTerm'

export interface ActionPayload {
  readonly [key: string]: string;
}

export interface ReportCardStage {
  readonly name: string,
  readonly type: ReportCardTemplateColumnType,
  readonly state: ReportCardStageState,
  readonly data: ReadonlyArray<ReportCardStageDataRow>
}

export type ReportCardTemplateColumnType = 'From1to10TwoDecimals' | 'AnyText'

export interface ReportCardStageDataRow {
  readonly subjectId: string,
  readonly value: string
}

export type ReportCardStageState = 'Disabled' | 'Pending' | 'Ready' | 'Approved' | 'Published'

export interface ReportCardActionJSON {
  readonly payload: ActionPayload,
  readonly type: ReportCardActionType;
}
