import {Component, OnInit, Output, Input, EventEmitter, NgZone} from '@angular/core'
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload'
import {environment} from '../../../environments/environment'

@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.styl']
})

export class UploadFileComponent implements OnInit {
  @Output('onUpload') onUpload = new EventEmitter<any>()


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'tags' has no initializer and is not defi... Remove this comment to see the full error message
  @Input() tags: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'source' has no initializer and is not de... Remove this comment to see the full error message
  @Input() source: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'preset' has no initializer and is not de... Remove this comment to see the full error message
  @Input() preset: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'uploader' has no initializer and is not ... Remove this comment to see the full error message
  public uploader: FileUploader
  public hovering: boolean = false
  public uploading: boolean = false
  public progress: number = 0
  public placeholder = '/public/assets/img/dot.png'
  private cloudName = environment.CLOUDINARY.CLOUD_NAME

  private uploaderOptions: FileUploaderOptions = {
    url: `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`,
    autoUpload: true,
    queueLimit: 1, // for uploading one file at a time
    //      allowedMimeType: [
    //        'text/csv',
    //        'text/plain',
    //        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //        'application/vnd.ms-excel'
    //      ], // restrict to text files
    isHTML5: true,
    removeAfterUpload: true,
    headers: [
      {
        name: 'X-Requested-With',
        value: 'XMLHttpRequest'
      }
    ]
  }

  constructor(
    private zone: NgZone
  ) {
  }

  public ngOnInit() {
    this.uploader = new FileUploader(this.uploaderOptions)

    // Add custom tag for displaying the uploaded photo in the list
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      if (this.preset) {
        form.append('upload_preset', this.preset)
      }
      form.append('tags', this.tags)
      form.append('file', fileItem)

      fileItem.withCredentials = false
      return {fileItem, form}
    }

    this.uploader.onBeforeUploadItem = (): any => {
      console.log('upload starting')
      this.uploading = true
      this.progress = 0
    }

    this.uploader.onCompleteItem = (): any => {
      console.log('upload completed')
      this.uploading = false
    }


    // @ts-expect-error ts-migrate(6133) FIXME: 'fileItem' is declared but its value is never read... Remove this comment to see the full error message
    this.uploader.onProgressItem = (fileItem: any, progress: any): any => {
      console.log(`uploading... ${progress}`)
      // http://orizens.com/wp/topics/angular-2-ngzone-intro-the-new-scope-apply/
      this.zone.run(() => {
        this.progress = progress
      })
    }

    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      const res: any = JSON.parse(response)
      this.onUpload.next(res)
      return {item, response, status, headers}
    }
  }

  public onFileOver(e: any): void {
    this.hovering = e
  }

  handleFileInput(files: File[]) {
    this.uploader.addToQueue(files, this.uploaderOptions)
  }
}
