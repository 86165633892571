import { Component, OnInit, Input } from '@angular/core'
import { MeService } from '../../services'

@Component({
  selector: 'user-avatar',
  template: '<img class="img-circle" [src]="avatarSrc" [alt]="avatarAlt" [width]="size" [height]="size"/>'
})
export class UserAvatar implements OnInit {
  @Input() size: number = 50


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'avatarSrc' has no initializer and is not... Remove this comment to see the full error message
  public avatarSrc: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'avatarAlt' has no initializer and is not... Remove this comment to see the full error message
  public avatarAlt: string

  constructor (
    private meService: MeService
  ) {}

  ngOnInit (): void {
    this.size = +this.size
    const user = this.meService.getMe()
    this.onUserChange(user)
    // this.meService.subscribeTenantChange(this)
  }

  public onUserChange (user: any) {
    const name = user && user.name
    const fullName = name.last === '.' ? name.first : `${name.first} ${name.last}`

    // http://ourcodeworld.com/articles/read/337/how-to-declare-a-new-property-on-the-window-object-with-typescript
    this.avatarSrc = (window as any).LetterAvatar(fullName, this.size)
    this.avatarAlt = fullName || 'User'
  }
}
