import { Component, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { GuardianService, ViewService } from '../../services'
import { DetailView, User, UserValidator } from '../../interfaces'
import { GuardianStudentsTab } from '../../widgets/guardian-students-tab'

@Component({
  selector: 'app-guardian',
  templateUrl: './guardian.component.html'
})

export class GuardianComponent extends DetailView<User> {
  @ViewChild(GuardianStudentsTab) studentsTab: any


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'isForcePasswordAllowed' has no initializ... Remove this comment to see the full error message
  public isForcePasswordAllowed: boolean

  constructor (
    viewService: ViewService,
    service: GuardianService,
    route: ActivatedRoute
  ) {
    super(viewService, service, route, 'guardian', UserValidator.newFormGroup(), false)
    this.deletePermission = 'remove'
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'model' is declared but its value is never read.
  protected onModelLoaded (model: any) {
    if (!this.isCreate) {
      setTimeout(() => {
        this.activateStudentsTab()
      }, 0)
    }
  }

  public activateStudentsTab () {
    this.studentsTab.activate()
  }

  protected getValue () {
    return this.form.value
  }

  protected getDefaultDetails (): User {
    return {
      gender: biz.GENDERS.FEMALE
    } as User
  }

  protected checkPermissions () {
    super.checkPermissions()
    this.isForcePasswordAllowed = !this.isCreate && this.viewService.isAllowed('tenant', 'force-member-password')
  }
}
