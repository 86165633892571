import { Pipe, PipeTransform } from '@angular/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'

@Pipe({ name: 'contactTypeIcon' })
export class ContactTypeIconPipe implements PipeTransform {

  transform (value: any): string {
    switch (value) {
      case biz.CONTACT_TYPES.EMAIL:
        return 'fa-envelope'
      case biz.CONTACT_TYPES.MOBILE:
        return 'fa-mobile'
      case biz.CONTACT_TYPES.PHONE:
        return 'fa-phone'
      case biz.CONTACT_TYPES.FAX:
        return 'fa-fax'
      default:
        return ''
    }
  }
}
