import { PipeTransform } from '@angular/core';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
var SchoolShiftLabelPipe = /** @class */ (function () {
    function SchoolShiftLabelPipe() {
        var _a;
        this.LABELS = (_a = {},
            _a[biz.SCHOOL_SHIFTS.MORNING] = 'label-morning',
            _a[biz.SCHOOL_SHIFTS.AFTERNOON] = 'label-afternoon',
            _a[biz.SCHOOL_SHIFTS.DOUBLE] = 'label-double',
            _a[biz.SCHOOL_SHIFTS.NIGHT] = 'label-night',
            _a);
    }
    SchoolShiftLabelPipe.prototype.transform = function (value) {
        if (!value || typeof value !== 'string') {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
            return;
        }
        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        return this.LABELS[value] || '';
    };
    return SchoolShiftLabelPipe;
}());
export { SchoolShiftLabelPipe };
