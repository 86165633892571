import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Column } from '../../models'
import { StudentService, ViewService } from '../../services'
import { SearchView, User } from '../../interfaces'

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html'
})

export class StudentsComponent extends SearchView<User> {
  public isImportAllowed = true
  public columns: Column [] = [
    this.routerColumn('_id', 'USER.ID', { isPrimary: true }),
    this.routerColumn('name.last', 'USER.LAST_NAME', { isPrimary: true }),
    this.routerColumn('name.first', 'USER.FIRST_NAME', { isPrimary: true }),


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    new Column('membership.stats.late', 'TOPIC_TYPES.late', false, null, 'warningBadgeCell', 90, 90, 'centeredColHeader'),


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    new Column('membership.stats.absence', 'TOPIC_TYPES.absence', false, null, 'dangerBadgeCell', 90, 90, 'centeredColHeader'),


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    new Column('membership.stats.justified', 'TOPIC_TYPES.justified', false, null, 'infoBadgeCell', 110, 110, 'centeredColHeader'),


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    new Column('membership.tcAccepted', 'USER.TC_ACCEPTED', false, null, 'boolCell', 80, 80),


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    new Column('membership.devices', 'USER.DEVICES', false, null, 'devicesCell', 90, 90)
  ]

  constructor (
    viewService: ViewService,
    route: ActivatedRoute,
    service: StudentService
  ) {
    super(viewService, route, service, 'students', 'student', 'create')
    this.params.sort = {
      'name.last': 1,
      'name.first': 1
    }
  }
}
