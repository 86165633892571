/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ngx-bootstrap/timepicker/timepicker.component.ngfactory";
import * as i2 from "ngx-bootstrap/timepicker/reducer/timepicker.store";
import * as i3 from "ngx-bootstrap/timepicker/timepicker.component";
import * as i4 from "ngx-bootstrap/timepicker/timepicker.config";
import * as i5 from "ngx-bootstrap/timepicker/reducer/timepicker.actions";
import * as i6 from "@angular/forms";
import * as i7 from "./time-only.picker";
var styles_TimeOnlyPicker = [];
var RenderType_TimeOnlyPicker = i0.ɵcrt({ encapsulation: 2, styles: styles_TimeOnlyPicker, data: {} });
export { RenderType_TimeOnlyPicker as RenderType_TimeOnlyPicker };
export function View_TimeOnlyPicker_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "timepicker", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.innerValue = $event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.onBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_TimepickerComponent_0, i1.RenderType_TimepickerComponent)), i0.ɵprd(512, null, i2.TimepickerStore, i2.TimepickerStore, []), i0.ɵdid(2, 704512, null, 0, i3.TimepickerComponent, [i4.TimepickerConfig, i0.ChangeDetectorRef, i2.TimepickerStore, i5.TimepickerActions], { hourStep: [0, "hourStep"], minuteStep: [1, "minuteStep"], arrowkeys: [2, "arrowkeys"], showSpinners: [3, "showSpinners"], showMeridian: [4, "showMeridian"] }, null), i0.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TimepickerComponent]), i0.ɵdid(4, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [2, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i0.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [i6.NgControl], null, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = 1; var currVal_8 = 10; var currVal_9 = true; var currVal_10 = false; var currVal_11 = false; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = _co.innerValue; _ck(_v, 4, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_TimeOnlyPicker_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "timeonlypicker", [], null, null, null, View_TimeOnlyPicker_0, RenderType_TimeOnlyPicker)), i0.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.TimeOnlyPicker]), i0.ɵdid(2, 311296, null, 0, i7.TimeOnlyPicker, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TimeOnlyPickerNgFactory = i0.ɵccf("timeonlypicker", i7.TimeOnlyPicker, View_TimeOnlyPicker_Host_0, { value: "value" }, {}, []);
export { TimeOnlyPickerNgFactory as TimeOnlyPickerNgFactory };
