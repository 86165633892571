import { FormGroup } from '@angular/forms';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { AuthService, TopicService } from '../../services';
import { environment } from '../../../environments/environment';
import { RichEditor } from '../rich-editor';
var TopicPanel = /** @class */ (function () {
    function TopicPanel(authService) {
        var _a;
        this.tags = 'attachment topic';
        this.preset = environment.CLOUDINARY.ATTACHMENTS_PRESET;
        this.focusOnRecipients = false;
        this.loadingRecipients = false;
        this.allowedRecipients = [];
        this.topicTypes = TopicService.TOPIC_COMPONENT_TYPES;
        this.audience = { guardian: true, student: false };
        this.oppositeAudience = {
            guardian: 'student',
            student: 'guardian'
        };
        this.responseLink = '';
        this.allowResponses = false;
        this.user = authService.getCurrentUser();
        this.locale = authService.getCurrentUser().locale;
        this.responseLink = ((_a = authService.getSelectedStage().responseLink) !== null && _a !== void 0 ? _a : '').trim();
    }
    TopicPanel.prototype.ngAfterViewInit = function () {
        this.addUserSignatureToTextEditor();
    };
    TopicPanel.prototype.addUserSignatureToTextEditor = function () {
        var _this = this;
        var _a;
        if (this.mode !== 'create') {
            setTimeout(function () {
                var _a;
                _this.allowResponses = ((_a = _this.editor.value) === null || _a === void 0 ? void 0 : _a.includes("<p><a href=\"" + _this.responseLink + "\">Responder</a></p>")) === true;
            }, 0);
            return;
        }
        var fullName = this.user.name.first + " " + this.user.name.last;
        var email = (_a = this.user.contacts.filter(function (e) { return e.type === "email"; }).sort(function (e) { return e.tag === "work" ? -1 : 1; })[0]) === null || _a === void 0 ? void 0 : _a.value;
        var emailStr = email ? "<br><a href=\"mailto:" + email + "\">" + email + "</a>" : '';
        var responseStr = this.allowResponses && this.responseLink !== '' ? "<p><a href=\"" + this.responseLink + "\">Responder</a></p>" : '';
        this.editor.writeValue("<br><br><br>\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500<br>" + fullName + emailStr + responseStr);
    };
    TopicPanel.prototype.onTypeChange = function () {
        this.allowedRecipients = biz.TOPIC_RECIPIENT_TYPES[this.form.controls.type.value];
        this.audience = this.form.value.audience; // sync form and local audience value
    };
    TopicPanel.prototype.onAudienceChange = function (role) {
        var anyAudience = this.audience.guardian || this.audience.student;
        if (!anyAudience) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            this.audience[this.oppositeAudience[role]] = true;
        }
        this.form.controls.audience.setValue(this.audience); // sync form and local audience value
    };
    TopicPanel.prototype.toggleLoadingRecipients = function (active) {
        this.loadingRecipients = active;
        // console.log(`loading recipients: ${active}`)
    };
    TopicPanel.prototype.onAttachmentUpload = function (res) {
        this.form.controls.attachments.value.push(res);
    };
    TopicPanel.prototype.removeAttachment = function (index) {
        this.form.controls.attachments.value.splice(index, 1);
    };
    TopicPanel.prototype.onChangeAllowResponses = function () {
        var _a;
        this.allowResponses = !this.allowResponses;
        if (this.allowResponses) {
            this.editor.writeValue(this.editor.value + ("<p><a href=\"" + this.responseLink + "\">Responder</a></p>"));
        }
        else {
            this.editor.writeValue((_a = this.editor.value) === null || _a === void 0 ? void 0 : _a.replace("<p><a href=\"" + this.responseLink + "\">Responder</a></p>", ''));
        }
    };
    return TopicPanel;
}());
export { TopicPanel };
