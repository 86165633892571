import { Component } from '@angular/core'
import { LoggedUser } from '../../interfaces'
import { AuthService, MeService, ViewService } from '../../services'

@Component({
  /* tslint:disable */
  selector: '.userBox',
  /* tslint:enable */
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.styl']
})
export class UserBoxComponent {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'currentUser' has no initializer and is n... Remove this comment to see the full error message
  public currentUser: LoggedUser

  constructor (
    private authService: AuthService,
    private meService: MeService,
    private viewService: ViewService
  ) {
    this.meService.subscribeTenantChange(this)
    this.onTenantChange()
  }

  public switchTenant (tenant: any) {
    this.meService.switchTenant(tenant._id)
    .subscribe(
      () => {
        this.viewService.toastr.successT('USERBOX.MSG_SWITCHED_TENANT')
        this.viewService.goTo(['/'], { queryParams: { tenant: tenant._id } })
      },
      this.viewService.handleError
    )
  }

  public logout () {
    return this.authService.logout()
  }

  public onTenantChange () {
    this.currentUser = this.authService.getCurrentUser()
  }
}
