<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content text-center text-muted" *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</section>

<section class="content" *ngIf="!isLoading">
  <div class="row">
    <div class="col-xs-12">

      <form class="form-fixed-help"
          [formGroup]="form"
          (ngSubmit)="submit()">

        <div class="row">
          <div class="form-group col-md-6"
              [ngClass]="{ 'has-error': !form.controls.name.valid && form.controls.name.touched }">
            <label>
              {{ 'SUBJECT.NAME' | translate }}
              <required-icon></required-icon>
            </label>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              {{ form.get('name').value }}
            </p>
            <input type="text"
                *ngIf="isSaveAllowed"
                class="form-control"
                formControlName="name">
            <span class="help-block">
              <span *ngIf="form.controls.name.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6">
            <label>{{ 'SUBJECT.DESCRIPTION' | translate }}</label>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              {{ form.get('description').value }}
            </p>
            <textarea class="form-control"
                *ngIf="isSaveAllowed"
                formControlName="description"
                rows="3">
            </textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <button
                type="submit"
                class="btn btn-success btn-lg"
                *ngIf="isSaveAllowed"
                [disabled]="!form.valid"
                [ladda]="isSubmitting">
              {{ 'COMMON.BTN_SAVE' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-default btn-lg"
                (click)="goBack()">
              {{ 'COMMON.BTN_CANCEL' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-danger btn-lg pull-right-md"
                *ngIf="isDeleteAllowed"
                (click)="remove()"
                [ladda]="isSubmitting">
              {{ 'COMMON.BTN_REMOVE' | translate }}
            </button>
          </div>
        </div>

      </form>
    </div>
  </div>
</section>
