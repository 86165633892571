/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../app-datatable/app-datatable.component.ngfactory";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../app-datatable/app-datatable.component";
import * as i4 from "./student-divisions.tab";
import * as i5 from "../../services/view.service";
import * as i6 from "../../services/division.service";
var styles_StudentDivisionsTab = [];
var RenderType_StudentDivisionsTab = i0.ɵcrt({ encapsulation: 2, styles: styles_StudentDivisionsTab, data: {} });
export { RenderType_StudentDivisionsTab as RenderType_StudentDivisionsTab };
export function View_StudentDivisionsTab_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "appdatatable", [], null, [[null, "page"], [null, "sort"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("sort" === en)) {
        var pd_1 = (_co.onSort($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_AppDataTableComponent_0, i1.RenderType_AppDataTableComponent)), i0.ɵprd(135680, null, i2.TranslatePipe, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(4, 49152, null, 0, i3.AppDataTableComponent, [], { loading: [0, "loading"], rows: [1, "rows"], limit: [2, "limit"], count: [3, "count"], columns: [4, "columns"], sorts: [5, "sorts"] }, { pageEmitter: "page", sortEmitter: "sort" }), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _co.rows; var currVal_2 = _co.params.limit; var currVal_3 = _co.count; var currVal_4 = _co.columns; var currVal_5 = _co.params.sort; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_StudentDivisionsTab_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "student-divisions-tab", [], null, null, null, View_StudentDivisionsTab_0, RenderType_StudentDivisionsTab)), i0.ɵprd(131584, null, i2.TranslatePipe, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(2, 573440, null, 0, i4.StudentDivisionsTab, [i5.ViewService, i6.DivisionService, i2.TranslatePipe], null, null)], null, null); }
var StudentDivisionsTabNgFactory = i0.ɵccf("student-divisions-tab", i4.StudentDivisionsTab, View_StudentDivisionsTab_Host_0, { studentId: "studentId", divisions: "divisions" }, {}, []);
export { StudentDivisionsTabNgFactory as StudentDivisionsTabNgFactory };
