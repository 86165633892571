/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "../app-datatable/app-datatable.component.ngfactory";
import * as i4 from "../app-datatable/app-datatable.component";
import * as i5 from "../course-panel/course.panel.ngfactory";
import * as i6 from "../course-panel/course.panel";
import * as i7 from "../../services/division.service";
import * as i8 from "../../services/view.service";
import * as i9 from "../../services/teacher.service";
import * as i10 from "../../services/stage.service";
import * as i11 from "../../pipes/username.pipe";
import * as i12 from "./division-courses.tab";
var styles_DivisionCoursesTab = [];
var RenderType_DivisionCoursesTab = i0.ɵcrt({ encapsulation: 2, styles: styles_DivisionCoursesTab, data: {} });
export { RenderType_DivisionCoursesTab as RenderType_DivisionCoursesTab };
function View_DivisionCoursesTab_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "btn-toolbar btn-footer clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-primary pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToEditionMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, ["\n      ", "\n    "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("DIVISION.CREATE_COURSE")); _ck(_v, 3, 0, currVal_0); }); }
function View_DivisionCoursesTab_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DivisionCoursesTab_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "appdatatable", [], null, [[null, "page"], [null, "sort"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("sort" === en)) {
        var pd_1 = (_co.onSort($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_AppDataTableComponent_0, i3.RenderType_AppDataTableComponent)), i0.ɵprd(135680, null, i1.TranslatePipe, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(7, 49152, null, 0, i4.AppDataTableComponent, [], { loading: [0, "loading"], rows: [1, "rows"], limit: [2, "limit"], count: [3, "count"], columns: [4, "columns"], sorts: [5, "sorts"] }, { pageEmitter: "page", sortEmitter: "sort" }), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSaveAllowed; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.loading; var currVal_2 = _co.rows; var currVal_3 = _co.params.limit; var currVal_4 = _co.count; var currVal_5 = _co.columns; var currVal_6 = _co.params.sort; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_DivisionCoursesTab_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "course-panel", [], null, [[null, "onSave"], [null, "onCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSave" === en)) {
        var pd_0 = (_co.cancelEditionMode() !== false);
        ad = (pd_0 && ad);
    } if (("onCancel" === en)) {
        var pd_1 = (_co.cancelEditionMode() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_CoursePanel_0, i5.RenderType_CoursePanel)), i0.ɵprd(135680, null, i1.TranslatePipe, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(4, 573440, null, 0, i6.CoursePanel, [i7.DivisionService, i8.ViewService, i9.TeacherService, i10.StageService, i11.UsernamePipe], { division: [0, "division"], model: [1, "model"] }, { onSave: "onSave", onCancel: "onCancel" }), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.division; var currVal_1 = _co.model; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_DivisionCoursesTab_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DivisionCoursesTab_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DivisionCoursesTab_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.model; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.model; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DivisionCoursesTab_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "division-courses-tab", [], null, null, null, View_DivisionCoursesTab_0, RenderType_DivisionCoursesTab)), i0.ɵdid(1, 49152, null, 0, i12.DivisionCoursesTab, [i7.DivisionService, i8.ViewService], null, null)], null, null); }
var DivisionCoursesTabNgFactory = i0.ɵccf("division-courses-tab", i12.DivisionCoursesTab, View_DivisionCoursesTab_Host_0, { division: "division" }, {}, []);
export { DivisionCoursesTabNgFactory as DivisionCoursesTabNgFactory };
