import { Component } from '@angular/core'


// @ts-expect-error ts-migrate(6133) FIXME: 'FormGroup' is declared but its value is never rea... Remove this comment to see the full error message
import { FormGroup } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { MeService, ViewService } from '../../services'
import { PasswordChangeValidator } from '../../interfaces'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent {
  public form = PasswordChangeValidator.newFormGroup()
  public pageData: any = {}

  constructor (
    private meService: MeService,
    private viewService: ViewService,
    private router: Router,
    route: ActivatedRoute
  ) {
    route.data.subscribe(pageData => {
      this.pageData = pageData
    })
  }

  public submit () {
    this.meService.changePassword(this.form.value)
    .subscribe(


      // @ts-expect-error ts-migrate(6133) FIXME: 'user' is declared but its value is never read.
      user => {
        this.viewService.toastr.successT('COMMON.MSG_UPDATE_OK')
        return this.router.navigate( [''] )
      },
      this.viewService.handleError
    )
  }

  public goBack () {
    return this.viewService.goBack()
  }
}
