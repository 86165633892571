import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { Column } from '../../models'
import { DivisionService, ViewService } from '../../services'
import { SearchView, Division } from '../../interfaces'

@Component({
  selector: 'app-divisions',
  templateUrl: './divisions.component.html',
  providers: [TranslatePipe]
})

export class DivisionsComponent extends SearchView<Division> {
  public columns: Column[]


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'shifts' has no initializer and is not de... Remove this comment to see the full error message
  public shifts: any[]

  constructor (
    viewService: ViewService,
    route: ActivatedRoute,
    service: DivisionService
  ) {
    super(viewService, route, service, 'divisions', 'division', 'create')
    this.columns = [
      this.routerColumn('name', 'DIVISION.NAME', { isPrimary: true }),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('students.length', 'STUDENT.SEARCH_TITLE', true, null, 'defaultBadgeCell', 120, 120),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('shift', 'DIVISION.SHIFT', true, null, 'schoolShiftCell', 120, 120),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('grade.name', 'GRADE.DETAIL_TITLE', true, null, null, 120, 120)
    ]
    // default sort
    this.params.populate = {
      grade: 'name'
    }
    this.params.sort = { shift: 1, name: 1 }
  }

  protected onLangReady () {
    this.shifts = this.viewService.optionalList(biz.values(biz.SCHOOL_SHIFTS), '')
  }
}
