/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/tooltip/tooltip.directive";
import * as i2 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i3 from "ngx-bootstrap/tooltip/tooltip.config";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./help.icon";
var styles_HelpIcon = [];
var RenderType_HelpIcon = i0.ɵcrt({ encapsulation: 2, styles: styles_HelpIcon, data: {} });
export { RenderType_HelpIcon as RenderType_HelpIcon };
export function View_HelpIcon_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa fa-question-circle icon-help"], ["containerClass", "tooltip-help"]], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.TooltipDirective, [i0.ViewContainerRef, i0.Renderer2, i0.ElementRef, i2.ComponentLoaderFactory, i3.TooltipConfig], { tooltip: [0, "tooltip"], containerClass: [1, "containerClass"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.key)), ""); var currVal_1 = "tooltip-help"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_HelpIcon_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "help-icon", [], null, null, null, View_HelpIcon_0, RenderType_HelpIcon)), i0.ɵdid(1, 49152, null, 0, i5.HelpIcon, [], null, null)], null, null); }
var HelpIconNgFactory = i0.ɵccf("help-icon", i5.HelpIcon, View_HelpIcon_Host_0, { key: "key" }, {}, []);
export { HelpIconNgFactory as HelpIconNgFactory };
