import { Component, OnInit, OnDestroy } from '@angular/core'
import { BreadcrumbService, MessagesService } from '../../services'


// @ts-expect-error ts-migrate(6192) FIXME: All imports in import declaration are unused.
import { Message, User } from '../../interfaces'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {
  public date: Date = new Date()

  constructor (


    // @ts-expect-error ts-migrate(6138) FIXME: Property 'msgServ' is declared but its value is ne... Remove this comment to see the full error message
    private msgServ: MessagesService,
    private breadServ: BreadcrumbService
  ) {
    // TODO
  }

  public ngOnInit () {
    // // sending a test message
    // this.msgServ.addMessage( new Message( {
    //   author: user2,
    //   content: 'le contenu d\'un message d\'une importance extreme',
    //   destination: user1,
    //   title: 'un message super important'
    // }) )
  }

  public ngOnDestroy () {
    // removing the header
    this.breadServ.clear()
  }
}
