/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/tooltip/tooltip.directive";
import * as i2 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i3 from "ngx-bootstrap/tooltip/tooltip.config";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./required.icon";
var styles_RequiredIcon = [];
var RenderType_RequiredIcon = i0.ɵcrt({ encapsulation: 2, styles: styles_RequiredIcon, data: {} });
export { RenderType_RequiredIcon as RenderType_RequiredIcon };
function View_RequiredIcon_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa fa-exclamation-circle icon-required"], ["containerClass", "tooltip-required"]], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.TooltipDirective, [i0.ViewContainerRef, i0.Renderer2, i0.ElementRef, i2.ComponentLoaderFactory, i3.TooltipConfig], { tooltip: [0, "tooltip"], containerClass: [1, "containerClass"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("CLIENTERROR.CERR-00001")), ""); var currVal_1 = "tooltip-required"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_RequiredIcon_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RequiredIcon_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RequiredIcon_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "required-icon", [], null, null, null, View_RequiredIcon_0, RenderType_RequiredIcon)), i0.ɵdid(1, 49152, null, 0, i6.RequiredIcon, [], null, null)], null, null); }
var RequiredIconNgFactory = i0.ɵccf("required-icon", i6.RequiredIcon, View_RequiredIcon_Host_0, { visible: "visible" }, {}, []);
export { RequiredIconNgFactory as RequiredIconNgFactory };
