import { Component, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { DivisionService, ViewService, StageService } from '../../services'


// @ts-expect-error ts-migrate(6133) FIXME: 'Stage' is declared but its value is never read.
import { DetailView, DivisionValidator, Division, Stage, Grade } from '../../interfaces'
import { QueryString } from '../../models'
import { DivisionStudentsTab } from '../../widgets/division-students-tab'
import { DivisionCoursesTab } from '../../widgets/division-courses-tab'
import { DivisionExamsTab } from '../../widgets/division-exams-tab'
import { DivisionRatingsTab } from '../../widgets/division-ratings-tab'
import { UsernamePipe } from '../../pipes'

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  providers: [TranslatePipe, UsernamePipe]
})

export class DivisionComponent extends DetailView<Division> {
  @ViewChild(DivisionStudentsTab) studentsTab: any
  @ViewChild(DivisionCoursesTab) coursesTab: any
  @ViewChild(DivisionExamsTab) examsTab: any
  @ViewChild(DivisionRatingsTab) RatingsTab: any

  public isAttendanceAllowed: boolean
  public isExamAllowed: boolean
  public isPromoteAllowed: boolean

  public shifts = biz.values(biz.SCHOOL_SHIFTS)


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'grades' has no initializer and is not de... Remove this comment to see the full error message
  public grades: Grade[]

  constructor (
    viewService: ViewService,
    service: DivisionService,
    route: ActivatedRoute,
    private stageService: StageService
  ) {
    super(viewService, service, route, 'division', DivisionValidator.newFormGroup(), true)
    this.deletePermission = 'remove'
    this.options.populate = {
      grade: 'name'
    }
    const isCurrentTermSelected = this.viewService.auth.isCurrentTermSelected()


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.isAttendanceAllowed = isCurrentTermSelected && this.viewService.isAllowed('division', 'attendance')


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.isExamAllowed = isCurrentTermSelected && this.viewService.isAllowed('division', 'schedule-exam')
    this.isPromoteAllowed = !isCurrentTermSelected && this.viewService.isAllowed('division', 'promote')
  }

  public remove(){
    super.remove("COMMON.MSG_DELETE_DIVISION","Eliminar Curso")
  }

  // @ts-expect-error ts-migrate(6133) FIXME: 'model' is declared but its value is never read.
  protected onModelLoaded (model: any) {
    if (!this.isCreate) {
      setTimeout(() => {
        this.activateStudentsTab()
      }, 0)
    } else {
      this.loadGrades()
    }
  }

  public activateStudentsTab () {
    this.studentsTab.activate()
  }

  public activateCoursesTab () {
    this.coursesTab.activate()
  }

  public activateExamsTab() {
    this.examsTab.activate()
  }

  public activateRatingsTab() {
    this.RatingsTab.activate()
  }

  protected getValue () {
    return this.form.value
  }

  protected getDefaultDetails (): Division {
    const model: any = {}
    return model as Division
  }

  private loadGrades (reset: boolean = false) {
    const params = new QueryString({
      sort: {
        order: 1
      }
    })

    const selectedStage = this.viewService.getSelectedStage()
    if (selectedStage) {
      this.stageService.getGrades(params)
      .subscribe(
        result => {
          this.grades = result.list
          if (this.grades && this.grades.length) {


            this.form.controls.grade.setValue(this.grades[0]._id)
          } else if (reset) {


            this.form.controls.grade.reset()
          }
        },
        this.viewService.handleError
      )
    }
  }
}
