import { Pipe, PipeTransform } from '@angular/core'
import * as moment from 'moment'
import { AuthService } from '../services'

@Pipe({ name: 'timeAgo' })
export class TimeAgoPipe implements PipeTransform {

  constructor (private authService: AuthService) {}

  transform (value: any): string {
    const locale = this.authService.getCurrentUser().locale
    return moment(value).locale(locale).calendar()
  }
}
