import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable'
import * as moment from 'moment'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'


// @ts-expect-error ts-migrate(6133) FIXME: 'User' is declared but its value is never read.
import { User, Language } from '../interfaces'

@Injectable()
export class AppTranslateService {
  private languages: Language [] = []

  constructor (
    private translate: TranslateService,
    private http: HttpClient
  ) {
    (window as any).moment = moment

    this.initLanguages()
    const codes = this.languages.map(lang => lang.code)
    translate.addLangs( codes as string [] )
    // this language will be used as a fallback when a translation isn't found in the current language
    this.use(biz.DEFAULT_LOCALE)
    // this.switch(biz.DEFAULT_LOCALE).subscribe(console.log, console.error)

    /*this.userServ.currentUser.subscribe(( user: User ) => {
      this.currentUser = user;

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      let browserLang = this.translate.getBrowserLang();
      let browserCultureLang = this.translate.getBrowserCultureLang();
      console.log('Detected browser language: "' + browserCultureLang + '"');

      // check if current User has a Preferred Language set, and it differs from his browser lang
      let useLang = 'en';
      let prefLang = ( this.currentUser ) ? this.currentUser.locale : null;
      if ( !prefLang ) {
        useLang = browserLang.match( langmatch ) ? browserLang : 'en';
      } else {
        console.log('Detected User preferred language: "' + prefLang + '"');
        useLang = prefLang.match( langmatch ) ? prefLang : 'en';
      }
      this.translate.use( useLang );
      console.log('Translation language has been set to: "' + useLang + '"');
      // translate.use('ru');
    });*/
  }

  public getLanguages (): Language [] {
    return this.languages
  }

  public switch (language: string): Observable<string> {
    if (this.translate.currentLang === language) {
      return Observable.of(language)
    }

    const momentLocales = moment.locales()
    const found = momentLocales.find(loc => loc === language)

    if (found) {
      this.use(language)
      return Observable.of(language)
    }

    // lazily load translations for moment
    return this.http.get(`/public/assets/i18n/moment/${language}.js`, {responseType: 'text'})
    .map((response: any) => {
      try {
        eval.call(window, response)
      } catch (err) {
        console.error(`Error applying date translations for ${language}`)
      }
      this.use(language)
      return language
    })
    // @ts-expect-error ts-migrate(6133) FIXME: 'err' is declared but its value is never read.
    .catch(err => {
      this.use(language)
      return language
    })
  }

  public getTranslate (key: string, ...interpolateParams: any[]): Observable<any> {
    return this.translate.get(key, interpolateParams)
  }

  public onReady () {
    const currentLang = this.translate.currentLang
    return this.translate.getTranslation(currentLang)
  }

  private use (language: string) {
    moment.locale(language)
    this.translate.use(language)
  }

  private initLanguages () {
    let newLanguage = {
      code: 'en',
      name: 'English'
    } as Language
    this.languages.push(newLanguage)
    newLanguage = {
      code: 'es',
      name: 'Español'
    } as Language
    this.languages.push(newLanguage)
  }
}
