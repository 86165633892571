<div class="pull-left info" *ngIf="errorsDetail.length">
  <ul class="nav navbar-nav">
    <ng-template #errorsDetailDialog >
      <ul *ngFor="let errorDetail of errorsDetail" class="popover-list">
        <li>
          {{ errorDetail | translateError }}
        </li>
      </ul>
    </ng-template>
    <span
      class="txt-overflow"
      (click)="errorsDetailPopover.toggle()"
      >
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
    </span>
    <span [popover]="errorsDetailDialog"
      #errorsDetailPopover="bs-popover"
      placement="left"
      container="body">
    </span>
  </ul>
</div>


