import { Injectable } from '@angular/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'mome... Remove this comment to see the full error message
import * as moment from 'moment-timezone'

@Injectable()
export class DateService {
  public isPast (v: any, timezone?: string) {
    const today = this.toMoment(null, null, timezone)

    if (v.year > today.year()) {
      return false
    } else if (v.year === today.year()) {
      if (v.month > today.month()) {
        return false
      } else if (v.month === today.month() && v.date >= today.date()) {
        return false
      }
    }
    return true
  }

  public isFuture (v: any, timezone?: string) {
    const today = this.toMoment(null, null, timezone)

    if (v.year < today.year()) {
      return false
    } else if (v.year === today.year()) {
      if (v.month < today.month()) {
        return false
      } else if (v.month === today.month() && v.date <= today.date()) {
        return false
      }
    }
    return true
  }

  public toMoment (date: any, time: any, timezone?: string) {
    if (date && typeof date.toObject === 'function') {
      date = date.toObject()
    }
    if (time && typeof time.toObject === 'function') {
      time = time.toObject()
    }

    const timestamp = timezone ? moment.tz(timezone) : moment()
    if (date) {
      timestamp.date(date.date)
      timestamp.month(date.month || date.months)
      timestamp.year(date.year || date.years)
    }
    if (time) {
      timestamp.hour(time.hour)
      timestamp.minutes(time.minutes)
    }
    return timestamp
  }
}
