import { Pipe, PipeTransform } from '@angular/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'

@Pipe({ name: 'topicAttendanceLabel' })
export class TopicAttendanceLabelPipe implements PipeTransform {
  private LABELS = {
    [biz.TOPIC_TYPES.LATE_ARRIVAL]: 'label-late',
    [biz.TOPIC_TYPES.ABSENCE]: 'label-absence',
    [biz.TOPIC_TYPES.JUSTIFIED_ABSENCE]: 'label-justified'
  }

  transform (value: string): string {
    if (!value || typeof value !== 'string') {


      // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      return
    }



    // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    return this.LABELS[value] || ''
  }

}
