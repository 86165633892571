<ng-template #personItemTemplate let-model="item" let-index="index">
  <div class="person-roles">
    <span>{{ model.name | username }}</span>
    <span class="text-muted" *ngIf="model.roles?.length">
      <i class="fa fa-fw" *ngFor="let role of model.roles" [ngClass]="{
        'fa-universal-access': role === 'student',
        'fa-user-circle-o': role === 'guardian',
        'fa-id-card-o': role === 'teacher',
        'fa-id-badge': role === 'employee',
        'fa-street-view': role === 'supervisor'
      }"></i>
    </span>
  </div>
</ng-template>

<div *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="form-group col-sm-3"
        [ngClass]="{ 'has-error': !form.controls._id.valid && form.controls._id.touched }">
      <label>
        {{ 'USER.ID' | translate }}
        <required-icon [visible]="isCreate"></required-icon>
      </label>
      <input *ngIf="isCreate"
          type="text"
          class="form-control"
          formControlName="_id"
          [(ngModel)]="idFilter"
          [typeahead]="idSource"
          [typeaheadItemTemplate]="personItemTemplate"
          typeaheadOptionField="_id"
          typeaheadWaitMs="250"
          (typeaheadOnSelect)="onUserChosen($event)">
      <div class="form-control-static" *ngIf="!isCreate">
        {{ form.controls._id.value }}
      </div>
      <span class="help-block">
        <span *ngIf="form.controls._id.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
        <span *ngIf="form.controls._id.hasError('minlength')">{{ 'CLIENTERROR.CERR-00010' | translate }}</span>
      </span>
    </div>

    <div class="form-group col-sm-3"
        [ngClass]="{ 'has-error': !form.controls.dob.valid && form.controls.dob.touched }">
      <label>
        {{ 'USER.DOB' | translate }}
        <required-icon *ngIf="dobRequired"></required-icon>
      </label>
      <dateonlypicker-popup formControlName="dob" [locale]="locale"></dateonlypicker-popup>
      <span class="help-block">
        <span *ngIf="form.controls.dob.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
      </span>
    </div>

    <!--
    <div class="form-group col-sm-3"
        [ngClass]="{ 'has-error': !form.controls.gender.valid && form.controls.gender.touched }">
      <label>
        {{ 'USER.GENDER' | translate }}
        <required-icon></required-icon>
      </label>
      <div>
        <div class="btn-group btn-group-2">
          <button type="button"
              class="btn btn-default"
              btnRadio="female"
              formControlName="gender"
              [(ngModel)]="gender">
    -->
              <!-- ngModel is needed because ngx-bootstrap doesn't toggle the other options off without it -->
    <!--
            {{ 'GENDERS.female' | translate }}
          </button>
          <button type="button"
              class="btn btn-default"
              btnRadio="male"
              formControlName="gender"
              [(ngModel)]="gender">
    -->
              <!-- ngModel is needed because ngx-bootstrap doesn't toggle the other options off without it -->
    <!--
            {{ 'GENDERS.male' | translate }}
          </button>
        </div>
      </div>
      <span class="help-block">
        <span *ngIf="form.controls.gender.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
      </span>
    </div>
    -->

    <div *ngIf="form.controls.relationship"
        class="form-group col-sm-3"
        [ngClass]="{ 'has-error': !form.controls.relationship.valid && form.controls.relationship.touched }">
      <label>
        {{ 'STUDENT.GUARDIAN_RELATIONSHIP' | translate }}
        <required-icon></required-icon>
      </label>
      <input type="text"
          class="form-control"
          formControlName="relationship">
  <!--     <tag-input
          [onlyFromAutocomplete]="false"
          [modelAsStrings]="true"
          placeholder="+"
          secondaryPlaceholder="..."
          theme="bootstrap"
          formControlName="relationship">
        <tag-input-dropdown
            [showDropdownIfEmpty]="false"
            [appendToBody] = "false"
            [autocompleteItems]="relationships">
        </tag-input-dropdown>
      </tag-input> -->
      <span class="help-block">
        <span *ngIf="form.controls.relationship.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
      </span>
    </div>
  </div>

  <div class="row">
    <div [formGroup]="form.controls.name"
        class="form-group col-sm-3"
        [ngClass]="{ 'has-error': !form.get('name.first').valid && form.get('name.first').touched }">
      <label>
        {{ 'USER.FIRST_NAME' | translate }}
        <required-icon></required-icon>
      </label>
      <input type="text"
          class="form-control"
          formControlName="first">
<!--       <input *ngIf="isAutocompletable"
          class="form-control"
          formControlName="first"
          [(ngModel)]="firstNameFilter"
          [typeahead]="firstNameSource"
          typeaheadOptionField="name.first"
          typeaheadWaitMs="250"
          (typeaheadOnSelect)="onUserChosen($event)"> -->
      <span class="help-block">
        <span *ngIf="form.get('name.first').hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
      </span>
    </div>

    <div [formGroup]="form.controls.name"
        class="form-group col-sm-3"
        [ngClass]="{ 'has-error': !form.get('name.last').valid && form.get('name.last').touched }">
      <label>
        {{ 'USER.LAST_NAME' | translate }}
        <required-icon></required-icon>
      </label>
      <input type="text"
          class="form-control"
          formControlName="last">
<!--       <input *ngIf="isAutocompletable"
          class="form-control"
          formControlName="last"
          [(ngModel)]="lastNameFilter"
          [typeahead]="lastNameSource"
          typeaheadOptionField="name.last"
          typeaheadWaitMs="250"
          (typeaheadOnSelect)="onUserChosen($event)"> -->
      <span class="help-block">
        <span *ngIf="form.get('name.last').hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
      </span>
    </div>
  </div>
<!--
  <fieldset *ngIf="!isCreate && form.controls.divisions?.length">
    <legend>
      {{ 'DIVISION.DETAIL_TITLE' | translate }}
      <div class="btn-group" *ngIf="form.controls.division.value">
        <button type="button"
            class="btn"
            (click)="removeCurrentDivision()">
          {{ 'COMMON.BTN_REMOVE' | translate }}
        </button>
      </div>
    </legend>
  </fieldset>

  <div class="row" *ngIf="!isCreate && form.controls.division && !form.controls.division.value">
    <div class="form-group col-xs-12 text-danger">
      {{ 'STUDENT.NO_DIVISION' | translate }}
    </div>
  </div>

  <div class="row" *ngIf="!isCreate && form.controls.division?.value">
    <div class="form-group col-sm-3">
      <label>
        {{ 'STAGE.DETAIL_TITLE' | translate }}
      </label>
      <div class="form-control-static">
        <a routerLink="/stages/{{ form.controls.division.value.stage._id }}">{{ form.controls.division.value.stage.name }}</a>
      </div>
    </div>

    <div class="form-group col-sm-3">
      <label>
        {{ 'DIVISION.DETAIL_TITLE' | translate }}
      </label>
      <div class="form-control-static">
        <a routerLink="/divisions/{{ form.controls.division.value._id }}">{{ form.controls.division.value.name }}</a>
      </div>
    </div>

    <div class="form-group col-sm-3">
      <label>
        {{ 'DIVISION.SHIFT' | translate }}
      </label>
      <div class="form-control-static">
        <span class="label" [ngClass]="form.controls.division.value.shift | schoolShiftLabel">
          {{ form.controls.division.value.shift | schoolShift }}
        </span>
      </div>
    </div>
  </div>
-->
</div>


<fieldset>
  <legend>
    {{ 'USER.CONTACTS' | translate }}
    <div class="btn-group">
      <button type="button"
          class="btn"
          (click)="form.addContact()">
        {{ 'COMMON.BTN_ADD' | translate }}
      </button>
    </div>
  </legend>
</fieldset>

<table class="table no-more-table table-fixed table-condensed">
  <thead>
    <tr>
      <th class="col-sm-4">
        {{ 'USER.CONTACT_TYPE' | translate }}
        <required-icon></required-icon>
      </th>
      <th class="col-sm-4">
        {{ 'USER.CONTACT_TAG' | translate }}
        <required-icon></required-icon>
      </th>
      <th class="col-sm-4">
        {{ 'USER.CONTACT_DATA' | translate }}
        <required-icon></required-icon>
      </th>
      <th>&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="!form.getContactForms().length">
      <td colspan="4" class="text-center text-muted">{{ 'COMMON.MSG_NO_DATA' | translate }}</td>
    </tr>
    <tr *ngFor="let contactForm of form.getContactForms().controls; let i = index" [formGroup]="contactForm">
      <td class="col-sm-4 form-group text-left"
          [ngClass]="{ 'has-error': !contactForm.get('type').valid && contactForm.get('type').touched }"
          [attr.data-title]="'USER.CONTACT_TYPE' | translate">
        <select class="form-control" formControlName="type">
          <option *ngFor="let contactType of contactTypes" [ngValue]="contactType">
            {{ contactType | contactType }}
          </option>
        </select>
      </td>
      <td class="col-sm-4 form-group text-left"
          [ngClass]="{ 'has-error': !contactForm.get('tag').valid && contactForm.get('tag').touched }"
          [attr.data-title]="'USER.CONTACT_TAG' | translate">
        <select class="form-control" formControlName="tag">
          <option *ngFor="let contactTag of contactTags" [ngValue]="contactTag">
            {{ contactTag | contactTag }}
          </option>
        </select>
      </td>
      <td class="col-sm-4 form-group text-left"
          [ngClass]="{ 'has-error': !contactForm.get('value').valid }"
          [attr.data-title]="'USER.CONTACT_DATA' | translate">
        <input type="text"
            class="form-control"
            formControlName="value">
      </td>
      <td class="text-center remove-col">
        <a>
          <i class="fa fa-times action-icon action-icon-danger"
              aria-hidden="true"
              (click)="form.removeContactAt(i)"
              tooltip="{{ 'COMMON.BTN_REMOVE' | translate }}">
          </i>
        </a>
      </td>
    </tr>
  </tbody>
</table>
