import { Component, Input, Output, EventEmitter, forwardRef, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { TagInputComponent } from 'ngx-chips'
import { TranslatePipe } from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'


// @ts-expect-error ts-migrate(6133) FIXME: 'StageService' is declared but its value is never ... Remove this comment to see the full error message
import { DivisionService, StudentService, StageService, ViewService } from '../../services'
import { QueryString } from '../../models'


// @ts-expect-error ts-migrate(6133) FIXME: 'Stage' is declared but its value is never read.
import { Recipient, User, Division, Stage } from '../../interfaces'

const INPUT_PATTERN =  /^(\$|#|@)/ // /^(\$|#|@).+/

// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export const RECIPIENTS_SELECTOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RecipientsSelector),
  multi: true
}

@Component({
  selector: 'recipients-selector',
  templateUrl: './recipients.selector.html',
  providers: [RECIPIENTS_SELECTOR_VALUE_ACCESSOR, TranslatePipe]
})

export class RecipientsSelector implements ControlValueAccessor {
  @ViewChild(TagInputComponent) tagInput: any
  @Input() readonly: boolean = false
  @Output() onFocus = new EventEmitter<string>()
  @Output() onBlur = new EventEmitter<string>()
  @Output() onLoadingStart = new EventEmitter<null>()
  @Output() onLoadingEnd = new EventEmitter<null>()
  public onChange: any = Function.prototype
  public onTouched: any = Function.prototype
  public innerValue: any[] = []


  // @ts-expect-error ts-migrate(2564) FIXME: Property '_recipientTypes' has no initializer and ... Remove this comment to see the full error message
  private _recipientTypes: string[]
  private RECIPIENT_TYPES: string[] = biz.values(biz.RECIPIENT_TYPES)

  private loadStudents = (filter: string): Observable<any> => {
    const params = this.getParams(filter, 20)
    return this.studentService.search(params)
  }
  private loadDivisions = (filter: string): Observable<any> => {
    const params = this.getParams(filter)
    return this.divisionService.search(params)
  }
  private recipientsConfig = {
    [biz.RECIPIENT_TYPES.USER]: { loader: this.loadStudents, mapper: this.mapUserToItem, char: '@' },
    [biz.RECIPIENT_TYPES.DIVISION]: { loader: this.loadDivisions, mapper: this.mapDivisionToItem, char: '#' }
  }

  constructor (
    private divisionService: DivisionService,
    private studentService: StudentService,
    private viewService: ViewService
  ) {
  }

  @Input()
  set recipientTypes (recipientTypes: string[]) {
    recipientTypes = recipientTypes || []
    // remove invalid types
    if (recipientTypes.length) {
      this._recipientTypes = recipientTypes.filter(recipientType => {
        return this.RECIPIENT_TYPES.find(type => recipientType === type)
      })
    }
    // consider all the types if none is left in the list provided
    if (!recipientTypes.length) {
      this._recipientTypes = this.RECIPIENT_TYPES
    }

    // Quick fix, remove stages from the recipient types
    this._recipientTypes = this._recipientTypes.filter(type  => type !== biz.RECIPIENT_TYPES.STAGE)

    // remove existing recipients non included in the restricted list
    this.innerValue = this.innerValue.filter(item => {
      return this._recipientTypes.find(item.type)
    })
  }

  get recipientTypes (): string[] {
    return this._recipientTypes
  }

  public get value (): any[] {
    return this.innerValue.map(item => {
      return {
        type: item.type,
        reference: item.id
      }
    })
  }

  @Input()
  public set value (_value: any[]) {
    _value = _value || []
    this.innerValue = _value.map((recipient: Recipient) => {
      const validType = this._recipientTypes.find(type => type === recipient.type)
      if (validType) {


        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        return this.recipientsConfig[recipient.type].mapper(recipient.reference)
      }
    }).filter(elem => elem) // Filter out any undefined elements inside the array
  }

  public removeItem (index: number) {
    // this was not working properly when removing through the X button on each tag item
    // const item = this.innerValue.splice(index, 1)[0]
    const item = this.innerValue[index]
    if (item) {
      this.tagInput.removeItem(item, index)
    }
  }

  public getItemClass (item: any): string {
    switch (item.type) {
      case biz.RECIPIENT_TYPES.DIVISION:
        return 'tag__wrapper_2'
      case biz.RECIPIENT_TYPES.USER:
        return 'tag__wrapper_1'
      default:
        return 'tag__wrapper_4'
    }
  }

  public onInputFocused (value: any) {
    this.onFocus.emit(value)
  }

  public onInputBlurred (value: any) {
    this.onBlur.emit(value)
    this.onTouched()
  }

  public onSelectionChange () {
    this.onChange(this.value)
  }

  public matchingFn (value: any, target: any) {
    // console.log(`${value} - ${JSON.stringify(target)}`)

    // discard tag character
    if (INPUT_PATTERN.test(value)) {
      value = value.slice(1)
    }
    const targetValue = target.itemName.toString()

    return targetValue && targetValue
        .toLowerCase()
        .indexOf(value.toLowerCase()) >= 0
  }

  public source = (filter: string): Observable<any> => {
    // retain the scope to get access to instance properties
    // https://www.npmjs.com/package/ng2-tag-input#api-for-taginputdropdowncomponent

    // if there's only one recipient type, search for it and ignore the first character if it's a special one
    if (this._recipientTypes.length === 1) {


      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      const recipientConfig = this.recipientsConfig[this._recipientTypes[0]]
      if (filter.charAt(0) === recipientConfig.char) {
        filter = filter.slice(1)
      }
      return this.loadRecipients(filter, recipientConfig.loader, recipientConfig.mapper)
    }

    // iterate over allowed recipient types and check
    for (var i = this._recipientTypes.length - 1; i >= 0; i--) {


      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      const recipientConfig = this.recipientsConfig[this._recipientTypes[i]]
      if (filter.charAt(0) === recipientConfig.char) {
        filter = filter.slice(1)
        return this.loadRecipients(filter, recipientConfig.loader, recipientConfig.mapper)
      }
    }

    // if it gets to this point no initial character was matched, so search for students if they are allowed
    if (this._recipientTypes.indexOf(biz.RECIPIENT_TYPES.USER) > -1) {
      return this.loadRecipients(filter, this.loadStudents, this.mapUserToItem)
    }

    // always return an observable
    return Observable.of([])
  }

  private loadRecipients (filter: string, loader: (f: string) => Observable<any>, mapper: (v: any) => any) {
    this.onLoadingStart.emit()

    return loader(filter)
    .finally(() => { this.onLoadingEnd.emit() })
    .map(
      result => result.list.map(mapper),
      this.viewService.handleError
    )
  }

  private getParams (filter: string, limit?: number): QueryString {
    let props: any = {
      q: filter
    }
    if (limit) {
      props.limit = limit
    }
    return new QueryString(props)
  }

  private mapUserToItem (user: User) {
    return {
      id: user._id,
      itemName: `${user.name.last}, ${user.name.first}`,
      type: biz.RECIPIENT_TYPES.USER
    }
  }

  private mapDivisionToItem (division: Division) {
    return {
      id: division._id,
      itemName: division.name,
      type: biz.RECIPIENT_TYPES.DIVISION
    }
  }

  // ControlValueAccessor
  // model -> view
  public writeValue (value: any): void {
    this.value = value
  }

  public registerOnChange (fn: any): void {
    this.onChange = fn
  }

  public registerOnTouched (fn: any): void {
    this.onTouched = fn
  }
}
