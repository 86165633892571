import { FormBuilder, FormGroup, Validators } from '@angular/forms'

export interface Name {
  first: string
  last: string
  full?: string
}

export class NameValidator {

  public static newFormGroup (): FormGroup {
    const fb = new FormBuilder()

    return fb.group({
      first: fb.control('', Validators.required),
      last: fb.control('', Validators.required)
    })
  }
}
