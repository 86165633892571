<div class="wrapper">
  <app-header></app-header>
  <app-menu-aside [links]="mylinks"></app-menu-aside>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <app-toastr></app-toastr>
    <confirmation-modal (onInitialized)="onConfirmationModalInitialized($event)"></confirmation-modal>

    <!-- Main content -->
    <div class="row">
      <div [ngClass]="pageData.colWidth ? pageData.colWidth : 'col-xs-12'" class="page-content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- /.content -->

  </div>
  <!-- /.content-wrapper -->
  <!-- <app-footer></app-footer> -->
</div>
<!-- ./wrapper -->
