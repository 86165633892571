<div *ngIf="!model">
  <div class="btn-toolbar btn-footer clearfix" *ngIf="isSaveAllowed">
    <button
        type="button"
        class="btn btn-primary pull-right"
        (click)="switchToEditionMode()">
      {{ 'DIVISION.CREATE_COURSE' | translate }}
    </button>
  </div>

  <appdatatable
      [rows]="rows"
      [columns]="columns"
      [sorts]="params.sort"
      [count]="count"
      [limit]="params.limit"
      [loading]="loading"
      (page)="onPage($event)"
      (sort)="onSort($event)">
  </appdatatable>
</div>

<div *ngIf="model">
  <course-panel
      [division]="division"
      [model]="model"
      (onSave)="cancelEditionMode()"
      (onCancel)="cancelEditionMode()">
  </course-panel>
</div>
