import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'
import { TermService, DivisionService, ViewService } from '../../services'
import { Term, Stage, Division, User } from '../../interfaces'
import { QueryString } from '../../models'

@Component({
  selector: 'app-promote',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.styl'],
  providers: [TranslatePipe]
})
export class PromotionComponent implements OnInit {
  public isLoading = false
  public isSubmitting = false
  public pageData: any = {}

  public divisions: any = {} // indexed by stageId for caching, includes divisions for current stage
  public students: StudentWrapper[] = []
  public result: any // result from the promote operation

  // FROM
  public selectedTerm: Term
  public selectedStage: Stage


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'selectedDivision' has no initializer and... Remove this comment to see the full error message
  public selectedDivision: Division

  // TO


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'terms' has no initializer and is not def... Remove this comment to see the full error message
  public terms: Term[]
  public target: any = {}

  constructor (
    route: ActivatedRoute,
    private viewService: ViewService,
    private termService: TermService,
    private divisionService: DivisionService
  ) {
    route.data.subscribe(data => {
      this.pageData = data
    })

    // to know from which division user arrived to this page
    this.selectedTerm = this.viewService.getSelectedTerm()
    this.selectedStage = this.viewService.getSelectedStage()

    route.params.subscribe(params => {
      // this.selectedDivision = params.id

      divisionService.search()
      .subscribe(
        results => {
          // this.divisions[this.selectedStage._id] = results.list


          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Division | undefined' is not assignable to t... Remove this comment to see the full error message
          this.selectedDivision = results.list.find((division: any) => division._id === params.id)
          this.loadStudents()
        },
        this.viewService.handleError
      )
    })
  }

  ngOnInit () {
    this.isLoading = true
    const conditions = { current: true }
    // const conditions = { _id: { $ne: this.selectedTerm._id } }

    this.termService.search(new QueryString({ conditions }))
    .finally(() => this.isLoading = false )
    .subscribe(
      results => {
        this.terms = results.list
        if (this.terms.length) {
          this.target.termId = this.terms[0] && this.terms[0]._id
          this.onTermChange()
        }
      },
      this.viewService.handleError
    )
  }

  public onTermChange () {
    const term = this.target.term = this.terms.find(term => term._id === this.target.termId)


    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    this.target.stageId = term.stages[0] && term.stages[0]._id
    this.onStageChange()
  }

  public onStageChange () {
    let divisions = this.divisions[this.target.stageId]
    if (divisions) {
      // avoid crashing if there are no divisions for the stage
      this.target.divisionId = divisions.length ? divisions[0]._id : undefined
      this.onDivisionChange()
      return
    }

    // divisions for stage were not yet retrieved, go get them
    this.divisionService.searchByTermAndStage(this.target.termId, this.target.stageId)
    .subscribe(
      result => {
        divisions = this.divisions[this.target.stageId] = result.list
        this.target.divisionId = divisions.length ? divisions[0]._id : undefined
        this.onDivisionChange()
      },
      this.viewService.handleError
    )
  }

  public onDivisionChange () {
    this.result = undefined
  }

  public submit () {
    const studentsToPromote = this.filterSelectedStudents()
    if (!this.target.divisionId || !studentsToPromote.length) {
      return
    }

    this.result = undefined
    this.isSubmitting = true
    this.divisionService.promoteStudents(this.selectedDivision._id, this.target.divisionId, studentsToPromote)
    .finally(() => { this.isSubmitting = false })
    .subscribe(
      result => {
        this.viewService.toastr.successT('COMMON.MSG_UPDATE_OK')
        this.result = result
      },
      this.viewService.handleError
    )
  }

  public goBack () {
    this.viewService.goBack()
  }

  private loadStudents () {
    if (!this.selectedDivision) {
      this.students = []
      return
    }

    this.divisionService.getStudents(this.selectedDivision._id)
    .subscribe(result => {
      this.students = result as StudentWrapper[]
      this.students.forEach(student => {
        student.selected = true
      })
    })
  }

  private filterSelectedStudents (): string[] {
    return this.students.filter(student => student.selected).map(student => student._id)
  }
}

interface StudentWrapper extends User {
  selected: boolean
}
