var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { StageDependantCrudService, Topic } from '../interfaces';
import { AuthService } from './auth.service';
import { AppCacheService } from './app-cache.service';
var TopicService = /** @class */ (function (_super) {
    __extends(TopicService, _super);
    function TopicService(http, auth, cacheService) {
        var _this = _super.call(this, http, '/topics', auth, false, function (json) { return Topic.fromJson(json); }) || this;
        _this.cacheService = cacheService;
        return _this;
    }
    TopicService.prototype.loadFeed = function (studentId, next) {
        var requestOptions;
        if (next) {
            var search = new URLSearchParams();
            search.set('next', next);
            requestOptions = new RequestOptions({ search: search });
        }
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        return this.http.get(this.url + "/feed/" + studentId, requestOptions);
    };
    TopicService.prototype.getDeliveryGroups = function (topicId, options) {
        var _this = this;
        var key = "topic." + topicId + ".groups";
        var cachedResult = this.cacheService.get(key);
        if (cachedResult) {
            return Observable.of(cachedResult);
        }
        return this.http.get(this.url + "/" + topicId + "/divisions", this.toRequestOptions(options)).map(function (result) {
            // cache result if it doesn't have a virtual division
            var groups = result;
            var hasVirtualDivision = groups.find(function (group) { return !group._id; });
            if (!hasVirtualDivision) {
                _this.cacheService.set(key, groups);
            }
            return groups;
        });
    };
    TopicService.prototype.getDeliveryGroupDetails = function (topicId, divisionId, options) {
        return this.http.get(this.url + "/" + topicId + "/divisions/" + divisionId, this.toRequestOptions(options));
    };
    TopicService.TOPIC_COMPONENT_TYPES = [
        biz.TOPIC_TYPES.NEWS,
        biz.TOPIC_TYPES.EVENT,
        biz.TOPIC_TYPES.HOLIDAY,
        biz.TOPIC_TYPES.STRIKE,
        biz.TOPIC_TYPES.NO_CLASSES,
        biz.TOPIC_TYPES.WARNING,
        biz.TOPIC_TYPES.ACHIEVEMENT
    ];
    return TopicService;
}(StageDependantCrudService));
export { TopicService };
