import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { QueryString } from '../models'
import { StageDependantCrudService, User } from '../interfaces'
import { AuthService } from './auth.service'

@Injectable()
export class StudentService extends StageDependantCrudService<User> {

  constructor (http: HttpClient, protected authService: AuthService) {
    super(http, '/students', authService)
  }

  public getGuardians (id: string, params: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/guardians`, this.toRequestOptions(params))
  }

  public addGuardian (id: string, guardian: User): Observable<any> {
    this.updateUrl()
    return this.http.post(`${this.url}/${id}/guardians`, guardian)
  }

  public updateGuardian (id: any, guardianId: any, guardian: User): Observable<any> {
    this.updateUrl()
    return this.http.patch(`${this.url}/${id}/guardians/${guardianId}`, guardian)
  }

  public removeGuardian (id: any, guardianId: any) {
    this.updateUrl()
    return this.http.delete(`${this.url}/${id}/guardians/${guardianId}`)
  }

  public getAttendance (id: string): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/attendance`)
  }
}
