import { Component, Input } from '@angular/core'

@Component({
  selector: 'required-icon',
  template: `<i class="fa fa-exclamation-circle icon-required"
    *ngIf="visible"
    aria-hidden="true"
    tooltip="{{ 'CLIENTERROR.CERR-00001' | translate }}"
    containerClass="tooltip-required"></i>`
})

export class RequiredIcon {
  @Input() visible: boolean = true
}
