var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { StageDependantCrudService } from '../interfaces';
import { AuthService } from './auth.service';
var SupervisorService = /** @class */ (function (_super) {
    __extends(SupervisorService, _super);
    function SupervisorService(http, authService) {
        return _super.call(this, http, '/supervisors', authService) || this;
    }
    return SupervisorService;
}(StageDependantCrudService));
export { SupervisorService };
