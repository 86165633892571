

// @ts-expect-error ts-migrate(6133) FIXME: 'Input' is declared but its value is never read.
import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core'
import { ActivatedRoute } from '@angular/router'


// @ts-expect-error ts-migrate(6133) FIXME: 'FormGroup' is declared but its value is never rea... Remove this comment to see the full error message
import { FormGroup } from '@angular/forms'
import { TranslatePipe } from '@ngx-translate/core'


// @ts-expect-error ts-migrate(6133) FIXME: 'AuthService' is declared but its value is never r... Remove this comment to see the full error message
import { ViewService, StageService, SubjectService, AuthService, GradeWrapperService } from '../../services'


// @ts-expect-error ts-migrate(6133) FIXME: 'Subject' is declared but its value is never read.
import { Grade, GradeValidator, Subject, CrudService, DetailView } from '../../interfaces'
import { QueryString } from '../../models'

@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  providers: [TranslatePipe]
})

export class GradeComponent extends DetailView<Grade> {

  constructor (
    protected viewService: ViewService,
    protected stageService: StageService,
    protected subjectService: SubjectService,
    route: ActivatedRoute
  ) {
    super(viewService, new GradeWrapperService(stageService), route, 'stage', GradeValidator.newFormGroup())
    this.options.populate = 'subjects'
    this.updatePermission = 'update-grade'
    this.createPermission = 'add-grade'
    this.deletePermission = 'remove-grade'
  }

  public searchSubjects = (filter: string) => {
    // retain the scope to get access to instance properties
    // https://www.npmjs.com/package/ng2-tag-input#api-for-taginputdropdowncomponent
    const params = new QueryString({
      limit: 20,
      q: filter
    })

    return this.subjectService.search(params)
    .map(
      result => {
        return result.list
      },
      this.viewService.handleError
    )
  }

  protected getValue () {
    return this.form.value
  }

  public submit () {
    this.form.value.term = this.viewService.getSelectedTerm()._id
    return super.submit()
  }
}
