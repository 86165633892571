import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { TranslatePipe } from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable'
import { ViewService, StageService, DivisionService, TeacherService } from '../../services'
import { Division, Course, CourseValidator, Subject, Teacher, CrudService } from '../../interfaces'
import { QueryString } from '../../models'
import { UsernamePipe } from '../../pipes'

@Component({
  selector: 'course-panel',
  templateUrl: './course.panel.html',
  providers: [TranslatePipe]
})

export class CoursePanel implements OnChanges {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'division' has no initializer and is not ... Remove this comment to see the full error message
  @Input() division: Division


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'model' has no initializer and is not def... Remove this comment to see the full error message
  @Input() model: Course
  @Output() onSave = new EventEmitter<any>()
  @Output() onRemove = new EventEmitter<any>()
  @Output() onCancel = new EventEmitter<void>()



  // @ts-expect-error ts-migrate(2564) FIXME: Property '_id' has no initializer and is not defin... Remove this comment to see the full error message
  public _id: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'form' has no initializer and is not defi... Remove this comment to see the full error message
  public form: FormGroup


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'isCreate' has no initializer and is not ... Remove this comment to see the full error message
  public mode: 'create'|'edit'|'readonly';
  public isSaveAllowed: boolean = false


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'isSubmitting' has no initializer and is ... Remove this comment to see the full error message
  public isSubmitting: boolean



  // @ts-expect-error ts-migrate(2564) FIXME: Property 'subjects' has no initializer and is not ... Remove this comment to see the full error message
  public subjects: Subject[]


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'teachers' has no initializer and is not ... Remove this comment to see the full error message
  public teachers: Teacher[]

  constructor (
    private service: DivisionService,
    private viewService: ViewService,
    private teacherService: TeacherService,
    private stageService: StageService,
    private usernamePipe: UsernamePipe
  ) {
  }

  public ngOnChanges (changes: SimpleChanges) {


    if (changes.model) {
      this.generateTeachersFullName(this.model.teachers)
      this._id = this.model && this.model._id
      if(!this._id){
        this.mode='create'
      }else{
        this.mode='edit'
      }
      this.isSaveAllowed = this.viewService.isAllowed('division', this.mode=='create' ? 'add-course' : 'update-course')
      if(!this.isSaveAllowed){
        this.mode='readonly'
      }
      this.form = CourseValidator.newFormGroup(this.model)
    }


    if (changes.division) {
      this.loadSubjects()
    }
  }

  public cancel () {
    this.onCancel.emit()
  }

  public submit () {
    if (this.mode=='readonly') {
      console.warn('User not allowed to save changes')
      return
    } else if (!this.form || !this.form.valid) { // be extra sure
      return this.viewService.toastr.warningT('COMMON.MSG_INVALID_FORM')
    }

    this.isSubmitting = true
    const model = this.form.value
    const onSuccess = () => { this.onSave.emit(model) }
    const onFinally = () => { this.isSubmitting = false }
    const service = new CourseWrapperService(this.division._id, this.service)
    this.viewService.save(this._id, service, this.form, onSuccess, onFinally, model)
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'filter' is declared but its value is never read.
  public searchTeachers = (filter: string) => {
    return Observable.of(this.teachers)
  }

  public onSubjectChosen () {


    //this.form.controls.teachers.reset()
    this.loadTeachers()
  }

  private loadTeachers () {


    const subject = this.form.controls.subject.value
    if (!subject) {
      this.teachers = []
      return
    }

    const params = new QueryString({ select: 'name' })
    this.teacherService.search(params)
    .subscribe(
      result => {
        this.generateTeachersFullName(result.list)
        this.teachers = result.list
      }
    )
  }

  private generateTeachersFullName (teachers: any) {
    teachers = teachers || []
    teachers.forEach((teacher: any) => {
      teacher.fullName = this.usernamePipe.transform(teacher.name)
    })
  }
  
  private loadSubjects () {
    const params = new QueryString({
      select: 'name',
      sort: {
        name: 1
      }
    })

    this.stageService.getGradeSubjects(this.division.grade, params)
    .subscribe(
      result => {
        this.subjects = result.list
        const subject = this.subjects && this.subjects[0]
        if (this.mode=='create' || this.mode=='edit' && subject) {


          this.form.controls.subject.setValue(subject._id)
          this.onSubjectChosen()
        }
      },
      this.viewService.handleError
    )
  }
}

class CourseWrapperService extends CrudService<Course> {

  constructor (
    private id: string,
    private service: DivisionService
  ) {


    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
    super(null, '')
  }

  public create (object: any): Observable<any> {
    return this.service.addCourse(this.id, object)
  }

  public update (id: string, object: any): Observable<any> {
    return this.service.updateCourse(this.id, id, object)
  }
}
