var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { DivisionService, ViewService, StageService } from '../../services';
// @ts-expect-error ts-migrate(6133) FIXME: 'Stage' is declared but its value is never read.
import { DetailView, DivisionValidator, Division, Grade } from '../../interfaces';
import { QueryString } from '../../models';
var DivisionComponent = /** @class */ (function (_super) {
    __extends(DivisionComponent, _super);
    function DivisionComponent(viewService, service, route, stageService) {
        var _this = _super.call(this, viewService, service, route, 'division', DivisionValidator.newFormGroup(), true) || this;
        _this.stageService = stageService;
        _this.shifts = biz.values(biz.SCHOOL_SHIFTS);
        _this.deletePermission = 'remove';
        _this.options.populate = {
            grade: 'name'
        };
        var isCurrentTermSelected = _this.viewService.auth.isCurrentTermSelected();
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        _this.isAttendanceAllowed = isCurrentTermSelected && _this.viewService.isAllowed('division', 'attendance');
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        _this.isExamAllowed = isCurrentTermSelected && _this.viewService.isAllowed('division', 'schedule-exam');
        _this.isPromoteAllowed = !isCurrentTermSelected && _this.viewService.isAllowed('division', 'promote');
        return _this;
    }
    DivisionComponent.prototype.remove = function () {
        _super.prototype.remove.call(this, "COMMON.MSG_DELETE_DIVISION", "Eliminar Curso");
    };
    // @ts-expect-error ts-migrate(6133) FIXME: 'model' is declared but its value is never read.
    DivisionComponent.prototype.onModelLoaded = function (model) {
        var _this = this;
        if (!this.isCreate) {
            setTimeout(function () {
                _this.activateStudentsTab();
            }, 0);
        }
        else {
            this.loadGrades();
        }
    };
    DivisionComponent.prototype.activateStudentsTab = function () {
        this.studentsTab.activate();
    };
    DivisionComponent.prototype.activateCoursesTab = function () {
        this.coursesTab.activate();
    };
    DivisionComponent.prototype.activateExamsTab = function () {
        this.examsTab.activate();
    };
    DivisionComponent.prototype.activateRatingsTab = function () {
        this.RatingsTab.activate();
    };
    DivisionComponent.prototype.getValue = function () {
        return this.form.value;
    };
    DivisionComponent.prototype.getDefaultDetails = function () {
        var model = {};
        return model;
    };
    DivisionComponent.prototype.loadGrades = function (reset) {
        var _this = this;
        if (reset === void 0) { reset = false; }
        var params = new QueryString({
            sort: {
                order: 1
            }
        });
        var selectedStage = this.viewService.getSelectedStage();
        if (selectedStage) {
            this.stageService.getGrades(params)
                .subscribe(function (result) {
                _this.grades = result.list;
                if (_this.grades && _this.grades.length) {
                    _this.form.controls.grade.setValue(_this.grades[0]._id);
                }
                else if (reset) {
                    _this.form.controls.grade.reset();
                }
            }, this.viewService.handleError);
        }
    };
    return DivisionComponent;
}(DetailView));
export { DivisionComponent };
