import { Component } from '@angular/core'
import { ActivatedRoute, Params } from '@angular/router'
import { Observable } from 'rxjs/Observable'

import * as _ from 'lodash-es'

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { DivisionService, ViewService, TeacherService, DateService } from '../../services'
import { DetailView, Exam, ExamValidator, CrudService, Subject } from '../../interfaces'
import { QueryString } from '../../models'
import { AppToastrService } from '../../services/app-toastr.service'

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html'
})

export class ExamComponent extends DetailView<Exam> {

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'divisionId' has no initializer and is no... Remove this comment to see the full error message
  public divisionId: string

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'subjects' has no initializer and is not ... Remove this comment to see the full error message
  public subjects: Subject[]
  public isAssessmentAllowed = false

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'service' has no initializer and is not d... Remove this comment to see the full error message
  protected service: ExamWrapperService // protected to comply with DetailView signature

  constructor (
    viewService: ViewService,
    divisionService: DivisionService,
    route: ActivatedRoute,
    private teacherService: TeacherService,
    private dateService: DateService,
    private apptoastrService: AppToastrService
  ) {
    super(viewService, new ExamWrapperService(divisionService), route, 'topic', ExamValidator.newFormGroup())
    this.options.populate = {
      'recipients.reference': 'name'
    }
  }

  public dateChanged () {
    // FIXME, this shouldn't be needed
    // We need to mark the control as touched manually with the datePicker PopUp. Otherwise, errors regarding the date
    // will not be shown unless the user manually changes the html input field

    this.form.controls.date.markAsTouched()
  }

  protected onParamsChange (params: Params) {
    this.isLoading = true
    this.divisionId = params.divisionId
    this.service.id = params.divisionId // set the division id for the wrapper service

    let operation
    const usr = this.viewService.getCurrentUser()
    if (usr.currentRole === biz.ROLES.TEACHER) {
      operation = this.teacherService.getSubjectsForDivision(usr._id, this.divisionId)
    } else {
      operation = Observable.of({ list: [] }) // empty response with no subjects
    }

    operation.finally(() => { this.isLoading = false })
    .subscribe(
      result => {
        this.subjects = result.list
        if (this.subjects == null) {
          this.apptoastrService.errorT("El docente no tiene asignaturas asignadas para crear el examen")
        }
        // only after subjects are returned we can continue with the detail-view machinery
        super.onParamsChange(params)
      },
      err => {
        this.isLoading = false
        this.viewService.handleError(err)
      }
    )
  }

  protected getValue () {
    return this.form.value
  }

  protected getDefaultDetails (): Exam {
    return {
      allowMessages: false,
      division: this.divisionId,
      time: {
        hour: 8,
        minutes: 0
      }
    } as Exam
  }

  // @ts-expect-error ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  protected getDetailsFromParams (params: Params): Exam {
    if (!this.isCreate) {
      return {
        _id: params.id,
        allowMessages: false,
        division: params.divisionId
      } as Exam
    }
  }

  protected onModelLoaded (model: Exam) {
    if (this.isCreate) {
      model.subject = model.subject || this.subjects && this.subjects[0] && this.subjects[0]._id
    }
  }

  // @ts-expect-error ts-migrate(6133) FIXME: 't' is declared but its value is never read.
  protected onSaved (t: Exam) {
    this.viewService.goBack()
  }

  protected checkPermissions () {
    super.checkPermissions()

    if (!this.isCreate) {
      const isPastExam = this.dateService.isPast(this.model.date)
      const subject = _.find(this.subjects, {_id: this.model.subject})

      // check if it's a future exam and subject is assigned to the current user
      this.isSaveAllowed = this.isSaveAllowed && !isPastExam && !!subject
      this.isDeleteAllowed = this.isDeleteAllowed && !isPastExam && !!subject
      this.isAssessmentAllowed = isPastExam && !!subject
    }

  }
}

class ExamWrapperService extends CrudService<Exam> {

  constructor (
    private service: DivisionService,
    public id?: string
  ) {

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
    super(null, '')
  }

  public getById (id: string, params?: QueryString): Observable<Exam> {

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return this.service.getExamById(this.id, id, params)
  }

  public create (object: any): Observable<any> {

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return this.service.addExam(this.id, object)
  }

  public update (id: string, object: any): Observable<any> {

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return this.service.updateExam(this.id, id, object)
  }

  public remove (id: string): Observable<any> {

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return this.service.removeExam(this.id, id)
  }
}
