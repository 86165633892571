import { PipeTransform } from '@angular/core'

export interface RouterColumnOptions {
  sortable?: boolean,
  pipe?: PipeTransform,
  minWidth?: number,
  maxWidth?: number,
  isPrimary?: boolean
  newTab?: boolean
}

export class Column {
  public prop: string
  public name: string // header title
  public headerTemplate: string
  public sortable: boolean
  public pipe: PipeTransform
  public cellTemplateName: string
  public minWidth: number
  public maxWidth: number
  public routerLink: any // function|any
  public routerParams: any // function


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'routerNewTab' has no initializer and is ... Remove this comment to see the full error message
  public routerNewTab: boolean
  public onClick: any // function
  public isPrimary = false


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'actions' has no initializer and is not d... Remove this comment to see the full error message
  public actions: any[]

  public constructor (
    prop: string,
    name: string,
    sortable = true,
    pipe?: PipeTransform,
    cellTemplateName?: string,
    minWidth?: number,
    maxWidth?: number,
    headerTemplate?: string,
    onClick?: any
  ) {
    this.prop = prop
    this.name = name
    this.sortable = sortable


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'PipeTransform | undefined' is not assignable... Remove this comment to see the full error message
    this.pipe = pipe


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.cellTemplateName = cellTemplateName


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.minWidth = minWidth


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.maxWidth = maxWidth
    this.headerTemplate = headerTemplate || ''
    this.onClick = onClick
  }

  public static forRouter (
    prop: string,
    name: string,
    routerLink: any,
    routerParams?: any,
    options?: RouterColumnOptions
  ) {
    options = options || {}
    const column = new Column(prop, name, options.sortable, options.pipe, 'hrefRouterCell', options.minWidth, options.maxWidth)
    column.routerLink = typeof routerLink === 'function' ? routerLink : () => routerLink
    column.routerParams = routerParams || (() => {})
    column.routerNewTab = !!options.newTab


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    column.isPrimary = options.isPrimary
    return column
  }

  public static forClick (
    prop: string,
    name: string,
    onClick: any,
    sortable = true,
    pipe?: PipeTransform,
    minWidth?: number,
    maxWidth?: number,
    cellTemplateName = 'hrefClickCell',
    isPrimary = true
  ) {
    const column = new Column(prop, name, sortable, pipe, cellTemplateName, minWidth, maxWidth)
    column.onClick = onClick
    column.isPrimary = isPrimary
    return column
  }

  public static forActions (
    actions: any[],
    width?: number,
    prop = '_id',
    headerTemplate = 'hideColumnHeader'
  ) {


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    const column = new Column(prop, null, false, null, 'iconActionsCell', width, width, headerTemplate)
    column.actions = actions
    return column
  }
}
