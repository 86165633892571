import {Component, Input, OnInit, ElementRef, forwardRef} from '@angular/core'
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export const RICH_EDITOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RichEditor),
  multi: true
};

@Component({
  selector: 'rich-editor',
  templateUrl: './rich.editor.html',
  providers: [RICH_EDITOR_VALUE_ACCESSOR]
})

export class RichEditor implements ControlValueAccessor, OnInit {

  @Input() locale: string | undefined
  
  private _readonly: boolean = false
  
  @Input() set readonly(value: boolean) {
    this._readonly = value;
    if (this.editor) {
      this.editor.isReadOnly = value
    }
  }

  get readonly() {
    return this._readonly;
  }

  public onChange: any = Function.prototype;
  public onTouched: any = Function.prototype;
  public initialData: string | null = null

  public config: any

  // @ts-ignore
  private editor: Editor | null = null

  constructor(protected element: ElementRef) {
  }

  public ngOnInit() {
    const editorConfig: Config = {
      removePlugins: ['SourceEditing']
    }
    ClassicEditor
      .create(document.getElementById('editor')!, editorConfig)
      .then((editor: Editor) => {
        this.editor = editor
        if (this.initialData) {
          editor.setData(this.initialData);
          this.initialData = this.value
        }
        editor.model.document.on('change:data', () => {
          if(this.editor?.data && !this.initialData){
              this.initialData=this.value
          }
          this.onContentChange()
        });
        editor.isReadOnly = this.readonly
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  public get value(): string | null {
    return this.editor?.data.get();
  }

  public onContentChange() {
    if (this.initialData === this.value) {
      return
    }
    this.onChange(this.value)
    this.onTouched()
  }

  public onFocus() {
    if (!this.readonly) {
      this.onTouched()
    }
  }

  // ControlValueAccessor
  // model -> view
  public writeValue(value: any): void {
    if (value === null) value = ""
    this.editor?.setData(value);
    this.initialData = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
