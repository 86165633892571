<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content">
  <div class="row">
    <div class="form-group col-sm-6 col-md-3">
      <label>{{ 'BATCH_JOB.ID' | translate }}</label>
      <p class="form-control-static">
        {{ _id }}
      </p>
    </div>

    <div class="form-group col-sm-6 col-md-3">
      <label>{{ 'BATCH_JOB.TYPE' | translate }}</label>
      <p class="form-control-static">
        <span class="label label-default">{{ model.type | batchJobType }}</span>
      </p>
    </div>

    <div class="form-group col-sm-6 col-md-3">
      <label>{{ 'BATCH_JOB.DATE' | translate }}</label>
      <p class="form-control-static">
        {{ model.createdAt | amUtc | amDateFormat: 'DD/MM/YYYY HH:mm' }}
      </p>
    </div>

    <div class="form-group col-sm-6 col-md-3">
      <label>{{ 'BATCH_JOB.USER' | translate }}</label>
      <p class="form-control-static">
        {{ model.createdBy?.name | username }}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-sm-6 col-md-3">
      <label>{{ 'BATCH_JOB.STATUS' | translate }}</label>
      <p class="form-control-static">
        <span class="label" [ngClass]="model.status | batchJobStatusLabel">{{ model.status | batchJobStatus }}</span>
      </p>
    </div>

    <div class="form-group col-sm-6 col-md-3">
      <label>{{ 'BATCH_JOB.PROGRESS' | translate }}</label>
      <p class="form-control-static">
        <span *ngIf="!model.stats?.total">-</span>
        <progressbar
            class="progress-striped"
            *ngIf="model.stats?.total"
            [max]="model.stats?.total"
            [value]="model.stats?.step"
            [tooltip]="(model.stats?.step | percentage:model.stats?.total:true) + '%'"
            placement="bottom">
          {{ model.stats?.step }} / {{ model.stats?.total }}
        </progressbar>
      </p>
    </div>

    <div class="form-group col-sm-6 col-md-3"
        *ngIf="model.data?.original_filename">
      <label>{{ 'BATCH_JOB.FILE' | translate }}</label>
      <p class="form-control-static">
        <a [href]="model.data?.secure_url" [download]="model.data?.original_filename || 'archivo.csv'">{{ model.data?.original_filename }}</a>
      </p>
    </div>

    <div class="form-group col-sm-6 col-md-3"
        *ngIf="model.failure">
      <label>{{ 'BATCH_JOB.FAILURE' | translate }}</label>
      <p class="form-control-static">
        {{ model.failure | translateError }}
      </p>
    </div>
  </div>

  <div *ngIf="model.stats?.errors">
    <fieldset>
      <legend>{{ 'BATCH_JOB.ERRORS' | translate }}</legend>
    </fieldset>

    <batch-job-errors-tab [batchJob]="_id" [status]="model.status"></batch-job-errors-tab>
  </div>
</section>
