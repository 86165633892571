/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "../app-datatable/app-datatable.component.ngfactory";
import * as i4 from "../app-datatable/app-datatable.component";
import * as i5 from "angular2-ladda/module/ladda.directive";
import * as i6 from "angular2-ladda/module/ladda-config";
import * as i7 from "@angular/forms";
import * as i8 from "../user-panel/user.panel.ngfactory";
import * as i9 from "../user-panel/user.panel";
import * as i10 from "../../services/view.service";
import * as i11 from "../../services/person.service";
import * as i12 from "./student-guardians.tab";
import * as i13 from "../../services/student.service";
var styles_StudentGuardiansTab = [];
var RenderType_StudentGuardiansTab = i0.ɵcrt({ encapsulation: 2, styles: styles_StudentGuardiansTab, data: {} });
export { RenderType_StudentGuardiansTab as RenderType_StudentGuardiansTab };
function View_StudentGuardiansTab_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToEditionMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["\n      ", "\n    "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("STUDENT.ADD_GUARDIAN")); _ck(_v, 1, 0, currVal_0); }); }
function View_StudentGuardiansTab_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "btn-toolbar btn-footer clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StudentGuardiansTab_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "appdatatable", [], null, [[null, "page"], [null, "sort"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("sort" === en)) {
        var pd_1 = (_co.onSort($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_AppDataTableComponent_0, i3.RenderType_AppDataTableComponent)), i0.ɵprd(135680, null, i1.TranslatePipe, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(10, 49152, null, 0, i4.AppDataTableComponent, [], { loading: [0, "loading"], rows: [1, "rows"], limit: [2, "limit"], count: [3, "count"], columns: [4, "columns"], sorts: [5, "sorts"] }, { pageEmitter: "page", sortEmitter: "sort" }), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCreateAllowed; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.loading; var currVal_2 = _co.rows; var currVal_3 = _co.params.limit; var currVal_4 = _co.count; var currVal_5 = _co.columns; var currVal_6 = _co.params.sort; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_StudentGuardiansTab_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-success"], ["type", "submit"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i5.LaddaDirective, [i0.ElementRef, [2, i6.LaddaConfig]], { loading: [0, "loading"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(2, null, ["\n            ", "\n          "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSubmitting; var currVal_1 = !_co.form.valid; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("STUDENT.SAVE_GUARDIAN")); _ck(_v, 2, 0, currVal_2); }); }
function View_StudentGuardiansTab_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 28, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 25, "form", [["class", "form-fixed-help"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.saveGuardian() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i7.ɵbf, [], null, null), i0.ɵdid(4, 540672, null, 0, i7.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i7.ControlContainer, null, [i7.FormGroupDirective]), i0.ɵdid(6, 16384, null, 0, i7.NgControlStatusGroup, [i7.ControlContainer], null, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "user-panel", [], null, null, null, i8.View_UserPanel_0, i8.RenderType_UserPanel)), i0.ɵprd(135680, null, i1.TranslatePipe, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(10, 573440, null, 0, i9.UserPanel, [i10.ViewService, i11.PersonService], { form: [0, "form"], isCreate: [1, "isCreate"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n    "])), (_l()(), i0.ɵeld(12, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(14, 0, null, null, 11, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(16, 0, null, null, 8, "div", [["class", "btn-toolbar btn-footer"], ["role", "toolbar"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StudentGuardiansTab_4)), i0.ɵdid(19, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(21, 0, null, null, 2, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEditionMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(22, null, ["\n            ", "\n          "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.isCreate; _ck(_v, 10, 0, currVal_8, currVal_9); var currVal_10 = _co.isSaveAllowed; _ck(_v, 19, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_11 = i0.ɵunv(_v, 22, 0, i0.ɵnov(_v, 23).transform("COMMON.BTN_CANCEL")); _ck(_v, 22, 0, currVal_11); }); }
export function View_StudentGuardiansTab_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_StudentGuardiansTab_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StudentGuardiansTab_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.form; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.form; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_StudentGuardiansTab_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "student-guardians-tab", [], null, null, null, View_StudentGuardiansTab_0, RenderType_StudentGuardiansTab)), i0.ɵdid(1, 573440, null, 0, i12.StudentGuardiansTab, [i10.ViewService, i13.StudentService], null, null)], null, null); }
var StudentGuardiansTabNgFactory = i0.ɵccf("student-guardians-tab", i12.StudentGuardiansTab, View_StudentGuardiansTab_Host_0, { studentId: "studentId", editable: "editable" }, {}, []);
export { StudentGuardiansTabNgFactory as StudentGuardiansTabNgFactory };
