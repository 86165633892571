<!-- Menu Toggle Button -->
<a href="#" class="dropdown-toggle" data-toggle="dropdown">
  <!-- [tooltip]="currentUser.name | username" placement="left"> -->
  <!-- The user image in the navbar-->
  <user-avatar size=25 class="user-image"></user-avatar>
  <!-- hidden-xs hides the username on small devices so only the image appears. -->
  <!-- <span class="hidden-xs">{{ fullName }}</span> -->
</a>
<ul class="dropdown-menu">
  <!-- The user image in the menu -->
  <li class="user-header" *ngFor="let membership of currentUser.memberships">
    <a (click)="switchTenant(membership.tenant)" href="javascript:void(0)">
      <span>
        <avatar [name]="membership.tenant.name" [photo]="membership.tenant.logo?.secure_url" size="40"></avatar>
      </span>
      <span>{{ membership.tenant.name }}</span>
    </a>
  </li>
  <!-- Menu Footer-->
  <li class="user-footer">
    <a routerLink="/password">
      <i class="fa fa-fw fa-key"></i>{{ 'USERBOX.LNK_CHANGEPASS' | translate }}
    </a>
  </li>
  <li class="user-footer">
    <a href="javascript:void(0)" (click)="logout()">
      <i class="fa fa-fw fa-sign-out"></i>{{ 'USERBOX.LNK_SIGNOUT' | translate }}
    </a>
  </li>

<!--
  <li class="user-footer">
    <div class="pull-left">
      <a routerLink="/profile" class="btn btn-default btn-flat">{{ 'USERBOX.LNK_CHANGEPASS' | translate }}</a>
    </div>
    <div class="pull-right">
      <a (click)="logout()" class="btn btn-default btn-flat">{{ 'USERBOX.LNK_SIGNOUT' | translate }}</a>
    </div>
  </li>
-->
</ul>
