import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'
import { ViewService, StageService, GradeWrapperService } from '../../services'
import { Column } from '../../models'
import { Grade, SearchView } from '../../interfaces'
import { ArrayPipe } from '../../pipes'

@Component({
  selector: 'app-grades',
  templateUrl: './grades.component.html',
  providers: [TranslatePipe]
})

export class GradesComponent extends SearchView<Grade> {
  public columns: Column []

  constructor (
    viewService: ViewService,
    route: ActivatedRoute,
    stageService: StageService
  ) {
    super(viewService, route, new GradeWrapperService(stageService), 'grades', 'stage', 'add-grade')

    this.params.populate = 'subjects'
    this.params.select = 'order name subjects'

    this.columns = [
      this.routerColumn('order', 'GRADE.ORDER', { isPrimary: true, minWidth: 90, maxWidth: 90 }),
      this.routerColumn('name', 'GRADE.NAME', { isPrimary: true }),
      new Column('subjects', 'SUBJECT.SEARCH_TITLE', false, new ArrayPipe('name'), 'defaultLabelsCell')
    ]
  }
}
