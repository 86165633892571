var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash-es';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { DivisionService, ViewService, TeacherService, DateService, SubjectService } from '../../services';
import { DetailView, RatingValidator, CrudService } from '../../interfaces';
var RatingComponent = /** @class */ (function (_super) {
    __extends(RatingComponent, _super);
    function RatingComponent(viewService, divisionService, route, teacherService, 
    // @ts-expect-error ts-migrate(6138) FIXME: Property 'dateService' is declared but its value i... Remove this comment to see the full error message
    dateService, 
    // @ts-expect-error ts-migrate(6138) FIXME: Property 'subjectService' is declared but its valu... Remove this comment to see the full error message
    subjectService) {
        var _this = _super.call(this, viewService, new RatingWrapperService(divisionService), route, 'topic', RatingValidator.newFormGroup()) || this;
        _this.divisionService = divisionService;
        _this.teacherService = teacherService;
        _this.dateService = dateService;
        _this.subjectService = subjectService;
        _this.isAssessmentAllowed = true;
        _this.isLoading = false;
        _this.isCreate = false;
        _this.options.populate = {
            'recipients.reference': 'name'
        };
        return _this;
    }
    RatingComponent.prototype.dateChanged = function () {
        // FIXME, this shouldn't be needed
        // We need to mark the control as touched manually with the datePicker PopUp. Otherwise, errors regarding the date
        // will not be shown unless the user manually changes the html input field
        this.form.controls.date.markAsTouched();
    };
    RatingComponent.prototype.onSave = function (assessments) {
        this.isLoading = true;
        this.assessments = assessments;
        if (this.isCreate) {
            this.submit();
        }
        else {
            this.onSaved(this.model);
        }
    };
    RatingComponent.prototype.onParamsChange = function (params) {
        var _this = this;
        this.isLoading = true;
        this.divisionId = params.divisionId;
        this.service.id = params.divisionId; // set the division id for the wrapper service
        var operation;
        var usr = this.viewService.getCurrentUser();
        if (usr.currentRole === biz.ROLES.TEACHER) {
            operation = this.teacherService.getSubjectsForDivision(usr._id, this.divisionId);
        }
        else {
            operation = Observable.of({ list: [] }); // empty response with no subjects
        }
        operation.finally(function () { _this.isLoading = false; })
            .subscribe(function (result) {
            _this.subjects = result.list;
            // only after subjects are returned we can continue with the detail-view machinery
            _super.prototype.onParamsChange.call(_this, params);
        }, function (err) {
            _this.isLoading = false;
            _this.viewService.handleError(err);
        });
    };
    RatingComponent.prototype.getValue = function () {
        return this.form.value;
    };
    RatingComponent.prototype.getDefaultDetails = function () {
        return {
            allowMessages: false,
            division: this.divisionId
        };
    };
    // @ts-expect-error ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
    RatingComponent.prototype.getDetailsFromParams = function (params) {
        if (!this.isCreate) {
            return {
                _id: params.id,
                allowMessages: false,
                division: params.divisionId
            };
        }
    };
    RatingComponent.prototype.onModelLoaded = function (model) {
        if (this.isCreate) {
            model.subject = model.subject || this.subjects && this.subjects[0] && this.subjects[0]._id;
        }
    };
    RatingComponent.prototype.onSaved = function (t) {
        var _this = this;
        this.divisionService.updateRatingAssessments(this.divisionId, t._id, this.assessments).subscribe(function () {
            _this.isLoading = false;
            _this.viewService.goBack();
        });
    };
    RatingComponent.prototype.checkPermissions = function () {
        _super.prototype.checkPermissions.call(this);
        if (!this.isCreate) {
            var subject = _.find(this.subjects, { _id: this.model.subject });
            // check if it's assigned to the current user
            this.isSaveAllowed = this.isSaveAllowed && !!subject;
            this.isDeleteAllowed = this.isDeleteAllowed && !!subject;
            // this.isAssessmentAllowed = !!subject
        }
    };
    return RatingComponent;
}(DetailView));
export { RatingComponent };
var RatingWrapperService = /** @class */ (function (_super) {
    __extends(RatingWrapperService, _super);
    function RatingWrapperService(service, id) {
        var _this = 
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        _super.call(this, null, '') || this;
        _this.service = service;
        _this.id = id;
        return _this;
    }
    RatingWrapperService.prototype.getById = function (id, params) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        return this.service.getRatingById(this.id, id, params);
    };
    RatingWrapperService.prototype.create = function (object) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        return this.service.addRating(this.id, object);
    };
    RatingWrapperService.prototype.update = function (id, object) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        return this.service.updateRating(this.id, id, object);
    };
    RatingWrapperService.prototype.remove = function (id) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        return this.service.removeRating(this.id, id);
    };
    return RatingWrapperService;
}(CrudService));
