/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../pipes/translate-error.pipe";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "ngx-bootstrap/popover/popover.directive";
import * as i5 from "ngx-bootstrap/popover/popover.config";
import * as i6 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i7 from "./batch-job-error.popover";
var styles_BatchJobErrorPopover = [];
var RenderType_BatchJobErrorPopover = i0.ɵcrt({ encapsulation: 2, styles: styles_BatchJobErrorPopover, data: {} });
export { RenderType_BatchJobErrorPopover as RenderType_BatchJobErrorPopover };
function View_BatchJobErrorPopover_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "ul", [["class", "popover-list"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n          ", "\n        "])), i0.ɵpid(0, i1.TranslateErrorPipe, [i2.TranslatePipe]), (_l()(), i0.ɵted(-1, null, ["\n      "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_v.context.$implicit)); _ck(_v, 3, 0, currVal_0); }); }
function View_BatchJobErrorPopover_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BatchJobErrorPopover_3)), i0.ɵdid(2, 802816, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorsDetail; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BatchJobErrorPopover_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "pull-left info"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 12, "ul", [["class", "nav navbar-nav"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(0, [["errorsDetailDialog", 2]], null, 0, null, View_BatchJobErrorPopover_2)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "span", [["class", "txt-overflow"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-info-circle fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(11, 16777216, null, null, 2, "span", [["container", "body"], ["placement", "left"]], null, null, null, null, null)), i0.ɵdid(12, 212992, [["errorsDetailPopover", 4]], 0, i4.PopoverDirective, [i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i5.PopoverConfig, i6.ComponentLoaderFactory], { popover: [0, "popover"], placement: [1, "placement"], container: [2, "container"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4); var currVal_1 = "left"; var currVal_2 = "body"; _ck(_v, 12, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_BatchJobErrorPopover_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BatchJobErrorPopover_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorsDetail.length; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BatchJobErrorPopover_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "batch-job-error-popover", [], null, null, null, View_BatchJobErrorPopover_0, RenderType_BatchJobErrorPopover)), i0.ɵdid(1, 49152, null, 0, i7.BatchJobErrorPopover, [], null, null)], null, null); }
var BatchJobErrorPopoverNgFactory = i0.ɵccf("batch-job-error-popover", i7.BatchJobErrorPopover, View_BatchJobErrorPopover_Host_0, { errorsDetail: "errorsDetail" }, {}, []);
export { BatchJobErrorPopoverNgFactory as BatchJobErrorPopoverNgFactory };
