import { Observable } from 'rxjs/Observable'
import { Column, QueryString } from '../../models'
import { ViewService } from '../../services'
import { SearchPanel } from '../../interfaces'

export abstract class StudentsTab extends SearchPanel<any> {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'columns' has no initializer and is not d... Remove this comment to see the full error message
  public columns: Column []

  constructor (
    viewService: ViewService
  ) {
    super(viewService, 'user')
    this.params.limit = 50
    this.params.sort = {
      'name.last': 1,
      'name.first': 1
    }
  }

  public activate () {
    this.createColumns()
    this.updateFilter()
  }

  public onSelect (selected: any) {
    return this.viewService.goToDetail('students', 'student', selected)
  }

  protected abstract fetch (params: QueryString): Observable<any>

  protected createColumns (): void {
    this.columns = [
      Column.forClick('_id', 'USER.ID', this.onSelect.bind(this)),
      Column.forClick('name.last', 'USER.LAST_NAME', this.onSelect.bind(this)),
      Column.forClick('name.first', 'USER.FIRST_NAME', this.onSelect.bind(this)),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.stats.late', 'TOPIC_TYPES.late', false, null, 'warningBadgeCell', 90, 90, 'centeredColHeader'),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.stats.absence', 'TOPIC_TYPES.absence', false, null, 'dangerBadgeCell', 90, 90, 'centeredColHeader'),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.stats.justified', 'TOPIC_TYPES.justified', false, null, 'infoBadgeCell', 110, 110, 'centeredColHeader'),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.stats.early_pick_up', 'TOPIC_TYPES.early_pick_up', false, null, 'infoBadgeCell', 110, 110, 'centeredColHeader'),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.tcAccepted', 'USER.TC_ACCEPTED', false, null, 'boolCell', 80, 80),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('membership.devices', 'USER.DEVICES', false, null, 'devicesCell', 90, 90)
    ]
  }
}
