<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title pull-left"><strong>{{ 'TOPIC.RECIPIENTS' | translate }}</strong></h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <topic-recipients-tab [topic]="this.topic._id"></topic-recipients-tab>
    </div>
</div>