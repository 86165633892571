import { OnInit } from '@angular/core'
import { ActivatedRoute, Params } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { Observable } from 'rxjs/Observable'
import { PersistentModel } from './persistent-model'
import { CrudService } from './crud.service'
import { ViewService } from '../services'
import { QueryString } from '../models'

export abstract class DetailView<T extends PersistentModel> implements OnInit {


  // @ts-expect-error ts-migrate(2564) FIXME: Property '_id' has no initializer and is not defin... Remove this comment to see the full error message
  public _id: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'model' has no initializer and is not def... Remove this comment to see the full error message
  public model: T
  public pageData: any = {}
  public locale: string
  public isCreate: boolean = false
  public isLoading: boolean = false
  public isSubmitting: boolean = false
  public isSaveAllowed: boolean = false
  public isDeleteAllowed: boolean = false
  protected options = new QueryString()
  protected createPermission: string = 'create'
  protected updatePermission: string = 'update'
  protected deletePermission: string = 'delete'

  constructor (
    protected viewService: ViewService,
    protected service: CrudService<T>,
    private route: ActivatedRoute,
    protected resource: string,
    public form: FormGroup,
    private editAfterCreation = false
  ) {
    this.locale = viewService.getCurrentUser().locale
    viewService.onLangReady().subscribe(() => this.onLangReady())
    this.route.data.subscribe(pageData => { this.pageData = pageData })
  }

  public ngOnInit () {
    this.route.params
    .subscribe(this.onParamsChange.bind(this))
  }

  public isDirty (): boolean {
    return this.form && this.form.dirty
  }

  public submit () {
    if (!this.isSaveAllowed) {
      console.warn('User not allowed to save changes')
      return
    } else if (!this.form || !this.form.valid) { // be extra sure
      return this.viewService.toastr.warningT('COMMON.MSG_INVALID_FORM')
    }

    this.isSubmitting = true
    const onFinally = () => { this.isSubmitting = false }
    this._save(this.onSaved.bind(this), onFinally, this.getValue())
  }

  public remove (message?: string, title?: string) {
    if (!this.isDeleteAllowed) {
      console.warn('User not allowed to remove')
    }
    
    if(message == null || message == ''){
      message= 'COMMON.MSG_CONFIRM_DELETE'
    }
    
    this.viewService.askConfirmation(message,title)
    .finally(() => {
      this.isSubmitting = false
    })
    .subscribe(
      value => {
        if (value) {
          this.isSubmitting = true
          this._remove().subscribe(


            // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
            data => {
              return this.goBack()
            },
            this.viewService.handleError
          )
        }
      },
      this.viewService.handleError
    )
  }

  public goBack () {
    return this.viewService.goBack()
  }

  protected onParamsChange (params: Params) {
    this.isLoading = true
    this.form && this.form.reset()
    this.isCreate = this.isCreateOperation(params)

    if (this.isCreate) {
      this.model = this.getDetailsFromParams(params) || this.getDefaultDetails()
      this.onModelLoaded(this.model) // before setting value to form so we can change model
      this.setFormValue(this.model)
      this.checkPermissions()
      if (!this.isSaveAllowed) {
        this.viewService.toastr.warningT('ERROR.BERR-X0007')
        return this.viewService.goBack()
      }
      this.isLoading = false
    } else {
      this.model = this.getDetailsFromCache(params) || this.getDetailsFromParams(params) || this.getDefaultDetails()
      this.getDetails(params)
      .finally(() => { this.isLoading = false })
      .subscribe(
        model => {
          this._id = model._id
          this.model = model
          this.onModelLoaded(this.model)  // before setting value to form so we can change model
          this.setFormValue(this.model)
          this.checkPermissions()
        },
        this.viewService.handleError
      )
    }
  }

  protected onSaved (model: T) {
    if (this.isCreate && this.editAfterCreation) {
      // go to edition
      this.viewService.goTo([`../${model._id}`], { relativeTo: this.route, replaceUrl: true })
    } else {
      this.viewService.goTo(['..'], { relativeTo: this.route })
    }
  }

  protected isCreateOperation (params: Params): boolean {


    return params.id === 'new'
  }

  protected checkPermissions () {
    const currentSelected = this.viewService.auth.isCurrentTermSelected()


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.isSaveAllowed = this.viewService.isAllowed(this.resource, this.isCreate ? this.createPermission : this.updatePermission) && currentSelected


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.isDeleteAllowed = !this.isCreate && this.viewService.isAllowed(this.resource, this.deletePermission) && currentSelected
  }

  protected getDetailsFromCache (params: Params): T {


    return this.viewService.cache.get(`${this.resource}_${params.id}`) as T
  }



  // @ts-expect-error ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  protected getDetailsFromParams (params: Params): T {
    if (!this.isCreate) {


      return { _id: params.id } as T
    }
  }

  protected getDefaultDetails (): T {
    return {} as T
  }

  protected getDetails (params: Params): Observable<T> {


    return this.service.getById(params.id, this.options)
  }

  protected getValue () {
    return this.model
  }

  protected _save (onSuccess: Function, onFinally: Function, value: any) {
    this.viewService.save(this._id, this.service, this.form, onSuccess, onFinally, value)
  }

  protected _remove () {
    return this.service.remove(this._id)
  }

  protected setFormValue (model: T) {
    this.form && this.form.patchValue(model)
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'model' is declared but its value is never read.
  protected onModelLoaded (model: T) {
    // empty implementation
  }

  protected onLangReady () {
    // empty implementation
  }
}
