import { Observable } from 'rxjs/Observable'
import { ViewService } from '../services'
import { QueryString, Column, RouterColumnOptions } from '../models'
import { CrudSearchResult } from './crud.service'

export interface SearchRouterColumnOptions extends RouterColumnOptions {
  permission?: string
  routerLink?: ((row: any, value: any) => any[])
}

export abstract class SearchPanel<T> {
  public locale: string
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'timezone' has no initializer and is not ... Remove this comment to see the full error message
  public timezone: string

  public isCreateAllowed: boolean = false
  public loading: boolean = false
  public rows: T[] = []
  public count: number = 0
  public params: QueryString = new QueryString({
    page: 1,
    limit: 10,
    conditions: {}
  })

  protected constructor (
      protected viewService: ViewService,
      protected resource: string,
      createPermission: string = 'create'
  ) {
    this.isCreateAllowed = this.isAllowed(resource, createPermission)
    this.locale = viewService.getLocale()
    viewService.onLangReady().subscribe(() => this.onLangReady())
  }

  public isAllowed (resource: string, action: string): boolean {
    return this.viewService.isAllowedAndCurrentTerm(resource, action)
  }

  // @ts-expect-error ts-migrate(6133) FIXME: 'selected' is declared but its value is never read... Remove this comment to see the full error message
  public onSelect (selected: any) {
    // empty implementation
  }

  public onPage (event: any) {
    this.params.page = event.offset + 1
    this.getItems()
  }

  public onSort (event: any) {
    this.params.sort = event.sort
    this.params.page = event.page
    this.getItems()
  }

  public updateFilter () {
    this.params.page = 1
    this.getItems()
  }

  public getItems () {
    this.loading = true
    this.fetch(this.params)
        .finally(() => {
          this.loading = false
        })
        .subscribe(
            data => this.onDataFetched(data),
            this.viewService.handleError
        )
  }

  protected abstract fetch (params: QueryString): Observable<CrudSearchResult<T> | T>

  protected routerColumn (
      prop: string,
      name: string,
      options?: SearchRouterColumnOptions
  ) {
    options = options || {}
    options.permission = options.permission || 'get'
    // return link column if navigation is allowed
    if (options.routerLink && (/*!options.permission || */ this.viewService.isAllowed(this.resource, options.permission))) {
      return Column.forRouter(prop, name, options.routerLink, null, options)
    }
    // display as regular column otherwise
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    return new Column(prop, name, options.sortable, options.pipe, null, options.minWidth, options.maxWidth)
  }

  protected onDataFetched (data: CrudSearchResult<T> | T) {
    // TODO refactor to avoid having to do this
    if (Array.isArray(data)) {
      data = {
        // @ts-ignore
        count: data.length,
        list: data
      }
    }
    // @ts-ignore
    this.count = (data.meta && data.meta.total) || data.list.length
    // @ts-ignore
    this.rows = data.list
  }

  protected onLangReady () {
    // empty implementation
  }
}
