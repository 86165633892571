import { Injectable } from '@angular/core'
import { Router, NavigationExtras } from '@angular/router'
import { Location } from '@angular/common'
import { FormGroup } from '@angular/forms'
import { Observable } from 'rxjs/Observable'
import { AuthService } from './auth.service'
import { AppToastrService } from './app-toastr.service'
import { AppCacheService } from './app-cache.service'
import { ConfirmationService } from './confirmation.service'
import { AppTranslateService } from './app-translate.service'
import { PersistentModel, SearchableService, CrudService, TenantSummary, LoggedUser, Term, Stage } from '../interfaces'
import { QueryString } from '../models'

import * as _ from 'lodash-es'
import { environment } from '../../environments/environment'

@Injectable()
export class ViewService {

  constructor (
    public auth: AuthService,
    public toastr: AppToastrService,
    public cache: AppCacheService,
    public confirmation: ConfirmationService,
    public router: Router,
    private location: Location,
    public translate: AppTranslateService
  ) {
    this.handleError = this.toastr.handleError
  }

  public askConfirmation (message: string, title?: string) {
    return this.confirmation.show(message, title)
  }

  public goBack () {
    this.location.back()
  }

  public goTo (commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras)
  }

  // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
  public goToDetail (
    path: string,
    resource: string,
    model: any,
    permission = 'get'
  ) {
    if (!permission || this.auth.isAllowed(resource, permission)) {
      this.cache.set( `${resource}_${model._id}`, model )
      return this.router.navigate([ path, model._id ])
    }
  }

  public save<T extends PersistentModel> (_id: string, service: CrudService<T>, form: FormGroup, onSuccess?: Function, onFinally?: Function, value?: any) {
    let operation
    let message: any
    onSuccess = onSuccess || Function.prototype
    onFinally = onFinally || Function.prototype
    value = value || form.value

    if (_id) {
      operation = service.update(_id, value)
      message = 'COMMON.MSG_UPDATE_OK'
    } else {
      operation = service.create(value)
      message = 'COMMON.MSG_SAVE_OK'
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Function' is not assignable to p... Remove this comment to see the full error message
    operation.finally(onFinally)
    .subscribe(
      data => {
        this.toastr.successT(message)
        // this.form.reset()
        form.markAsPristine()
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onSuccess(data)
      },
      this.handleError
    )
  }

  public getLocale () {
    return this.getCurrentUser().locale || this.getTenant().locale || environment.LOCALE
  }

  public getTimezone () {
    return this.getTenant().tz || environment.TIMEZONE
  }

  public isAllowed (resource: string, action: string): boolean {
    return this.auth.isAllowed(resource, action)
  }

  public hasUserRole (role: string): boolean {
    return this.auth.hasUserRole(role)
  }

  public isCurrentUser (userId: any) {
    return this.auth.isCurrentUser(userId)
  }

  public getCurrentUser (): LoggedUser {
    return this.auth.getCurrentUser()
  }

  public getTenant (): TenantSummary {
    return this.auth.getTenant()
  }

  public getSelectedTerm (): Term {
    return this.auth.getSelectedTerm()
  }

  public getSelectedStage (): Stage {
    return this.auth.getSelectedStage()
  }

  public isCurrentTermSelected (): boolean {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    return this.auth.isCurrentTermSelected()
  }

  public isAllowedAndCurrentTerm (resource: string, action: string): boolean {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
    return this.auth.isCurrentTermSelected() && this.auth.isAllowed(resource, action)
  }

  // @ts-expect-error ts-migrate(6133) FIXME: 'err' is declared but its value is never read.
  public handleError (err: any): any {
    // empty implementation to be replaced in the constructor
  }

  public optionalList (list: any[], emptyElement: any): any[] {
    list = list.slice(0) // returns a shallow copy into a new array
    list.unshift(emptyElement)
    return list
  }

  public clone (v: any) {
    return _.cloneDeep(v)
  }

  public onLangReady () {
    return this.translate.onReady()
  }

  public newTypeaheadSource (service: SearchableService<any>, field: string, observable: Function, conditions?: any, exactMatchesOnly?: boolean): Observable<any> {
    return Observable.create(observable)
    .mergeMap((filter: any) => {
      const params = filter && new QueryString({
        limit: 10,
        conditions: {
          [field]: filter
        }
      })
      if (conditions) {
        params.conditions = params.conditions || {}
        Object.keys(conditions).forEach(key => {
          params.conditions[key] = conditions[key]
        })
      }

      return service.search(params).map(result => {
        if (exactMatchesOnly) {
          return result.list.filter((item: any) => item[field] === filter)
        } else {
          return result.list
        }
      })
    })
  }
}
