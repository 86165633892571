var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnChanges, SimpleChanges } from '@angular/core';
import { Column } from '../../../models';
import { ViewService } from '../../../services';
import { SearchPanel } from '../../../interfaces';
import { ReportCardsService } from '../../../services/report-cards.service';
var StudentReportCardsTab = /** @class */ (function (_super) {
    __extends(StudentReportCardsTab, _super);
    function StudentReportCardsTab(viewService, reportCardsService) {
        var _this = _super.call(this, viewService, 'guardian') || this;
        _this.reportCardsService = reportCardsService;
        _this.fetch = function () {
            return _this.reportCardsService.getReportCardsForStudent(_this.studentId);
        };
        return _this;
    }
    StudentReportCardsTab.prototype.ngOnChanges = function (_) {
        if (this.studentId) {
            this.columns = [
                Column.forClick('title', 'REPORT_CARDS.REPORT_CARD_NAME', this.onSelect.bind(this), false, undefined, 120),
                Column.forClick('stageName', 'REPORT_CARDS.STAGE_NAME', this.onSelect.bind(this), false, undefined, 90, 90),
                Column.forClick('divisionName', 'REPORT_CARDS.DIVISION_NAME', this.onSelect.bind(this), false)
            ];
            this.updateFilter();
        }
    };
    StudentReportCardsTab.prototype.onSelect = function (selected) {
        return this.viewService.goToDetail('report-cards', 'report-card', selected);
    };
    return StudentReportCardsTab;
}(SearchPanel));
export { StudentReportCardsTab };
