import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'


// @ts-expect-error ts-migrate(6133) FIXME: 'Assessment' is declared but its value is never re... Remove this comment to see the full error message
import { StageDependantCrudService, Division, Course, User, Exam, Assessment, Rating, RatingAssessment } from '../interfaces'
import { QueryString } from '../models'
import { AuthService } from './auth.service'

@Injectable()
export class DivisionService extends StageDependantCrudService<Division> {

  constructor (http: HttpClient, auth: AuthService) {
    super(http, '/divisions', auth)
  }

  searchByTermAndStage (termId: string, stageId: string, params?: QueryString): Observable<any> {
    const tempUrl = this.apiUrl + `/terms/${termId}/stages/${stageId}` + this.urlSpecific
    return this.http.get(tempUrl, this.toRequestOptions(params))
  }

  // courses management
  public getCourses (id: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/courses`, this.toRequestOptions(params))
  }

  public getCourseById (id: string, courseId: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/courses/${courseId}`, this.toRequestOptions(params))
  }

  public addCourse (id: string, course: Course): Observable<any> {
    this.updateUrl()
    return this.http.post(`${this.url}/${id}/courses`, course)
  }

  public updateCourse (id: string, courseId: string, course: Course): Observable<any> {
    this.updateUrl()
    return this.http.patch(`${this.url}/${id}/courses/${courseId}`, course)
  }

  public removeCourse (id: string, courseId: string): Observable<any> {
    this.updateUrl()
    return this.http.delete(`${this.url}/${id}/courses/${courseId}`)
  }

  // student management
  public getStudents (id: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/students`, this.toRequestOptions(params))
  }

  public addStudent (id: string, student: User): Observable<any> {
    this.updateUrl()
    return this.http.post(`${this.url}/${id}/students`, student)
  }

  public removeStudent (id: string, studentId: string): Observable<any> {
    this.updateUrl()
    return this.http.delete(`${this.url}/${id}/students/${studentId}`)
  }

  public includeStudents (id: string, students: string[]): Observable<any> {
    this.updateUrl()
    return this.http.post(`${this.url}/${id}/students/include`, students)
  }

  public excludeStudents (id: string, students: string[]): Observable<any> {
    this.updateUrl()
    return this.http.post(`${this.url}/${id}/students/exclude`, students)
  }

  public promoteStudents (from: string, to: string, students: string[]) : Observable<any> {
    return this.http.post(`${this.url}/${from}/students/promote`, { to, students })
  }

  // attendance management
  public saveAttendance (id: string, date: any, report: any): Observable<any> {
    this.updateUrl()
    if (typeof date === 'number') {
      date = new Date(date)
    }
    date = {
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate()
    }
    return this.http.post(`${this.url}/${id}/attendance/${date.year}/${date.month}/${date.date}?isV2=true`, { report })
  }

  public getAttendanceByWeek (id: string, year: number, isoWeek: number) {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/attendance/${year}/weeks/${isoWeek}?isV2=true`)
  }

  // subjects management
  public getSubjects (id: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/subjects`, this.toRequestOptions(params))
  }

  // exam management
  public getExams (id: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/exams`, this.toRequestOptions(params))
  }

  public getExamById (id: string, examId: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/exams/${examId}`, this.toRequestOptions(params))
  }

  public addExam (id: string, exam: Exam): Observable<any> {
    this.updateUrl()
    return this.http.post(`${this.url}/${id}/exams`, exam)
  }

  public updateExam (id: string, examId: string, exam: Exam): Observable<any> {
    this.updateUrl()
    return this.http.patch(`${this.url}/${id}/exams/${examId}`, exam)
  }

  public removeExam (id: string, examId: string): Observable<any> {
    this.updateUrl()
    return this.http.delete(`${this.url}/${id}/exams/${examId}`)
  }

  public getExamAssessments (id: string, examId: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/exams/${examId}/assessments`, this.toRequestOptions(params))
  }

  public updateExamAssessments (id: string, examId: string, studentsWithAssessments: any): Observable<any> {
    this.updateUrl()
    return this.http.patch(`${this.url}/${id}/exams/${examId}/assessments`, studentsWithAssessments)
  }

  // ratings management
  public getRatings (id: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/ratings`, this.toRequestOptions(params))
  }

  public getRatingById (id: string, ratingId: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/ratings/${ratingId}`, this.toRequestOptions(params))
  }

  public addRating (id: string, rating: Rating): Observable<any> {
    this.updateUrl()
    return this.http.post(`${this.url}/${id}/ratings`, rating)
  }

  public updateRating (id: string, ratingId: string, rating: Rating): Observable<any> {
    this.updateUrl()
    return this.http.patch(`${this.url}/${id}/ratings/${ratingId}`, rating)
  }

  public removeRating (id: string, ratingId: string): Observable<any> {
    this.updateUrl()
    return this.http.delete(`${this.url}/${id}/ratings/${ratingId}`)
  }

  public getRatingAssessments (id: string, ratingId: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/ratings/${ratingId}/assessments`, this.toRequestOptions(params))
  }

  public updateRatingAssessments (id: string, ratingId: string, studentsWithAssessments: any): Observable<any> {
    this.updateUrl()
    return this.http.patch(`${this.url}/${id}/ratings/${ratingId}/assessments`, studentsWithAssessments)
  }
}
