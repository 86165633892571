<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content text-center text-muted" *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</section>

<section class="content" *ngIf="!isLoading">
  <div class="row">
    <div class="col-xs-12">

      <form class="form-fixed-help"
          (ngSubmit)="submit()">
        <topic-panel #topicPanel [form]="form" [mode]="mode"></topic-panel>

        <div class="row">
          <div class="col-xs-12">
            <div class="btn-toolbar btn-footer" role="toolbar">
              <button
                  type="submit"
                  class="btn btn-success btn-lg"
                  *ngIf="isSaveAllowed"
                  [disabled]="!form.valid"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_SEND' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-default btn-lg"
                  (click)="goBack()">
                {{ 'COMMON.BTN_CANCEL' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-danger btn-lg pull-right-md"
                  *ngIf="isDeleteAllowed"
                  (click)="remove()"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_REMOVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="this.model?._id" class="form-tab-container">
        <tabset>
          <tab heading="{{ 'TOPIC.RECIPIENTS' | translate }}"></tab>
          <div class="col-xs-12">
            <topic-recipients-tab [topic]="this.model._id"></topic-recipients-tab>
          </div>
        </tabset>
      </div>
    </div>
  </div>
</section>
