import { Component, Input } from '@angular/core'
import { Observable } from 'rxjs/Observable'
import { Column, QueryString } from '../../models'
import { ViewService, GuardianService } from '../../services'
import { StudentsTab } from '../students-tab'

@Component({
  selector: 'guardian-students-tab',
  templateUrl: './guardian-students.tab.html'
})

export class GuardianStudentsTab extends StudentsTab {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'guardianId' has no initializer and is no... Remove this comment to see the full error message
  @Input() guardianId: string

  constructor (
    viewService: ViewService,
    private service: GuardianService
  ) {
    super(viewService)
    this.params.limit = 50
    this.params.sort = {
      'name.last': 1,
      'name.first': 1
    }
  }

  protected fetch (params: QueryString): Observable<any> {
    return this.service.getStudents(this.guardianId, params)
  }

  protected createColumns (): void {
    super.createColumns()


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    this.columns.push(new Column('division.shift', 'DIVISION.SHIFT', false, null, 'schoolShiftCell', 120, 120))
    this.columns.push(new Column('division.name', 'DIVISION.DETAIL_TITLE', false))
  }
}
