import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { ApiService, SearchableService, User } from '../interfaces'
import { QueryString } from '../models'

@Injectable()
export class UserService extends ApiService implements SearchableService<User> {
  private url = this.apiUrl + '/users'

  constructor (private http: HttpClient,) {
    super()
  }

  public search (params?: QueryString): Observable<any> {
    return this.http.get(this.url, this.toRequestOptions(params))
  }
}
