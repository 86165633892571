import { Component, OnInit, OnDestroy } from '@angular/core'
import { AuthService } from '../../services'
import { Stage, TermAndStage } from '../../interfaces'

@Component({
  selector: '.stagePicker',
  templateUrl: './stage-picker.component.html'
})
export class StagePickerComponent implements OnInit, OnDestroy {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'selectedStage' has no initializer and is... Remove this comment to see the full error message
  public selectedStage: Stage


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'stages' has no initializer and is not de... Remove this comment to see the full error message
  public stages: Stage[]
  private stageSubscription: any

  constructor (private authService: AuthService) {}

  ngOnInit () {
    this.stageSubscription = this.authService.subscribeToStage(this.onTermAndStageChange.bind(this))
  }

  ngOnDestroy () {
    this.stageSubscription.unsubscribe()
  }

  changeSelectedStage (stage: any) {
    this.authService.changeSelectedStage(stage._id)
  }

  private onTermAndStageChange (data: TermAndStage) {
    if (data) {
      const selectedTerm = this.authService.getSelectedTerm()
      if (selectedTerm) {
        this.stages = selectedTerm.stages
      }
      this.selectedStage = data.stage
    }
  }
}
