import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { Name, NameValidator } from './name'
import { Membership } from './membership'
import { Subject } from './subject'
import { Contact, ContactValidator } from './contact'
import { PersistentModel } from './persistent-model'

export interface User extends PersistentModel {
  _id: string
  name: Name
  dob: any
  gender: string
  membership: Membership
  memberships: Membership[]
  contacts: Contact[]
  locale: string
}

export interface Teacher extends User {
  subjects: string[]|Subject[]
}

export class UserFormGroup extends FormGroup {

  constructor () {
    const fb = new FormBuilder()
    super({
      _id: fb.control('', [Validators.required, Validators.minLength(biz.ID_MIN_LENGTH)]),
      name: NameValidator.newFormGroup(),
      dob: fb.control(''),
      gender: fb.control(''),
      contacts: fb.array([])
    })
  }

  public patchValue (value: {
      [key: string]: any;
  }, options?: {
      onlySelf?: boolean
      emitEvent?: boolean
  }): void {
    super.patchValue(value, options)

    this.controls.contacts.reset()

    if (value.contacts) {

      value.contacts.forEach(this.addContact.bind(this))
    }
  }

  public reset () {
    super.reset()
    const contactForms = this.getContactForms()
    while (contactForms.length !== 0) {
      contactForms.removeAt(0)
    }
  }

  public addContact (contact?: Contact): void {
    const contactForm = ContactValidator.newFormGroup(contact)
    this.getContactForms().push(contactForm)
  }

  public removeContactAt (index: number): void {
    this.getContactForms().removeAt(index)
  }

  public getContactForms (): FormArray {

    return this.controls.contacts as FormArray
  }
}

export class UserValidator {

  public static newFormGroup (): UserFormGroup {
    return new UserFormGroup()
  }

  public static newStudentFormGroup (): UserFormGroup {


    // @ts-expect-error ts-migrate(6133) FIXME: 'fb' is declared but its value is never read.
    const fb = new FormBuilder()

    const form = UserValidator.newFormGroup()

    form.controls.dob.setValidators(Validators.required)

    return form
  }

  public static newTeacherFormGroup (): UserFormGroup {


    // @ts-expect-error ts-migrate(6133) FIXME: 'fb' is declared but its value is never read.
    const fb = new FormBuilder()

    const form = UserValidator.newFormGroup()

    return form
  }

  public static newGuardianFormGroup (): UserFormGroup {
    const fb = new FormBuilder()

    const form = UserValidator.newFormGroup()
    form.addControl('relationship', fb.control('', Validators.required))

    return form
  }
}
