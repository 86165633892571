import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { CredentialsValidator, PasswordChangeValidator } from '../../interfaces'
import { AuthService, MeService, AppToastrService, AppTranslateService } from '../../services'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl']
})

export class LoginComponent implements OnInit {
  public form = CredentialsValidator.newFormGroup()

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'passwordForm' has no initializer and is ... Remove this comment to see the full error message
  public passwordForm: FormGroup
  public rememberMe: boolean = false
  public isSubmitting = false

  constructor (
    private authService: AuthService,
    private meService: MeService,
    private router: Router,
    private appToastr: AppToastrService,
    private appTranslate: AppTranslateService
  ) {}

  public ngOnInit () {
    window.dispatchEvent(new Event('resize'))
  }

  public submit () {
    this.isSubmitting = true

    let operation
    if (!this.passwordForm) {
      const credentials = this.form.value
      operation = this.authService.login(credentials, this.rememberMe)
    } else {
      const changeValue = this.passwordForm.value
      operation = this.meService.changePassword(changeValue)
    }

    return operation
    .finally(() => { this.isSubmitting = false })
    .subscribe(
      user => {
        return this.appTranslate.switch(user.locale)
        // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
        .subscribe(() => {
          if (!user.forcePassword) {
            return this.router.navigate([''])
          }
          if (!this.passwordForm) {
            this.passwordForm = PasswordChangeValidator.newFormGroup()
            this.passwordForm.controls.password.setValue(this.form.controls.password.value)
          }
        })
      },
      err => {
        this.appToastr.handleError(err)
      }
    )
  }

  public cancelChangePassword () {
    delete this.passwordForm
    this.form.reset()
    this.form.markAsPristine()
  }
}
