var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SimpleChanges, OnChanges } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { Column } from '../../models';
import { ViewService, BatchJobService } from '../../services';
import { TranslateErrorPipe } from '../../pipes';
import { SearchPanel } from '../../interfaces';
var BatchJobErrorsTab = /** @class */ (function (_super) {
    __extends(BatchJobErrorsTab, _super);
    function BatchJobErrorsTab(viewService, service, translatePipe) {
        var _this = _super.call(this, viewService, 'batch-error') || this;
        _this.service = service;
        _this.isInProgress = false;
        _this.columns = [
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('step', 'BATCH_JOB_ERROR.STEP', true, null, null, null, 80),
            new Column('failure', 'BATCH_JOB_ERROR.FAILURE', false, new TranslateErrorPipe(translatePipe)),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('details', null, false, null, 'errorsDetailCell', null, 50, 'hideColumnHeader')
        ];
        _this.params.sort = {
            step: 1
        };
        return _this;
    }
    BatchJobErrorsTab.prototype.ngOnChanges = function (changes) {
        if (!changes.batchJob || !changes.batchJob.currentValue) {
            this.count = 0;
            this.rows = [];
        }
        else {
            this.params.conditions.batchJob = this.batchJob;
            this.updateFilter();
        }
        if (changes.status) {
            this.isInProgress = this.status !== biz.BATCH_JOB_STATUSES.completed && this.status !== biz.BATCH_JOB_STATUSES.failed;
        }
    };
    BatchJobErrorsTab.prototype.exportList = function () {
        var _this = this;
        this.service.exportErrors(this.batchJob)
            .subscribe(function () {
            _this.viewService.toastr.successT('COMMON.MSG_REPORT_OK');
        }, this.viewService.handleError);
    };
    BatchJobErrorsTab.prototype.fetch = function (params) {
        return this.service.getErrorsByBatchJob(this.batchJob, params);
    };
    return BatchJobErrorsTab;
}(SearchPanel));
export { BatchJobErrorsTab };
