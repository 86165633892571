import { Component, Input } from '@angular/core'
import { DivisionService, ViewService } from '../../services'
import { SearchPanel, Subject } from '../../interfaces'
import { Column, QueryString } from '../../models'
import { Observable } from 'rxjs'
import { ShortDatePipe, TimePipe } from '../../pipes'

@Component({
  selector: 'division-ratings-tab',
  templateUrl: './division-ratings.tab.html'
})

export class DivisionRatingsTab extends SearchPanel<any> {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'divisionId' has no initializer and is no... Remove this comment to see the full error message
  @Input() divisionId: string
  columns: Column[] = []
  subjects: Subject[] = []

  constructor (protected viewService: ViewService, private divisionService: DivisionService) {
    super(viewService, 'topic')
    this.params.limit = 50
    this.params.sort = { dateTime: -1 }
  }

  public activate (): void {
    this.loadSubjects()
    this.createColumns()
    this.updateFilter()
  }

  protected fetch (params: QueryString): Observable<any> {
    return this.divisionService.getRatings(this.divisionId, params)
  }

  private onRatingSelected (rating: any) {
    this.viewService.goToDetail(`divisions/${this.divisionId}/ratings`, 'division', rating)
  }

  private createColumns () {
    this.columns = [
      Column.forClick('date', 'TOPIC.DATE', this.onRatingSelected.bind(this), true, new ShortDatePipe()),
      Column.forClick('time', 'TOPIC.TIME', this.onRatingSelected.bind(this), true, new TimePipe()),
      Column.forClick('title', 'SUBJECT.DETAIL_TITLE', this.onRatingSelected.bind(this), true),
      new Column('content', '', false),
    ]
  }

  private loadSubjects () {
    this.divisionService.getSubjects(this.divisionId)
    .subscribe(({ list }) => {
        this.subjects = this.viewService.optionalList(list, {_id: undefined, name: ''})
      },
      this.viewService.handleError
    )
  }
}
