import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt'
import { BehaviorSubject, Subscription } from 'rxjs/Rx'
import { Observable } from 'rxjs/Observable'

import * as _ from 'lodash-es'

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { StoreService } from './store.service'

// @ts-expect-error ts-migrate(6133) FIXME: 'Tenant' is declared but its value is never read.
import { ApiService, LoggedUser, TenantSummary, Term, Stage, TermAndStage, Tenant } from '../interfaces'

const TOKEN_KEY: string = 'token'
const USER_KEY: string = 'user'
const TERMS_KEY: string = 'terms'
const TERM_KEY: string = 'term'
const STAGE_KEY: string = 'stage'
const PERMISSIONS_KEY: string = 'permissions'
const DEVICE = { type: biz.DEVICE_TYPES.WEB }

@Injectable()
export class AuthService extends ApiService {
  private url = this.baseUrl + '/auth'

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'termAndStageSubject' has no initializer ... Remove this comment to see the full error message
  private termAndStageSubject: BehaviorSubject<TermAndStage>

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'selectedTerm' has no initializer and is ... Remove this comment to see the full error message
  private selectedTerm: Term

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'selectedStage' has no initializer and is... Remove this comment to see the full error message
  private selectedStage: Stage

  constructor (
    private router: Router,
    protected http: HttpClient,
    private storeService: StoreService,
    private jwtHelper: JwtHelperService
  ) {
    super()
    // init selected term when refreshing page while still logged in
    if (this.isLoggedIn()) {

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      this.termAndStageSubject = new BehaviorSubject<TermAndStage>(undefined)

      const termId = this.storeService.getItem(TERM_KEY)
      const found = this.initSelectedTerm((term: any) => term._id === termId)
      if (!found) {
        this.initSelectedTerm((term: any) => term.current)
      }
    }
  }

  public saveData (token: string, user: LoggedUser, permissions: any, terms: Term[], isSwitchTenant: boolean = false) {
    // discard terms without stages, just in case
    terms = terms.filter(term => term && term.stages && !!term.stages.length)

    this.storeService.setItem(TOKEN_KEY, token)
    this.storeService.setJson(USER_KEY, user)
    this.storeService.setJson(TERMS_KEY, terms)
    this.storeService.setJson(PERMISSIONS_KEY, {...permissions, 'report-card': ['get', 'update']}) // TODO remove hardcoded permission
    
    if(isSwitchTenant){
      const term = this.getTerms().find((term: any) => term.current)
      this.storeService.setItem(STAGE_KEY, term!.stages[0] && term!.stages[0]._id)

    }
    // init selected term upon a new login
    this.initSelectedTerm((term: any) => term.current)
  }

  public isLoggedIn (): boolean {
    const token = this.getCurrentToken()
    const user = this.getCurrentUser()

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | boolean' is not assignable to type ... Remove this comment to see the full error message
    return token && !this.jwtHelper.isTokenExpired(token) && !user.forcePassword
  }

  // @ts-expect-error ts-migrate(6133) FIXME: 'remember' is declared but its value is never read... Remove this comment to see the full error message
  public login (body: any, remember: boolean): Observable<LoggedUser> {
    body.internal = true
    body.device = DEVICE

    return this.http.post(`${this.url}/login`, body)
    .map((body: any) => {
      if (body.user.isSuperuser) {
        const err: any = new Error('BERR-X0007')
        err.berrCode = 'BERR-X0007'
        throw err
      }

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      this.termAndStageSubject = new BehaviorSubject<TermAndStage>(undefined)
      this.saveData(body.token, body.user, body.permissions, body.terms)
      return body.user as LoggedUser
    })
  }

  public logout () {
    this.storeService.clear()
    this.termAndStageSubject.complete()
    delete this.termAndStageSubject
    return this.router.navigate(['/login'])
  }

  public getCurrentUser (): LoggedUser {
    return this.storeService.getJson(USER_KEY) as LoggedUser
  }

  public getCurrentToken (): string {
    return this.storeService.getItem(TOKEN_KEY)
  }

  public hasUserRole (role: string): boolean {
    const user = this.getCurrentUser()
    return user.currentRole === role
  }

  public isSuperUser (): boolean {
    return this.getCurrentUser().isSuperuser
  }

  public isAllowed (resource: string, action: string): boolean {
    if (this.isSuperUser()) {
      return true
    } else {
      return _.includes(this.getPermissions()[resource], action)
    }
  }

  public isCurrentUser (userId: any) {
    return userId === this.getCurrentUser()._id
  }

  public getTenant (): TenantSummary {
    const user = this.getCurrentUser()

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'TenantSummary | undefined' is not assignable... Remove this comment to see the full error message
    return user && user.currentTenant
  }

  public getTerms (): Term[] {
    return this.storeService.getJson(TERMS_KEY)
  }

  public getSelectedTerm (): Term {
    // const terms = this.getTerms()
    // return terms.find(term => term._id === this.selectedTerm)
    return this.selectedTerm
  }

  public changeSelectedTerm (termId: string, initStage = false) {
    const terms = this.getTerms()
    const term = terms.find(term => term._id === termId)
    if (term) {
      this.selectedTerm = term
      this.storeService.setItem(TERM_KEY, term._id)
      let stageId
      if (initStage) {
        stageId = this.storeService.getItem(STAGE_KEY)
      }
      if (!stageId) {
        stageId = term.stages[0] && term.stages[0]._id
      }
      this.changeSelectedStage(stageId, initStage)
    }
  }

  public getSelectedStage (): Stage {
    return this.selectedStage
  }

  public changeSelectedStage (stageId: string, initStage = false) {
    const term = this.getSelectedTerm()
    const stage = term && term.stages.find(stage => stage._id === stageId)
    if (stage) {
      this.selectedStage = stage
      this.storeService.setItem(STAGE_KEY, stage._id)
      this.termAndStageSubject.next({ term, stage, initial: initStage })
    }
  }

  public subscribeToStage (observer: any): Subscription {
    return this.termAndStageSubject.subscribe(observer)
  }

  public isCurrentTermSelected () {
    const currentTerm = this.getSelectedTerm()
    if (!currentTerm) {
      return false
    }
    return currentTerm.current
  }

  // @ts-expect-error ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  private initSelectedTerm (predicate: any): boolean {
    const term = this.getTerms().find(predicate)
    if (term) {
      this.changeSelectedTerm(term._id, true)
      return true
    }
  }

  private getPermissions (): any {
    return this.storeService.getJson(PERMISSIONS_KEY) || {}
  }
}
