<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
  <div class="btn-toolbar pull-right-sm"
      role="toolbar"
      *ngIf="!isCreate && !!model.stage">
    <a class="btn btn-primary"
        *ngIf="isAttendanceAllowed"
        routerLink="/attendance/{{ model.stage }}/{{ model.grade._id }}/{{ _id }}">
      <i class="fa fa-check-square-o"></i>
      <span>{{ 'DIVISION.ATTENDANCE' | translate }}</span>
    </a>
    <a class="btn btn-primary"
        *ngIf="isPromoteAllowed"
        routerLink="/divisions/{{ _id }}/promote">
      <i class="fa fa-level-up"></i>
      <span>{{ 'PROMOTION.PROMOTE' | translate }}</span>
    </a>
  </div>
</section>

<section class="content text-center text-muted" *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</section>

<section class="content" *ngIf="!isLoading">
  <div class="row">
    <div class="col-xs-12">

      <form class="form-fixed-help"
          [formGroup]="form"
          (ngSubmit)="submit()">

        <div class="row">
          <div class="form-group col-sm-4 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.grade.valid && form.controls.grade.touched }">
            <label>
              {{ 'GRADE.DETAIL_TITLE' | translate }}
              <required-icon [visible]="isCreate"></required-icon>
            </label>
            <div class="form-control-static" *ngIf="!isCreate">
              {{ model.grade?.name }}
            </div>
            <select *ngIf="isCreate"
                class="form-control"
                formControlName="grade">
              <option *ngFor="let grade of grades" [ngValue]="grade._id">
                {{ grade.name }}
              </option>
            </select>
            <span class="help-block">
              <span *ngIf="form.controls.grade.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>

          <div class="form-group col-sm-4 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.name.valid && form.controls.name.touched }">
            <label>
              {{ 'DIVISION.NAME' | translate }}
              <required-icon></required-icon>
              <help-icon key="DIVISION.FULL_NAME_HELP"></help-icon>
            </label>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              {{ form.get('name').value }}
            </p>
            <input type="text"
                *ngIf="isSaveAllowed"
                class="form-control"
                formControlName="name">
            <span class="help-block">
              <span *ngIf="form.controls.name.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>

          <div class="form-group col-sm-4 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.shift.valid && form.controls.shift.touched }">
            <label>
              {{ 'DIVISION.SHIFT' | translate }}
              <required-icon></required-icon>
            </label>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              <span class="label" [ngClass]="form.get('shift').value | schoolShiftLabel">
                {{ form.get('shift').value | schoolShift }}
              </span>
            </p>
            <select class="form-control" formControlName="shift" *ngIf="isSaveAllowed">
              <option *ngFor="let shift of shifts" [value]="shift">
                {{ shift | schoolShift }}
              </option>
            </select>
            <span class="help-block">
              <span *ngIf="form.controls.shift.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <button
                type="submit"
                class="btn btn-success btn-lg"
                *ngIf="isSaveAllowed"
                [disabled]="!form.valid"
                [ladda]="isSubmitting">
              {{ 'COMMON.BTN_SAVE' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-danger btn-lg pull-right-md"
                *ngIf="isDeleteAllowed"
                (click)="remove()"
                [ladda]="isSubmitting">
              {{ 'COMMON.BTN_REMOVE_DIVISION' | translate }}
            </button>
          </div>
        </div>
      </form>

      <div *ngIf="!isCreate" class="form-tab-container">
        <tabset>
          <tab heading="{{ 'STUDENT.SEARCH_TITLE' | translate }}" (select)="activateStudentsTab()">
            <div class="col-xs-12">
              <division-students-tab [divisionId]="_id"></division-students-tab>
            </div>
          </tab>
          <tab heading="{{ 'DIVISION.COURSES' | translate }}" (select)="activateCoursesTab()">
            <div class="col-xs-12">
              <division-courses-tab [division]="model"></division-courses-tab>
            </div>
          </tab>
          <tab heading="{{ 'DIVISION.EXAMS' | translate }}" (select)="activateExamsTab()">
            <div class="col-xs-12">
              <division-exams-tab [divisionId]="_id"></division-exams-tab>
            </div>
          </tab>
          <tab heading="{{ 'DIVISION.RATINGS' | translate }}" (select)="activateRatingsTab()">
            <div class="col-xs-12">
              <division-ratings-tab [divisionId]="_id"></division-ratings-tab>
            </div>
          </tab>
        </tabset>
      </div>

    </div>
  </div>
</section>
