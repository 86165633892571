var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { GuardianService, ViewService } from '../../services';
import { DetailView, UserValidator } from '../../interfaces';
var GuardianComponent = /** @class */ (function (_super) {
    __extends(GuardianComponent, _super);
    function GuardianComponent(viewService, service, route) {
        var _this = _super.call(this, viewService, service, route, 'guardian', UserValidator.newFormGroup(), false) || this;
        _this.deletePermission = 'remove';
        return _this;
    }
    // @ts-expect-error ts-migrate(6133) FIXME: 'model' is declared but its value is never read.
    GuardianComponent.prototype.onModelLoaded = function (model) {
        var _this = this;
        if (!this.isCreate) {
            setTimeout(function () {
                _this.activateStudentsTab();
            }, 0);
        }
    };
    GuardianComponent.prototype.activateStudentsTab = function () {
        this.studentsTab.activate();
    };
    GuardianComponent.prototype.getValue = function () {
        return this.form.value;
    };
    GuardianComponent.prototype.getDefaultDetails = function () {
        return {
            gender: biz.GENDERS.FEMALE
        };
    };
    GuardianComponent.prototype.checkPermissions = function () {
        _super.prototype.checkPermissions.call(this);
        this.isForcePasswordAllowed = !this.isCreate && this.viewService.isAllowed('tenant', 'force-member-password');
    };
    return GuardianComponent;
}(DetailView));
export { GuardianComponent };
