import { Component, Input, OnChanges } from '@angular/core'
import { Observable } from 'rxjs/Observable'

import * as _ from 'lodash-es'

// @ts-expect-error ts-migrate(6133) FIXME: 'biz' is declared but its value is never read.
import { biz } from 'nexedu-common'
import { DivisionService, ViewService } from '../../services'

@Component({
  selector: 'exam-assessments',
  templateUrl: './exam-assessments.html'
})

export class ExamAssessments implements OnChanges {

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'examId' has no initializer and is not de... Remove this comment to see the full error message
  @Input() examId: string

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'divisionId' has no initializer and is no... Remove this comment to see the full error message
  @Input() divisionId: string
  @Input() isAssessmentAllowed: boolean = false
  public students: any
  public assessments: any
  public isLoading = false
  public isSubmitting = false

  constructor (
    private divisionService: DivisionService,
    private viewService: ViewService
  ) {}

  // @ts-expect-error ts-migrate(6133) FIXME: 'changes' is declared but its value is never read.
  ngOnChanges (changes: any) {
    this.loadAssessments()
  }

  public updateAssessments () {
    let assessments: any = []
    this.isSubmitting = true
    this.students.forEach((student: any) => {
      if (this.assessments[student._id] && this.assessments[student._id].assessment) {
        assessments.push(this.assessments[student._id])
      }
    })
    this.divisionService.updateExamAssessments(this.divisionId, this.examId, assessments)
    .finally(() => { this.isSubmitting = false })
    // @ts-expect-error ts-migrate(6133) FIXME: 'result' is declared but its value is never read.
    .subscribe(result => {
      this.viewService.toastr.successT('COMMON.MSG_UPDATE_OK')
    }, this.viewService.handleError)
  }

  private loadAssessments () {
    if (!this.divisionId || !this.examId) {
      return
    }

    this.isLoading = true
    // wait for both requests to complete
    // http://reactivex.io/documentation/operators/zip.html
    Observable.forkJoin(
      this.retrieveStudents(),
      this.retrieveAssessments()
    )
    .finally(() => { this.isLoading = false })
    .subscribe(
      results => {
        const students = results[0]
        const assessments = results[1]

        this.assessments = _.keyBy(assessments, 'student')
        this.students = students

        this.students.forEach((student: any) => {
          if (!this.assessments[student._id]) {
            this.assessments[student._id] = { assessment: '', comment: '', student: student._id }
          }
        })
      },
      this.viewService.handleError
    )
  }

  private retrieveStudents () {
    return this.divisionService.getStudents(this.divisionId)
  }

  private retrieveAssessments () {
    return this.divisionService.getExamAssessments(this.divisionId, this.examId)
  }
}
