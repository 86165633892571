var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { Column } from '../../models';
import { StudentService, ViewService } from '../../services';
import { SearchView } from '../../interfaces';
var StudentsComponent = /** @class */ (function (_super) {
    __extends(StudentsComponent, _super);
    function StudentsComponent(viewService, route, service) {
        var _this = _super.call(this, viewService, route, service, 'students', 'student', 'create') || this;
        _this.isImportAllowed = true;
        _this.columns = [
            _this.routerColumn('_id', 'USER.ID', { isPrimary: true }),
            _this.routerColumn('name.last', 'USER.LAST_NAME', { isPrimary: true }),
            _this.routerColumn('name.first', 'USER.FIRST_NAME', { isPrimary: true }),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.late', 'TOPIC_TYPES.late', false, null, 'warningBadgeCell', 90, 90, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.absence', 'TOPIC_TYPES.absence', false, null, 'dangerBadgeCell', 90, 90, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.justified', 'TOPIC_TYPES.justified', false, null, 'infoBadgeCell', 110, 110, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.tcAccepted', 'USER.TC_ACCEPTED', false, null, 'boolCell', 80, 80),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.devices', 'USER.DEVICES', false, null, 'devicesCell', 90, 90)
        ];
        _this.params.sort = {
            'name.last': 1,
            'name.first': 1
        };
        return _this;
    }
    return StudentsComponent;
}(SearchView));
export { StudentsComponent };
