/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../avatar/avatar.component.ngfactory";
import * as i2 from "../avatar/avatar.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "./menu-aside.component";
import * as i7 from "../../services/me.service";
import * as i8 from "../../services/view.service";
var styles_MenuAsideComponent = [];
var RenderType_MenuAsideComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MenuAsideComponent, data: {} });
export { RenderType_MenuAsideComponent as RenderType_MenuAsideComponent };
function View_MenuAsideComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "tenant-menu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(2, 0, null, null, 10, "a", [["class", "tenant-entry"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToCurrentTenant() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "avatar", [["size", "40"]], null, null, null, i1.View_Avatar_0, i1.RenderType_Avatar)), i0.ɵdid(7, 573440, null, 0, i2.Avatar, [], { photo: [0, "photo"], name: [1, "name"], size: [2, "size"] }, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["\n          ", "\n        "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tenant == null) ? null : ((_co.tenant.logo == null) ? null : _co.tenant.logo.secure_url)); var currVal_1 = ((_co.tenant == null) ? null : _co.tenant.name); var currVal_2 = "40"; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.tenant == null) ? null : _co.tenant.name); _ck(_v, 11, 0, currVal_3); }); }
function View_MenuAsideComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "li", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n        "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_v.parent.context.$implicit.data.title)); _ck(_v, 2, 0, currVal_0); }); }
function View_MenuAsideComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[2, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["\n              "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n              "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n            "]))], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.$implicit.link; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵinlineInterpolate(1, "fa fa-fw fa-", _v.parent.parent.context.$implicit.data.icon, ""); _ck(_v, 3, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_v.parent.parent.context.$implicit.data.title)); _ck(_v, 6, 0, currVal_4); }); }
function View_MenuAsideComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "a", [], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n              "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n              "])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n            "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.link; var currVal_1 = _v.parent.parent.context.$implicit.target; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "fa fa-fw fa-", _v.parent.parent.context.$implicit.data.icon, ""); _ck(_v, 2, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_v.parent.parent.context.$implicit.data.title)); _ck(_v, 5, 0, currVal_3); }); }
function View_MenuAsideComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(1, 0, null, null, 10, "li", [["routerLinkActive", "active"]], null, null, null, null, null)), i0.ɵdid(2, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_5)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_6)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var currVal_0 = "active"; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.$implicit.external; _ck(_v, 7, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.external; _ck(_v, 10, 0, currVal_2); }, null); }
function View_MenuAsideComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "fa fa-fw fa-", _v.parent.parent.context.$implicit.data.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_MenuAsideComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "fa fa-fw fa-", _v.parent.parent.context.$implicit.data.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_MenuAsideComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[4, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_11)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n                  "]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.link; _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.data.icon; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_v.parent.context.$implicit.data.title)); _ck(_v, 7, 0, currVal_4); }); }
function View_MenuAsideComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "fa fa-fw fa-", _v.parent.parent.context.$implicit.data.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_MenuAsideComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "a", [], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_13)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n                  "]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.data.icon; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.link; var currVal_1 = _v.parent.context.$implicit.target; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_v.parent.context.$implicit.data.title)); _ck(_v, 6, 0, currVal_3); }); }
function View_MenuAsideComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(1, 0, null, null, 10, "li", [["routerLinkActive", "active"]], null, null, null, null, null)), i0.ɵdid(2, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(-1, null, ["\n                  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_10)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_12)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵted(-1, null, ["\n              "]))], function (_ck, _v) { var currVal_0 = "active"; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.external; _ck(_v, 7, 0, currVal_1); var currVal_2 = _v.context.$implicit.external; _ck(_v, 10, 0, currVal_2); }, null); }
function View_MenuAsideComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵeld(1, 0, null, null, 19, "li", [["class", "treeview"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵeld(3, 0, null, null, 10, "a", [["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n              "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_8)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n              "])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n              "])), (_l()(), i0.ɵeld(12, 0, null, null, 0, "i", [["class", "fa fa-angle-left pull-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵeld(15, 0, null, null, 4, "ul", [["class", "treeview-menu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n              "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_9)), i0.ɵdid(18, 802816, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵted(-1, null, ["\n          "])), (_l()(), i0.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.data.icon; _ck(_v, 6, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.sublinks; _ck(_v, 18, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_v.parent.context.$implicit.data.title)); _ck(_v, 9, 0, currVal_1); }); }
function View_MenuAsideComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\n        "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_3)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n        "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_4)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n        "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_7)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n      "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.header; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((!_v.context.$implicit.header && !_v.context.$implicit.sublinks) && _co.isVisible(_v.context.$implicit)); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((!_v.context.$implicit.header && _v.context.$implicit.sublinks) && _co.isAllowed(_v.context.$implicit)); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_MenuAsideComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(1, 0, null, null, 23, "aside", [["class", "main-sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(4, 0, null, null, 18, "section", [["class", "sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_1)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(15, 0, null, null, 5, "ul", [["class", "sidebar-menu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuAsideComponent_2)), i0.ɵdid(19, 802816, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tenant; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.links; _ck(_v, 19, 0, currVal_1); }, null); }
export function View_MenuAsideComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-menu-aside", [], null, null, null, View_MenuAsideComponent_0, RenderType_MenuAsideComponent)), i0.ɵdid(1, 49152, null, 0, i6.MenuAsideComponent, [i7.MeService, i8.ViewService], null, null)], null, null); }
var MenuAsideComponentNgFactory = i0.ɵccf("app-menu-aside", i6.MenuAsideComponent, View_MenuAsideComponent_Host_0, { links: "links" }, {}, []);
export { MenuAsideComponentNgFactory as MenuAsideComponentNgFactory };
