import { Injectable } from '@angular/core'
import { CacheService, CacheStoragesEnum } from 'ng2-cache';

@Injectable()
export class AppCacheService {
  private cache: CacheService

  constructor (service: CacheService) {
    this.cache = service.useStorage(CacheStoragesEnum.MEMORY)
  }

  /**
   * Gets a value from the cache
   * @param  {string} key identifier for the value
   * @return {any}        value stored in the cache
   */
  public get (key: string): any {
    return this.cache.get(key)
  }

  /**
   * Stores a value in the cache
   * @param {string}    key identifier for the value
   * @param {any}       data value to store
   * @param {number}    maxAge max time to live in seconds (5 minutes by default)
   */
  public set (key: string, data: any, maxAge: number = 60 * 5) {
    this.cache.set(key, data, { maxAge })
  }

  public remove (key: string) {
    this.cache.remove(key)
  }
}
