import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { CrudSearchResult, CrudService } from './crud.service'
import { PersistentModel } from './persistent-model'
import { AuthService } from '../services'
import { QueryString } from '../models'

export abstract class StageDependantCrudService<T extends PersistentModel> extends CrudService<T> {

  protected constructor (
    protected http: HttpClient,
    protected urlSpecific: string,
    protected authService: AuthService,
    protected termOnly: boolean = false,
    typeDeserializer: (json: any) => T = (e) => e
  ) {
    super(http, urlSpecific, typeDeserializer)
  }

  public search (params?: QueryString): Observable<CrudSearchResult<T>> {
    this.updateUrl()
    return super.search(params)
  }

  public getById (id: string, params?: QueryString): Observable<T> {
    this.updateUrl()
    return super.getById(id, params)
  }

  public create (object: any): Observable<any> {
    this.updateUrl()
    return super.create(object)
  }

  public update (id: string, object: any): Observable<any> {
    this.updateUrl()
    return super.update(id, object)
  }

  public remove (id: string): Observable<any> {
    this.updateUrl()
    return super.remove(id)
  }

  protected updateUrl () {
    const term = this.authService.getSelectedTerm()
    this.url = `${this.apiUrl}/terms/${term._id}`
    if (!this.termOnly) {
      const stage = this.authService.getSelectedStage()
      this.url += `/stages/${stage._id}`
    }
    this.url += `${this.urlSpecific}`
  }
}
