import { Component, TemplateRef, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'
import { Column } from '../../models'
import {
  MeService,
  SupervisorService,
  TeacherService,
  TopicService,
  ViewService
} from '../../services'
import { SearchView, Topic } from '../../interfaces'
import { ShortDatePipe, TopicTypePipe } from '../../pipes'
import { zip } from 'rxjs-compat/observable/zip'
import { CrudSearchResult } from '../../interfaces/crud.service'
import { BehaviorSubject } from 'rxjs-compat/Rx'
import * as _ from 'lodash-es'
import { Observable } from 'rxjs-compat/Observable'
import { Subscribable } from 'rxjs-compat/Observable'
import * as moment from 'moment'
import { Moment } from 'moment'
import { BsModalRef, BsModalService } from 'ngx-bootstrap'
import { RecipientsListModal } from '../../widgets/recipients-list-modal/recipients-list-modal'

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  providers: [TranslatePipe]
})

export class TopicsComponent extends SearchView<Topic> {
  public topicTypes: any[]
  public selectedTopic: string | undefined
  public columns: Column []

  public readonly today = moment().startOf('day').valueOf()
  public startDate: Moment | undefined
  public endDate: Moment | undefined

  public selectedAuthors: any

  private fetchedAuthorsSubject: BehaviorSubject<IdNamePair[]> = new BehaviorSubject([] as IdNamePair[])
  private resultsAuthorsSubject: BehaviorSubject<IdNamePair[]> = new BehaviorSubject([] as IdNamePair[])

  @ViewChild('modalTemplate') modalTemplate!: TemplateRef<any>;
  modalRef?: BsModalRef;

  constructor (
      viewService: ViewService,
      route: ActivatedRoute,
      service: TopicService,
      translatePipe: TranslatePipe,
      private readonly teacherService: TeacherService,
      private readonly supervisorService: SupervisorService,
      private readonly meService: MeService,
      private modalService: BsModalService,
  ) {
    super(viewService, route, service, 'topics', 'topic')
    // default sort
    this.params.sort = { dateTime: -1 }
    this.params.conditions.type = {
      $in: TopicService.TOPIC_COMPONENT_TYPES
    }

    this.topicTypes = this.viewService.optionalList(TopicService.TOPIC_COMPONENT_TYPES, undefined)

    const shortDatePipe = new ShortDatePipe()
    shortDatePipe.locale = viewService.auth.getCurrentUser().locale

    this.columns = [
      this.routerColumn('date', 'TOPIC.DATE', {
        isPrimary: true,
        minWidth: 90,
        maxWidth: 90,
        pipe: shortDatePipe
      }),
      Column.forClick('type', 'TOPIC.TYPE', this.onSelect.bind(this), true, new TopicTypePipe(translatePipe), 90, 90, 'defaultLabelCell'),
      this.routerColumn('title', 'TOPIC.TITLE', {isPrimary: true}),

      new Column('recipients', 'TOPIC.RECIPIENTS', false, undefined, 'topicRecipientsCell', undefined, undefined, undefined, (topic: Topic) => {
        this.openModal(topic)
      }),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('audience', 'TOPIC.AUDIENCE', false, null, 'topicAudienceCell', 120, 120),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('attachments.length', 'TOPIC.ATTACHMENTS', false, null, 'defaultBadgeCell', 80, 80),

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('resolvedName', 'TOPIC.AUTHOR', false, null)
    ]
  }

  ngOnInit () {
    const user: Subscribable<IdNamePair> = Observable.of(this.meService.getMe())
        .map((t) => new IdNamePair(t._id, (t.name.full ?? (t.name.last + ', ' + t.name.first)) + ' (' + t._id + ')'))
    const teachers: Subscribable<IdNamePair[]> = this.teacherService.search()
        .map((e) => e.list.map((t) => new IdNamePair(t._id, (t.name.full ?? (t.name.last + ', ' + t.name.first)) + ' (' + t._id + ')')))
    const supervisors: Subscribable<IdNamePair[]> = this.supervisorService.search()
        .map((e) => e.list.map((t) => new IdNamePair(t._id, (t.name.full ?? (t.name.last + ', ' + t.name.first)) + ' (' + t._id + ')')))
    zip(teachers, supervisors, user, (a: IdNamePair[], b: IdNamePair[], c: IdNamePair) => [c, ...a, ...b])
        .map((e: IdNamePair[]) => _.uniqBy(e, pair => pair.id))
        .subscribe((e: IdNamePair[]) => {
          this.selectedAuthors = [e[0]]
          this.fetchedAuthorsSubject.next(e)
          this.setAuthorsFilter(this.selectedAuthors)
          super.ngOnInit()
        })
  }

  public searchAuthors = () => {
    console.log('Searching authors')
    return zip(this.fetchedAuthorsSubject, this.resultsAuthorsSubject, (a: IdNamePair[], b: IdNamePair[]) => [...a, ...b])
        .map((e: IdNamePair[]) => _.uniqBy(e, pair => pair.id))
  }

  public setAuthorsFilter (idNamePairs: IdNamePair[]) {
    this.params.conditions.createdBy = idNamePairs.map((e) => e.id)
    this.updateFilter()
  }

  onStartDateChange (timestamp: number) {
    if (!this.params.conditions.timestamp) {
      this.params.conditions.timestamp = {}
    }
    this.params.conditions.timestamp['$gt'] = timestamp
    this.updateFilter()
  }

  onEndDateChange (timestamp: number) {
    if (!this.params.conditions.timestamp) {
      this.params.conditions.timestamp = {}
    }
    this.params.conditions.timestamp['$lt'] = timestamp
    this.updateFilter()
  }

  
  
  updateTopicFilter () {
    if (this.selectedTopic === undefined) {
      this.params.conditions.type.$in = TopicService.TOPIC_COMPONENT_TYPES
    } else {
      this.params.conditions.type.$in = [this.selectedTopic]
    }
    this.updateFilter()
  }

  protected onDataFetched (data: CrudSearchResult<Topic>) {
    this.resultsAuthorsSubject.next(data.list.map((e) => {
      const createdBy = typeof e.createdBy === 'string' ? e.createdBy : e.createdBy?._id
      return new IdNamePair(createdBy, createdBy)
    }))
    // @ts-ignore
    data.list.forEach((e) => e.resolvedName = this.fetchedAuthorsSubject.getValue().find((a) => a.id === e.createdBy)?.name ?? e.createdBy)
    super.onDataFetched(data)
  }

  openModal(topic: Topic) {
    this.modalRef = this.modalService.show(RecipientsListModal, { initialState: { topic }, class: 'modal-lg' });
  }
}

class IdNamePair {
  constructor (readonly id: String, readonly name: String) {
  }
}
