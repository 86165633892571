<div ng2FileDrop
    [ngClass]="{ 'dragover': hovering }"
    (fileOver)="onFileOver($event)"
    [uploader]="uploader"
    class="upload-file">
  <img [src]="placeholder">
  <div style="
  display: flex;
  flex-direction: column;">
    <p>Arrastar y soltar archivos para subirlos</p>
    <label for="file" class="custom-file-input"></label>
    <input type="file" id="file" (change)="handleFileInput($event.target.files)" title=" " style="display: none">
  </div>
</div>
<progressbar *ngIf="uploading" [value]="progress" class="progress-striped">
  {{ progress || 0 }}%
</progressbar>
