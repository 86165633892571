import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { StageDependantCrudService, Teacher } from '../interfaces'
import { QueryString } from '../models'
import { AuthService } from './auth.service'

@Injectable()
export class TeacherService extends StageDependantCrudService<Teacher> {

  constructor (http: HttpClient, authService: AuthService) {
    super(http, '/teachers', authService)
  }

  getSubjectsForDivision (id: string, divisionId: string, params?: QueryString): Observable<any> {
    this.updateUrl()
    return this.http.get(`${this.url}/${id}/divisions/${divisionId}/subjects`, this.toRequestOptions(params))
  }
}
