import { Component, Input } from '@angular/core'
import { Observable } from 'rxjs/Observable'
import { Column, QueryString } from '../../models'
import { ViewService, DivisionService } from '../../services'
import { SearchPanel, Course, Division } from '../../interfaces'
import { UsersArrayPipe, UsernamePipe } from '../../pipes'

@Component({
  selector: 'division-courses-tab',
  templateUrl: './division-courses.tab.html'
})

export class DivisionCoursesTab extends SearchPanel<any> {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'division' has no initializer and is not ... Remove this comment to see the full error message
  @Input() division: Division



  // @ts-expect-error ts-migrate(2564) FIXME: Property 'columns' has no initializer and is not d... Remove this comment to see the full error message
  public columns: Column []
  public isSaveAllowed: boolean = false
  public isSubmitting: boolean = false
  public isRemoving: boolean = false


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'model' has no initializer and is not def... Remove this comment to see the full error message
  public model: Course

  constructor (
    private service: DivisionService,
    viewService: ViewService
  ) {
    super(viewService, 'division', 'add-course')
    this.params.populate = {
      subject: 'name',
      teachers: 'name'
    }
    this.params.select = 'subject teachers'
    this.isSaveAllowed = viewService.isAllowed('division', 'add-course')
  }

  public activate () {
    this.createColumns()
    this.updateFilter()
  }

  public switchToEditionMode (model: Course = {
  } as Course) {
    this.model = model
  }

  public cancelEditionMode () {
    this.createColumns()


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Course... Remove this comment to see the full error message
    this.model = undefined
    this.updateFilter()
  }

  protected fetch (params: QueryString): Observable<any> {
    return this.service.getCourses(this.division._id, params)
  }

  private createColumns (): void {
    const canRemove = this.viewService.isCurrentTermSelected()
    const actions = [{
      class: 'fa-times action-icon-danger',
      onClick: this.onRemove.bind(this),
      visible: () => canRemove
    }]
    this.columns = [


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      Column.forClick('subject.name', 'SUBJECT.DETAIL_TITLE', this.switchToEditionMode.bind(this), true, null, 200, 200),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      Column.forClick('teachers', 'TEACHER.SEARCH_TITLE', this.switchToEditionMode.bind(this), false, new UsersArrayPipe(new UsernamePipe()), null, null, 'defaultLabelsCell'),
      Column.forActions(actions, 50)
    ]
  }

  private onRemove (row: any) {
    this.viewService.askConfirmation('COMMON.MSG_CONFIRM_REMOVE')
    .subscribe(confirmed => {
      if (confirmed) {
        this.isRemoving = true

        this.service.removeCourse(this.division._id, row._id)
        .finally(() => this.isRemoving = false)
        .subscribe(


          // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
          data => {
            this.viewService.toastr.successT('COMMON.MSG_OPERATION_OK')
            this.getItems()
          },
          this.viewService.handleError
        )
      }
    })
  }
}
