import { RequestOptions, URLSearchParams } from '@angular/http'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { StageDependantCrudService, Topic } from '../interfaces'
import { QueryString } from '../models'
import { AuthService } from './auth.service'
import { AppCacheService } from './app-cache.service'

@Injectable()
export class TopicService extends StageDependantCrudService<Topic> {

  public static TOPIC_COMPONENT_TYPES = [
    biz.TOPIC_TYPES.NEWS,
    biz.TOPIC_TYPES.EVENT,
    biz.TOPIC_TYPES.HOLIDAY,
    biz.TOPIC_TYPES.STRIKE,
    biz.TOPIC_TYPES.NO_CLASSES,
    biz.TOPIC_TYPES.WARNING,
    biz.TOPIC_TYPES.ACHIEVEMENT
  ]

  constructor (http: HttpClient, auth: AuthService, private cacheService: AppCacheService) {
    super(http, '/topics', auth, false, (json: any) => Topic.fromJson(json))
  }

  public loadFeed (studentId: string, next?: string): Observable<any> {
    let requestOptions
    if (next) {
      const search = new URLSearchParams()
      search.set('next', next)
      requestOptions = new RequestOptions({ search })
    }


    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    return this.http.get(`${this.url}/feed/${studentId}`, requestOptions)
  }

  public getDeliveryGroups (topicId: string, options?: QueryString) {
    const key = `topic.${topicId}.groups`
    const cachedResult = this.cacheService.get(key)
    if (cachedResult) {
      return Observable.of(cachedResult)
    }

    return this.http.get(`${this.url}/${topicId}/divisions`, this.toRequestOptions(options)).map((result: any) => {
      // cache result if it doesn't have a virtual division
      const groups = result as any[]
      const hasVirtualDivision = groups.find(group => !group._id)
      if (!hasVirtualDivision) {
        this.cacheService.set(key, groups)
      }
      return groups
    })
  }

  public getDeliveryGroupDetails (topicId: string, divisionId: string, options?: QueryString) {
    return this.http.get(`${this.url}/${topicId}/divisions/${divisionId}`, this.toRequestOptions(options))
  }
}
