<div class="row" *ngIf="form" [formGroup]="form">
  <style>
    .form-check-input {
      clear: left;
    }

    .form-switch.form-switch-sm {
      margin-bottom: 0.5rem;
      /* JUST FOR STYLING PURPOSE */
    }

    .form-switch.form-switch-sm .form-check-input {
      height: 1rem;
      width: calc(1rem + 0.75rem);
      border-radius: 2rem;
    }

    .form-switch.form-switch-md {
      margin-bottom: 1rem;
      /* JUST FOR STYLING PURPOSE */
    }

    .form-switch.form-switch-md .form-check-input {
      height: 1.5rem;
      width: calc(2rem + 0.75rem);
      border-radius: 3rem;
    }

    .form-switch.form-switch-lg {
      margin-bottom: 1.5rem;
      /* JUST FOR STYLING PURPOSE */
    }

    .form-switch.form-switch-lg .form-check-input {
      height: 2rem;
      width: calc(3rem + 0.75rem);
      border-radius: 4rem;
    }

    .form-switch.form-switch-xl {
      margin-bottom: 2rem;
      /* JUST FOR STYLING PURPOSE */
    }

    .form-switch.form-switch-xl .form-check-input {
      height: 2.5rem;
      width: calc(4rem + 0.75rem);
      border-radius: 5rem;
    }
  </style>
  <div class="form-group col-sm-6 col-md-3"
    [ngClass]="{ 'has-error': !form.get('type').valid && form.get('type').touched }">
    <label>
      {{ 'TOPIC.TYPE' | translate }}
      <required-icon></required-icon>
    </label>
    <p class="form-control-static" *ngIf="mode !== 'create'">
      <span class="label label-default">
        {{ form.get('type').value | topicType }}
      </span>
    </p>
    <select class="form-control" *ngIf="mode === 'create'" (change)="onTypeChange()" formControlName="type">
      <option *ngFor="let topicType of topicTypes" [value]="topicType">
        {{ topicType | topicType }}
      </option>
    </select>
    <span class="help-block">
      <span *ngIf="form.controls.type.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
    </span>
  </div>

  <div class="form-group col-sm-6 col-md-3"
    [ngClass]="{ 'has-error': !form.controls.title.valid && form.controls.title.touched }">
    <label>
      {{ 'TOPIC.TITLE' | translate }}
      <required-icon></required-icon>
    </label>
    <p class="form-control-static" *ngIf="mode === 'readonly'">
      {{ form.get('title').value }}
    </p>
    <input type="text" *ngIf="mode !== 'readonly'" class="form-control" formControlName="title">
    <span class="help-block">
      <span *ngIf="form.controls.title.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
    </span>
  </div>

  <div class="form-group col-sm-6 col-md-3" *ngIf="form.controls.date"
    [ngClass]="{ 'has-error': !form.controls.date.valid && form.controls.date.touched }">
    <label>
      {{ 'TOPIC.DATE' | translate }}
      <required-icon></required-icon>
    </label>
    <p class="form-control-static" *ngIf="mode !== 'create'">
      {{ form.get('date').value | shortDate : locale }}
    </p>
    <dateonlypicker-popup formControlName="date" *ngIf="mode === 'create'" [locale]="locale">
    </dateonlypicker-popup>
    <span class="help-block">
      <span *ngIf="form.controls.date.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
    </span>
  </div>

  <div class="form-group col-sm-6 col-md-3" *ngIf="form.controls.audience">
    <label>
      {{ 'TOPIC.AUDIENCE' | translate }}
      <required-icon></required-icon>
    </label>
    <div>
      <p class="form-control-static" *ngIf="mode !== 'create'">
        <span class="label label-default" *ngIf="form.get('audience').value.guardian">
          {{ 'guardian' | tenantRole }}
        </span>
        <span class="label label-default" *ngIf="form.get('audience').value.student">
          {{ 'student' | tenantRole }}
        </span>
      </p>
      <div class="btn-group btn-group-2" *ngIf="mode === 'create'">
        <button type="button" class="btn btn-default" btnCheckbox [(ngModel)]="audience.guardian"
          [ngModelOptions]="{ standalone: true }" (click)="onAudienceChange('guardian')">
          {{ 'guardian' | tenantRole }}
        </button>
        <button type="button" class="btn btn-default" btnCheckbox [(ngModel)]="audience.student"
          [ngModelOptions]="{ standalone: true }" (click)="onAudienceChange('student')">
          {{ 'student' | tenantRole }}
        </button>
      </div>
    </div>
  </div>

  <div class="form-group col-xs-12" *ngIf="form.controls.recipients" [ngClass]="{
        'has-error': mode === 'create' && !form.controls.recipients.valid && form.controls.recipients.touched,
        'has-info': mode === 'create' && focusOnRecipients
      }">
    <label>
      {{ 'TOPIC.RECIPIENTS' | translate }}
    </label>
    <recipients-selector formControlName="recipients" [readonly]="mode !== 'create'"
      [recipientTypes]="allowedRecipients" (onFocus)="focusOnRecipients = true" (onBlur)="focusOnRecipients = false"
      (onLoadingStart)="toggleLoadingRecipients(true)" (onLoadingEnd)="toggleLoadingRecipients(false)">
    </recipients-selector>
    <span class="help-block">
      <span *ngIf="focusOnRecipients && !loadingRecipients">{{ 'TOPIC.RECIPIENTS_HELP' | translate }}</span>
      <span *ngIf="loadingRecipients">{{ 'COMMON.SEARCHING' | translate }}</span>
    </span>
  </div>

  <div class="form-group col-xs-12"
    [ngClass]="{ 'has-error': !form.controls.content.valid && form.controls.content.touched }">
    <rich-editor #editor formControlName="content" [locale]="locale" [readonly]="mode === 'readonly'"></rich-editor>
  </div>

  <div class="form-group col-xs-12" style="display: flex; align-items:start;" *ngIf="responseLink !== ''">
    <label for="allowResponses" style="margin-right: 8px;">Permitir respuestas: </label>
    <input type="checkbox" class="switch" name="allowResponses" [checked]="allowResponses"
      (change)="onChangeAllowResponses()" />
  </div>

  <div class="form-group col-xs-12">
    <label>{{ 'TOPIC.ATTACHMENTS' | translate }}</label>
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let attachment of form.controls.attachments.value; index as i">
        <a href="{{ attachment.secure_url }}" target="_blank">
          <span>{{ attachment.original_filename }}</span>
          <span class="text-muted">&nbsp;({{ attachment.bytes | prettySize }})</span>
        </a>
        <a>
          <i class="fa fa-times action-icon action-icon-danger" *ngIf="mode !== 'readonly'" aria-hidden="true"
            (click)="removeAttachment(i)" tooltip="{{ 'COMMON.BTN_REMOVE' | translate }}">
          </i>
        </a>
      </li>
      <li class="list-group-item text-center text-muted" *ngIf="!form.controls.attachments.value.length">
        <span>{{ 'TOPIC.NO_ATTACHMENTS' | translate }}</span>
      </li>
    </ul>
    <upload-file *ngIf="mode !== 'readonly'" [preset]="preset" [tags]="tags"
      (onUpload)="onAttachmentUpload($event)"></upload-file>
  </div>
</div>