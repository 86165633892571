import { forwardRef, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export var TAGS_INPUT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return TagsInput; }),
    multi: true
};
var TagsInput = /** @class */ (function () {
    function TagsInput() {
        this.readonly = false;
        this.onChange = Function.prototype;
        this.onTouched = Function.prototype;
    }
    Object.defineProperty(TagsInput.prototype, "value", {
        get: function () {
            return this.innerValue.map(function (item) { return item._id; });
        },
        set: function (_value) {
            // _value = _value || []
            // this.innerValue = _value.map(item => )
            this.innerValue = _value;
        },
        enumerable: false,
        configurable: true
    });
    TagsInput.prototype.onTagsChange = function () {
        this.onChange(this.value);
    };
    TagsInput.prototype.onBlur = function () {
        this.onTouched();
    };
    // ControlValueAccessor
    // model -> view
    TagsInput.prototype.writeValue = function (value) {
        this.value = value;
    };
    TagsInput.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
        // this.registered = true
        // if (this.adjusted) {
        //   this.onChange(this.value)
        // }
    };
    TagsInput.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    TagsInput.prototype.isTouchDevice = function () {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            // @ts-ignore
            (navigator.msMaxTouchPoints > 0));
    };
    return TagsInput;
}());
export { TagsInput };
