<div *ngIf="mode === 'search'">
  <div class="btn-toolbar btn-footer clearfix">
    <div class="btn-group">
      <button class="btn btn-default" (click)="export()">
        <i class="fa fa-file-excel-o"></i>
        <span>{{ 'COMMON.EXPORT' | translate }}</span>
      </button>
    </div>
    <div class="btn-group pull-right">
      <button
          type="button"
          class="btn btn-primary"
          (click)="switchToInclusionMode()">
        {{ 'DIVISION.ADD_STUDENTS' | translate }}
      </button>
      <button
          type="button"
          class="btn btn-default"
          (click)="switchToEditionMode()">
        {{ 'DIVISION.CREATE_STUDENT' | translate }}
      </button>
    </div>
  </div>

  <appdatatable
      [rows]="rows"
      [columns]="columns"
      [sorts]="params.sort"
      [count]="count"
      [limit]="params.limit"
      [loading]="loading"
      (page)="onPage($event)"
      (sort)="onSort($event)">
  </appdatatable>
</div>

<div *ngIf="mode === 'edition' && form">
  <form class="form-fixed-help"
      [formGroup]="form"
      (ngSubmit)="submit()">
    <user-panel [form]="form" [isCreate]="isCreate" [dobRequired]="true"></user-panel>

    <div class="row">
      <div class="col-xs-12">
        <div class="btn-toolbar btn-footer" role="toolbar">
          <button
              type="submit"
              class="btn btn-success"
              *ngIf="isSaveAllowed"
              [disabled]="!form.valid"
              [ladda]="isSubmitting">
            {{ 'COMMON.BTN_SAVE' | translate }}
          </button>
          <button
              type="button"
              class="btn btn-default"
              (click)="switchToSearchMode()">
            {{ 'COMMON.BTN_CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="mode === 'inclusion'">
  <div class="form-group">
    <label>
      {{ 'STUDENT.SEARCH_TITLE' | translate }}
      <required-icon></required-icon>
      <help-icon key="DIVISION.INCLUDE_STUDENTS_HELP"></help-icon>
    </label>

    <ng-template #studentDropdownTemplate let-fullName="fullName" let-division="division">
      <span [ngClass]="{'has-division': division}">
        {{ fullName }}
        <span *ngIf="division">
          | {{division.name}}
        </span>
      </span>
    </ng-template>

    <ng-template #studentItemTemplate let-fullName="fullName" let-division="division">
      <div [ngClass]="{'has-division': division}">
        {{ fullName }}
      </div>
    </ng-template>

    <tags-input
        [(ngModel)]="students"
        identifyBy="_id"
        displayBy="fullName"
        [source]="loadStudents"
        [dropdownTemplate]="studentDropdownTemplate"
        [itemTemplate]="studentItemTemplate">
    </tags-input>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <div class="btn-toolbar btn-footer" role="toolbar">
        <button
            type="button"
            class="btn btn-success"
            (click)="include()"
            [disabled]="!students?.length"
            [ladda]="isSubmitting">
          {{ 'COMMON.BTN_SAVE' | translate }}
        </button>
        <button
            type="button"
            class="btn btn-default"
            (click)="switchToSearchMode()">
          {{ 'COMMON.BTN_CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
