import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { ApiService } from './api.service'
import { SearchableService } from './searchable.service'
import { PersistentModel } from './persistent-model'
import { QueryString } from '../models'

export abstract class CrudService<T extends PersistentModel> extends ApiService implements SearchableService<T> {
  protected url: string

  protected constructor (
      protected http: HttpClient,
      protected urlSpecific: string,
      private readonly typeDeserializer: (json: any) => T = (e) => e
  ) {
    super()
    this.url = this.apiUrl + urlSpecific
    this.toModel = this.toModel.bind(this)
  }

  public search (params?: QueryString): Observable<CrudSearchResult<T>> {
    return this.http
        .get(this.url, this.toRequestOptions(params))
        .map((json: any) => CrudSearchResult.fromJson(json, this.typeDeserializer))
  }

  public getById (id: string, params?: QueryString): Observable<T> {
    return this.http.get(`${this.url}/${id}`, this.toRequestOptions(params))
    .map(this.toModel)
  }

  public create (object: any): Observable<any> {
    return this.http.post(this.url, object)
  }

  public update (id: string, object: any): Observable<any> {
    return this.http.patch(`${this.url}/${id}`, object)
  }

  public remove (id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}`)
  }

  protected toModel (res: any): T {
    return res as T
  }

  protected toId (id: string | PersistentModel): string {
    return typeof id === 'string' ? id : id._id
  }
}

export class CrudSearchResult<T> {
  constructor (readonly list: T[], readonly meta: CrudSearchResultMeta) {
  }

  static fromJson<T> (json: any, typeDeserializer: (json: any) => T) {
    if (Array.isArray(json)) {
      const array = json as any[]
      return new CrudSearchResult(
          array.map(typeDeserializer),
          new CrudSearchResultMeta(1, array.length, array.length))
    } else {
      const array = json.list as any[]
      const meta = json?.meta
      return new CrudSearchResult(
          array.map(typeDeserializer),
          new CrudSearchResultMeta(
              meta?.page ?? 1,
              meta?.pageSize ?? array.length,
              meta?.total ?? array.length))
    }
  }
}

export class CrudSearchResultMeta {
  constructor (readonly page: number, readonly pageSize: number, readonly total: number) {
  }
}
