/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./division-students.tab.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../app-datatable/app-datatable.component.ngfactory";
import * as i4 from "../app-datatable/app-datatable.component";
import * as i5 from "angular2-ladda/module/ladda.directive";
import * as i6 from "angular2-ladda/module/ladda-config";
import * as i7 from "@angular/forms";
import * as i8 from "../user-panel/user.panel.ngfactory";
import * as i9 from "../user-panel/user.panel";
import * as i10 from "../../services/view.service";
import * as i11 from "../../services/person.service";
import * as i12 from "@angular/common";
import * as i13 from "../label-icons/required.icon.ngfactory";
import * as i14 from "../label-icons/required.icon";
import * as i15 from "../label-icons/help.icon.ngfactory";
import * as i16 from "../label-icons/help.icon";
import * as i17 from "../tags-input/tags.input.ngfactory";
import * as i18 from "../tags-input/tags.input";
import * as i19 from "./division-students.tab";
import * as i20 from "../../services/division.service";
import * as i21 from "../../services/student.service";
import * as i22 from "../../pipes/username.pipe";
var styles_DivisionStudentsTab = [i0.styles];
var RenderType_DivisionStudentsTab = i1.ɵcrt({ encapsulation: 0, styles: styles_DivisionStudentsTab, data: {} });
export { RenderType_DivisionStudentsTab as RenderType_DivisionStudentsTab };
function View_DivisionStudentsTab_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 24, "div", [["class", "btn-toolbar btn-footer clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.export() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-file-excel-o"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(16, 0, null, null, 9, "div", [["class", "btn-group pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToInclusionMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(19, null, ["\n        ", "\n      "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToEditionMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(23, null, ["\n        ", "\n      "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(28, 0, null, null, 3, "appdatatable", [], null, [[null, "page"], [null, "sort"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("sort" === en)) {
        var pd_1 = (_co.onSort($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_AppDataTableComponent_0, i3.RenderType_AppDataTableComponent)), i1.ɵprd(135680, null, i2.TranslatePipe, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵdid(30, 49152, null, 0, i4.AppDataTableComponent, [], { loading: [0, "loading"], rows: [1, "rows"], limit: [2, "limit"], count: [3, "count"], columns: [4, "columns"], sorts: [5, "sorts"] }, { pageEmitter: "page", sortEmitter: "sort" }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.loading; var currVal_4 = _co.rows; var currVal_5 = _co.params.limit; var currVal_6 = _co.count; var currVal_7 = _co.columns; var currVal_8 = _co.params.sort; _ck(_v, 30, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("COMMON.EXPORT")); _ck(_v, 11, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("DIVISION.ADD_STUDENTS")); _ck(_v, 19, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("DIVISION.CREATE_STUDENT")); _ck(_v, 23, 0, currVal_2); }); }
function View_DivisionStudentsTab_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-success"], ["type", "submit"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i5.LaddaDirective, [i1.ElementRef, [2, i6.LaddaConfig]], { loading: [0, "loading"], disabled: [1, "disabled"] }, null), (_l()(), i1.ɵted(2, null, ["\n            ", "\n          "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSubmitting; var currVal_1 = !_co.form.valid; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.BTN_SAVE")); _ck(_v, 2, 0, currVal_2); }); }
function View_DivisionStudentsTab_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 25, "form", [["class", "form-fixed-help"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.submit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i7.ɵbf, [], null, null), i1.ɵdid(4, 540672, null, 0, i7.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i7.ControlContainer, null, [i7.FormGroupDirective]), i1.ɵdid(6, 16384, null, 0, i7.NgControlStatusGroup, [i7.ControlContainer], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "user-panel", [], null, null, null, i8.View_UserPanel_0, i8.RenderType_UserPanel)), i1.ɵprd(135680, null, i2.TranslatePipe, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵdid(10, 573440, null, 0, i9.UserPanel, [i10.ViewService, i11.PersonService], { form: [0, "form"], isCreate: [1, "isCreate"], dobRequired: [2, "dobRequired"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(12, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(14, 0, null, null, 11, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(16, 0, null, null, 8, "div", [["class", "btn-toolbar btn-footer"], ["role", "toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DivisionStudentsTab_3)), i1.ɵdid(19, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToSearchMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(22, null, ["\n            ", "\n          "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.isCreate; var currVal_10 = true; _ck(_v, 10, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.isSaveAllowed; _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_12 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("COMMON.BTN_CANCEL")); _ck(_v, 22, 0, currVal_12); }); }
function View_DivisionStudentsTab_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n          | ", "\n        "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.division.name; _ck(_v, 1, 0, currVal_0); }); }
function View_DivisionStudentsTab_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 6, "span", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i12.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "has-division": 0 }), (_l()(), i1.ɵted(4, null, ["\n        ", "\n        "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DivisionStudentsTab_6)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.division); _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.division; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.context.fullName; _ck(_v, 4, 0, currVal_1); }); }
function View_DivisionStudentsTab_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i12.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "has-division": 0 }), (_l()(), i1.ɵted(4, null, ["\n        ", "\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.division); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.fullName; _ck(_v, 4, 0, currVal_1); }); }
function View_DivisionStudentsTab_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 44, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 23, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["\n      ", "\n      "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "required-icon", [], null, null, null, i13.View_RequiredIcon_0, i13.RenderType_RequiredIcon)), i1.ɵdid(8, 49152, null, 0, i14.RequiredIcon, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "help-icon", [["key", "DIVISION.INCLUDE_STUDENTS_HELP"]], null, null, null, i15.View_HelpIcon_0, i15.RenderType_HelpIcon)), i1.ɵdid(11, 49152, null, 0, i16.HelpIcon, [], { key: [0, "key"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(0, [["studentDropdownTemplate", 2]], null, 0, null, View_DivisionStudentsTab_5)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵand(0, [["studentItemTemplate", 2]], null, 0, null, View_DivisionStudentsTab_7)), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵeld(18, 0, null, null, 6, "tags-input", [["displayBy", "fullName"], ["identifyBy", "_id"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.students = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_TagsInput_0, i17.RenderType_TagsInput)), i1.ɵdid(19, 49152, null, 0, i18.TagsInput, [], { identifyBy: [0, "identifyBy"], displayBy: [1, "displayBy"], source: [2, "source"], dropdownTemplate: [3, "dropdownTemplate"], itemTemplate: [4, "itemTemplate"] }, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i18.TagsInput]), i1.ɵdid(21, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [2, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(23, 16384, null, 0, i7.NgControlStatus, [i7.NgControl], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(27, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(29, 0, null, null, 13, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(31, 0, null, null, 10, "div", [["class", "btn-toolbar btn-footer"], ["role", "toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(33, 0, null, null, 3, "button", [["class", "btn btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.include() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(34, 737280, null, 0, i5.LaddaDirective, [i1.ElementRef, [2, i6.LaddaConfig]], { loading: [0, "loading"], disabled: [1, "disabled"] }, null), (_l()(), i1.ɵted(35, null, ["\n          ", "\n        "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(38, 0, null, null, 2, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToSearchMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(39, null, ["\n          ", "\n        "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "DIVISION.INCLUDE_STUDENTS_HELP"; _ck(_v, 11, 0, currVal_1); var currVal_9 = "_id"; var currVal_10 = "fullName"; var currVal_11 = _co.loadStudents; var currVal_12 = i1.ɵnov(_v, 14); var currVal_13 = i1.ɵnov(_v, 16); _ck(_v, 19, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.students; _ck(_v, 21, 0, currVal_14); var currVal_15 = _co.isSubmitting; var currVal_16 = !((_co.students == null) ? null : _co.students.length); _ck(_v, 34, 0, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("STUDENT.SEARCH_TITLE")); _ck(_v, 5, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 23).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 23).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 23).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 23).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 23).ngClassValid; var currVal_7 = i1.ɵnov(_v, 23).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 23).ngClassPending; _ck(_v, 18, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_17 = i1.ɵunv(_v, 35, 0, i1.ɵnov(_v, 36).transform("COMMON.BTN_SAVE")); _ck(_v, 35, 0, currVal_17); var currVal_18 = i1.ɵunv(_v, 39, 0, i1.ɵnov(_v, 40).transform("COMMON.BTN_CANCEL")); _ck(_v, 39, 0, currVal_18); }); }
export function View_DivisionStudentsTab_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DivisionStudentsTab_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DivisionStudentsTab_2)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DivisionStudentsTab_4)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mode === "search"); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.mode === "edition") && _co.form); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.mode === "inclusion"); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_DivisionStudentsTab_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "division-students-tab", [], null, null, null, View_DivisionStudentsTab_0, RenderType_DivisionStudentsTab)), i1.ɵdid(1, 49152, null, 0, i19.DivisionStudentsTab, [i10.ViewService, i20.DivisionService, i21.StudentService, i22.UsernamePipe], null, null)], null, null); }
var DivisionStudentsTabNgFactory = i1.ɵccf("division-students-tab", i19.DivisionStudentsTab, View_DivisionStudentsTab_Host_0, { divisionId: "divisionId" }, {}, []);
export { DivisionStudentsTabNgFactory as DivisionStudentsTabNgFactory };
