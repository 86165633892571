import { Component } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { Topic } from "../../interfaces";

@Component({
    selector: 'recipients-list-modal',
    templateUrl: './recipients-list-modal.html'
})

export class RecipientsListModal {
    topic!: Topic;

    constructor(public bsModalRef: BsModalRef) { }

    dismiss() {
        this.bsModalRef.hide()
    }
}