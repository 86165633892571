import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { StageDependantCrudService, User } from '../interfaces'
import { AuthService } from './auth.service'

@Injectable()
export class SupervisorService extends StageDependantCrudService<User> {

  constructor (http: HttpClient, authService: AuthService) {
    super(http, '/supervisors', authService)
  }
}
