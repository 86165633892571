import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'
import { ShortDateTimePipe, BatchJobTypePipe } from '../../pipes'
import { BatchJobService, ViewService } from '../../services'
import { SearchView, BatchJob } from '../../interfaces'
import { Column } from '../../models'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'

@Component({
  selector: 'app-batch-jobs',
  templateUrl: './file-jobs.component.html',
  providers: [TranslatePipe]
})

export class FileJobsComponent extends SearchView<BatchJob> {
  public columns: Column[]


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'batchJobTypes' has no initializer and is... Remove this comment to see the full error message
  public batchJobTypes: any[]


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'batchJobStatuses' has no initializer and... Remove this comment to see the full error message
  public batchJobStatuses: any[]
  public batchJobType: any = ''
  private BATCH_JOB_TYPES = [
    biz.BATCH_JOB_TYPES.importStudents
  ]

  constructor (
    viewService: ViewService,
    route: ActivatedRoute,
    service: BatchJobService,
    translatePipe: TranslatePipe
  ) {
    super(viewService, route, service, 'batch-jobs', 'batch-job')

    this.columns = [
      Column.forClick('createdAt', 'BATCH_JOB.DATE', this.onSelect.bind(this), true, new ShortDateTimePipe(), 140),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      Column.forClick('data.original_filename', 'BATCH_JOB.FILE', this.onSelect.bind(this), false, null, null, 200),
      new Column('type', 'BATCH_JOB.TYPE', true, new BatchJobTypePipe(translatePipe), 'defaultLabelCell'),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('status', 'BATCH_JOB.STATUS', true, null, 'batchJobStatusCell'),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('stats', 'BATCH_JOB.PROGRESS', false, null, 'batchJobProgressCell'),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('stats.errors', 'BATCH_JOB.ERRORS', false, null, 'dangerBadgeCell')
    ]

    // default sort
    this.params.sort = { createdAt: -1 }
    this.params.populate = 'createdBy'
    this.params.select = 'createdAt type status data stats'
    this.defaultConditions.type = {
      $in: this.BATCH_JOB_TYPES
    }
  }

  public onTypeChange () {
    // doing this so default dropdown option remains selected even after assign the default condition
    this.params.conditions.type = this.batchJobType
    this.updateFilter()
  }

  protected onLangReady () {
    this.batchJobTypes = this.viewService.optionalList(this.BATCH_JOB_TYPES, '')
    this.batchJobStatuses = this.viewService.optionalList(biz.values(biz.BATCH_JOB_STATUSES), undefined)
  }

  protected fromQueryString (qs: any) {
    super.fromQueryString(qs)
    if (this.params.conditions.type) {
      this.batchJobType = this.params.conditions.type
    }
  }
}
