import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../../environments/environment'
import { DetailView, Album, AlbumValidator, AlbumFormGroup, Division } from '../../interfaces'
import { ViewService, AlbumService, DivisionService } from '../../services'

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html'
})
export class AlbumComponent extends DetailView<Album> {
  public divisions: Division[] = []
  public preset = environment.CLOUDINARY.ALBUMS_PRESET
  public tags: string

  constructor (
    viewService: ViewService,
    service: AlbumService,
    route: ActivatedRoute,
    private divisionService: DivisionService
  ) {
    super(viewService, service, route, 'employee', AlbumValidator.newFormGroup(), false)
    this.tags = `album, topic, ${this.viewService.getTenant()._id}`
  }

  ngOnInit () {
    super.ngOnInit()
    if (this.isCreate) {
      this.divisionService.search().subscribe(divisions => {
        this.divisions = divisions.list
      })
    }
  }

  protected getValue () {
    return this.form.value
  }

  public onFileUpload (res: any) {
    (this.form as AlbumFormGroup).addAttachment(res)
  }

  public removeAttachment (i: any) {
    (this.form as AlbumFormGroup).removeAttachmentAt(i)
  }
}
