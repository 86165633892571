var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { ShortDateTimePipe, BatchJobTypePipe } from '../../pipes';
import { BatchJobService, ViewService } from '../../services';
import { SearchView } from '../../interfaces';
import { Column } from '../../models';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
var FileJobsComponent = /** @class */ (function (_super) {
    __extends(FileJobsComponent, _super);
    function FileJobsComponent(viewService, route, service, translatePipe) {
        var _this = _super.call(this, viewService, route, service, 'batch-jobs', 'batch-job') || this;
        _this.batchJobType = '';
        _this.BATCH_JOB_TYPES = [
            biz.BATCH_JOB_TYPES.importStudents
        ];
        _this.columns = [
            Column.forClick('createdAt', 'BATCH_JOB.DATE', _this.onSelect.bind(_this), true, new ShortDateTimePipe(), 140),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            Column.forClick('data.original_filename', 'BATCH_JOB.FILE', _this.onSelect.bind(_this), false, null, null, 200),
            new Column('type', 'BATCH_JOB.TYPE', true, new BatchJobTypePipe(translatePipe), 'defaultLabelCell'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('status', 'BATCH_JOB.STATUS', true, null, 'batchJobStatusCell'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('stats', 'BATCH_JOB.PROGRESS', false, null, 'batchJobProgressCell'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('stats.errors', 'BATCH_JOB.ERRORS', false, null, 'dangerBadgeCell')
        ];
        // default sort
        _this.params.sort = { createdAt: -1 };
        _this.params.populate = 'createdBy';
        _this.params.select = 'createdAt type status data stats';
        _this.defaultConditions.type = {
            $in: _this.BATCH_JOB_TYPES
        };
        return _this;
    }
    FileJobsComponent.prototype.onTypeChange = function () {
        // doing this so default dropdown option remains selected even after assign the default condition
        this.params.conditions.type = this.batchJobType;
        this.updateFilter();
    };
    FileJobsComponent.prototype.onLangReady = function () {
        this.batchJobTypes = this.viewService.optionalList(this.BATCH_JOB_TYPES, '');
        this.batchJobStatuses = this.viewService.optionalList(biz.values(biz.BATCH_JOB_STATUSES), undefined);
    };
    FileJobsComponent.prototype.fromQueryString = function (qs) {
        _super.prototype.fromQueryString.call(this, qs);
        if (this.params.conditions.type) {
            this.batchJobType = this.params.conditions.type;
        }
    };
    return FileJobsComponent;
}(SearchView));
export { FileJobsComponent };
