var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { Column } from '../../models';
import { DivisionService, ViewService } from '../../services';
import { SearchView } from '../../interfaces';
var DivisionsComponent = /** @class */ (function (_super) {
    __extends(DivisionsComponent, _super);
    function DivisionsComponent(viewService, route, service) {
        var _this = _super.call(this, viewService, route, service, 'divisions', 'division', 'create') || this;
        _this.columns = [
            _this.routerColumn('name', 'DIVISION.NAME', { isPrimary: true }),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('students.length', 'STUDENT.SEARCH_TITLE', true, null, 'defaultBadgeCell', 120, 120),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('shift', 'DIVISION.SHIFT', true, null, 'schoolShiftCell', 120, 120),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('grade.name', 'GRADE.DETAIL_TITLE', true, null, null, 120, 120)
        ];
        // default sort
        _this.params.populate = {
            grade: 'name'
        };
        _this.params.sort = { shift: 1, name: 1 };
        return _this;
    }
    DivisionsComponent.prototype.onLangReady = function () {
        this.shifts = this.viewService.optionalList(biz.values(biz.SCHOOL_SHIFTS), '');
    };
    return DivisionsComponent;
}(SearchView));
export { DivisionsComponent };
