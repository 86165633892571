var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnChanges, SimpleChanges } from '@angular/core';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { Column } from '../../models';
import { ViewService, StudentService } from '../../services';
import { SearchPanel, UserValidator, CrudService } from '../../interfaces';
var StudentGuardiansTab = /** @class */ (function (_super) {
    __extends(StudentGuardiansTab, _super);
    function StudentGuardiansTab(viewService, service) {
        var _this = _super.call(this, viewService, 'guardian') || this;
        _this.service = service;
        _this.editable = false;
        _this.isSaveAllowed = false;
        _this.isSubmitting = false;
        _this.isRemoving = false;
        _this.isSaveAllowed = _this.isCreateAllowed;
        return _this;
    }
    // @ts-expect-error ts-migrate(6133) FIXME: 'changes' is declared but its value is never read.
    StudentGuardiansTab.prototype.ngOnChanges = function (changes) {
        if (this.studentId) {
            this.createColumns();
            this.updateFilter();
        }
    };
    StudentGuardiansTab.prototype.switchToEditionMode = function (guardian) {
        if (guardian === void 0) { guardian = {
            gender: biz.GENDERS.FEMALE
        }; }
        this.form = UserValidator.newGuardianFormGroup();
        this.form.patchValue(guardian);
        this.isCreate = !!guardian;
        // @ts-expect-error ts-migrate(6133) FIXME: 'permission' is declared but its value is never re... Remove this comment to see the full error message
        var permission = this.isCreate ? 'create' : 'update';
    };
    StudentGuardiansTab.prototype.cancelEditionMode = function () {
        this.createColumns();
        this.form.reset();
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'UserFo... Remove this comment to see the full error message
        this.form = undefined;
        this.updateFilter();
    };
    StudentGuardiansTab.prototype.saveGuardian = function () {
        var _this = this;
        if (!this.isSaveAllowed) {
            console.warn('User not allowed to save changes');
            return;
        }
        else if (!this.form || !this.form.valid) { // be extra sure
            return this.viewService.toastr.warningT('COMMON.MSG_INVALID_FORM');
        }
        this.isSubmitting = true;
        var onSuccess = function () { _this.cancelEditionMode(); };
        var onFinally = function () { _this.isSubmitting = false; };
        var model = this.form.value;
        var service = new GuardianWrapperService(this.studentId, this.service);
        var guardianId = !this.isCreate && model._id;
        this.viewService.save(guardianId, service, this.form, onSuccess, onFinally, model);
    };
    StudentGuardiansTab.prototype.onSelect = function (selected) {
        return this.viewService.goToDetail('guardians', 'guardian', selected.guardian);
    };
    StudentGuardiansTab.prototype.fetch = function (params) {
        return this.service.getGuardians(this.studentId, params);
    };
    StudentGuardiansTab.prototype.createColumns = function () {
        var canRemove = this.viewService.isCurrentTermSelected();
        var actions = [{
                class: 'fa-times action-icon-danger',
                onClick: this.onRemove.bind(this),
                visible: function () { return canRemove; }
            }];
        this.columns = [
            Column.forClick('guardian._id', 'USER.ID', this.onSelect.bind(this)),
            Column.forClick('guardian.name.first', 'USER.FIRST_NAME', this.onSelect.bind(this)),
            Column.forClick('guardian.name.last', 'USER.LAST_NAME', this.onSelect.bind(this)),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('relationship', 'STUDENT.GUARDIAN_RELATIONSHIP', false, null, 'defaultLabelCell'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('guardian.contacts.0', 'USER.CONTACTS', false, null, 'contactCell'),
            Column.forActions(actions, 50)
        ];
    };
    StudentGuardiansTab.prototype.onRemove = function (row) {
        var _this = this;
        this.viewService.askConfirmation('COMMON.MSG_CONFIRM_REMOVE')
            .subscribe(function (confirmed) {
            if (confirmed) {
                _this.isRemoving = true;
                _this.service.removeGuardian(_this.studentId, row.guardian._id)
                    .finally(function () { return _this.isRemoving = false; })
                    .subscribe(
                // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
                function (data) {
                    _this.viewService.toastr.successT('COMMON.MSG_OPERATION_OK');
                    _this.getItems();
                }, _this.viewService.handleError);
            }
        });
    };
    return StudentGuardiansTab;
}(SearchPanel));
export { StudentGuardiansTab };
var GuardianWrapperService = /** @class */ (function (_super) {
    __extends(GuardianWrapperService, _super);
    function GuardianWrapperService(id, service) {
        var _this = 
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        _super.call(this, null, '') || this;
        _this.id = id;
        _this.service = service;
        return _this;
    }
    GuardianWrapperService.prototype.create = function (object) {
        return this.service.addGuardian(this.id, object);
    };
    GuardianWrapperService.prototype.update = function (id, object) {
        return this.service.updateGuardian(this.id, id, object);
    };
    return GuardianWrapperService;
}(CrudService));
