var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { NameValidator } from './name';
import { ContactValidator } from './contact';
var UserFormGroup = /** @class */ (function (_super) {
    __extends(UserFormGroup, _super);
    function UserFormGroup() {
        var _this = this;
        var fb = new FormBuilder();
        _this = _super.call(this, {
            _id: fb.control('', [Validators.required, Validators.minLength(biz.ID_MIN_LENGTH)]),
            name: NameValidator.newFormGroup(),
            dob: fb.control(''),
            gender: fb.control(''),
            contacts: fb.array([])
        }) || this;
        return _this;
    }
    UserFormGroup.prototype.patchValue = function (value, options) {
        _super.prototype.patchValue.call(this, value, options);
        this.controls.contacts.reset();
        if (value.contacts) {
            value.contacts.forEach(this.addContact.bind(this));
        }
    };
    UserFormGroup.prototype.reset = function () {
        _super.prototype.reset.call(this);
        var contactForms = this.getContactForms();
        while (contactForms.length !== 0) {
            contactForms.removeAt(0);
        }
    };
    UserFormGroup.prototype.addContact = function (contact) {
        var contactForm = ContactValidator.newFormGroup(contact);
        this.getContactForms().push(contactForm);
    };
    UserFormGroup.prototype.removeContactAt = function (index) {
        this.getContactForms().removeAt(index);
    };
    UserFormGroup.prototype.getContactForms = function () {
        return this.controls.contacts;
    };
    return UserFormGroup;
}(FormGroup));
export { UserFormGroup };
var UserValidator = /** @class */ (function () {
    function UserValidator() {
    }
    UserValidator.newFormGroup = function () {
        return new UserFormGroup();
    };
    UserValidator.newStudentFormGroup = function () {
        // @ts-expect-error ts-migrate(6133) FIXME: 'fb' is declared but its value is never read.
        var fb = new FormBuilder();
        var form = UserValidator.newFormGroup();
        form.controls.dob.setValidators(Validators.required);
        return form;
    };
    UserValidator.newTeacherFormGroup = function () {
        // @ts-expect-error ts-migrate(6133) FIXME: 'fb' is declared but its value is never read.
        var fb = new FormBuilder();
        var form = UserValidator.newFormGroup();
        return form;
    };
    UserValidator.newGuardianFormGroup = function () {
        var fb = new FormBuilder();
        var form = UserValidator.newFormGroup();
        form.addControl('relationship', fb.control('', Validators.required));
        return form;
    };
    return UserValidator;
}());
export { UserValidator };
