import { Component } from '@angular/core'


// @ts-expect-error ts-migrate(6133) FIXME: 'Params' is declared but its value is never read.
import { ActivatedRoute, Params } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'
import { BatchJobService, ViewService } from '../../services'
import { BatchJob, DetailView } from '../../interfaces'

@Component({
  selector: 'app-batch-job',
  templateUrl: './batch-job.component.html',
  providers: [TranslatePipe]
})

export class BatchJobComponent extends DetailView<BatchJob> {

  constructor (
    viewService: ViewService,
    service: BatchJobService,
    route: ActivatedRoute
  ) {


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    super(viewService, service, route, 'batch-job', null)
    this.options.populate = 'createdBy'
  }
}
