

// @ts-expect-error ts-migrate(6133) FIXME: 'OnInit' is declared but its value is never read.
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'help-icon',
  template: '<i class="fa fa-question-circle icon-help" aria-hidden="true" tooltip="{{ key | translate }}" containerClass="tooltip-help"></i>'
})

export class HelpIcon {
  @Input() key: any
}
