import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Params } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'
import { TopicService, ViewService } from '../../services'
import { Topic } from '../../interfaces'

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  providers: [TranslatePipe]
})

export class FeedComponent implements OnInit {
  public pageData: any = {}
  public topics: Topic[] = []


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'studentId' has no initializer and is not... Remove this comment to see the full error message
  public studentId: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'nextCursor' has no initializer and is no... Remove this comment to see the full error message
  public nextCursor: string
  public hasMore: boolean = true
  public locale: string

  constructor (
    private service: TopicService,
    private viewService: ViewService,
    private route: ActivatedRoute
  ) {
    this.route.data.subscribe(pageData => { this.pageData = pageData })
    this.locale = viewService.getCurrentUser().locale
  }

  public ngOnInit () {
    this.route.params
    .subscribe(this.onParamsChange.bind(this))
  }

  private onParamsChange (params: Params) {


    this.studentId = params.id
    this.getNextPage()
  }

  public reset () {
    this.topics = []


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string'.
    this.nextCursor = null
    this.hasMore = true
    this.getNextPage()
  }

  public getNextPage () {
    if (!this.hasMore) {
      return
    }

    this.service.loadFeed(this.studentId, this.nextCursor)
    .subscribe(
      result => {
        result.results.forEach((topic: any) => {
          this.topics.push(topic)
        })
        this.hasMore = result.hasNext
        this.nextCursor = result.next
      },
      this.viewService.handleError
    )
  }
}
