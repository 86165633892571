var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs/Rx';
import * as _ from 'lodash-es';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { StoreService } from './store.service';
// @ts-expect-error ts-migrate(6133) FIXME: 'Tenant' is declared but its value is never read.
import { ApiService, LoggedUser, TenantSummary, Term, Stage, TermAndStage } from '../interfaces';
var TOKEN_KEY = 'token';
var USER_KEY = 'user';
var TERMS_KEY = 'terms';
var TERM_KEY = 'term';
var STAGE_KEY = 'stage';
var PERMISSIONS_KEY = 'permissions';
var DEVICE = { type: biz.DEVICE_TYPES.WEB };
var AuthService = /** @class */ (function (_super) {
    __extends(AuthService, _super);
    function AuthService(router, http, storeService, jwtHelper) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.http = http;
        _this.storeService = storeService;
        _this.jwtHelper = jwtHelper;
        _this.url = _this.baseUrl + '/auth';
        // init selected term when refreshing page while still logged in
        if (_this.isLoggedIn()) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
            _this.termAndStageSubject = new BehaviorSubject(undefined);
            var termId_1 = _this.storeService.getItem(TERM_KEY);
            var found = _this.initSelectedTerm(function (term) { return term._id === termId_1; });
            if (!found) {
                _this.initSelectedTerm(function (term) { return term.current; });
            }
        }
        return _this;
    }
    AuthService.prototype.saveData = function (token, user, permissions, terms, isSwitchTenant) {
        if (isSwitchTenant === void 0) { isSwitchTenant = false; }
        // discard terms without stages, just in case
        terms = terms.filter(function (term) { return term && term.stages && !!term.stages.length; });
        this.storeService.setItem(TOKEN_KEY, token);
        this.storeService.setJson(USER_KEY, user);
        this.storeService.setJson(TERMS_KEY, terms);
        this.storeService.setJson(PERMISSIONS_KEY, __assign(__assign({}, permissions), { 'report-card': ['get', 'update'] })); // TODO remove hardcoded permission
        if (isSwitchTenant) {
            var term = this.getTerms().find(function (term) { return term.current; });
            this.storeService.setItem(STAGE_KEY, term.stages[0] && term.stages[0]._id);
        }
        // init selected term upon a new login
        this.initSelectedTerm(function (term) { return term.current; });
    };
    AuthService.prototype.isLoggedIn = function () {
        var token = this.getCurrentToken();
        var user = this.getCurrentUser();
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | boolean' is not assignable to type ... Remove this comment to see the full error message
        return token && !this.jwtHelper.isTokenExpired(token) && !user.forcePassword;
    };
    // @ts-expect-error ts-migrate(6133) FIXME: 'remember' is declared but its value is never read... Remove this comment to see the full error message
    AuthService.prototype.login = function (body, remember) {
        var _this = this;
        body.internal = true;
        body.device = DEVICE;
        return this.http.post(this.url + "/login", body)
            .map(function (body) {
            if (body.user.isSuperuser) {
                var err = new Error('BERR-X0007');
                err.berrCode = 'BERR-X0007';
                throw err;
            }
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
            _this.termAndStageSubject = new BehaviorSubject(undefined);
            _this.saveData(body.token, body.user, body.permissions, body.terms);
            return body.user;
        });
    };
    AuthService.prototype.logout = function () {
        this.storeService.clear();
        this.termAndStageSubject.complete();
        delete this.termAndStageSubject;
        return this.router.navigate(['/login']);
    };
    AuthService.prototype.getCurrentUser = function () {
        return this.storeService.getJson(USER_KEY);
    };
    AuthService.prototype.getCurrentToken = function () {
        return this.storeService.getItem(TOKEN_KEY);
    };
    AuthService.prototype.hasUserRole = function (role) {
        var user = this.getCurrentUser();
        return user.currentRole === role;
    };
    AuthService.prototype.isSuperUser = function () {
        return this.getCurrentUser().isSuperuser;
    };
    AuthService.prototype.isAllowed = function (resource, action) {
        if (this.isSuperUser()) {
            return true;
        }
        else {
            return _.includes(this.getPermissions()[resource], action);
        }
    };
    AuthService.prototype.isCurrentUser = function (userId) {
        return userId === this.getCurrentUser()._id;
    };
    AuthService.prototype.getTenant = function () {
        var user = this.getCurrentUser();
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'TenantSummary | undefined' is not assignable... Remove this comment to see the full error message
        return user && user.currentTenant;
    };
    AuthService.prototype.getTerms = function () {
        return this.storeService.getJson(TERMS_KEY);
    };
    AuthService.prototype.getSelectedTerm = function () {
        // const terms = this.getTerms()
        // return terms.find(term => term._id === this.selectedTerm)
        return this.selectedTerm;
    };
    AuthService.prototype.changeSelectedTerm = function (termId, initStage) {
        if (initStage === void 0) { initStage = false; }
        var terms = this.getTerms();
        var term = terms.find(function (term) { return term._id === termId; });
        if (term) {
            this.selectedTerm = term;
            this.storeService.setItem(TERM_KEY, term._id);
            var stageId = void 0;
            if (initStage) {
                stageId = this.storeService.getItem(STAGE_KEY);
            }
            if (!stageId) {
                stageId = term.stages[0] && term.stages[0]._id;
            }
            this.changeSelectedStage(stageId, initStage);
        }
    };
    AuthService.prototype.getSelectedStage = function () {
        return this.selectedStage;
    };
    AuthService.prototype.changeSelectedStage = function (stageId, initStage) {
        if (initStage === void 0) { initStage = false; }
        var term = this.getSelectedTerm();
        var stage = term && term.stages.find(function (stage) { return stage._id === stageId; });
        if (stage) {
            this.selectedStage = stage;
            this.storeService.setItem(STAGE_KEY, stage._id);
            this.termAndStageSubject.next({ term: term, stage: stage, initial: initStage });
        }
    };
    AuthService.prototype.subscribeToStage = function (observer) {
        return this.termAndStageSubject.subscribe(observer);
    };
    AuthService.prototype.isCurrentTermSelected = function () {
        var currentTerm = this.getSelectedTerm();
        if (!currentTerm) {
            return false;
        }
        return currentTerm.current;
    };
    // @ts-expect-error ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
    AuthService.prototype.initSelectedTerm = function (predicate) {
        var term = this.getTerms().find(predicate);
        if (term) {
            this.changeSelectedTerm(term._id, true);
            return true;
        }
    };
    AuthService.prototype.getPermissions = function () {
        return this.storeService.getJson(PERMISSIONS_KEY) || {};
    };
    return AuthService;
}(ApiService));
export { AuthService };
