import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { StageDependantCrudService, Subject } from '../interfaces'
import { AuthService } from './auth.service'

@Injectable()
export class SubjectService extends StageDependantCrudService<Subject> {

  constructor (http: HttpClient, auth: AuthService) {
    super(http, '/subjects', auth)
  }
}
