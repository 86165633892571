import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
// @ts-expect-error ts-migrate(6133) FIXME: 'User' is declared but its value is never read.
import { Language } from '../interfaces';
var AppTranslateService = /** @class */ (function () {
    function AppTranslateService(translate, http) {
        this.translate = translate;
        this.http = http;
        this.languages = [];
        window.moment = moment;
        this.initLanguages();
        var codes = this.languages.map(function (lang) { return lang.code; });
        translate.addLangs(codes);
        // this language will be used as a fallback when a translation isn't found in the current language
        this.use(biz.DEFAULT_LOCALE);
        // this.switch(biz.DEFAULT_LOCALE).subscribe(console.log, console.error)
        /*this.userServ.currentUser.subscribe(( user: User ) => {
          this.currentUser = user;
    
          // the lang to use, if the lang isn't available, it will use the current loader to get them
          let browserLang = this.translate.getBrowserLang();
          let browserCultureLang = this.translate.getBrowserCultureLang();
          console.log('Detected browser language: "' + browserCultureLang + '"');
    
          // check if current User has a Preferred Language set, and it differs from his browser lang
          let useLang = 'en';
          let prefLang = ( this.currentUser ) ? this.currentUser.locale : null;
          if ( !prefLang ) {
            useLang = browserLang.match( langmatch ) ? browserLang : 'en';
          } else {
            console.log('Detected User preferred language: "' + prefLang + '"');
            useLang = prefLang.match( langmatch ) ? prefLang : 'en';
          }
          this.translate.use( useLang );
          console.log('Translation language has been set to: "' + useLang + '"');
          // translate.use('ru');
        });*/
    }
    AppTranslateService.prototype.getLanguages = function () {
        return this.languages;
    };
    AppTranslateService.prototype.switch = function (language) {
        var _this = this;
        if (this.translate.currentLang === language) {
            return Observable.of(language);
        }
        var momentLocales = moment.locales();
        var found = momentLocales.find(function (loc) { return loc === language; });
        if (found) {
            this.use(language);
            return Observable.of(language);
        }
        // lazily load translations for moment
        return this.http.get("/public/assets/i18n/moment/" + language + ".js", { responseType: 'text' })
            .map(function (response) {
            try {
                eval.call(window, response);
            }
            catch (err) {
                console.error("Error applying date translations for " + language);
            }
            _this.use(language);
            return language;
        })
            // @ts-expect-error ts-migrate(6133) FIXME: 'err' is declared but its value is never read.
            .catch(function (err) {
            _this.use(language);
            return language;
        });
    };
    AppTranslateService.prototype.getTranslate = function (key) {
        var interpolateParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            interpolateParams[_i - 1] = arguments[_i];
        }
        return this.translate.get(key, interpolateParams);
    };
    AppTranslateService.prototype.onReady = function () {
        var currentLang = this.translate.currentLang;
        return this.translate.getTranslation(currentLang);
    };
    AppTranslateService.prototype.use = function (language) {
        moment.locale(language);
        this.translate.use(language);
    };
    AppTranslateService.prototype.initLanguages = function () {
        var newLanguage = {
            code: 'en',
            name: 'English'
        };
        this.languages.push(newLanguage);
        newLanguage = {
            code: 'es',
            name: 'Español'
        };
        this.languages.push(newLanguage);
    };
    return AppTranslateService;
}());
export { AppTranslateService };
