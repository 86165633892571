import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { TeacherService, ViewService } from '../../services'
import { DetailView, Teacher, UserValidator } from '../../interfaces'

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html'
})

export class TeacherComponent extends DetailView<Teacher> {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'isForcePasswordAllowed' has no initializ... Remove this comment to see the full error message
  public isForcePasswordAllowed: boolean

  constructor (
    viewService: ViewService,
    service: TeacherService,
    route: ActivatedRoute
  ) {
    super(viewService, service, route, 'teacher', UserValidator.newTeacherFormGroup(), false)
    this.deletePermission = 'remove'
  }

  protected getValue () {
    return this.form.value
  }

  protected getDefaultDetails (): Teacher {
    return {
      gender: biz.GENDERS.FEMALE
    } as Teacher
  }

  protected checkPermissions () {
    super.checkPermissions()
    // overwrite permissions
    const isCurrentUser = this.viewService.isCurrentUser(this._id)
    this.isDeleteAllowed = !isCurrentUser && this.isDeleteAllowed
    this.isForcePasswordAllowed = !isCurrentUser && !this.isCreate && this.viewService.isAllowed('tenant', 'force-member-password')
  }
}
