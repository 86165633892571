'use strict'

// constants related to the app/company

module.exports = {
  name: 'Nexedu',
  website: 'https://nexedu.com.ar',
  motto: 'El nexo educativo',
  emails: {
    logo: 'https://bit.ly/2p3qa68',
    domain: 'nexedu.com.ar'
  },
  social: {
    facebook: 'https://www.facebook.com/nexedu',
    twitter: '',
    instagram: ''
  }
}
