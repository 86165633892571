import { Component, Input, SimpleChanges, OnChanges } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { Column, QueryString } from '../../models'
import { ViewService, BatchJobService } from '../../services'
import { TranslateErrorPipe } from '../../pipes'
import { SearchPanel } from '../../interfaces'

@Component({
  selector: 'batch-job-errors-tab',
  templateUrl: './batch-job-errors.tab.html',
  providers: [TranslatePipe]
})

export class BatchJobErrorsTab extends SearchPanel<any> implements OnChanges {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'batchJob' has no initializer and is not ... Remove this comment to see the full error message
  @Input() batchJob: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'status' has no initializer and is not de... Remove this comment to see the full error message
  @Input() status: string
  public isInProgress = false
  public columns: Column []

  constructor (
    viewService: ViewService,
    private service: BatchJobService,
    translatePipe: TranslatePipe
  ) {
    super(viewService, 'batch-error')

    this.columns = [


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('step', 'BATCH_JOB_ERROR.STEP', true, null, null, null, 80),
      new Column('failure', 'BATCH_JOB_ERROR.FAILURE', false, new TranslateErrorPipe(translatePipe)),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('details', null, false, null, 'errorsDetailCell', null, 50, 'hideColumnHeader')
    ]

    this.params.sort = {
      step: 1
    }
  }

  public ngOnChanges (changes: SimpleChanges) {


    if (!changes.batchJob || !changes.batchJob.currentValue) {
      this.count = 0
      this.rows = []
    } else {
      this.params.conditions.batchJob = this.batchJob
      this.updateFilter()
    }


    if (changes.status) {
      this.isInProgress = this.status !== biz.BATCH_JOB_STATUSES.completed && this.status !== biz.BATCH_JOB_STATUSES.failed
    }
  }

  public exportList () {
    this.service.exportErrors(this.batchJob)
    .subscribe(
      () => {
        this.viewService.toastr.successT('COMMON.MSG_REPORT_OK')
      },
      this.viewService.handleError
    )
  }

  protected fetch (params: QueryString): Observable<any> {
    return this.service.getErrorsByBatchJob(this.batchJob, params)
  }
}
