import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PersistentModel } from './persistent-model'

export interface Subject extends PersistentModel {
  name: string
  description: string
}

export class SubjectValidator {

  public static newFormGroup (): FormGroup {
    const fb = new FormBuilder()

    const formGroup = fb.group({
      name: fb.control('', Validators.required),
      description: fb.control('')
    })

    return formGroup
  }
}
