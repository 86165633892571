import { Pipe, PipeTransform } from '@angular/core'

import * as _ from 'lodash-es'
import { TranslatePipe } from '@ngx-translate/core'

@Pipe({
  name: 'translateError',
  pure: false
})
export class TranslateErrorPipe implements PipeTransform {

  constructor (public translatePipe: TranslatePipe) {
  }

  transform (berrCode: string): string {
    if (_.startsWith(berrCode, 'BERR-')) {
      return this.translatePipe.transform(`ERROR.${berrCode}`)
    }
    return berrCode
  }
}
