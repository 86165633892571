import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'


// @ts-expect-error ts-migrate(6133) FIXME: 'QueryString' is declared but its value is never r... Remove this comment to see the full error message
import { QueryString } from '../models'
import { ApiService } from '../interfaces'

@Injectable()
export class TenantService extends ApiService {
  private url = this.apiUrl + '/tenants'

  constructor (protected http: HttpClient,) {
    super()
  }

  // public getRoles (id: string = 'current'): Observable<any> {
  //   return this.http.get(`${this.url}/${id}/roles`)
  // }

  // public addMember (id: string = 'current', member: any): Observable<any> {
  //   return this.http.post(`${this.url}/${id}/members`, member)
  // }

  // public getMembers (id: string = 'current', params?: QueryString): Observable<any> {
  //   return this.http.get(`${this.url}/${id}/members`, this.toRequestOptions(params))
  // }

  public getMemberById (id: string = 'current', userId: string): Observable<any> {
    return this.http.get(`${this.url}/${id}/members/${userId}`)
  }

  // public updateMember (id: string = 'current', userId: string, member: any): Observable<any> {
  //   return this.http.patch(`${this.url}/${id}/members/${userId}`, member)
  // }

  // public removeMember (id: string = 'current', userId: string): Observable<any> {
  //   return this.http.delete(`${this.url}/${id}/members/${userId}`)
  // }

  public forceMemberPassword (id: string = 'current', userId: string, password: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/members/${userId}/force-password`, { password })
  }
}
