import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Column } from '../../models'
import { SubjectService, ViewService } from '../../services'
import { SearchView, Subject } from '../../interfaces'

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html'
})

export class SubjectsComponent extends SearchView<Subject> {
  public columns: Column [] = [
    this.routerColumn('name', 'SUBJECT.NAME', { isPrimary: true }),
    new Column('description', 'SUBJECT.DESCRIPTION')
  ]

  constructor (
    viewService: ViewService,
    route: ActivatedRoute,
    service: SubjectService
  ) {
    super(viewService, route, service, 'subjects', 'subject')
    // default sort
    this.params.sort = { name: 1 }
  }
}
