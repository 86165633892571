<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content text-center text-muted" *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</section>

<section class="content" *ngIf="!isLoading">
  <div class="row">
    <div class="col-xs-12">

      <form class="form-fixed-help"
          (ngSubmit)="submit()">

        <div class="row" [formGroup]="form">
          <div class="form-group col-sm-6 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.title.valid && form.controls.title.touched }">
            <label>
              {{ 'ALBUM.TITLE' | translate }}
              <required-icon></required-icon>
            </label>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              {{ form.get('title').value }}
            </p>
            <input type="text"
                *ngIf="isSaveAllowed"
                class="form-control"
                formControlName="title">
            <span class="help-block">
              <span *ngIf="form.controls.title.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>

          <div class="form-group col-sm-6 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.division.valid && form.controls.division.touched }">
            <label>
              {{ 'ALBUM.DIVISION' | translate }}
              <required-icon></required-icon>
            </label>
            <p class="form-control-static" *ngIf="!isCreate || !isSaveAllowed">
              {{ form.get('division').value.name }}
            </p>
            <select *ngIf="isCreate && isSaveAllowed"
                class="form-control"
                formControlName="division">
              <option *ngFor="let division of divisions" [value]="division._id">{{division.name}}</option>
            </select>
          </div>
<!--
          <div class="form-group col-sm-6 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.date.valid && form.controls.date.touched }">
            <label>
              {{ 'ALBUM.DATE' | translate }}
              <required-icon></required-icon>
            </label>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              {{ form.get('date').value | shortDate : locale }}
            </p>
            <dateonlypicker-popup formControlName="date"
                *ngIf="isSaveAllowed"
                [locale]="locale">
            </dateonlypicker-popup>
            <span class="help-block">
              <span *ngIf="form.controls.date.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>
-->
        </div>

        <div class="row">
          <div class="form-group col-xs-12">
            <label>{{ 'ALBUM.ATTACHMENTS' | translate }}</label>
            <ul class="list-group">
              <li class="list-group-item text-danger text-center" *ngIf="form.controls.attachments.value.length === 0">
                <span>{{ 'ALBUM.NO_ATTACHMENTS_WARNING' | translate }}</span>
              </li>
              <li class="float-left list-group-item col-xs-3 text-center" *ngFor="let attachment of form.controls.attachments.value; index as i">
                <img [src]="attachment.eager?.w400.secure_url || attachment.secure_url" class="img-thumbnail img-fuild">
                <a>
                  <i class="fa fa-times action-icon action-icon-danger"
                      *ngIf="isSaveAllowed"
                      aria-hidden="true"
                      (click)="removeAttachment(i)"
                      tooltip="{{ 'COMMON.BTN_REMOVE' | translate }}">
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-xs-12">
            <upload-image [tags]="tags" [preset]="preset" (onUpload)="onFileUpload($event)"></upload-image>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="btn-toolbar btn-footer" role="toolbar">
              <button
                  type="submit"
                  class="btn btn-success btn-lg"
                  *ngIf="isSaveAllowed"
                  [disabled]="!form.valid"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_PUBLISH' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-default btn-lg"
                  (click)="goBack()">
                {{ 'COMMON.BTN_CANCEL' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-danger btn-lg pull-right-md"
                  *ngIf="isDeleteAllowed"
                  (click)="remove()"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_REMOVE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
