<div>
  <div class="text-center text-muted" *ngIf="loading">
    <i class="fa fa-circle-o-notch fa-spin"></i>
  </div>

  <div class="text-center text-muted" *ngIf="!loading && !rows?.length">
    {{ 'COMMON.MSG_NO_DATA' | translate }}
  </div>

  <accordion [closeOthers]="false" *ngIf="!loading && !!rows.length">
    <accordion-group *ngFor="let group of rows"
        [(isOpen)]="group.open">
      <div accordion-heading class="clearfix">
        <span class="month-badge">
          {{ group.dateTime | date:'MMMM':timezone:locale }}
        </span>
        <span class="badge label-late"
            [tooltip]="'late' | topicType"
            placement="bottom"
            *ngIf="group.totals.late">
          {{ group.totals.late }}
        </span>
        <span class="badge label-absence"
            [tooltip]="'absence' | topicType"
            placement="bottom"
            *ngIf="group.totals.absence">
          {{ group.totals.absence }}
        </span>
        <span class="badge label-justified"
            [tooltip]="'justified' | topicType"
            placement="bottom"
            *ngIf="group.totals.justified">
          {{ group.totals.justified }}
        </span>
        <i class="fa pull-right" [ngClass]="group.open ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
      </div>

      <table class="table table-striped table-hover table-fixed table-attendance">
        <tbody>
          <tr *ngFor="let topic of group.topics; let i = index">
            <td class="date-badge">
              <!-- the icon as a time element -->
              <time [attr.datetime]="topic.dateTime | date:'yyyy-MM-dd':timezone:locale" class="date-badge">
                <!-- <em>{{ topic.dateTime | date:'EEEE':timezone:locale }}</em> -->
                <strong>{{ topic.dateTime | date:'EEEE':timezone:locale }}</strong>
                <span>{{ topic.date.date }}</span>
              </time>
            </td>
            <td>
              <span class="label" [ngClass]="topic.type | topicAttendanceLabel">
                {{ topic.type | topicType }}
              </span>
              <div class="text-muted created-by">
                {{ topic.createdBy.name | username }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </accordion-group>
  </accordion>
</div>
