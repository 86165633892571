import { Component, Input, forwardRef, TemplateRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Observable } from 'rxjs/Observable'

// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export const TAGS_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TagsInput),
  multi: true
}

@Component({
  selector: 'tags-input',
  templateUrl: './tags.input.html',
  providers: [TAGS_INPUT_VALUE_ACCESSOR]
})

export class TagsInput implements ControlValueAccessor {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'identifyBy' has no initializer and is no... Remove this comment to see the full error message
  @Input() identifyBy: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'displayBy' has no initializer and is not... Remove this comment to see the full error message
  @Input() displayBy: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'source' has no initializer and is not de... Remove this comment to see the full error message
  @Input() source: Observable<any>


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'dropdownTemplate' has no initializer and... Remove this comment to see the full error message
  @Input() dropdownTemplate: TemplateRef<any>


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'itemTemplate' has no initializer and is ... Remove this comment to see the full error message
  @Input() itemTemplate: TemplateRef<any>
  @Input() readonly = false

  public onChange: any = Function.prototype
  public onTouched: any = Function.prototype


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'innerValue' has no initializer and is no... Remove this comment to see the full error message
  public innerValue: any[]

  public get value (): any {
    return this.innerValue.map(item => item._id)
  }

  @Input()
  public set value (_value: any) {
    // _value = _value || []
    // this.innerValue = _value.map(item => )
    this.innerValue = _value
  }

  public onTagsChange () {
    this.onChange(this.value)
  }

  public onBlur () {
    this.onTouched()
  }

  // ControlValueAccessor
  // model -> view
  public writeValue (value: any): void {
    this.value = value
  }

  public registerOnChange (fn: any): void {
    this.onChange = fn
    // this.registered = true
    // if (this.adjusted) {
    //   this.onChange(this.value)
    // }
  }

  public registerOnTouched (fn: any): void {
    this.onTouched = fn
  }

  public isTouchDevice(): boolean {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       // @ts-ignore
       (navigator.msMaxTouchPoints > 0));
  }
}
