import { AbstractControl, ValidatorFn } from '@angular/forms'

function nonEmpty (): ValidatorFn {


  // @ts-expect-error ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  return (control: AbstractControl): {[key: string]: any} => {
    if (!control.value || !control.value.length || control.value.length === 0) {
      return { error: 'empty' }
    }
  }
}

export default nonEmpty
