var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DivisionService, ViewService, AuthService } from '../../services';
import { SearchPanel } from '../../interfaces';
import { Column } from '../../models';
import { ShortDatePipe, TimePipe } from '../../pipes';
var DivisionExamsTab = /** @class */ (function (_super) {
    __extends(DivisionExamsTab, _super);
    function DivisionExamsTab(viewService, divisionService, authService) {
        var _this = _super.call(this, viewService, 'topic') || this;
        _this.viewService = viewService;
        _this.divisionService = divisionService;
        _this.columns = [];
        _this.subjects = [];
        _this.params.limit = 50;
        _this.params.sort = { dateTime: -1 };
        _this.user = authService.getCurrentUser();
        console.log(_this.user);
        return _this;
    }
    DivisionExamsTab.prototype.activate = function () {
        this.loadSubjects();
        this.createColumns();
        this.updateFilter();
    };
    DivisionExamsTab.prototype.fetch = function (params) {
        return this.divisionService.getExams(this.divisionId, params);
    };
    DivisionExamsTab.prototype.onExamSelected = function (exam) {
        this.viewService.goToDetail("divisions/" + this.divisionId + "/exams", 'division', exam);
    };
    DivisionExamsTab.prototype.createColumns = function () {
        this.columns = [
            Column.forClick('date', 'TOPIC.DATE', this.onExamSelected.bind(this), true, new ShortDatePipe()),
            Column.forClick('time', 'TOPIC.TIME', this.onExamSelected.bind(this), true, new TimePipe()),
            Column.forClick('title', 'SUBJECT.DETAIL_TITLE', this.onExamSelected.bind(this), true),
            new Column('content', '', false),
        ];
    };
    DivisionExamsTab.prototype.loadSubjects = function () {
        var _this = this;
        this.divisionService.getSubjects(this.divisionId)
            .subscribe(function (_a) {
            var list = _a.list;
            _this.subjects = _this.viewService.optionalList(list, { _id: undefined, name: '' });
        }, this.viewService.handleError);
    };
    return DivisionExamsTab;
}(SearchPanel));
export { DivisionExamsTab };
