import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core'
import { Observable } from 'rxjs/Observable'

import * as _ from 'lodash-es'

// @ts-expect-error ts-migrate(6133) FIXME: 'biz' is declared but its value is never read.
import { biz } from 'nexedu-common'
import { DivisionService, ViewService } from '../../services'

@Component({
  selector: 'rating-assessments',
  templateUrl: './rating-assessments.html'
})

export class RatingAssessments implements OnChanges {

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'ratingId' has no initializer and is not ... Remove this comment to see the full error message
  @Input() ratingId: string

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'divisionId' has no initializer and is no... Remove this comment to see the full error message
  @Input() divisionId: string
  @Input() isAssessmentAllowed: boolean = false
  @Output() onSave = new EventEmitter<any>()
  public isLoading = false
  public students: any
  public assessments: any
  public isSubmitting = false

  constructor (
    private divisionService: DivisionService,
    private viewService: ViewService
  ) {}

  // @ts-expect-error ts-migrate(6133) FIXME: 'changes' is declared but its value is never read.
  ngOnChanges (changes: any) {
    this.loadAssessments()
  }

  public updateAssessments () {
    let assessments: any = []
    this.isSubmitting = true
    this.students.forEach((student: any) => {
      if (this.assessments[student._id] && this.assessments[student._id].assessment) {
        assessments.push(this.assessments[student._id])
      }
    })
    this.onSave.emit(assessments)
    // this.divisionService.updateRatingAssessments(this.divisionId, this.ratingId, assessments)
    // .finally(() => { this.isSubmitting = false })
    // .subscribe(result => {
    //  this.viewService.toastr.successT('COMMON.MSG_UPDATE_OK')
    // }, this.viewService.handleError)
  }

  private loadAssessments () {
    this.isLoading = true
    if (!this.divisionId || !this.ratingId) {
      this.retrieveStudents().subscribe(results => {
        this.assessments = {}
        this.students = results
        this.students.forEach((student: any) => {
          this.assessments[student._id] = { assessment: '', comment: '', student: student._id }
        })
        this.isLoading = false
      })
      return
    }

    // wait for both requests to complete
    // http://reactivex.io/documentation/operators/zip.html
    Observable.forkJoin(
      this.retrieveStudents(),
      this.retrieveAssessments()
    )
    .finally(() => { this.isLoading = false })
    .subscribe(
      results => {
        const students = results[0]
        const assessments = results[1]

        this.assessments = _.keyBy(assessments, 'student')
        this.students = students

        this.students.forEach((student: any) => {
          if (!this.assessments[student._id]) {
            this.assessments[student._id] = { assessment: '', comment: '', student: student._id }
          }
        })
      },
      this.viewService.handleError
    )
  }

  private retrieveStudents () {
    return this.divisionService.getStudents(this.divisionId)
  }

  private retrieveAssessments () {
    return this.divisionService.getRatingAssessments(this.divisionId, this.ratingId)
  }
}
