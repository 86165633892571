/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/tooltip/tooltip.directive";
import * as i2 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i3 from "ngx-bootstrap/tooltip/tooltip.config";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "../app-datatable/app-datatable.component.ngfactory";
import * as i7 from "../app-datatable/app-datatable.component";
import * as i8 from "./batch-job-errors.tab";
import * as i9 from "../../services/view.service";
import * as i10 from "../../services/batch-job.service";
var styles_BatchJobErrorsTab = [];
var RenderType_BatchJobErrorsTab = i0.ɵcrt({ encapsulation: 2, styles: styles_BatchJobErrorsTab, data: {} });
export { RenderType_BatchJobErrorsTab as RenderType_BatchJobErrorsTab };
function View_BatchJobErrorsTab_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getItems() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 16777216, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa fa-refresh"], ["placement", "bottom"]], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i1.TooltipDirective, [i0.ViewContainerRef, i0.Renderer2, i0.ElementRef, i2.ComponentLoaderFactory, i3.TooltipConfig], { tooltip: [0, "tooltip"], placement: [1, "placement"] }, null), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("COMMON.BTN_REFRESH")); var currVal_1 = "bottom"; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_BatchJobErrorsTab_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "btn-group pull-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 7, "a", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exportList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-fw fa-file-excel-o"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("COMMON.EXPORT")); _ck(_v, 7, 0, currVal_0); }); }
export function View_BatchJobErrorsTab_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "btn-toolbar"], ["role", "toolbar"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BatchJobErrorsTab_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BatchJobErrorsTab_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "appdatatable", [], null, [[null, "page"], [null, "sort"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("sort" === en)) {
        var pd_1 = (_co.onSort($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_AppDataTableComponent_0, i6.RenderType_AppDataTableComponent)), i0.ɵprd(135680, null, i4.TranslatePipe, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(11, 49152, null, 0, i7.AppDataTableComponent, [], { loading: [0, "loading"], rows: [1, "rows"], limit: [2, "limit"], count: [3, "count"], columns: [4, "columns"] }, { pageEmitter: "page", sortEmitter: "sort" }), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInProgress; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.count; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.loading; var currVal_3 = _co.rows; var currVal_4 = _co.params.limit; var currVal_5 = _co.count; var currVal_6 = _co.columns; _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_BatchJobErrorsTab_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "batch-job-errors-tab", [], null, null, null, View_BatchJobErrorsTab_0, RenderType_BatchJobErrorsTab)), i0.ɵprd(131584, null, i4.TranslatePipe, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(2, 573440, null, 0, i8.BatchJobErrorsTab, [i9.ViewService, i10.BatchJobService, i4.TranslatePipe], null, null)], null, null); }
var BatchJobErrorsTabNgFactory = i0.ɵccf("batch-job-errors-tab", i8.BatchJobErrorsTab, View_BatchJobErrorsTab_Host_0, { batchJob: "batchJob", status: "status" }, {}, []);
export { BatchJobErrorsTabNgFactory as BatchJobErrorsTabNgFactory };
