<div *ngIf="!form">
  <div class="btn-toolbar btn-footer clearfix">
    <button
        type="button"
        class="btn btn-primary pull-right"
        *ngIf="isCreateAllowed"
        (click)="switchToEditionMode()">
      {{ 'STUDENT.ADD_GUARDIAN' | translate }}
    </button>
  </div>

  <appdatatable
      [rows]="rows"
      [columns]="columns"
      [sorts]="params.sort"
      [count]="count"
      [limit]="params.limit"
      [loading]="loading"
      (page)="onPage($event)"
      (sort)="onSort($event)">
  </appdatatable>
</div>

<div *ngIf="form">
  <form class="form-fixed-help"
      [formGroup]="form"
      (ngSubmit)="saveGuardian()">
    <user-panel [form]="form" [isCreate]="isCreate"></user-panel>

    <div class="row">
      <div class="col-xs-12">
        <div class="btn-toolbar btn-footer" role="toolbar">
          <button
              type="submit"
              class="btn btn-success"
              *ngIf="isSaveAllowed"
              [disabled]="!form.valid"
              [ladda]="isSubmitting">
            {{ 'STUDENT.SAVE_GUARDIAN' | translate }}
          </button>
          <button
              type="button"
              class="btn btn-default"
              (click)="cancelEditionMode()">
            {{ 'COMMON.BTN_CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
