import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { IsUserAllowedGuard, IsUserAlreadyLoggedGuard, IsUserLoggedGuard, IsFormDirtyGuard } from './guards'

import { LayoutsAuthComponent, PAGES } from './layouts/auth/auth'
import { HomeComponent, ReportCardComponent } from './pages'
import { LoginComponent } from './pages'
import { ProfileComponent } from './pages'
import { BatchJobComponent } from './pages'
import { FileJobsComponent } from './pages'
import { ReportsComponent } from './pages'
// custom per app
import { ForcePasswordComponent } from './pages'


// @ts-expect-error ts-migrate(6192) FIXME: All imports in import declaration are unused.
import { TermComponent, TermsComponent } from './pages'
import { SubjectComponent, SubjectsComponent } from './pages'
import { DivisionComponent, DivisionsComponent } from './pages'
import { StudentComponent, StudentsComponent } from './pages'
import { GuardianComponent, GuardiansComponent } from './pages'
import { TeacherComponent, TeachersComponent } from './pages'
import { SupervisorComponent, SupervisorsComponent } from './pages'
import { EmployeeComponent, EmployeesComponent } from './pages'
import { TopicsComponent, TopicComponent } from './pages'
import { FeedComponent } from './pages'
import { AlbumComponent } from './pages'
import { AlbumsComponent } from './pages'
import { AttendanceComponent } from './pages'
import { ExamComponent } from './pages'
import { RatingComponent } from './pages'
import { GradesComponent } from './pages'
import { GradeComponent } from './pages'
import { PromotionComponent } from './pages'

const routes: Routes = [
  // logged routes
  {
    path: '',
    component: LayoutsAuthComponent,
    canActivate: [ IsUserLoggedGuard ],
    children: [
      {
        // redirecting to default child route, based on
        // http://stackoverflow.com/a/42590459/2115580
        // http://stackoverflow.com/a/37605802/2115580
        path: '',
        pathMatch: 'full',
        redirectTo: PAGES.home.path
      },
      {
        path: PAGES.home.path,
        component: HomeComponent,
        data: PAGES.home.data
      },
      {
        path: PAGES.profile.path,
        component: ProfileComponent,
        data: PAGES.profile.data
      },
      {
        path: PAGES.forcePassword.path,
        component: ForcePasswordComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.forcePassword.data
      },
      {
        path: PAGES.batchJobs.path,
        component: FileJobsComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.batchJobs.data
      },
      {
        path: PAGES.batchJob.path,
        component: BatchJobComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.batchJob.data
      },

      // custom per app
      // {
      //   path: PAGES.terms.path,
      //   component: TermsComponent,
      //   canActivate: [ IsUserAllowedGuard ],
      //   data: PAGES.terms.data
      // },
      // {
      //   path: PAGES.term.path,
      //   component: TermComponent,
      //   canActivate: [ IsUserAllowedGuard ],
      //   canDeactivate: [ IsFormDirtyGuard ],
      //   data: PAGES.term.data
      // },
      {
        path: PAGES.subjects.path,
        component: SubjectsComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.subjects.data
      },
      {
        path: PAGES.subject.path,
        component: SubjectComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.subject.data
      },
      {
        path: PAGES.grades.path,
        component: GradesComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.grades.data
      },
      {
        path: PAGES.grade.path,
        component: GradeComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.grade.data
      },
      {
        path: PAGES.divisions.path,
        component: DivisionsComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.divisions.data
      },
      {
        path: PAGES.division.path,
        component: DivisionComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.division.data
      },
      {
        path: PAGES.reportCard.path,
        component: ReportCardComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.reportCard.data
      },
      {
        path: PAGES.students.path,
        component: StudentsComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.students.data
      },
      {
        path: PAGES.student.path,
        component: StudentComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.student.data
      },
      {
        path: PAGES.guardians.path,
        component: GuardiansComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.guardians.data
      },
      {
        path: PAGES.guardian.path,
        component: GuardianComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.guardian.data
      },
      {
        path: PAGES.teachers.path,
        component: TeachersComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.teachers.data
      },
      {
        path: PAGES.teacher.path,
        component: TeacherComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.teacher.data
      },
      {
        path: PAGES.employees.path,
        component: EmployeesComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.employees.data
      },
      {
        path: PAGES.employee.path,
        component: EmployeeComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.employee.data
      },
      {
        path: PAGES.supervisors.path,
        component: SupervisorsComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.supervisors.data
      },
      {
        path: PAGES.supervisor.path,
        component: SupervisorComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.supervisor.data
      },
      {
        path: PAGES.topics.path,
        component: TopicsComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.topics.data
      },
      {
        path: PAGES.topic.path,
        component: TopicComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.topic.data
      },
      {
        path: PAGES.albums.path,
        component: AlbumsComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.albums.data
      },
      {
        path: PAGES.album.path,
        component: AlbumComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.album.data
      },
      {
        path: PAGES.feed.path,
        component: FeedComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.feed.data
      },
      {
        path: PAGES.attendance.path,
        component: AttendanceComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.attendance.data
      },
      {
        path: PAGES.promotion.path,
        component: PromotionComponent,
        canActivate: [ IsUserAllowedGuard ],
        data: PAGES.promotion.data
      },
      {
        path: PAGES.exam.path,
        component: ExamComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.exam.data
      },
      {
        path: PAGES.rating.path,
        component: RatingComponent,
        canActivate: [ IsUserAllowedGuard ],
        canDeactivate: [ IsFormDirtyGuard ],
        data: PAGES.rating.data
      },

      {
        path: PAGES.reports.path,
        component: ReportsComponent
      }
    ]
  },
  // not logged routes
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [IsUserAlreadyLoggedGuard]
  },
  // not found route when no match is found
  {
    path: '**',
    redirectTo: ''
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutes {}
