var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ViewService, StageService, DivisionService, TeacherService } from '../../services';
import { CourseValidator, CrudService } from '../../interfaces';
import { QueryString } from '../../models';
import { UsernamePipe } from '../../pipes';
var CoursePanel = /** @class */ (function () {
    function CoursePanel(service, viewService, teacherService, stageService, usernamePipe) {
        var _this = this;
        this.service = service;
        this.viewService = viewService;
        this.teacherService = teacherService;
        this.stageService = stageService;
        this.usernamePipe = usernamePipe;
        this.onSave = new EventEmitter();
        this.onRemove = new EventEmitter();
        this.onCancel = new EventEmitter();
        this.isSaveAllowed = false;
        // @ts-expect-error ts-migrate(6133) FIXME: 'filter' is declared but its value is never read.
        this.searchTeachers = function (filter) {
            return Observable.of(_this.teachers);
        };
    }
    CoursePanel.prototype.ngOnChanges = function (changes) {
        if (changes.model) {
            this.generateTeachersFullName(this.model.teachers);
            this._id = this.model && this.model._id;
            if (!this._id) {
                this.mode = 'create';
            }
            else {
                this.mode = 'edit';
            }
            this.isSaveAllowed = this.viewService.isAllowed('division', this.mode == 'create' ? 'add-course' : 'update-course');
            if (!this.isSaveAllowed) {
                this.mode = 'readonly';
            }
            this.form = CourseValidator.newFormGroup(this.model);
        }
        if (changes.division) {
            this.loadSubjects();
        }
    };
    CoursePanel.prototype.cancel = function () {
        this.onCancel.emit();
    };
    CoursePanel.prototype.submit = function () {
        var _this = this;
        if (this.mode == 'readonly') {
            console.warn('User not allowed to save changes');
            return;
        }
        else if (!this.form || !this.form.valid) { // be extra sure
            return this.viewService.toastr.warningT('COMMON.MSG_INVALID_FORM');
        }
        this.isSubmitting = true;
        var model = this.form.value;
        var onSuccess = function () { _this.onSave.emit(model); };
        var onFinally = function () { _this.isSubmitting = false; };
        var service = new CourseWrapperService(this.division._id, this.service);
        this.viewService.save(this._id, service, this.form, onSuccess, onFinally, model);
    };
    CoursePanel.prototype.onSubjectChosen = function () {
        //this.form.controls.teachers.reset()
        this.loadTeachers();
    };
    CoursePanel.prototype.loadTeachers = function () {
        var _this = this;
        var subject = this.form.controls.subject.value;
        if (!subject) {
            this.teachers = [];
            return;
        }
        var params = new QueryString({ select: 'name' });
        this.teacherService.search(params)
            .subscribe(function (result) {
            _this.generateTeachersFullName(result.list);
            _this.teachers = result.list;
        });
    };
    CoursePanel.prototype.generateTeachersFullName = function (teachers) {
        var _this = this;
        teachers = teachers || [];
        teachers.forEach(function (teacher) {
            teacher.fullName = _this.usernamePipe.transform(teacher.name);
        });
    };
    CoursePanel.prototype.loadSubjects = function () {
        var _this = this;
        var params = new QueryString({
            select: 'name',
            sort: {
                name: 1
            }
        });
        this.stageService.getGradeSubjects(this.division.grade, params)
            .subscribe(function (result) {
            _this.subjects = result.list;
            var subject = _this.subjects && _this.subjects[0];
            if (_this.mode == 'create' || _this.mode == 'edit' && subject) {
                _this.form.controls.subject.setValue(subject._id);
                _this.onSubjectChosen();
            }
        }, this.viewService.handleError);
    };
    return CoursePanel;
}());
export { CoursePanel };
var CourseWrapperService = /** @class */ (function (_super) {
    __extends(CourseWrapperService, _super);
    function CourseWrapperService(id, service) {
        var _this = 
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        _super.call(this, null, '') || this;
        _this.id = id;
        _this.service = service;
        return _this;
    }
    CourseWrapperService.prototype.create = function (object) {
        return this.service.addCourse(this.id, object);
    };
    CourseWrapperService.prototype.update = function (id, object) {
        return this.service.updateCourse(this.id, id, object);
    };
    return CourseWrapperService;
}(CrudService));
