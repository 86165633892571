import { PipeTransform } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'

export class PrefixedTranslatePipe implements PipeTransform {

  constructor (private translatePipe: TranslatePipe, private prefix: string) {}

  transform (value: string): string {
    if (typeof value === 'undefined' || value === '') {
      return this.translatePipe.transform(this.prefix + '__all')
    }


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    if (!value) { return }
    return this.translatePipe.transform(this.prefix + value)
  }

}
