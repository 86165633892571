import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Column } from '../../models'
import { TeacherService, ViewService } from '../../services'
import { SearchView, Teacher } from '../../interfaces'


// @ts-expect-error ts-migrate(6133) FIXME: 'ArrayPipe' is declared but its value is never rea... Remove this comment to see the full error message
import { ArrayPipe } from '../../pipes'

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html'
})

export class TeachersComponent extends SearchView<Teacher> {
  public columns: Column []

  constructor (
    viewService: ViewService,
    route: ActivatedRoute,
    service: TeacherService
  ) {
    super(viewService, route, service, 'teachers', 'teacher')
    // default sort
    this.params.sort = {
      'name.last': 1,
      'name.first': 1
    }

    this.columns = [
      this.routerColumn('_id', 'USER.ID', { isPrimary: true, minWidth: 120, maxWidth: 120 }),
      this.routerColumn('name.last', 'USER.LAST_NAME', { isPrimary: true }),
      this.routerColumn('name.first', 'USER.FIRST_NAME', { isPrimary: true }),


      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      new Column('contacts.0', 'USER.CONTACTS', false, null, 'contactCell'),
    ]
  }
}
