import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PersistentModel } from './persistent-model'
import { Term, Subject } from './'

export interface Grade extends PersistentModel {
  name: string
  order: number
  term: Term|string
  subjects: Subject[]|string[]
}

export class GradeValidator {

  public static newFormGroup (model?: Grade): FormGroup {
    const fb = new FormBuilder()

    const formGroup = fb.group({
      name: fb.control(model ? model.name : '', Validators.required),
      order: fb.control(model ? model.order : 0, Validators.required),
      subjects: fb.control(model ? model.subjects : [], Validators.required)
    })

    return formGroup
  }
}
