import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { StudentService, ViewService } from '../../services'
import { DetailView, User, UserValidator } from '../../interfaces'

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html'
})

export class StudentComponent extends DetailView<User> {
  public isFeedAllowed: boolean


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'isForcePasswordAllowed' has no initializ... Remove this comment to see the full error message
  public isForcePasswordAllowed: boolean

  constructor (
    viewService: ViewService,
    service: StudentService,
    route: ActivatedRoute
  ) {
    super(viewService, service, route, 'student', UserValidator.newStudentFormGroup(), true)
    this.deletePermission = 'remove'

    const user = viewService.getCurrentUser()
    this.isFeedAllowed = user.isSuperuser || user.currentRole === biz.ROLES.OWNER || user.currentRole === biz.ROLES.SUPERVISOR
  }

  protected getValue () {
    return this.form.value
  }

  protected getDefaultDetails (): User {
    return {
      gender: biz.GENDERS.FEMALE
    } as User
  }

  protected checkPermissions () {
    super.checkPermissions()
    this.isForcePasswordAllowed = !this.isCreate && this.viewService.isAllowed('tenant', 'force-member-password')
  }
}
