var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../interfaces';
var TenantService = /** @class */ (function (_super) {
    __extends(TenantService, _super);
    function TenantService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.url = _this.apiUrl + '/tenants';
        return _this;
    }
    // public getRoles (id: string = 'current'): Observable<any> {
    //   return this.http.get(`${this.url}/${id}/roles`)
    // }
    // public addMember (id: string = 'current', member: any): Observable<any> {
    //   return this.http.post(`${this.url}/${id}/members`, member)
    // }
    // public getMembers (id: string = 'current', params?: QueryString): Observable<any> {
    //   return this.http.get(`${this.url}/${id}/members`, this.toRequestOptions(params))
    // }
    TenantService.prototype.getMemberById = function (id, userId) {
        if (id === void 0) { id = 'current'; }
        return this.http.get(this.url + "/" + id + "/members/" + userId);
    };
    // public updateMember (id: string = 'current', userId: string, member: any): Observable<any> {
    //   return this.http.patch(`${this.url}/${id}/members/${userId}`, member)
    // }
    // public removeMember (id: string = 'current', userId: string): Observable<any> {
    //   return this.http.delete(`${this.url}/${id}/members/${userId}`)
    // }
    TenantService.prototype.forceMemberPassword = function (id, userId, password) {
        if (id === void 0) { id = 'current'; }
        return this.http.post(this.url + "/" + id + "/members/" + userId + "/force-password", { password: password });
    };
    return TenantService;
}(ApiService));
export { TenantService };
