<div class="btn-toolbar" role="toolbar">
  <a class="btn btn-default" (click)="getItems()" *ngIf="isInProgress">
    <i class="fa fa-refresh" aria-hidden="true"
        [tooltip]="'COMMON.BTN_REFRESH' | translate"
        placement="bottom"></i>
  </a>

  <div class="btn-group pull-right" *ngIf="count">
    <a class="btn btn-default" (click)="exportList()">
      <i class="fa fa-fw fa-file-excel-o" aria-hidden="true"></i>
      <span>{{ 'COMMON.EXPORT' | translate }}</span>
    </a>
  </div>
</div>

<appdatatable
  [rows]="rows"
  [columns]="columns"
  [limit]="params.limit"
  [count]="count"
  [loading]="loading"
  (page)="onPage($event)"
  (sort)="onSort($event)">
</appdatatable>
