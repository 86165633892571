/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./stage-picker.component";
import * as i3 from "../../services/auth.service";
var styles_StagePickerComponent = [];
var RenderType_StagePickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StagePickerComponent, data: {} });
export { RenderType_StagePickerComponent as RenderType_StagePickerComponent };
function View_StagePickerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "dropdown-toggle"], ["data-toggle", "dropdown"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedStage.name; _ck(_v, 3, 0, currVal_0); }); }
function View_StagePickerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "hidden": 0 }), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeSelectedStage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_v.context.$implicit._id === ((_co.selectedStage == null) ? null : _co.selectedStage._id))); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_1); }); }
export function View_StagePickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_StagePickerComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "ul", [["class", "stage-picker dropdown-menu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StagePickerComponent_2)), i0.ɵdid(6, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedStage; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.stages; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_StagePickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "stagepicker"]], null, null, null, View_StagePickerComponent_0, RenderType_StagePickerComponent)), i0.ɵdid(1, 245760, null, 0, i2.StagePickerComponent, [i3.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StagePickerComponentNgFactory = i0.ɵccf(".stagePicker", i2.StagePickerComponent, View_StagePickerComponent_Host_0, {}, {}, []);
export { StagePickerComponentNgFactory as StagePickerComponentNgFactory };
