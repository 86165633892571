import { Pipe, PipeTransform } from '@angular/core'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'mome... Remove this comment to see the full error message
import * as moment from 'moment-timezone'

@Pipe({name: 'shortDateTime'})
export class ShortDateTimePipe implements PipeTransform {

  transform (value: Date): string {


    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    if (!value) { return }
    
    const dateAndTime = moment(value).format('DD/MM/YYYY HH:mm')
    return dateAndTime
  }
}
