'use strict'

/**
 * IMPORTANT!
 * Do not use any ES6 syntax feature in this file so we can re-use it from
 * admin. If not, webpack (uglify actually) will complain about an unexpected
 * token. For more info check the following link:
 * https://github.com/angular/angular-cli/issues/1663
 */

// constants related to the business of the app

const ID_MIN_LENGTH = 4
const PASSWORD_MIN_LENGTH = 4
const DEFAULT_LOCALE = 'es'

const ROLES = {
  // internal roles
  OWNER: 'owner',
  SUPERVISOR: 'supervisor',
  EMPLOYEE: 'employee',
  TEACHER: 'teacher',
  // external roles
  STUDENT: 'student',
  GUARDIAN: 'guardian'
}
// IMPORTANT: internal roles must be over 100, external ones under it
const INTERNAL_ROLE_WEIGHT = 100
const ROLE_WEIGHTS = {
  [ROLES.OWNER]: INTERNAL_ROLE_WEIGHT + 40,
  [ROLES.SUPERVISOR]: INTERNAL_ROLE_WEIGHT + 30,
  [ROLES.EMPLOYEE]: INTERNAL_ROLE_WEIGHT + 20,
  [ROLES.TEACHER]: INTERNAL_ROLE_WEIGHT + 10,
  [ROLES.GUARDIAN]: INTERNAL_ROLE_WEIGHT - 10,
  [ROLES.STUDENT]: INTERNAL_ROLE_WEIGHT - 20
}

const BATCH_JOB_STATUSES = {
  pending: 'pending',
  processing: 'processing',
  completed: 'completed',
  failed: 'failed'
}

const BATCH_JOB_TYPES = {
  importStudents: 'import-students'
}

const CONTACT_TYPES = {
  EMAIL: 'email',
  MOBILE: 'mobile',
  PHONE: 'phone'
  // FAX: 'fax'
}

const CONTACT_TAGS = {
  PERSONAL: 'personal',
  WORK: 'work'
}

const GENDERS = {
  MALE: 'male',
  FEMALE: 'female'
}

const TOPIC_TYPES = {
  PRESENCE: 'presence', // date
  LATE_ARRIVAL: 'late', // date+(time)
  ABSENCE: 'absence',   // date+(time)
  JUSTIFIED_ABSENCE: 'justified', // date+(time)

  HOLIDAY: 'holiday', // date
  STRIKE: 'strike', // date
  NO_CLASSES: 'no-classes', // date

  ALERT: 'alert', // date+time
  EVENT: 'event',     // date+time
  MEETING: 'meeting', // date+time

  EXAM: 'exam',       // date+time
  ASSESSMENT: 'assessment', // (date)+(time)
  RATING: 'rating', //date+time
  RATING_ASSESSMENT: 'rating-assessment', // (date)+(time)

  ACHIEVEMENT: 'achievement',
  WARNING: 'warning',

  NEWS: 'news', // date+(time)
  CHAT: 'chat', // (date)+(time)
  ALBUM: 'album'
}
const ATTENDANCE_TYPES = [
  TOPIC_TYPES.PRESENCE,
  TOPIC_TYPES.ABSENCE,
  TOPIC_TYPES.LATE_ARRIVAL,
  TOPIC_TYPES.JUSTIFIED_ABSENCE
]
const DIRECT_TOPIC_TYPES = [
  TOPIC_TYPES.CHAT,
  TOPIC_TYPES.MEETING,
  TOPIC_TYPES.EXAM,
  TOPIC_TYPES.ASSESSMENT
]
const TOPIC_LAYOUTS = [
  {
    name: 'regular',
    types: [
      TOPIC_TYPES.EVENT,
      TOPIC_TYPES.MEETING,
      TOPIC_TYPES.WARNING,
      TOPIC_TYPES.ACHIEVEMENT,
      TOPIC_TYPES.NEWS
    ]
  }, {
    name: 'highlight',
    types: [
      TOPIC_TYPES.PRESENCE,
      TOPIC_TYPES.LATE_ARRIVAL,
      TOPIC_TYPES.ABSENCE,
      TOPIC_TYPES.JUSTIFIED_ABSENCE,
      TOPIC_TYPES.HOLIDAY,
      TOPIC_TYPES.STRIKE,
      TOPIC_TYPES.NO_CLASSES,
      TOPIC_TYPES.EXAM
    ]
  }, {
    name: 'assessment',
    types: [
      TOPIC_TYPES.ASSESSMENT,
      TOPIC_TYPES.RATING_ASSESSMENT
    ]
  }, {
    name: 'album',
    types: [
      TOPIC_TYPES.ALBUM
    ]
  }
]

const TOPIC_ACTIONS = ['delivered', 'read', 'like', 'bookmark']

const RECIPIENT_TYPES = {
  USER: 'user',
  DIVISION: 'division',
  STAGE: 'stage'
}

const TOPIC_RECIPIENT_TYPES = {
  [TOPIC_TYPES.PRESENCE]: [ RECIPIENT_TYPES.USER ],
  [TOPIC_TYPES.LATE_ARRIVAL]: [ RECIPIENT_TYPES.USER ],
  [TOPIC_TYPES.ABSENCE]: [ RECIPIENT_TYPES.USER ],
  [TOPIC_TYPES.JUSTIFIED_ABSENCE]: [ RECIPIENT_TYPES.USER ],
  [TOPIC_TYPES.ACHIEVEMENT]: [ RECIPIENT_TYPES.USER ],
  [TOPIC_TYPES.WARNING]: [ RECIPIENT_TYPES.USER ],
  [TOPIC_TYPES.CHAT]: [ RECIPIENT_TYPES.USER, RECIPIENT_TYPES.DIVISION ]
}

const SCHOOL_SHIFTS = {
  NONE: 'none',
  MORNING: 'morning',
  AFTERNOON: 'afternoon',
  DOUBLE: 'double',
  NIGHT: 'night'
}

const DEVICE_TYPES = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web'
}

function isInternalRole (role) {
  const weight = ROLE_WEIGHTS[role]
  return weight && weight > INTERNAL_ROLE_WEIGHT
}
function getRoleWeight (role) {
  return ROLE_WEIGHTS[role]
}
function isValidRole (role) {
  return !!ROLE_WEIGHTS[role]
}

module.exports = {
  ID_MIN_LENGTH: ID_MIN_LENGTH,
  PASSWORD_MIN_LENGTH: PASSWORD_MIN_LENGTH,
  DEFAULT_LOCALE: DEFAULT_LOCALE,

  ROLES: ROLES,
  TOP_LEVEL_ROLE: ROLES.OWNER,

  BATCH_JOB_STATUSES: BATCH_JOB_STATUSES,
  BATCH_JOB_TYPES: BATCH_JOB_TYPES,

  CONTACT_TYPES: CONTACT_TYPES,
  CONTACT_TAGS: CONTACT_TAGS,

  GENDERS: GENDERS,
  SCHOOL_SHIFTS: SCHOOL_SHIFTS,

  TOPIC_TYPES: TOPIC_TYPES,
  ATTENDANCE_TYPES: ATTENDANCE_TYPES,
  DIRECT_TOPIC_TYPES: DIRECT_TOPIC_TYPES,
  TOPIC_LAYOUTS: TOPIC_LAYOUTS,
  TOPIC_ACTIONS: TOPIC_ACTIONS,

  RECIPIENT_TYPES: RECIPIENT_TYPES,
  TOPIC_RECIPIENT_TYPES: TOPIC_RECIPIENT_TYPES,
  DEVICE_TYPES: DEVICE_TYPES,

  isInternalRole: isInternalRole,
  getRoleWeight: getRoleWeight,
  isValidRole: isValidRole
}
