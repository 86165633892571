var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// @ts-expect-error ts-migrate(6133) FIXME: 'Params' is declared but its value is never read.
import { ActivatedRoute } from '@angular/router';
import { BatchJobService, ViewService } from '../../services';
import { DetailView } from '../../interfaces';
var BatchJobComponent = /** @class */ (function (_super) {
    __extends(BatchJobComponent, _super);
    function BatchJobComponent(viewService, service, route) {
        var _this = 
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        _super.call(this, viewService, service, route, 'batch-job', null) || this;
        _this.options.populate = 'createdBy';
        return _this;
    }
    return BatchJobComponent;
}(DetailView));
export { BatchJobComponent };
