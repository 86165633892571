import { EventEmitter, ElementRef, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import * as bsLocales from 'ngx-bootstrap/locale';
import * as moment from 'moment';
// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export var DATEPICKER_POPUP_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return DateOnlyPickerPopup; }),
    multi: true
};
var DateOnlyPickerPopup = /** @class */ (function () {
    function DateOnlyPickerPopup(element, bsLocaleService) {
        this.element = element;
        this.bsLocaleService = bsLocaleService;
        this.onChange = Function.prototype;
        this.onTouched = Function.prototype;
        this.format = 'DD/MM/YYYY';
        this.change = new EventEmitter();
        this.writes = 0;
        this.config = Object.assign({}, {
            containerClass: 'theme-blue',
            displayMonths: 1,
            showWeekNumbers: false
            // dateInputFormat: 'L'
        });
    }
    DateOnlyPickerPopup.prototype.ngOnChanges = function (changes) {
        if (changes.locale) {
            var localeKey = this.locale + "Locale";
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (bsLocales[localeKey]) {
                // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                defineLocale(this.locale, bsLocales[localeKey]);
                this.bsLocaleService.use(this.locale);
            }
        }
    };
    Object.defineProperty(DateOnlyPickerPopup.prototype, "value", {
        get: function () {
            if (!this.innerValue) {
                return;
            }
            return {
                date: this.innerValue.getDate(),
                month: this.innerValue.getMonth(),
                year: this.innerValue.getFullYear()
            };
        },
        set: function (_value) {
            this.innerValue = _value ? moment(_value).toDate() : _value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateOnlyPickerPopup.prototype, "minDate", {
        set: function (_minDate) {
            if (_minDate) {
                this.innerMinDate = moment(_minDate).toDate();
            }
            else {
                this.innerMinDate = undefined;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateOnlyPickerPopup.prototype, "maxDate", {
        set: function (_maxDate) {
            if (_maxDate) {
                this.innerMaxDate = moment(_maxDate).toDate();
            }
            else {
                this.innerMaxDate = undefined;
            }
        },
        enumerable: false,
        configurable: true
    });
    DateOnlyPickerPopup.prototype.onBlur = function () {
        this.onTouched();
    };
    DateOnlyPickerPopup.prototype.onModelChange = function (newValue) {
        if (!this.writes) {
            if (newValue !== this.innerValue) {
                this.innerValue = newValue;
                this.onChange(this.value);
                this.change.emit(this.value);
            }
        }
        else {
            this.writes--;
        }
    };
    // ControlValueAccessor
    // model -> view
    DateOnlyPickerPopup.prototype.writeValue = function (value) {
        this.value = value;
        this.writes++;
    };
    DateOnlyPickerPopup.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    DateOnlyPickerPopup.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    return DateOnlyPickerPopup;
}());
export { DateOnlyPickerPopup };
