import { OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs/Observable'

import * as _ from 'lodash-es'
import { CrudSearchResult, CrudService } from './crud.service'
import { PersistentModel } from './persistent-model'
import { SearchPanel, SearchRouterColumnOptions } from './search-panel'
import { ViewService } from '../services'
import { QueryString } from '../models'

export abstract class SearchView<T extends PersistentModel> extends SearchPanel<T> implements OnInit {
  public pageData: any = {}
  protected constantConditions: any = {}
  protected defaultConditions: any = {}

  constructor (
    viewService: ViewService,
    private route: ActivatedRoute,
    protected service: CrudService<T>,
    protected searchPath: string,
    resource: string,
    createPermission?: string
  ) {
    super(viewService, resource, createPermission)
    this.route.data.subscribe(pageData => { this.pageData = pageData })
  }

  public ngOnInit () {
    this.route.queryParams
    .subscribe(
      params => {
        this.params.conditions = this.params.conditions ? this.params.conditions : {}
        if (params) {
          this.fromQueryString(params)
        }
        _.forOwn(this.constantConditions, (value: any, key: any) => {
          this.params.conditions[key] = value
        })
        _.forOwn(this.defaultConditions, (value: any, key: any) => {
          if (!this.params.conditions[key]) {
            this.params.conditions[key] = value
          }
        })
        this.getItems()
      },
      this.viewService.handleError
    )
  }

  public onSelect (selected: any) {
    return this.viewService.goToDetail(this.searchPath, this.resource, selected)
  }

  public onPage (event: any) {
    this.params.page = event.offset + 1
    this.toQueryString()
  }

  public onSort (event: any) {
    this.params.sort = event.sort
    this.params.page = event.page
    this.toQueryString()
  }

  public updateFilter () {
    // items will be fetched if URL has changed
    this.params.page = 1
    this.toQueryString()
  }

  protected routerColumn (
    prop: string,
    name: string,
    options?: SearchRouterColumnOptions
  ) {
    options = options || {}
    options.routerLink = options.routerLink || ((row: any) => ['/', this.searchPath, row._id])
    return super.routerColumn(prop, name, options)
  }

  protected fetch (params: QueryString): Observable<CrudSearchResult<T>> {
    return this.service.search(params)
  }

  protected fromQueryString (qs: any) {
    if (!qs) {
      return
    }

    if (qs.page) {
      this.params.page = Number(qs.page)
    }
    if (qs.limit) {
      this.params.limit = Number(qs.limit)
    }
    if (qs.q) {
      this.params.q = qs.q
    }

    const sortPattern = /^sort\.(.+)/
    const conditionsPattern = /^conditions\.(.+)/
    const sort: any = {}
    const conditions: any = {}
    _.forOwn(qs, (value: any, key: any) => {
      if (!this.unflatParams(conditions, conditionsPattern, key, value)) {
        this.unflatParams(sort, sortPattern, key, value)
      }
    })
    if (!_.isEmpty(sort)) {
      this.params.sort = sort
    }
    if (!_.isEmpty(conditions)) {
      this.params.conditions = conditions
    }
  }

  private toQueryString () {
    if (!this.params) {
      return
    }

    const qs: any = {}
    if (this.params.page) {
      qs.page = this.params.page
    }
    if (this.params.limit) {
      qs.limit = this.params.limit
    }
    if (this.params.q) {
      qs.q = this.params.q
    }

    this.flatParams(qs, this.params.sort, 'sort')
    this.flatParams(qs, this.params.conditions, 'conditions')
    // populate: string | object
    // select: string

    this.viewService.goTo(['.'], {
      queryParams: qs,
      relativeTo: this.route
      // replaceUrl: true
    })
  }

  private flatParams (accum: any, object: any, prefix: string) {
    _.forOwn(object, (value: any, key: any) => {
      if (!_.isNil(value) && ((!_.isString(value) && !_.isArray(value) && !_.isPlainObject(value)) || !_.isEmpty(value))) {
        accum[prefix + '.' + key] = JSON.stringify(value)
      }
    })
  }

  private unflatParams (accum: any, pattern: any, key: string, value: any) {
    const result = key.match(pattern)
    if (result) {
      accum[result[1]] = JSON.parse(value)
    }
    return result
  }
}
