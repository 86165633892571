var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CrudService } from '../interfaces';
var GradeWrapperService = /** @class */ (function (_super) {
    __extends(GradeWrapperService, _super);
    function GradeWrapperService(stageService) {
        var _this = 
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        _super.call(this, null, '') || this;
        _this.stageService = stageService;
        return _this;
    }
    GradeWrapperService.prototype.getById = function (id, params) {
        return this.stageService.getGradeById(id, params);
    };
    GradeWrapperService.prototype.search = function (params) {
        return this.stageService.getGrades(params);
    };
    GradeWrapperService.prototype.create = function (object) {
        return this.stageService.addGrade(object);
    };
    GradeWrapperService.prototype.update = function (gradeId, object) {
        return this.stageService.updateGrade(gradeId, object);
    };
    GradeWrapperService.prototype.remove = function (gradeId) {
        return this.stageService.removeGrade(gradeId);
    };
    return GradeWrapperService;
}(CrudService));
export { GradeWrapperService };
