import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Params } from '@angular/router'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { AppValidators } from '../../validators'
import { TenantService, ViewService } from '../../services'
import { User } from '../../interfaces'

@Component({
  selector: 'app-force-password',
  templateUrl: './force-password.component.html'
})
export class ForcePasswordComponent implements OnInit {
  public form = ForcePasswordComponent.newFormGroup()
  public pageData: any = {}
  public isSubmitting = false


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'user' has no initializer and is not defi... Remove this comment to see the full error message
  public user: User

  constructor (
    private service: TenantService,
    private viewService: ViewService,
    private route: ActivatedRoute
  ) {
    route.data.subscribe(pageData => {
      this.pageData = pageData
    })
  }

  public ngOnInit () {
    this.route.params
    // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
    .subscribe((params: Params) => {


      const id = params.id
      this.user = this.getDetailsFromCache(id)
      if (!this.user) {
        return this.service.getMemberById(undefined, id)
        .subscribe(
          user => {
            this.user = user
          },
          this.viewService.handleError
        )
      }
    })
  }

  public submit () {
    this.isSubmitting = true
    this.service.forceMemberPassword(undefined, this.user._id, this.form.value.newPassword)
    .finally(() => { this.isSubmitting = false })
    .subscribe(


      // @ts-expect-error ts-migrate(6133) FIXME: 'user' is declared but its value is never read.
      user => {
        this.viewService.toastr.successT('COMMON.MSG_UPDATE_OK')
        this.form.markAsPristine()
        return this.goBack()
      },
      this.viewService.handleError
    )
  }

  public goBack () {
    return this.viewService.goBack()
  }

  public isDirty (): boolean {
    return this.form && this.form.dirty
  }

  private getDetailsFromCache (id: any) {
    return this.viewService.cache.get(`user_${id}`) as User
  }

  private static newFormGroup (): FormGroup {
    const group = {
      newPassword: new FormControl('', [ Validators.required, Validators.minLength(biz.PASSWORD_MIN_LENGTH) ]),
      rePassword: new FormControl('', [ Validators.required ])
    }

    return new FormGroup(group, AppValidators.mismatchedFields('newPassword', 'rePassword'))
  }
}
