import { Component, Input, OnInit } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import { TopicService, ViewService } from '../../services'
import { ShortDatePipe } from '../../pipes'

@Component({
  selector: 'topic-recipients-tab',
  templateUrl: './topic-recipients-tab.html',
  providers: [TranslatePipe, ShortDatePipe]
})

export class TopicRecipientsTab implements OnInit {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'topic' has no initializer and is not def... Remove this comment to see the full error message
  @Input() topic: string


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'groups' has no initializer and is not de... Remove this comment to see the full error message
  public groups: any[]
  public loading = false

  constructor (
    private service: TopicService,
    private viewService: ViewService
  ) {
  }

  public ngOnInit () {
    if (this.topic) {
      this.getGroups()
    }
  }

  public toggleGroup (opened: boolean, group: any) {
    if (opened) {
      this.getGroupDetails(group)
    }
  }

  private getGroups () {
    this.loading = true

    this.service.getDeliveryGroups(this.topic)
    .finally(() => { this.loading = false })
    .subscribe(
      groups => {
        this.groups = groups
        this.groups.forEach(group => {
          group.open = false
        })
      },
      this.viewService.handleError
    )
  }

  private getGroupDetails (group: any) {
    if (!group.students && !group.loading) {
      group.loading = true

      this.service.getDeliveryGroupDetails(this.topic, group._id)
      .finally(() => { group.loading = false })
      .subscribe(
        (studentsDetails: any) => {
          group.total = studentsDetails.length
          group.students = studentsDetails
        },
        this.viewService.handleError
      )
    }
  }
}
