<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="goBack()">
      <i class="fa fa-arrow-left" aria-hidden="true"
          [tooltip]="'COMMON.BTN_BACK' | translate"
          placement="bottom"></i>
    </a>
  </h1>
</section>

<section class="content text-center text-muted" *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</section>

<section class="content" *ngIf="!isLoading">
  <div class="row">
    <div class="col-xs-12">
      <form class="form-fixed-help"
          *ngIf="form"
          [formGroup]="form"
          (ngSubmit)="submit()">

        <div class="row">
          <div class="form-group col-sm-4 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.name.valid && form.controls.name.touched }">
            <label>
              {{ 'GRADE.NAME' | translate }}
              <required-icon></required-icon>
            </label>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              {{ form.get('name').value }}
            </p>
            <input type="text"
                *ngIf="isSaveAllowed"
                class="form-control"
                formControlName="name">
            <span class="help-block">
              <span *ngIf="form.controls.name.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>

          <div class="form-group col-sm-4 col-md-3"
              [ngClass]="{ 'has-error': !form.controls.order.valid && form.controls.order.touched }">
            <label>
              {{ 'GRADE.ORDER' | translate }}
              <required-icon></required-icon>
            </label>
            <p class="form-control-static" *ngIf="!isSaveAllowed">
              {{ form.get('order').value }}
            </p>
            <input type="number"
                *ngIf="isSaveAllowed"
                class="form-control"
                formControlName="order">
            <span class="help-block">
              <span *ngIf="form.controls.order.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-xs-12"
              [ngClass]="{ 'has-error': !form.controls.subjects.valid && form.controls.subjects.touched }">
            <label>
              {{ 'SUBJECT.SEARCH_TITLE' | translate }}
              <required-icon></required-icon>
            </label>
            <tags-input
                formControlName="subjects"
                identifyBy="_id"
                displayBy="name"
                [readonly]="!isSaveAllowed"
                [source]="searchSubjects">
            </tags-input>
            <span class="help-block">
              <span *ngIf="form.controls.subjects.hasError('required')">{{ 'CLIENTERROR.CERR-00001' | translate }}</span>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="btn-toolbar btn-footer" role="toolbar">
              <button
                  type="submit"
                  class="btn btn-success btn-lg"
                  *ngIf="isSaveAllowed"
                  [disabled]="!form.valid"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_SAVE' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-default btn-lg"
                  (click)="goBack()">
                {{ 'COMMON.BTN_CANCEL' | translate }}
              </button>
              <button
                  type="button"
                  class="btn btn-danger btn-lg pull-right-md"
                  *ngIf="isDeleteAllowed"
                  (click)="remove()"
                  [ladda]="isSubmitting">
                {{ 'COMMON.BTN_REMOVE' | translate }}
              </button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</section>
