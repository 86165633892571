import { Component } from '@angular/core'
import { ActivatedRoute, Params } from '@angular/router'
import { Observable } from 'rxjs/Observable'

import * as _ from 'lodash-es'

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { DivisionService, ViewService, TeacherService, DateService, SubjectService } from '../../services'
import { DetailView, Rating, RatingValidator, CrudService, Subject } from '../../interfaces'
import { QueryString } from '../../models'

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html'
})

export class RatingComponent extends DetailView<Rating> {

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'divisionId' has no initializer and is no... Remove this comment to see the full error message
  public divisionId: string

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'subjects' has no initializer and is not ... Remove this comment to see the full error message
  public subjects: Subject[]
  public isAssessmentAllowed = true
  public assessments: any
  public isLoading = false
  public isCreate = false

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'service' has no initializer and is not d... Remove this comment to see the full error message
  protected service: RatingWrapperService // protected to comply with DetailView signature

  constructor (
    viewService: ViewService,
    private divisionService: DivisionService,
    route: ActivatedRoute,
    private teacherService: TeacherService,

    // @ts-expect-error ts-migrate(6138) FIXME: Property 'dateService' is declared but its value i... Remove this comment to see the full error message
    private dateService: DateService,
    // @ts-expect-error ts-migrate(6138) FIXME: Property 'subjectService' is declared but its valu... Remove this comment to see the full error message
    private subjectService: SubjectService
  ) {
    super(viewService, new RatingWrapperService(divisionService), route, 'topic', RatingValidator.newFormGroup())
    this.options.populate = {
      'recipients.reference': 'name'
    }
  }

  public dateChanged () {
    // FIXME, this shouldn't be needed
    // We need to mark the control as touched manually with the datePicker PopUp. Otherwise, errors regarding the date
    // will not be shown unless the user manually changes the html input field

    this.form.controls.date.markAsTouched()
  }

  public onSave (assessments: any) {
    this.isLoading = true
    this.assessments = assessments
    if (this.isCreate) {
      this.submit()
    } else {
      this.onSaved(this.model)
    }
  }

  protected onParamsChange (params: Params) {
    this.isLoading = true

    this.divisionId = params.divisionId

    this.service.id = params.divisionId // set the division id for the wrapper service

    let operation
    const usr = this.viewService.getCurrentUser()
    if (usr.currentRole === biz.ROLES.TEACHER) {
      operation = this.teacherService.getSubjectsForDivision(usr._id, this.divisionId)
    } else {
      operation = Observable.of({ list: [] }) // empty response with no subjects
    }

    operation.finally(() => { this.isLoading = false })
    .subscribe(
      result => {
        this.subjects = result.list
        // only after subjects are returned we can continue with the detail-view machinery
        super.onParamsChange(params)
      },
      err => {
        this.isLoading = false
        this.viewService.handleError(err)
      }
    )
  }

  protected getValue () {
    return this.form.value
  }

  protected getDefaultDetails (): Rating {
    return {
      allowMessages: false,
      division: this.divisionId
    } as Rating
  }

  // @ts-expect-error ts-migrate(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  protected getDetailsFromParams (params: Params): Rating {
    if (!this.isCreate) {
      return {

        _id: params.id,
        allowMessages: false,

        division: params.divisionId
      } as Rating
    }
  }

  protected onModelLoaded (model: Rating) {
    if (this.isCreate) {
      model.subject = model.subject || this.subjects && this.subjects[0] && this.subjects[0]._id
    }
  }

  protected onSaved (t: Rating) {
    this.divisionService.updateRatingAssessments(this.divisionId, t._id, this.assessments).subscribe(() => {
      this.isLoading = false
      this.viewService.goBack()
    })
  }

  protected checkPermissions () {
    super.checkPermissions()

    if (!this.isCreate) {
      const subject = _.find(this.subjects, {_id: this.model.subject})

      // check if it's assigned to the current user
      this.isSaveAllowed = this.isSaveAllowed && !!subject
      this.isDeleteAllowed = this.isDeleteAllowed && !!subject
      // this.isAssessmentAllowed = !!subject
    }

  }
}

class RatingWrapperService extends CrudService<Rating> {

  constructor (
    private service: DivisionService,
    public id?: string
  ) {

    // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
    super(null, '')
  }

  public getById (id: string, params?: QueryString): Observable<Rating> {

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return this.service.getRatingById(this.id, id, params)
  }

  public create (object: any): Observable<any> {

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return this.service.addRating(this.id, object)
  }

  public update (id: string, object: any): Observable<any> {

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return this.service.updateRating(this.id, id, object)
  }

  public remove (id: string): Observable<any> {

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return this.service.removeRating(this.id, id)
  }
}
