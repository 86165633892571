<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="getItems()">
      <i class="fa fa-refresh" aria-hidden="true"
          [tooltip]="'COMMON.BTN_REFRESH' | translate"
          placement="bottom"></i>
    </a>
  </h1>
  <div class="btn-toolbar pull-right" role="toolbar">
    <a class="btn btn-success"
        routerLink="/topics/new"
        *ngIf="isCreateAllowed">
      <i class="fa fa-plus"></i>
      <span>{{ 'COMMON.BTN_NEW' | translate }}</span>
    </a>
  </div>
</section>

<section class="content">
  <div class="row search-panel">
    <div class="form-group col-sm-3">
      <label>{{ 'TOPIC.TYPE' | translate }}</label>
      <select class="form-control" [(ngModel)]="selectedTopic" (change)="updateTopicFilter()">
        <option *ngFor="let topicType of topicTypes" [ngValue]="topicType">
          {{ topicType | topicType }}
        </option>
      </select>
    </div>

    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'TOPIC.DETAIL_TITLE' | translate }}</label>
      <input
              type="text"
              [placeholder]="'COMMON.PLACEHOLDER' | translate"
              class="form-control"
              debounce
              [delay]="250"
              (func)="updateFilter()"
              [(ngModel)]="params.q"/>
    </div>

    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'TOPIC.AUTHOR' | translate }}</label>
      <tags-input #authors
                  displayBy="name"
                  [source]="searchAuthors"
                  (ngModelChange)="setAuthorsFilter(authors.innerValue)"
                  [(ngModel)]="selectedAuthors">
      </tags-input>
    </div>

    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'TOPIC.START_DATE' | translate }}</label>
      <datepicker-popup #start
                        [(ngModel)]="startDate"
                        [maxDate]="today"
                        [locale]="locale"
                        (change)="onStartDateChange(start.value.valueOf())">
      </datepicker-popup>
    </div>

    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'TOPIC.END_DATE' | translate }}</label>
      <datepicker-popup #end
                        [(ngModel)]="endDate"
                        [maxDate]="today"
                        [locale]="locale"
                        (change)="onEndDateChange(end.value.valueOf())">
      </datepicker-popup>
    </div>
  </div>

  <appdatatable
    [rows]="rows"
    [columns]="columns"
    [sorts]="params.sort"
    [count]="count"
    [limit]="params.limit"
    [loading]="loading"
    (page)="onPage($event)"
    (sort)="onSort($event)">
  </appdatatable>
</section>
