import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'


// @ts-expect-error ts-migrate(6133) FIXME: 'TermAndStage' is declared but its value is never ... Remove this comment to see the full error message
import { Stage, Grade, TermAndStage, StageDependantCrudService } from '../interfaces'
import { QueryString } from '../models'
import { AuthService } from './auth.service'

@Injectable()
export class StageService extends StageDependantCrudService<Stage> {

  constructor (http: HttpClient, auth: AuthService) {
    super(http, '/stages', auth, true)
  }

  // grades management
  public getGrades (params?: QueryString): Observable<any> {
    const gradesUrl = this.getGradesUrl()
    return this.http.get(gradesUrl, this.toRequestOptions(params))
  }

  public getGradeById (gradeId: string, params?: QueryString): Observable<any> {
    const gradesUrl = this.getGradesUrl()
    return this.http.get(`${gradesUrl}/${gradeId}`, this.toRequestOptions(params))
  }

  public addGrade (grade: Grade): Observable<any> {
    const gradesUrl = this.getGradesUrl()
    return this.http.post(gradesUrl, grade)
  }

  public updateGrade (gradeId: string, grade: Grade): Observable<any> {
    const gradesUrl = this.getGradesUrl()
    return this.http.patch(`${gradesUrl}/${gradeId}`, grade)
  }

  public removeGrade (gradeId: string): Observable<any> {
    const gradesUrl = this.getGradesUrl()
    return this.http.delete(`${gradesUrl}/${gradeId}`)
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'params' is declared but its value is never read.
  public getGradeSubjects (gradeId: string|Grade, params?: QueryString): Observable<any> {
    const gradesUrl = this.getGradesUrl()
    gradeId = this.toId(gradeId)

    return this.http.get(`${gradesUrl}/${gradeId}/subjects`)
  }

  private getGradesUrl () {
    this.updateUrl()
    const stage = this.authService.getSelectedStage()
    return `${this.url}/${stage._id}/grades`
  }
}
