var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
// @ts-expect-error ts-migrate(6133) FIXME: 'TermAndStage' is declared but its value is never ... Remove this comment to see the full error message
import { Stage, Grade, StageDependantCrudService } from '../interfaces';
import { AuthService } from './auth.service';
var StageService = /** @class */ (function (_super) {
    __extends(StageService, _super);
    function StageService(http, auth) {
        return _super.call(this, http, '/stages', auth, true) || this;
    }
    // grades management
    StageService.prototype.getGrades = function (params) {
        var gradesUrl = this.getGradesUrl();
        return this.http.get(gradesUrl, this.toRequestOptions(params));
    };
    StageService.prototype.getGradeById = function (gradeId, params) {
        var gradesUrl = this.getGradesUrl();
        return this.http.get(gradesUrl + "/" + gradeId, this.toRequestOptions(params));
    };
    StageService.prototype.addGrade = function (grade) {
        var gradesUrl = this.getGradesUrl();
        return this.http.post(gradesUrl, grade);
    };
    StageService.prototype.updateGrade = function (gradeId, grade) {
        var gradesUrl = this.getGradesUrl();
        return this.http.patch(gradesUrl + "/" + gradeId, grade);
    };
    StageService.prototype.removeGrade = function (gradeId) {
        var gradesUrl = this.getGradesUrl();
        return this.http.delete(gradesUrl + "/" + gradeId);
    };
    // @ts-expect-error ts-migrate(6133) FIXME: 'params' is declared but its value is never read.
    StageService.prototype.getGradeSubjects = function (gradeId, params) {
        var gradesUrl = this.getGradesUrl();
        gradeId = this.toId(gradeId);
        return this.http.get(gradesUrl + "/" + gradeId + "/subjects");
    };
    StageService.prototype.getGradesUrl = function () {
        this.updateUrl();
        var stage = this.authService.getSelectedStage();
        return this.url + "/" + stage._id + "/grades";
    };
    return StageService;
}(StageDependantCrudService));
export { StageService };
