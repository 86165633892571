import { PipeTransform } from '@angular/core';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
var TopicAttendanceLabelPipe = /** @class */ (function () {
    function TopicAttendanceLabelPipe() {
        var _a;
        this.LABELS = (_a = {},
            _a[biz.TOPIC_TYPES.LATE_ARRIVAL] = 'label-late',
            _a[biz.TOPIC_TYPES.ABSENCE] = 'label-absence',
            _a[biz.TOPIC_TYPES.JUSTIFIED_ABSENCE] = 'label-justified',
            _a);
    }
    TopicAttendanceLabelPipe.prototype.transform = function (value) {
        if (!value || typeof value !== 'string') {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
            return;
        }
        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        return this.LABELS[value] || '';
    };
    return TopicAttendanceLabelPipe;
}());
export { TopicAttendanceLabelPipe };
