import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { ConfirmationService } from '../services'
import { DetailView, PersistentModel } from '../interfaces'

@Injectable()
export class IsFormDirtyGuard implements CanDeactivate<DetailView<PersistentModel>> {
  constructor (private modalService: ConfirmationService) {}

  public canDeactivate (component: DetailView<PersistentModel>) {
    let result = component.isDirty()
    if (result) {
      return this.modalService.show('COMMON.MSG_DISCARD_CHANGES')
    } else {
      return !result
    }
  }
}
