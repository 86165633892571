var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { Column } from '../../models';
import { SubjectService, ViewService } from '../../services';
import { SearchView } from '../../interfaces';
var SubjectsComponent = /** @class */ (function (_super) {
    __extends(SubjectsComponent, _super);
    function SubjectsComponent(viewService, route, service) {
        var _this = _super.call(this, viewService, route, service, 'subjects', 'subject') || this;
        _this.columns = [
            _this.routerColumn('name', 'SUBJECT.NAME', { isPrimary: true }),
            new Column('description', 'SUBJECT.DESCRIPTION')
        ];
        // default sort
        _this.params.sort = { name: 1 };
        return _this;
    }
    return SubjectsComponent;
}(SearchView));
export { SubjectsComponent };
