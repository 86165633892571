import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Column } from '../../models'
import { SupervisorService, ViewService } from '../../services'
import { SearchView, User } from '../../interfaces'

@Component({
  selector: 'app-supervisors',
  templateUrl: './supervisors.component.html'
})

export class SupervisorsComponent extends SearchView<User> {
  public columns: Column [] = [
    this.routerColumn('_id', 'USER.ID', { isPrimary: true }),
    this.routerColumn('name.last', 'USER.LAST_NAME', { isPrimary: true }),
    this.routerColumn('name.first', 'USER.FIRST_NAME', { isPrimary: true }),


    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    new Column('contacts.0', 'USER.CONTACTS', false, null, 'contactCell')
  ]

  constructor (
    viewService: ViewService,
    route: ActivatedRoute,
    service: SupervisorService
  ) {
    super(viewService, route, service, 'supervisors', 'supervisor')
    // default sort
    this.params.sort = {
      'name.last': 1,
      'name.first': 1
    }
  }
}
