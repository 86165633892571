var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ApiService } from '../interfaces';
var MeService = /** @class */ (function (_super) {
    __extends(MeService, _super);
    function MeService(http, authService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.authService = authService;
        _this.url = _this.apiUrl + '/me';
        _this.tenantSubscribers = [];
        return _this;
    }
    MeService.prototype.changePassword = function (body) {
        var _this = this;
        body.isInternal = true;
        return this.http.put(this.url + "/password", body)
            .map(function (body) {
            _this.authService.saveData(body.token, body.user, body.permissions, body.terms);
            return body.user;
        });
    };
    MeService.prototype.getTenant = function () {
        return this.http.get(this.url + "/tenant")
            .map(function (res) { return res; });
    };
    MeService.prototype.switchTenant = function (idTenant) {
        var _this = this;
        return this.http.post(this.url + "/tenant/switch/" + idTenant, {})
            .map(function (body) {
            _this.authService.saveData(body.token, body.user, body.permissions, body.terms, true);
            _this.tenantSubscribers.forEach(function (subscriber) {
                if (_this.authService.isLoggedIn()) {
                    subscriber.onTenantChange(_this.authService.getCurrentUser().currentTenant);
                }
            });
        });
    };
    MeService.prototype.getMe = function () {
        return this.authService.getCurrentUser();
    };
    MeService.prototype.subscribeTenantChange = function (subscriber) {
        this.tenantSubscribers.push(subscriber);
    };
    return MeService;
}(ApiService));
export { MeService };
