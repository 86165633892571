import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, forwardRef, SimpleChanges } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker'
import { defineLocale } from 'ngx-bootstrap/chronos'
import * as bsLocales from 'ngx-bootstrap/locale'
import * as moment from 'moment'

// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export const DATEPICKER_POPUP_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickerPopup),
  multi: true
}

@Component({
  selector: 'datepicker-popup',
  templateUrl: './datepicker.popup.html',
  styleUrls: ['./datepicker.popup.styl'],
  providers: [DATEPICKER_POPUP_VALUE_ACCESSOR]
})

export class DatepickerPopup implements ControlValueAccessor {
  public config: Partial<BsDatepickerConfig>
  public innerValue: any
  public innerMinDate: any
  public innerMaxDate: any

  public onChange: any = Function.prototype
  public onTouched: any = Function.prototype

  @Input() format: string = 'DD/MM/YYYY'


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'locale' has no initializer and is not de... Remove this comment to see the full error message
  @Input() locale: string
  @Output() change = new EventEmitter<any>()
  @ViewChild('popup') popup: any

  private writes = 0

  constructor (
    protected element: ElementRef,
    private bsLocaleService: BsLocaleService
  ) {
    this.config = Object.assign({}, {
      containerClass: 'theme-blue',
      displayMonths: 1,
      showWeekNumbers: false
      // dateInputFormat: 'L'
    })
  }

  public ngOnChanges (changes: SimpleChanges) {


    if (changes.locale) {
      const localeKey = `${this.locale}Locale`


      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (bsLocales[localeKey]) {


        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        defineLocale(this.locale, bsLocales[localeKey])
        this.bsLocaleService.use(this.locale)
      }
    }
  }

  public get value (): any {
    if (this.innerValue) {
      return moment(this.innerValue)
    }
  }

  @Input()
  public set value (_value: any) {
    if (_value) {
      this.innerValue = moment(_value).toDate()
    } else {
      this.innerValue = undefined
    }
  }

  @Input()
  public set minDate (_minDate: any) {
    if (_minDate) {
      this.innerMinDate = moment(_minDate).toDate()
    } else {
      this.innerMinDate = undefined
    }
  }

  @Input()
  public set maxDate (_maxDate: any) {
    if (_maxDate) {
      this.innerMaxDate = moment(_maxDate).toDate()
    } else {
      this.innerMaxDate = undefined
    }
  }

  public onBlur () {
    this.onTouched()
  }

  public onModelChange (newValue: any) {
    if (!this.writes) {
      if (newValue !== this.innerValue) {
        this.innerValue = newValue
        this.onChange(this.value)
        this.change.emit(this.value)
      }
    } else {
      this.writes--
    }
  }

  // ControlValueAccessor
  // model -> view
  public writeValue (value: any): void {
    this.value = value
    this.writes++
  }

  public registerOnChange (fn: any): void {
    this.onChange = fn
  }

  public registerOnTouched (fn: any): void {
    this.onTouched = fn
  }
}
