import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'


// @ts-expect-error ts-migrate(6133) FIXME: 'CrudService' is declared but its value is never r... Remove this comment to see the full error message
import { SearchView, CrudService, Album, Division } from '../../interfaces'
import { ViewService, DivisionService, AlbumService } from '../../services'
import { Column } from '../../models'

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html'
})
export class AlbumsComponent extends SearchView<Album> {
  public columns: Column[]
  public divisions: Division[] = []

  constructor (
    viewService: ViewService,
    route: ActivatedRoute,
    albumService: AlbumService,
    divisionService: DivisionService
  ) {
    super(viewService, route, albumService, 'albums', 'topic', 'create')
    this.params.populate = 'division'
    this.params.conditions = { division: '' }

    this.columns = [
      this.routerColumn('title', 'ALBUM.TITLE', { isPrimary: true }),
      this.routerColumn('division.name', 'ALBUM.DIVISION', { isPrimary: true })
    ]

    divisionService.search().subscribe(results => {
      this.divisions = results.list
    })
  }
}

