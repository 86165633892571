<div ng2FileDrop
    [ngClass]="{ 'dragover': hovering }"
    (fileOver)="onFileOver($event)"
    [uploader]="uploader"
    class="upload-image">
  <img [src]="source || placeholder">
</div>
<progressbar *ngIf="uploading" [value]="progress" class="progress-striped">
  {{ progress || 0 }}%
</progressbar>
