import { Component, Input, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { TranslatePipe } from '@ngx-translate/core'

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'
import { AuthService, TopicService } from '../../services'
import { environment } from '../../../environments/environment'
import { RichEditor } from '../rich-editor'
import { LoggedUser } from '../../interfaces'

@Component({
  selector: 'topic-panel',
  templateUrl: './topic.panel.html',
  providers: [TranslatePipe]
})

export class TopicPanel {

  // @ts-expect-error ts-migrate(2564) FIXME: Property 'form' has no initializer and is not defi... Remove this comment to see the full error message
  @Input() form: FormGroup

  @Input() mode!: 'create' | 'edit' | 'readonly'
  @Input() locale: string

  @ViewChild('editor') editor!: RichEditor;

  public tags = 'attachment topic'
  public preset: string = environment.CLOUDINARY.ATTACHMENTS_PRESET

  public focusOnRecipients = false
  public loadingRecipients = false
  public allowedRecipients = []
  public topicTypes = TopicService.TOPIC_COMPONENT_TYPES
  public audience = { guardian: true, student: false }
  public user: LoggedUser
  private oppositeAudience = {
    guardian: 'student',
    student: 'guardian'
  }
  public responseLink: string = ''
  public allowResponses: boolean = false

  constructor(authService: AuthService) {
    this.user = authService.getCurrentUser()
    this.locale = authService.getCurrentUser().locale
    this.responseLink = (authService.getSelectedStage().responseLink ?? '').trim()
  }

  ngAfterViewInit() {
    this.addUserSignatureToTextEditor()
  }

  private addUserSignatureToTextEditor() {
    if (this.mode !== 'create') {
      setTimeout(() => {
        this.allowResponses = this.editor.value?.includes(`<p><a href=\"${this.responseLink}\">Responder</a></p>`) === true;
      }, 0)
      return;
    }
    const fullName = `${this.user.name.first} ${this.user.name.last}`
    const email = this.user.contacts.filter(e => e.type === "email").sort(e => e.tag === "work" ? -1 : 1)[0]?.value
    const emailStr = email ? `<br><a href=\"mailto:${email}\">${email}</a>` : ''
    const responseStr = this.allowResponses && this.responseLink !== '' ? `<p><a href=\"${this.responseLink}\">Responder</a></p>` : ''
    this.editor.writeValue(`<br><br><br>───────────<br>${fullName}${emailStr}${responseStr}`)
  }

  public onTypeChange() {


    this.allowedRecipients = biz.TOPIC_RECIPIENT_TYPES[this.form.controls.type.value]
    this.audience = this.form.value.audience // sync form and local audience value
  }

  public onAudienceChange(role: any) {
    const anyAudience = this.audience.guardian || this.audience.student
    if (!anyAudience) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      this.audience[this.oppositeAudience[role]] = true
    }

    this.form.controls.audience.setValue(this.audience) // sync form and local audience value
  }

  public toggleLoadingRecipients(active: boolean) {
    this.loadingRecipients = active
    // console.log(`loading recipients: ${active}`)
  }

  public onAttachmentUpload(res: any) {
    this.form.controls.attachments.value.push(res)
  }

  public removeAttachment(index: any) {
    this.form.controls.attachments.value.splice(index, 1)
  }

  public onChangeAllowResponses() {
    this.allowResponses = !this.allowResponses;
    if (this.allowResponses) {
      this.editor.writeValue(this.editor.value + `<p><a href=\"${this.responseLink}\">Responder</a></p>`)
    } else {
      this.editor.writeValue(this.editor.value?.replace(`<p><a href=\"${this.responseLink}\">Responder</a></p>`, ''))
    }
  }
}
