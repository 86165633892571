import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable'
import { Column } from '../../../models'
import { ViewService } from '../../../services'
import { SearchPanel } from '../../../interfaces'
import { ReportCard, ReportCardsService } from '../../../services/report-cards.service'

@Component({
  selector: 'student-report-cards-tab',
  templateUrl: './student-report-cards.tab.html',
  providers: [TranslatePipe]
})
export class StudentReportCardsTab extends SearchPanel<ReportCard[]> implements OnChanges {
  @Input() studentId!: string
  @Input() reportCards!: ReportCard[]

  public columns!: Column[]

  constructor (
    viewService: ViewService,
    private readonly reportCardsService: ReportCardsService
  ) {
    super(viewService, 'guardian')
  }

  public ngOnChanges (_: SimpleChanges) {
    if (this.studentId) {
      this.columns = [
        Column.forClick('title', 'REPORT_CARDS.REPORT_CARD_NAME', this.onSelect.bind(this), false, undefined, 120),
        Column.forClick('stageName', 'REPORT_CARDS.STAGE_NAME', this.onSelect.bind(this), false, undefined, 90, 90),
        Column.forClick('divisionName', 'REPORT_CARDS.DIVISION_NAME', this.onSelect.bind(this), false)
      ]
      this.updateFilter()
    }
  }

  public onSelect (selected: ReportCard) {
    return this.viewService.goToDetail('report-cards', 'report-card', selected)
  }

  protected fetch = (): Observable<ReportCard[]> =>
    this.reportCardsService.getReportCardsForStudent(this.studentId)
}
