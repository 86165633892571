var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Column } from '../../models';
import { SearchPanel } from '../../interfaces';
var StudentsTab = /** @class */ (function (_super) {
    __extends(StudentsTab, _super);
    function StudentsTab(viewService) {
        var _this = _super.call(this, viewService, 'user') || this;
        _this.params.limit = 50;
        _this.params.sort = {
            'name.last': 1,
            'name.first': 1
        };
        return _this;
    }
    StudentsTab.prototype.activate = function () {
        this.createColumns();
        this.updateFilter();
    };
    StudentsTab.prototype.onSelect = function (selected) {
        return this.viewService.goToDetail('students', 'student', selected);
    };
    StudentsTab.prototype.createColumns = function () {
        this.columns = [
            Column.forClick('_id', 'USER.ID', this.onSelect.bind(this)),
            Column.forClick('name.last', 'USER.LAST_NAME', this.onSelect.bind(this)),
            Column.forClick('name.first', 'USER.FIRST_NAME', this.onSelect.bind(this)),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.late', 'TOPIC_TYPES.late', false, null, 'warningBadgeCell', 90, 90, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.absence', 'TOPIC_TYPES.absence', false, null, 'dangerBadgeCell', 90, 90, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.justified', 'TOPIC_TYPES.justified', false, null, 'infoBadgeCell', 110, 110, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.stats.early_pick_up', 'TOPIC_TYPES.early_pick_up', false, null, 'infoBadgeCell', 110, 110, 'centeredColHeader'),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.tcAccepted', 'USER.TC_ACCEPTED', false, null, 'boolCell', 80, 80),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            new Column('membership.devices', 'USER.DEVICES', false, null, 'devicesCell', 90, 90)
        ];
    };
    return StudentsTab;
}(SearchPanel));
export { StudentsTab };
