import { forwardRef, DoCheck } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
// http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
export var TIME_ONLY_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return TimeOnlyPicker; }),
    multi: true
};
var TimeOnlyPicker = /** @class */ (function () {
    function TimeOnlyPicker() {
        this.onChange = Function.prototype;
        this.onTouched = Function.prototype;
    }
    Object.defineProperty(TimeOnlyPicker.prototype, "value", {
        get: function () {
            if (!this.innerValue) {
                return;
            }
            return {
                hour: this.innerValue.getHours(),
                minutes: this.innerValue.getMinutes()
            };
        },
        set: function (_value) {
            var adjusted = false;
            var date = new Date();
            if (_value) {
                if (_value.hour != null && _value.hour >= 0 && _value.hour <= 23) {
                    date.setHours(_value.hour);
                }
                else {
                    adjusted = true;
                }
                if (_value.minutes != null && _value.minutes >= 0 && _value.minutes <= 59) {
                    date.setMinutes(_value.minutes);
                }
                else {
                    date.setMinutes(0);
                    adjusted = true;
                }
                // this.innerValue = date
            }
            else {
                date.setMinutes(0);
                adjusted = true;
            }
            this.innerValue = date;
            this.oldValue = date.valueOf();
            if (adjusted) {
                // if (this.registered) {
                this.onChange(this.value);
                // } else {
                //   this.adjusted = true
                // }
            }
            // } else {
            //   this.adjusted = false
            // }
        },
        enumerable: false,
        configurable: true
    });
    TimeOnlyPicker.prototype.ngDoCheck = function () {
        // workaround since timepicker doesn't provide any hook for change detection
        if (!this.innerValue) {
            if (this.oldValue != undefined) {
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
                this.oldValue = undefined;
                this.onChange(this.value);
            }
        }
        else {
            if (this.innerValue.valueOf() !== this.oldValue) {
                this.oldValue = this.innerValue.valueOf();
                this.onChange(this.value);
            }
        }
    };
    TimeOnlyPicker.prototype.onBlur = function () {
        this.onTouched();
    };
    // ControlValueAccessor
    // model -> view
    TimeOnlyPicker.prototype.writeValue = function (value) {
        this.value = value;
    };
    TimeOnlyPicker.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
        // this.registered = true
        // if (this.adjusted) {
        //   this.onChange(this.value)
        // }
    };
    TimeOnlyPicker.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    return TimeOnlyPicker;
}());
export { TimeOnlyPicker };
