import { Injectable } from '@angular/core'


// @ts-expect-error ts-migrate(6133) FIXME: 'Response' is declared but its value is never read... Remove this comment to see the full error message
import { Response } from '@angular/http'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { AuthService } from './auth.service'
import { ApiService, LoggedUser, Tenant } from '../interfaces'

@Injectable()
export class MeService extends ApiService {
  private url = this.apiUrl + '/me'
  private tenantSubscribers: any[] = []

  constructor (
    private http: HttpClient,
    private authService: AuthService
  ) {
    super()
  }

  public changePassword (body: any): Observable<LoggedUser> {
    body.isInternal = true
    return this.http.put(`${this.url}/password` , body)
    .map((body: any) => {
      this.authService.saveData(body.token, body.user, body.permissions, body.terms)
      return body.user as LoggedUser
    })
  }

  public getTenant (): Observable<Tenant> {
    return this.http.get(`${this.url}/tenant`)
    .map((res: any) => res as Tenant)
  }

  public switchTenant (idTenant: string): Observable<any> {
    return this.http.post(`${this.url}/tenant/switch/${idTenant}`, {})
    .map((body: any) => {
      this.authService.saveData(body.token, body.user, body.permissions, body.terms, true)
      this.tenantSubscribers.forEach(subscriber => {
        if (this.authService.isLoggedIn()) {
          subscriber.onTenantChange(this.authService.getCurrentUser().currentTenant)
        }
      })
    })
  }

  public getMe (): LoggedUser {
    return this.authService.getCurrentUser()
  }

  public subscribeTenantChange (subscriber: any) {
    this.tenantSubscribers.push(subscriber)
  }
}
