var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { StageDependantCrudService } from '../interfaces';
import { AuthService } from './auth.service';
var StudentService = /** @class */ (function (_super) {
    __extends(StudentService, _super);
    function StudentService(http, authService) {
        var _this = _super.call(this, http, '/students', authService) || this;
        _this.authService = authService;
        return _this;
    }
    StudentService.prototype.getGuardians = function (id, params) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/guardians", this.toRequestOptions(params));
    };
    StudentService.prototype.addGuardian = function (id, guardian) {
        this.updateUrl();
        return this.http.post(this.url + "/" + id + "/guardians", guardian);
    };
    StudentService.prototype.updateGuardian = function (id, guardianId, guardian) {
        this.updateUrl();
        return this.http.patch(this.url + "/" + id + "/guardians/" + guardianId, guardian);
    };
    StudentService.prototype.removeGuardian = function (id, guardianId) {
        this.updateUrl();
        return this.http.delete(this.url + "/" + id + "/guardians/" + guardianId);
    };
    StudentService.prototype.getAttendance = function (id) {
        this.updateUrl();
        return this.http.get(this.url + "/" + id + "/attendance");
    };
    return StudentService;
}(StageDependantCrudService));
export { StudentService };
