/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./avatar.component";
var styles_Avatar = [];
var RenderType_Avatar = i0.ɵcrt({ encapsulation: 2, styles: styles_Avatar, data: {} });
export { RenderType_Avatar as RenderType_Avatar };
export function View_Avatar_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "img-circle"]], [[8, "src", 4], [8, "alt", 0], [8, "width", 0], [8, "height", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.avatarSrc; var currVal_1 = _co.name; var currVal_2 = _co.size; var currVal_3 = _co.size; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_Avatar_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "avatar", [], null, null, null, View_Avatar_0, RenderType_Avatar)), i0.ɵdid(1, 573440, null, 0, i1.Avatar, [], null, null)], null, null); }
var AvatarNgFactory = i0.ɵccf("avatar", i1.Avatar, View_Avatar_Host_0, { photo: "photo", name: "name", size: "size" }, {}, []);
export { AvatarNgFactory as AvatarNgFactory };
