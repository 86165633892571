import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { CrudService, Term } from '../interfaces'
import { QueryString } from '../models'
import { AuthService } from './auth.service'

@Injectable()
export class TermService extends CrudService<Term> {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'searchObservable' has no initializer and... Remove this comment to see the full error message
  private searchObservable: Observable<any>
  private searchResult: any

  private structureCache: any = {}

  constructor (
    http: HttpClient,
    private authService: AuthService
  ) {
    super(http, '/terms', (e) => e) // TODO implement typeDeserializer
  }

  public search (params?: QueryString): Observable<any> {
    const cacheable = !params || !params.hasFilters()
    if (!cacheable) {
      return super.search(params)
    }

    // based on https://stackoverflow.com/a/36291681/2115580
    if (this.searchResult) {
      return Observable.of(this.searchResult)
    } else if (this.searchObservable) {
      return this.searchObservable
    }

    this.searchObservable = super.search(params)
    .map(result => {
      this.searchResult = result


      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Observable<... Remove this comment to see the full error message
      this.searchObservable = null
      return result
    })
    .share() // make it shared so more than one subscriber can get the result

    return this.searchObservable
  }

  public getSchoolStructure (id: string): Observable<any> {
    let entry = this.structureCache[id] = this.structureCache[id] || {}

    if (entry.result) {
      return Observable.of(entry.result)
    } else if (entry.observable) {
      return entry.observable
    }

    entry.observable = this.http.get(`${this.url}/${id}/structure`)
    .map(result => {
      entry.result = result
      entry.observable = null
      return entry.result
    })
    .share() // make it shared so more than one subscriber can get the result

    return entry.observable
  }

  public getCurrentStructure (): Observable<any[]> {
    const term = this.authService.getSelectedTerm()
    if (!term) {
      return Observable.of([])
    }
    return this.getSchoolStructure(term._id)
  }
}
