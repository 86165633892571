'use strict'

/**
 * IMPORTANT!
 * Do not use any ES6 syntax feature in this file so we can re-use it from
 * admin. If not, webpack (uglify actually) will complain about an unexpected
 * token. For more info check the following link:
 * https://github.com/angular/angular-cli/issues/1663
 */

// constants related to the business of the app

const _ = require('lodash')
const biz = require('nexedu-constants').biz

biz.values = _.values

module.exports = biz
