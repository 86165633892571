var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { SubjectService, ViewService } from '../../services';
import { DetailView, SubjectValidator } from '../../interfaces';
var SubjectComponent = /** @class */ (function (_super) {
    __extends(SubjectComponent, _super);
    function SubjectComponent(viewService, service, route) {
        var _this = _super.call(this, viewService, service, route, 'subject', SubjectValidator.newFormGroup()) || this;
        _this.deletePermission = 'remove';
        return _this;
    }
    SubjectComponent.prototype.getValue = function () {
        return this.form.value;
    };
    return SubjectComponent;
}(DetailView));
export { SubjectComponent };
