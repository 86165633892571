var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Column } from '../../models';
import { ViewService, DivisionService } from '../../services';
import { SearchPanel } from '../../interfaces';
import { UsersArrayPipe, UsernamePipe } from '../../pipes';
var DivisionCoursesTab = /** @class */ (function (_super) {
    __extends(DivisionCoursesTab, _super);
    function DivisionCoursesTab(service, viewService) {
        var _this = _super.call(this, viewService, 'division', 'add-course') || this;
        _this.service = service;
        _this.isSaveAllowed = false;
        _this.isSubmitting = false;
        _this.isRemoving = false;
        _this.params.populate = {
            subject: 'name',
            teachers: 'name'
        };
        _this.params.select = 'subject teachers';
        _this.isSaveAllowed = viewService.isAllowed('division', 'add-course');
        return _this;
    }
    DivisionCoursesTab.prototype.activate = function () {
        this.createColumns();
        this.updateFilter();
    };
    DivisionCoursesTab.prototype.switchToEditionMode = function (model) {
        if (model === void 0) { model = {}; }
        this.model = model;
    };
    DivisionCoursesTab.prototype.cancelEditionMode = function () {
        this.createColumns();
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'Course... Remove this comment to see the full error message
        this.model = undefined;
        this.updateFilter();
    };
    DivisionCoursesTab.prototype.fetch = function (params) {
        return this.service.getCourses(this.division._id, params);
    };
    DivisionCoursesTab.prototype.createColumns = function () {
        var canRemove = this.viewService.isCurrentTermSelected();
        var actions = [{
                class: 'fa-times action-icon-danger',
                onClick: this.onRemove.bind(this),
                visible: function () { return canRemove; }
            }];
        this.columns = [
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            Column.forClick('subject.name', 'SUBJECT.DETAIL_TITLE', this.switchToEditionMode.bind(this), true, null, 200, 200),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            Column.forClick('teachers', 'TEACHER.SEARCH_TITLE', this.switchToEditionMode.bind(this), false, new UsersArrayPipe(new UsernamePipe()), null, null, 'defaultLabelsCell'),
            Column.forActions(actions, 50)
        ];
    };
    DivisionCoursesTab.prototype.onRemove = function (row) {
        var _this = this;
        this.viewService.askConfirmation('COMMON.MSG_CONFIRM_REMOVE')
            .subscribe(function (confirmed) {
            if (confirmed) {
                _this.isRemoving = true;
                _this.service.removeCourse(_this.division._id, row._id)
                    .finally(function () { return _this.isRemoving = false; })
                    .subscribe(
                // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
                function (data) {
                    _this.viewService.toastr.successT('COMMON.MSG_OPERATION_OK');
                    _this.getItems();
                }, _this.viewService.handleError);
            }
        });
    };
    return DivisionCoursesTab;
}(SearchPanel));
export { DivisionCoursesTab };
