/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./term-picker.component";
import * as i3 from "../../services/auth.service";
var styles_TermPickerComponent = [];
var RenderType_TermPickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TermPickerComponent, data: {} });
export { RenderType_TermPickerComponent as RenderType_TermPickerComponent };
function View_TermPickerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "dropdown-toggle"], ["data-toggle", "dropdown"], ["href", "#"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedTerm.name; _ck(_v, 3, 0, currVal_0); }); }
function View_TermPickerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "hidden": 0 }), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeSelectedTerm(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_v.context.$implicit._id === ((_co.selectedTerm == null) ? null : _co.selectedTerm._id))); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_1); }); }
export function View_TermPickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TermPickerComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "ul", [["class", "term-picker dropdown-menu"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TermPickerComponent_2)), i0.ɵdid(6, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedTerm; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.terms; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_TermPickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "termpicker"]], null, null, null, View_TermPickerComponent_0, RenderType_TermPickerComponent)), i0.ɵdid(1, 245760, null, 0, i2.TermPickerComponent, [i3.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermPickerComponentNgFactory = i0.ɵccf(".termPicker", i2.TermPickerComponent, View_TermPickerComponent_Host_0, {}, {}, []);
export { TermPickerComponentNgFactory as TermPickerComponentNgFactory };
