var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { CrudService } from '../interfaces';
import { AuthService } from './auth.service';
var TermService = /** @class */ (function (_super) {
    __extends(TermService, _super);
    function TermService(http, authService) {
        var _this = _super.call(this, http, '/terms', function (e) { return e; }) // TODO implement typeDeserializer
         || this;
        _this.authService = authService;
        _this.structureCache = {};
        return _this;
    }
    TermService.prototype.search = function (params) {
        var _this = this;
        var cacheable = !params || !params.hasFilters();
        if (!cacheable) {
            return _super.prototype.search.call(this, params);
        }
        // based on https://stackoverflow.com/a/36291681/2115580
        if (this.searchResult) {
            return Observable.of(this.searchResult);
        }
        else if (this.searchObservable) {
            return this.searchObservable;
        }
        this.searchObservable = _super.prototype.search.call(this, params)
            .map(function (result) {
            _this.searchResult = result;
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Observable<... Remove this comment to see the full error message
            _this.searchObservable = null;
            return result;
        })
            .share(); // make it shared so more than one subscriber can get the result
        return this.searchObservable;
    };
    TermService.prototype.getSchoolStructure = function (id) {
        var entry = this.structureCache[id] = this.structureCache[id] || {};
        if (entry.result) {
            return Observable.of(entry.result);
        }
        else if (entry.observable) {
            return entry.observable;
        }
        entry.observable = this.http.get(this.url + "/" + id + "/structure")
            .map(function (result) {
            entry.result = result;
            entry.observable = null;
            return entry.result;
        })
            .share(); // make it shared so more than one subscriber can get the result
        return entry.observable;
    };
    TermService.prototype.getCurrentStructure = function () {
        var term = this.authService.getSelectedTerm();
        if (!term) {
            return Observable.of([]);
        }
        return this.getSchoolStructure(term._id);
    };
    return TermService;
}(CrudService));
export { TermService };
