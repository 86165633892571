import { Pipe } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import { PrefixedTranslatePipe } from './prefixed-translate.pipe'

@Pipe({
  name: 'contactTag',
  pure: false
})
export class ContactTagPipe extends PrefixedTranslatePipe {

  constructor (translatePipe: TranslatePipe) {
    super(translatePipe, 'CONTACT_TAGS.')
  }
}
