<div class="modal fade" bsModal #batchJobFileModal="bs-modal"
     tabindex="-1" role="dialog" aria-labelledby="Upload File" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <form class="form-fixed-help">
      <div class="modal-content">

        <!-- HEADER -->
        <div class="modal-header">
          <h4 class="modal-title pull-left">{{ title | translate }}</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- /HEADER -->

        <!-- BODY -->
        <div class="modal-body">
          <p>{{ 'BATCH_JOB.UPLOAD_FILE_HELP' | translate }}</p>
          <div class="form-group">
            <upload-file [tags]="tags" [preset]="preset" (onUpload)="processData($event)"></upload-file>
          </div>
        </div>
        <!-- /BODY -->

      </div>
    </form>
  </div>
</div>
