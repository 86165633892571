import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import { Observable } from 'rxjs/Observable'
import { Column } from '../../models'
import { ViewService, DivisionService } from '../../services'
import { SearchPanel } from '../../interfaces'
import { QueryString } from '../../models'
import { SchoolShiftPipe } from '../../pipes'

@Component({
  selector: 'student-divisions-tab',
  templateUrl: './student-divisions.tab.html',
  providers: [TranslatePipe]
})
export class StudentDivisionsTab extends SearchPanel<any> implements OnChanges {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'studentId' has no initializer and is not... Remove this comment to see the full error message
  @Input() studentId: string

  // FIXME this should be removed and `rows`
  // from `SearchPanel` should be used instead


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'divisions' has no initializer and is not... Remove this comment to see the full error message
  @Input() divisions: any[]



  // @ts-expect-error ts-migrate(2564) FIXME: Property 'columns' has no initializer and is not d... Remove this comment to see the full error message
  public columns: Column []
  public isRemoving = false

  constructor (
    viewService: ViewService,
    private service: DivisionService,
    private translatePipe: TranslatePipe
  ) {
    super(viewService, 'guardian')
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'changes' is declared but its value is never read.
  public ngOnChanges (changes: SimpleChanges) {
    if (this.studentId) {
      this.createColumns()
      this.updateFilter()
    }
  }

  public onSelect (selected: any) {
    return this.viewService.goToDetail('divisions', 'division', selected)
  }



  // @ts-expect-error ts-migrate(6133) FIXME: 'params' is declared but its value is never read.
  protected fetch (params: QueryString): Observable<any> {
    return Observable.of(this.divisions)
  }

  private createColumns (): void {
    const canRemove = this.viewService.isCurrentTermSelected()
    const actions = [{
      class: 'fa-times action-icon-danger',
      onClick: this.onRemove.bind(this),
      visible: () => canRemove
    }]
    this.columns = [
      Column.forClick('shift', 'DIVISION.SHIFT', this.onSelect.bind(this), false, new SchoolShiftPipe(this.translatePipe), 90, 90, 'defaultLabelCell'),
      Column.forClick('name', 'DIVISION.NAME', this.onSelect.bind(this)),
      Column.forActions(actions, 50)
    ]
  }

  private onRemove (row: any) {
    this.viewService.askConfirmation('COMMON.MSG_CONFIRM_REMOVE')
    .subscribe(confirmed => {
      if (confirmed) {
        this.isRemoving = true

        this.service.removeStudent(row._id, this.studentId)
        .finally(() => this.isRemoving = false)
        .subscribe(


          // @ts-expect-error ts-migrate(6133) FIXME: 'data' is declared but its value is never read.
          data => {
            this.viewService.toastr.successT('COMMON.MSG_OPERATION_OK')
            const index = this.divisions.findIndex(division => division._id === row._id)
            this.divisions.splice(index, 1)
          },
          this.viewService.handleError
        )
      }
    })
  }
}
