import { Component } from '@angular/core'


// @ts-expect-error ts-migrate(6133) FIXME: 'FormGroup' is declared but its value is never rea... Remove this comment to see the full error message
import { FormGroup } from '@angular/forms'
import { ViewService } from '../../services'

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent {
  public products: any = {}

  constructor (


    // @ts-expect-error ts-migrate(6138) FIXME: Property 'viewService' is declared but its value i... Remove this comment to see the full error message
    private viewService: ViewService
  ) {
    this.products.submitting = false
    this.products.range = {}
  }

  public listProducts () {
    // this.products.submitting = true

    // this.service.listProducts(this.products.range)
    // .finally(() => { this.products.submitting = false })
    // .subscribe(
    //   () => {
    //     this.viewService.toastr.infoT('COMMON.MSG_REPORT_OK')
    //   },
    //   this.viewService.handleError
    // )
  }

}
