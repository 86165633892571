import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { AppToastrService } from './app-toastr.service';
import { AppCacheService } from './app-cache.service';
import { ConfirmationService } from './confirmation.service';
import { AppTranslateService } from './app-translate.service';
import { QueryString } from '../models';
import * as _ from 'lodash-es';
import { environment } from '../../environments/environment';
var ViewService = /** @class */ (function () {
    function ViewService(auth, toastr, cache, confirmation, router, location, translate) {
        this.auth = auth;
        this.toastr = toastr;
        this.cache = cache;
        this.confirmation = confirmation;
        this.router = router;
        this.location = location;
        this.translate = translate;
        this.handleError = this.toastr.handleError;
    }
    ViewService.prototype.askConfirmation = function (message, title) {
        return this.confirmation.show(message, title);
    };
    ViewService.prototype.goBack = function () {
        this.location.back();
    };
    ViewService.prototype.goTo = function (commands, extras) {
        return this.router.navigate(commands, extras);
    };
    // @ts-expect-error ts-migrate(7030) FIXME: Not all code paths return a value.
    ViewService.prototype.goToDetail = function (path, resource, model, permission) {
        if (permission === void 0) { permission = 'get'; }
        if (!permission || this.auth.isAllowed(resource, permission)) {
            this.cache.set(resource + "_" + model._id, model);
            return this.router.navigate([path, model._id]);
        }
    };
    ViewService.prototype.save = function (_id, service, form, onSuccess, onFinally, value) {
        var _this = this;
        var operation;
        var message;
        onSuccess = onSuccess || Function.prototype;
        onFinally = onFinally || Function.prototype;
        value = value || form.value;
        if (_id) {
            operation = service.update(_id, value);
            message = 'COMMON.MSG_UPDATE_OK';
        }
        else {
            operation = service.create(value);
            message = 'COMMON.MSG_SAVE_OK';
        }
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Function' is not assignable to p... Remove this comment to see the full error message
        operation.finally(onFinally)
            .subscribe(function (data) {
            _this.toastr.successT(message);
            // this.form.reset()
            form.markAsPristine();
            // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onSuccess(data);
        }, this.handleError);
    };
    ViewService.prototype.getLocale = function () {
        return this.getCurrentUser().locale || this.getTenant().locale || environment.LOCALE;
    };
    ViewService.prototype.getTimezone = function () {
        return this.getTenant().tz || environment.TIMEZONE;
    };
    ViewService.prototype.isAllowed = function (resource, action) {
        return this.auth.isAllowed(resource, action);
    };
    ViewService.prototype.hasUserRole = function (role) {
        return this.auth.hasUserRole(role);
    };
    ViewService.prototype.isCurrentUser = function (userId) {
        return this.auth.isCurrentUser(userId);
    };
    ViewService.prototype.getCurrentUser = function () {
        return this.auth.getCurrentUser();
    };
    ViewService.prototype.getTenant = function () {
        return this.auth.getTenant();
    };
    ViewService.prototype.getSelectedTerm = function () {
        return this.auth.getSelectedTerm();
    };
    ViewService.prototype.getSelectedStage = function () {
        return this.auth.getSelectedStage();
    };
    ViewService.prototype.isCurrentTermSelected = function () {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        return this.auth.isCurrentTermSelected();
    };
    ViewService.prototype.isAllowedAndCurrentTerm = function (resource, action) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        return this.auth.isCurrentTermSelected() && this.auth.isAllowed(resource, action);
    };
    // @ts-expect-error ts-migrate(6133) FIXME: 'err' is declared but its value is never read.
    ViewService.prototype.handleError = function (err) {
        // empty implementation to be replaced in the constructor
    };
    ViewService.prototype.optionalList = function (list, emptyElement) {
        list = list.slice(0); // returns a shallow copy into a new array
        list.unshift(emptyElement);
        return list;
    };
    ViewService.prototype.clone = function (v) {
        return _.cloneDeep(v);
    };
    ViewService.prototype.onLangReady = function () {
        return this.translate.onReady();
    };
    ViewService.prototype.newTypeaheadSource = function (service, field, observable, conditions, exactMatchesOnly) {
        return Observable.create(observable)
            .mergeMap(function (filter) {
            var _a;
            var params = filter && new QueryString({
                limit: 10,
                conditions: (_a = {},
                    _a[field] = filter,
                    _a)
            });
            if (conditions) {
                params.conditions = params.conditions || {};
                Object.keys(conditions).forEach(function (key) {
                    params.conditions[key] = conditions[key];
                });
            }
            return service.search(params).map(function (result) {
                if (exactMatchesOnly) {
                    return result.list.filter(function (item) { return item[field] === filter; });
                }
                else {
                    return result.list;
                }
            });
        });
    };
    return ViewService;
}());
export { ViewService };
