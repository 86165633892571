var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
// @ts-expect-error ts-migrate(6133) FIXME: 'CrudService' is declared but its value is never r... Remove this comment to see the full error message
import { SearchView, Album, Division } from '../../interfaces';
import { ViewService, DivisionService, AlbumService } from '../../services';
var AlbumsComponent = /** @class */ (function (_super) {
    __extends(AlbumsComponent, _super);
    function AlbumsComponent(viewService, route, albumService, divisionService) {
        var _this = _super.call(this, viewService, route, albumService, 'albums', 'topic', 'create') || this;
        _this.divisions = [];
        _this.params.populate = 'division';
        _this.params.conditions = { division: '' };
        _this.columns = [
            _this.routerColumn('title', 'ALBUM.TITLE', { isPrimary: true }),
            _this.routerColumn('division.name', 'ALBUM.DIVISION', { isPrimary: true })
        ];
        divisionService.search().subscribe(function (results) {
            _this.divisions = results.list;
        });
        return _this;
    }
    return AlbumsComponent;
}(SearchView));
export { AlbumsComponent };
