/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../widgets/app-header/app-header.component.ngfactory";
import * as i2 from "../../widgets/app-header/app-header.component";
import * as i3 from "../../widgets/menu-aside/menu-aside.component.ngfactory";
import * as i4 from "../../widgets/menu-aside/menu-aside.component";
import * as i5 from "../../services/me.service";
import * as i6 from "../../services/view.service";
import * as i7 from "../../widgets/app-toastr/app-toastr.component.ngfactory";
import * as i8 from "../../widgets/app-toastr/app-toastr.component";
import * as i9 from "../../widgets/confirmation-modal/confirmation.modal.ngfactory";
import * as i10 from "../../widgets/confirmation-modal/confirmation.modal";
import * as i11 from "@angular/common";
import * as i12 from "@angular/router";
import * as i13 from "./auth";
import * as i14 from "../../services/confirmation.service";
import * as i15 from "../../services/term.service";
import * as i16 from "../../services/auth.service";
var styles_LayoutsAuthComponent = [];
var RenderType_LayoutsAuthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LayoutsAuthComponent, data: {} });
export { RenderType_LayoutsAuthComponent as RenderType_LayoutsAuthComponent };
export function View_LayoutsAuthComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 32, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i1.View_AppHeaderComponent_0, i1.RenderType_AppHeaderComponent)), i0.ɵdid(3, 49152, null, 0, i2.AppHeaderComponent, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-menu-aside", [], null, null, null, i3.View_MenuAsideComponent_0, i3.RenderType_MenuAsideComponent)), i0.ɵdid(6, 49152, null, 0, i4.MenuAsideComponent, [i5.MeService, i6.ViewService], { links: [0, "links"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(9, 0, null, null, 20, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-toastr", [], null, null, null, i7.View_AppToastrComponent_0, i7.RenderType_AppToastrComponent)), i0.ɵdid(13, 49152, null, 0, i8.AppToastrComponent, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(15, 0, null, null, 1, "confirmation-modal", [], null, [[null, "onInitialized"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onInitialized" === en)) {
        var pd_0 = (_co.onConfirmationModalInitialized($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ConfirmationModal_0, i9.RenderType_ConfirmationModal)), i0.ɵdid(16, 114688, null, 0, i10.ConfirmationModal, [], null, { onInitialized: "onInitialized" }), (_l()(), i0.ɵted(-1, null, ["\n\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(19, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(21, 0, null, null, 5, "div", [["class", "page-content-wrapper"]], null, null, null, null, null)), i0.ɵdid(22, 278528, null, 0, i11.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(24, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(25, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mylinks; _ck(_v, 6, 0, currVal_0); _ck(_v, 16, 0); var currVal_1 = "page-content-wrapper"; var currVal_2 = (_co.pageData.colWidth ? _co.pageData.colWidth : "col-xs-12"); _ck(_v, 22, 0, currVal_1, currVal_2); _ck(_v, 25, 0); }, null); }
export function View_LayoutsAuthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layouts-auth", [], null, null, null, View_LayoutsAuthComponent_0, RenderType_LayoutsAuthComponent)), i0.ɵdid(1, 245760, null, 0, i13.LayoutsAuthComponent, [i14.ConfirmationService, i12.Router, i12.ActivatedRoute, i15.TermService, i16.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutsAuthComponentNgFactory = i0.ɵccf("app-layouts-auth", i13.LayoutsAuthComponent, View_LayoutsAuthComponent_Host_0, {}, {}, []);
export { LayoutsAuthComponentNgFactory as LayoutsAuthComponentNgFactory };
