import { Injectable } from '@angular/core'
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthService } from '../services'
// import { AppToastrService } from '../services'

import * as _ from 'lodash-es'

@Injectable()
export class IsUserAllowedGuard implements CanActivate {

  constructor (
    private router: Router,
    private authService: AuthService
    // private toastr: AppToastrService
  ) {
  }

  public canActivate (
    route: ActivatedRouteSnapshot,

    // @ts-expect-error ts-migrate(6133) FIXME: 'state' is declared but its value is never read.
    state: RouterStateSnapshot
  ) {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['login'])
      return false
    }

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const data = route.routeConfig.data
    if (!data) {
      return true
    }

    const tenant = this.authService.getTenant()

    if (data.requiresTenant && !tenant) {
      // this.toastr.warningT('CERR-00007')
      console.warn('User is not allowed')
      this.router.navigate([''])
      return false
    }

    const resource = _.isFunction(data.resource) ? data.resource(route, tenant) : data.resource

    const permission = _.isFunction(data.permission) ? data.permission(route, tenant) : data.permission
    if (this.authService.isAllowed(resource, permission)) {
      return true
    }

    // this.toastr.warningT('BERR-X0007')
    console.warn('User is not allowed to view the page')
    this.router.navigate([''])
    return false
  }
}
