import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

@Component({
  selector: 'avatar',
  template: '<img class="img-circle" [src]="avatarSrc" [alt]="name" [width]="size" [height]="size"/>'
})
export class Avatar implements OnChanges {
  @Input() photo: any
  @Input() name: any
  @Input() size: any


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'avatarSrc' has no initializer and is not... Remove this comment to see the full error message
  public avatarSrc: string

  public ngOnChanges (changes: SimpleChanges) {

    if (changes.name) {
      this.avatarSrc = (window as any).LetterAvatar(`${changes.name.currentValue}`, this.size)
    }
    
    if (changes.photo) {
      this.avatarSrc = this.photo || (window as any).LetterAvatar(`${this.name}`, this.size)
    }

   
    
  }

}
