import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PersistentModel } from './persistent-model'
import { Term } from './term'
import { Subject } from './subject'
import { User } from './user'
import { Division } from './division'


// @ts-expect-error ts-migrate(6133) FIXME: 'TenantSummary' is declared but its value is never... Remove this comment to see the full error message
import { TenantSummary } from './tenant-summary'

export interface Course extends PersistentModel {
  teachers: User[]|string[]
  subject: Subject|string
  term: Term|string
  division: Division|string
}

export class CourseValidator {

  public static newFormGroup (model: Course): FormGroup {
    const fb = new FormBuilder()

    const formGroup = fb.group({
      subject: fb.control(model.subject, Validators.required),
      teachers: fb.control(model.teachers, Validators.required)
    })

    return formGroup
  }
}
