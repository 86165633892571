import { Component, Input } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'


// @ts-expect-error ts-migrate(6133) FIXME: 'TopicService' is declared but its value is never ... Remove this comment to see the full error message
import { TopicService } from '../../services'
import { ShortDatePipe } from '../../pipes'

@Component({
  selector: 'topic-action-badge',
  templateUrl: './topic-action-badge.html',
  providers: [TranslatePipe, ShortDatePipe]
})

export class TopicActionBadge {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'activities' has no initializer and is no... Remove this comment to see the full error message
  @Input() activities: any[]
}
