

// @ts-expect-error ts-migrate(6133) FIXME: 'OnInit' is declared but its value is never read.
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'batch-job-error-popover',
  templateUrl: './batch-job-error.popover.html'
})

export class BatchJobErrorPopover {
  @Input() errorsDetail: any
}
