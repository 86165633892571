/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ngx-chips/ngx-chips.ngfactory";
import * as i2 from "ngx-chips";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "../../pipes/recipient-type.pipe";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./recipients.selector";
import * as i8 from "../../services/division.service";
import * as i9 from "../../services/student.service";
import * as i10 from "../../services/view.service";
var styles_RecipientsSelector = [];
var RenderType_RecipientsSelector = i0.ɵcrt({ encapsulation: 2, styles: styles_RecipientsSelector, data: {} });
export { RenderType_RecipientsSelector as RenderType_RecipientsSelector };
function View_RecipientsSelector_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "delete-icon", [["aria-label", "Remove tag"], ["role", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeItem(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DeleteIconComponent_0, i1.RenderType_DeleteIconComponent)), i0.ɵdid(1, 49152, null, 0, i2.DeleteIconComponent, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n      "]))], null, null); }
function View_RecipientsSelector_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "tag__wrapper tag__water"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "tag__text inline"], ["spellcheck", "false"]], [[1, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(5, null, ["\n        ", "\n      "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecipientsSelector_3)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tag__wrapper tag__water"; var currVal_1 = _co.getItemClass(_v.context.item); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = !_co.readonly; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.item.itemName; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.item.itemName; _ck(_v, 5, 0, currVal_3); }); }
function View_RecipientsSelector_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "tag-input", [["placeholder", ""], ["secondaryPlaceholder", ""], ["theme", "nexedu-tags"]], [[1, "tabindex", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onAdd"], [null, "onRemove"], [null, "onBlur"], [null, "onFocus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.innerValue = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onAdd" === en)) {
        var pd_1 = (_co.onSelectionChange() !== false);
        ad = (pd_1 && ad);
    } if (("onRemove" === en)) {
        var pd_2 = (_co.onSelectionChange() !== false);
        ad = (pd_2 && ad);
    } if (("onBlur" === en)) {
        var pd_3 = (_co.onInputBlurred($event) !== false);
        ad = (pd_3 && ad);
    } if (("onFocus" === en)) {
        var pd_4 = (_co.onInputFocused($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_TagInputComponent_0, i1.RenderType_TagInputComponent)), i0.ɵdid(1, 4308992, [[1, 4]], 2, i2.TagInputComponent, [i0.Renderer2, i2.ɵc], { displayBy: [0, "displayBy"], identifyBy: [1, "identifyBy"], placeholder: [2, "placeholder"], secondaryPlaceholder: [3, "secondaryPlaceholder"], onlyFromAutocomplete: [4, "onlyFromAutocomplete"], theme: [5, "theme"], onTextChangeDebounce: [6, "onTextChangeDebounce"], clearOnBlur: [7, "clearOnBlur"], disable: [8, "disable"] }, { onAdd: "onAdd", onRemove: "onRemove", onFocus: "onFocus", onBlur: "onBlur" }), i0.ɵqud(335544320, 2, { dropdown: 0 }), i0.ɵqud(603979776, 3, { templates: 1 }), i0.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.TagInputComponent]), i0.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [2, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i0.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [i4.NgControl], null, null), (_l()(), i0.ɵted(-1, 0, ["\n\n  "])), (_l()(), i0.ɵand(0, [[3, 2]], 0, 0, null, View_RecipientsSelector_2)), (_l()(), i0.ɵted(-1, 0, ["\n\n  "])), (_l()(), i0.ɵeld(11, 0, null, 0, 3, "tag-input-dropdown", [], null, [["window", "scroll"], ["window", "blur"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).scrollListener() !== false);
        ad = (pd_0 && ad);
    } if (("window:blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12).onWindowBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_TagInputDropdown_0, i1.RenderType_TagInputDropdown)), i0.ɵdid(12, 114688, [[2, 4]], 1, i2.TagInputDropdown, [i0.Injector], { showDropdownIfEmpty: [0, "showDropdownIfEmpty"], autocompleteObservable: [1, "autocompleteObservable"], displayBy: [2, "displayBy"], identifyBy: [3, "identifyBy"], matchingFn: [4, "matchingFn"], appendToBody: [5, "appendToBody"] }, null), i0.ɵqud(603979776, 4, { templates: 1 }), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, 0, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "itemName"; var currVal_9 = "id"; var currVal_10 = ""; var currVal_11 = ""; var currVal_12 = true; var currVal_13 = "nexedu-tags"; var currVal_14 = 500; var currVal_15 = true; var currVal_16 = _co.readonly; _ck(_v, 1, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_17 = _co.innerValue; _ck(_v, 5, 0, currVal_17); var currVal_18 = true; var currVal_19 = _co.source; var currVal_20 = "itemName"; var currVal_21 = "id"; var currVal_22 = _co.matchingFn; var currVal_23 = true; _ck(_v, 12, 0, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).tabindexAttr; var currVal_1 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 7).ngClassValid; var currVal_6 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_RecipientsSelector_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "tag__wrapper tag__water tag__wrapper_4"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "tag__text inline"], ["spellcheck", "false"]], [[1, "title", 0]], null, null, null, null)), i0.ɵpid(0, i5.RecipientTypePipe, [i6.TranslatePipe]), (_l()(), i0.ɵted(5, null, ["\n        ", "\n      "])), i0.ɵpid(0, i5.RecipientTypePipe, [i6.TranslatePipe]), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("school")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("school")); _ck(_v, 5, 0, currVal_1); }); }
function View_RecipientsSelector_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "tag-input", [["placeholder", ""], ["secondaryPlaceholder", ""], ["theme", "nexedu-tags"]], [[1, "tabindex", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.tenantValue = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TagInputComponent_0, i1.RenderType_TagInputComponent)), i0.ɵdid(1, 4308992, [[1, 4]], 2, i2.TagInputComponent, [i0.Renderer2, i2.ɵc], { displayBy: [0, "displayBy"], identifyBy: [1, "identifyBy"], placeholder: [2, "placeholder"], secondaryPlaceholder: [3, "secondaryPlaceholder"], onlyFromAutocomplete: [4, "onlyFromAutocomplete"], theme: [5, "theme"], disable: [6, "disable"] }, null), i0.ɵqud(335544320, 5, { dropdown: 0 }), i0.ɵqud(603979776, 6, { templates: 1 }), i0.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.TagInputComponent]), i0.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [2, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i0.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [i4.NgControl], null, null), (_l()(), i0.ɵted(-1, 0, ["\n\n  "])), (_l()(), i0.ɵand(0, [[6, 2]], 0, 0, null, View_RecipientsSelector_5)), (_l()(), i0.ɵted(-1, 0, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "itemName"; var currVal_9 = "id"; var currVal_10 = ""; var currVal_11 = ""; var currVal_12 = true; var currVal_13 = "nexedu-tags"; var currVal_14 = true; _ck(_v, 1, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.tenantValue; _ck(_v, 5, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).tabindexAttr; var currVal_1 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 7).ngClassValid; var currVal_6 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_RecipientsSelector_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { tagInput: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecipientsSelector_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecipientsSelector_4)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.readonly || _co.innerValue.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.readonly && !_co.innerValue.length); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_RecipientsSelector_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "recipients-selector", [], null, null, null, View_RecipientsSelector_0, RenderType_RecipientsSelector)), i0.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.RecipientsSelector]), i0.ɵprd(135680, null, i6.TranslatePipe, i6.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef]), i0.ɵdid(3, 49152, null, 0, i7.RecipientsSelector, [i8.DivisionService, i9.StudentService, i10.ViewService], null, null)], null, null); }
var RecipientsSelectorNgFactory = i0.ɵccf("recipients-selector", i7.RecipientsSelector, View_RecipientsSelector_Host_0, { readonly: "readonly", recipientTypes: "recipientTypes", value: "value" }, { onFocus: "onFocus", onBlur: "onBlur", onLoadingStart: "onLoadingStart", onLoadingEnd: "onLoadingEnd" }, []);
export { RecipientsSelectorNgFactory as RecipientsSelectorNgFactory };
