/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../topic-recipients-tab/topic-recipients-tab.ngfactory";
import * as i3 from "../../pipes/short-date.pipe";
import * as i4 from "../topic-recipients-tab/topic-recipients-tab";
import * as i5 from "../../services/topic.service";
import * as i6 from "../../services/view.service";
import * as i7 from "./recipients-list-modal";
import * as i8 from "ngx-bootstrap/modal/bs-modal-ref.service";
var styles_RecipientsListModal = [];
var RenderType_RecipientsListModal = i0.ɵcrt({ encapsulation: 2, styles: styles_RecipientsListModal, data: {} });
export { RenderType_RecipientsListModal as RenderType_RecipientsListModal };
export function View_RecipientsListModal_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 23, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "h5", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(9, 0, null, null, 4, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(16, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(18, 0, null, null, 3, "topic-recipients-tab", [], null, null, null, i2.View_TopicRecipientsTab_0, i2.RenderType_TopicRecipientsTab)), i0.ɵprd(135680, null, i1.TranslatePipe, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), i0.ɵprd(4608, null, i3.ShortDatePipe, i3.ShortDatePipe, []), i0.ɵdid(21, 114688, null, 0, i4.TopicRecipientsTab, [i5.TopicService, i6.ViewService], { topic: [0, "topic"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.topic._id; _ck(_v, 21, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("TOPIC.RECIPIENTS")); _ck(_v, 6, 0, currVal_0); }); }
export function View_RecipientsListModal_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "recipients-list-modal", [], null, null, null, View_RecipientsListModal_0, RenderType_RecipientsListModal)), i0.ɵdid(1, 49152, null, 0, i7.RecipientsListModal, [i8.BsModalRef], null, null)], null, null); }
var RecipientsListModalNgFactory = i0.ɵccf("recipients-list-modal", i7.RecipientsListModal, View_RecipientsListModal_Host_0, {}, {}, []);
export { RecipientsListModalNgFactory as RecipientsListModalNgFactory };
