import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { QueryString } from '../models'
import { CrudService, BatchJob } from '../interfaces'

@Injectable()
export class BatchJobService extends CrudService<BatchJob> {

  constructor (http: HttpClient) {
    super(http, '/batch-jobs', (e) => e) // TODO implement typeDeserializer
  }

  public getErrorsByBatchJob (id: string, params?: QueryString): Observable<any> {
    return this.http.get(`${this.url}/${id}/errors`, this.toRequestOptions(params))
  }

  public exportErrors (id: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/errors/export`, {})
  }
}
