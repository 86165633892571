var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
// @ts-expect-error ts-migrate(6133) FIXME: 'AuthService' is declared but its value is never r... Remove this comment to see the full error message
import { ViewService, StageService, SubjectService, GradeWrapperService } from '../../services';
// @ts-expect-error ts-migrate(6133) FIXME: 'Subject' is declared but its value is never read.
import { Grade, GradeValidator, DetailView } from '../../interfaces';
import { QueryString } from '../../models';
var GradeComponent = /** @class */ (function (_super) {
    __extends(GradeComponent, _super);
    function GradeComponent(viewService, stageService, subjectService, route) {
        var _this = _super.call(this, viewService, new GradeWrapperService(stageService), route, 'stage', GradeValidator.newFormGroup()) || this;
        _this.viewService = viewService;
        _this.stageService = stageService;
        _this.subjectService = subjectService;
        _this.searchSubjects = function (filter) {
            // retain the scope to get access to instance properties
            // https://www.npmjs.com/package/ng2-tag-input#api-for-taginputdropdowncomponent
            var params = new QueryString({
                limit: 20,
                q: filter
            });
            return _this.subjectService.search(params)
                .map(function (result) {
                return result.list;
            }, _this.viewService.handleError);
        };
        _this.options.populate = 'subjects';
        _this.updatePermission = 'update-grade';
        _this.createPermission = 'add-grade';
        _this.deletePermission = 'remove-grade';
        return _this;
    }
    GradeComponent.prototype.getValue = function () {
        return this.form.value;
    };
    GradeComponent.prototype.submit = function () {
        this.form.value.term = this.viewService.getSelectedTerm()._id;
        return _super.prototype.submit.call(this);
    };
    return GradeComponent;
}(DetailView));
export { GradeComponent };
