import { Pipe, PipeTransform } from '@angular/core'

@Pipe({name: 'optional'})
export class OptionalPipe implements PipeTransform {

  transform (value: string): string {
    return value || '-'
  }

}
