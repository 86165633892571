import { environment } from '../../environments/environment'
import { QueryString } from '../models'

export abstract class ApiService {
  protected baseUrl = environment.API_URL
  protected apiUrl = environment.API_URL + environment.API_PATH

  protected toRequestOptions (qs?: QueryString): any {
    if (qs) {
      const params = qs.toSearchParams()
      return { params }
    }
  }
}
