import { FormControl, FormGroup, Validators } from '@angular/forms'


// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common'

export interface Credentials {
  _id: string
  password: string
}

export class CredentialsValidator {

  public static newFormGroup (): FormGroup {
    const _id = new FormControl('', Validators.required)
    const password = new FormControl('', [ Validators.required, Validators.minLength(biz.PASSWORD_MIN_LENGTH) ])

    return new FormGroup({ _id, password })
  }

}
