import { Pipe } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import { PrefixedTranslatePipe } from './prefixed-translate.pipe'

@Pipe({
  name: 'batchJobStatus',
  pure: false
})
export class BatchJobStatusPipe extends PrefixedTranslatePipe {

  constructor(translatePipe: TranslatePipe) {
    super(translatePipe, 'BATCH_JOB_STATUS.')
  }

}
