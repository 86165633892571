<section class="content-header">
  <h1>
    <i class="fa fa-fw fa-{{ pageData.icon }}"></i>
    {{ pageData.title | translate }}
    <a class="btn btn-sm" (click)="getItems()">
      <i class="fa fa-refresh" aria-hidden="true"
          [tooltip]="'COMMON.BTN_REFRESH' | translate"
          placement="bottom"></i>
    </a>
  </h1>

  <div class="btn-toolbar pull-right-md" role="toolbar">
    <div class="btn-group" *ngIf="isCreateAllowed" dropdown placement="bottom right">
      <button
          type="button"
          class="btn btn-primary"
          (click)="uploadFileModal.show('BATCH_JOB.IMPORT_STUDENTS', 'import-students')">
        {{ 'BATCH_JOB.IMPORT_STUDENTS' | translate }}
      </button>
      <button
          type="button"
          dropdownToggle
          class="btn btn-primary dropdown-toggle dropdown-toggle-split">
        <span class="caret"></span>
        <span class="sr-only">Help menu</span>
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
        <li role="menuitem">
          <a class="dropdown-item" href="https://s3-sa-east-1.amazonaws.com/nexedu/templates/nexedu-carga-alumnos.xlsx" target="_blank">{{ 'BATCH_JOB.IMPORT_STUDENTS_SAMPLE' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="content">
  <div class="row search-panel">
    <div class="form-group col-sm-4 col-md-3" *ngIf="batchJobTypes?.length > 2">
      <label>{{ 'BATCH_JOB.TYPE' | translate }}</label>
      <select class="form-control" [(ngModel)]="batchJobType" (change)="onTypeChange()">
        <option *ngFor="let batchJobType of batchJobTypes" [value]="batchJobType">
          {{ batchJobType | batchJobType }}
        </option>
      </select>
    </div>

    <div class="form-group col-sm-4 col-md-3">
      <label>{{ 'BATCH_JOB.STATUS' | translate }}</label>
      <select class="form-control" [(ngModel)]="params.conditions.status" (change)="updateFilter()">
        <option *ngFor="let batchJobStatus of batchJobStatuses" [ngValue]="batchJobStatus">
          {{ batchJobStatus | batchJobStatus }}
        </option>
      </select>
    </div>
  </div>

  <appdatatable
    [rows]="rows"
    [columns]="columns"
    [sorts]="params.sort"
    [count]="count"
    [limit]="params.limit"
    [page]="params.page"
    [loading]="loading"
    (onPage)="onPage($event)"
    (onSort)="onSort($event)">
  </appdatatable>
</section>

<batch-job-file-modal #uploadFileModal="child" (saved)="updateFilter()"></batch-job-file-modal>
