<section class="content">
  <div class="row">

    <div class="col-xs-12 col-sm-6">
      <div class="row">
        <div class="col-xs-12">

          <report-box iconClass="fa-cube" translationEntry="REPORTS_PRODUCT">
            <div class="row">
              <div class="form-group col-sm-6">
                <label>{{ 'COMMON.DATE_START' | translate }}</label>
                <datepicker-popup [(ngModel)]="products.range.start"></datepicker-popup>
              </div>
              <div class="form-group col-sm-6">
                <label>{{ 'COMMON.DATE_END' | translate }}</label>
                <datepicker-popup [(ngModel)]="products.range.end"></datepicker-popup>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <div role="toolbar" class="btn-toolbar">
                  <button [ladda]="products.submitting" class="btn btn-info" (click)="listProducts()">{{ 'COMMON.BTN_GENERATE' | translate }}</button>
                </div>
              </div>
            </div>
          </report-box>

        </div>
      </div>
    </div>

  </div>
</section>
