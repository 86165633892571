import { AbstractControl, ValidatorFn } from '@angular/forms'
import { DateService } from '../services/date.service'

function futureDateOnly (timezone?: string): ValidatorFn {
  const dateService = new DateService()
  return (c: AbstractControl): {[key: string]: any} => {
    const v = c.value
    if (!v) {


      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{ [key: str... Remove this comment to see the full error message
      return null
    }

    const isFuture = dateService.isFuture(v, timezone)
    if (isFuture) {


      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{ [key: str... Remove this comment to see the full error message
      return null
    }
    return { futureDateOnly: true }
  }
}

export default futureDateOnly
