/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-box.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../avatar/avatar.component.ngfactory";
import * as i3 from "../avatar/avatar.component";
import * as i4 from "../user-avatar/user.avatar.ngfactory";
import * as i5 from "../user-avatar/user.avatar";
import * as i6 from "../../services/me.service";
import * as i7 from "@angular/common";
import * as i8 from "@angular/router";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./user-box.component";
import * as i11 from "../../services/auth.service";
import * as i12 from "../../services/view.service";
var styles_UserBoxComponent = [i0.styles];
var RenderType_UserBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserBoxComponent, data: {} });
export { RenderType_UserBoxComponent as RenderType_UserBoxComponent };
function View_UserBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [["class", "user-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 10, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchTenant(_v.context.$implicit.tenant) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "avatar", [["size", "40"]], null, null, null, i2.View_Avatar_0, i2.RenderType_Avatar)), i1.ɵdid(7, 573440, null, 0, i3.Avatar, [], { photo: [0, "photo"], name: [1, "name"], size: [2, "size"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.tenant.logo == null) ? null : _v.context.$implicit.tenant.logo.secure_url); var currVal_1 = _v.context.$implicit.tenant.name; var currVal_2 = "40"; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.tenant.name; _ck(_v, 11, 0, currVal_3); }); }
export function View_UserBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["class", "dropdown-toggle"], ["data-toggle", "dropdown"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "user-avatar", [["class", "user-image"], ["size", "25"]], null, null, null, i4.View_UserAvatar_0, i4.RenderType_UserAvatar)), i1.ɵdid(6, 114688, null, 0, i5.UserAvatar, [i6.MeService], { size: [0, "size"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(11, 0, null, null, 26, "ul", [["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserBoxComponent_1)), i1.ɵdid(15, 802816, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(18, 0, null, null, 8, "li", [["class", "user-footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "a", [["routerLink", "/password"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "fa fa-fw fa-key"]], null, null, null, null, null)), (_l()(), i1.ɵted(24, null, ["", "\n    "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(28, 0, null, null, 7, "li", [["class", "user-footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(30, 0, null, null, 4, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(32, 0, null, null, 0, "i", [["class", "fa fa-fw fa-sign-out"]], null, null, null, null, null)), (_l()(), i1.ɵted(33, null, ["", "\n    "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "25"; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.currentUser.memberships; _ck(_v, 15, 0, currVal_1); var currVal_4 = "/password"; _ck(_v, 21, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 21).target; var currVal_3 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform("USERBOX.LNK_CHANGEPASS")); _ck(_v, 24, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 33, 0, i1.ɵnov(_v, 34).transform("USERBOX.LNK_SIGNOUT")); _ck(_v, 33, 0, currVal_6); }); }
export function View_UserBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "userbox"]], null, null, null, View_UserBoxComponent_0, RenderType_UserBoxComponent)), i1.ɵdid(1, 49152, null, 0, i10.UserBoxComponent, [i11.AuthService, i6.MeService, i12.ViewService], null, null)], null, null); }
var UserBoxComponentNgFactory = i1.ɵccf(".userBox", i10.UserBoxComponent, View_UserBoxComponent_Host_0, {}, {}, []);
export { UserBoxComponentNgFactory as UserBoxComponentNgFactory };
