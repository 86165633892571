import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs/Rx'
import { AuthService } from '../../services'
import { Term, TermAndStage } from '../../interfaces'

@Component({
  selector: '.termPicker',
  templateUrl: './term-picker.component.html'
})
export class TermPickerComponent implements OnInit, OnDestroy {


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'selectedTerm' has no initializer and is ... Remove this comment to see the full error message
  public selectedTerm: Term


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'terms' has no initializer and is not def... Remove this comment to see the full error message
  public terms: Term[]


  // @ts-expect-error ts-migrate(2564) FIXME: Property 'stageSubscription' has no initializer an... Remove this comment to see the full error message
  private stageSubscription: Subscription

  constructor (private authService: AuthService) {}

  ngOnInit () {
    this.terms = this.authService.getTerms()
    this.stageSubscription = this.authService.subscribeToStage(this.onTermAndStageChange.bind(this))
  }

  ngOnDestroy () {
    if (this.stageSubscription) {
      this.stageSubscription.unsubscribe()
    }
  }

  public changeSelectedTerm (term: Term) {
    this.authService.changeSelectedTerm(term._id)
  }

  private onTermAndStageChange (data: TermAndStage) {
    if (data) {
      this.selectedTerm = data.term
    }
  }
}
