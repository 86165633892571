var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'nexe... Remove this comment to see the full error message
import { biz } from 'nexedu-common';
import { StudentService, ViewService } from '../../services';
import { DetailView, UserValidator } from '../../interfaces';
var StudentComponent = /** @class */ (function (_super) {
    __extends(StudentComponent, _super);
    function StudentComponent(viewService, service, route) {
        var _this = _super.call(this, viewService, service, route, 'student', UserValidator.newStudentFormGroup(), true) || this;
        _this.deletePermission = 'remove';
        var user = viewService.getCurrentUser();
        _this.isFeedAllowed = user.isSuperuser || user.currentRole === biz.ROLES.OWNER || user.currentRole === biz.ROLES.SUPERVISOR;
        return _this;
    }
    StudentComponent.prototype.getValue = function () {
        return this.form.value;
    };
    StudentComponent.prototype.getDefaultDetails = function () {
        return {
            gender: biz.GENDERS.FEMALE
        };
    };
    StudentComponent.prototype.checkPermissions = function () {
        _super.prototype.checkPermissions.call(this);
        this.isForcePasswordAllowed = !this.isCreate && this.viewService.isAllowed('tenant', 'force-member-password');
    };
    return StudentComponent;
}(DetailView));
export { StudentComponent };
